import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ParamedicService } from 'src/app/services';
import { Location } from '@angular/common';
import { Paramedic } from 'src/app/models/paramedic';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { environment } from '../../../../../../environments/environment';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-paramedico-editar',
  templateUrl: './paramedico-editar.component.html',
  styleUrls: ['./paramedico-editar.component.css']
})
export class ParamedicoEditarComponent implements OnInit {

  data: any;
  paramedico: Paramedic;
  paramedicoId: number;
  photoTemp: any;
  ineTemp: any;
  licenseTemp: any;
  certificateTemp: any;
  loader: boolean = false;

  nombresFormControl = new FormControl('', [
    Validators.required,
  ])

  apePaternoFormControl = new FormControl('', [
    Validators.required,
  ])

  apeMaternoFormControl = new FormControl('', [
    Validators.required,
  ]);

  celFormControl = new FormControl('', [
    Validators.required,
  ])

  telFormControl = new FormControl('', [
    Validators.required,
  ])

  direccionFormControl = new FormControl('', [
    Validators.required,
  ])

  dateBirthFormControl = new FormControl('', [
    Validators.required,
  ])

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public activatedRoute: ActivatedRoute,
    private _paramedicServie: ParamedicService,
    public location: Location,
    public dialog: MatDialog,
    private _router: Router,
    private _snackBar: MatSnackBar
    ) {
      this.photoTemp = "/assets/images/admin/upload-image.png";
      this.ineTemp = "/assets/images/admin/upload-image.png";
      this.licenseTemp = "/assets/images/admin/upload-image.png";
      this.certificateTemp = "/assets/images/admin/upload-image.png";
      this.paramedico = new Paramedic();
    }

  ngOnInit(): void {
    this.obtenerParamedico();
  }

  onFileSelectedPhoto(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.photoTemp = reader.result;
      this.paramedico.photo = reader.result;
    };

  }

  verFoto() {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${this.paramedico.photo}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  onFileSelectedIne(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.ineTemp = reader.result;
      this.paramedico.ine = reader.result;
    };

  }

  verIne() {
    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: this.paramedico.ine
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  onFileSelectedLicense(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.licenseTemp = reader.result;
      this.paramedico.license = reader.result;
    };

  }

  verLicencia() {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: this.paramedico.license
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  onFileSelectedCertificate(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.certificateTemp = reader.result;
      this.paramedico.certificate = reader.result;
    };

  }

  verCertificado() {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: this.paramedico.certificate
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  obtenerParamedico() {
    this.activatedRoute.params.subscribe( params => {

      let id = params['id'];
      this.paramedicoId = id;

      this._paramedicServie.getParamedic(id).subscribe(
        response => {
          console.log(response)
          this.paramedico.name = response.data.professional.user.name;
          this.paramedico.lastnamepat = response.data.professional.user.lastnamepat;
          this.paramedico.lastnamemat = response.data.professional.user.lastnamemat;
          this.paramedico.birth_date = response.data.professional.user.birth_date;
          this.paramedico.email = response.data.professional.user.email;
          this.paramedico.gender = response.data.professional.user.gender;
          this.paramedico.telephone = response.data.professional.user.telephone;
          this.paramedico.address = response.data.professional.user.address;
          this.paramedico.institution = response.data.institution;
          this.paramedico.phone = response.data.professional.user.phone;
          this.paramedico.photo = `${environment.sourceUrl}${response.data.professional.user.image}`;
          this.paramedico.ine = `${environment.sourceUrl}${response.data.professional.ine}`;
          this.paramedico.certificate = `${environment.sourceUrl}${response.data.certificate}`;
          this.paramedico.license = `${environment.sourceUrl}${response.data.license}`;
        },
        error => {
          console.log(error)
        }
      )

    });
  }

  volver() {
    this._router.navigate(['/usuario/profesional/paramedicos']);
  }

  editarParamedico(f: NgForm) {
    this.loader = true;

    console.log(this.paramedico);
    this._paramedicServie.updateParamedic(this.paramedicoId, this.paramedico).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('Paramedico actualizado');
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.loader = false;
        this.openError('Error al actualizar paramédico', 'Ok');
      }
    )

  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
