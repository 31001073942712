import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EmployeeService, UserService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Employee } from 'src/app/models/employee';
import { Password } from 'src/app/admin/configuracion/configuracion.component';
import { NgForm } from '@angular/forms';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UploadImage } from 'src/app/models/upload_image';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { Company } from 'src/app/models/company';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-colaborador-ver',
  templateUrl: './colaborador-ver.component.html',
  styleUrls: ['./colaborador-ver.component.css']
})
export class ColaboradorVerComponent implements OnInit {

  hide = true;
  hideC = true;
  hideA = true;

  loaderUpdPwd = false;

  uploadImage: UploadImage;

  sourceUrl = environment.sourceUrl;

  tempImage: any;

  displayedColumns: string[] = ['id', 'service', 'professional', 'paramedic', 'date', 'action'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  employee: Employee;
  company: Company;
  employeeId: any;
  userId: any;

  password: Password;

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    private _employeeService: EmployeeService,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _router: Router,
    public dialog: MatDialog,
    public location: Location
  ) {
    this.employee = new Employee();
    this.company = new Company();
    this.password = new Password('','','');
    this.uploadImage = new UploadImage();
  }

  ngOnInit(): void {

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.obtenerPaciente();
  }

  applyFilter(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerPaciente() {
    this._activatedRoute.params.subscribe(
      params => {
        this.employeeId = params['id'];

        this._employeeService.getEmployee(this.employeeId).subscribe(
          response => {
            console.log('EMPLEADO ', response)
            this.employee.name = response.data.patient.user.name;
            this.employee.lastnamepat = `${response.data.patient.user.lastnamepat}`;
            this.employee.email = response.data.patient.user.email;
            this.employee.telephone = response.data.patient.user.telephone;
            this.employee.birth_date = response.data.patient.user.birth_date;
            this.employee.address = response.data.patient.user.address;
            this.employee.image = response.data.patient.user.image;
            this.employee.status = response.data.status == 'activado' ? true: false;
            this.userId = response.data.patient.user.id;
            this.tempImage = response.data.patient.user.image;
            this.company = response.data.company;
            this.employee.status = response.data.status;

            this.dataSource = fillTable(response.data.attentions, this.paginator, this.sort);
          },
          error => {
            this.openError('Error al obtener paciente', 'Ok');
          }
        )
      }
    )
  }

  actualizarContrasenia(f: NgForm) {
    this.loaderUpdPwd = true;
    this._userService.updatePassword(this.userId, this.password).subscribe(
      response => {
        this.loaderUpdPwd = false;
        if(response.code == 404) {
          this.openError('Error al actualizar contraseña', 'Ok');
        }else {
          f.resetForm();
          this.mostrarExitoDialog('Contraseña actualizada');
        }
      },
      error => {
        this.loaderUpdPwd = false;
        this.openError('Error al actualizar contraseña', 'Ok');
      }
    )
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.uploadImage.image = reader.result;
      this.tempImage = reader.result;
    };
  }

  guardarImagen() {

    this._userService.uploadImage(this.userId, this.uploadImage).subscribe(
      response => {
        console.log('response', response);
        console.log('response', response.data.image)
        this.tempImage = response.data.image;
        this.uploadImage.image = null;
        this.employee.image = response.data.image;

        this.mostrarExitoDialog('Imagen actualizada')

      },
      error => {
        console.log('error', error);
      }
    )
  }

  cancelar() {
    this.tempImage = this.employee.image;
    this.uploadImage.image = null;
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  volver() {
    this._router.navigate(['/usuario/paciente/empresas']);
  }
}
