import { Component, OnInit } from '@angular/core';
import { AdministrativeService, UserService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Administrative } from 'src/app/models/administrative';
import { NgForm } from '@angular/forms';
import { Password } from 'src/app/admin/configuracion/configuracion.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { environment } from 'src/environments/environment';
import { UploadImage } from 'src/app/models/upload_image';
import { Location } from '@angular/common';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-administrativo-ver',
  templateUrl: './administrativo-ver.component.html',
  styleUrls: ['./administrativo-ver.component.css']
})
export class AdministrativoVerComponent implements OnInit {

  hide = true;
  hideC = true;
  hideA = true;

  loaderUpdPwd = false;
  userId:any;

  password: Password;

  administrative: Administrative;
  administrativeId: any;

  sourceUrl = environment.sourceUrl;

  uploadImage: UploadImage;
  tempImage: any;

  role: any;

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    private _administrativeService: AdministrativeService,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _router: Router,
    public dialog: MatDialog,
    public location: Location
  ) {
    this.administrative = new Administrative();
    this.password = new Password('', '', '');
    this.uploadImage = new UploadImage();
    this.role = JSON.parse(localStorage.getItem('role')).name;
  }

  ngOnInit(): void {
    this.obtenerAdministrador();
  }

  obtenerAdministrador(): void {
    this._activatedRoute.params.subscribe(
      params => {
        this.administrativeId = params['id'];

        this._administrativeService.getAdministrative(this.administrativeId).subscribe(
          response => {
            console.log('administrador', response);
            this.administrative.name = response.data.user.name;
            this.administrative.lastnamepat = `${response.data.user.lastnamepat}`;
            this.administrative.email = response.data.user.email;
            this.administrative.telephone = response.data.user.telephone;
            this.administrative.birth_date = response.data.user.birth_date;
            this.administrative.address = response.data.user.address;
            this.administrative.image = response.data.user.image;
            this.administrative.status = response.data.status;
            this.tempImage = response.data.user.image;
            this.userId = response.data.user.id;
          },
          error => {
            this.openError('Error al obtener administrador', 'Ok');
          }
        )
      }
    )
  }

  actualizarContrasenia(f: NgForm) {
    this.loaderUpdPwd = true;
    this._userService.updatePassword(this.userId, this.password).subscribe(
      response => {
        this.loaderUpdPwd = false;
        if(response.code == 404) {
          this.openError('Error al actualizar contraseña', 'Ok');
        }else {
          f.resetForm();
          this.mostrarExitoDialog('Contraseña actualizada');
        }
      },
      error => {
        this.loaderUpdPwd = false;
        this.openError('Error al actualizar contraseña', 'Ok');
      }
    )
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.uploadImage.image = reader.result;
      this.tempImage = reader.result;
    };
  }

  guardarImagen() {

    this._userService.uploadImage(this.userId, this.uploadImage).subscribe(
      response => {
        console.log('response', response);
        console.log('response', response.data.image)
        this.tempImage = response.data.image;
        this.uploadImage.image = null;
        this.administrative.image = response.data.image;

        this.mostrarExitoDialog('Imagen actualizada')

      },
      error => {
        console.log('error', error);
      }
    )
  }

  cancelar() {
    this.tempImage = this.administrative.image;
    this.uploadImage.image = null;
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  volver() {
    this._router.navigate(['/usuario/administrativo']);
  }

}
