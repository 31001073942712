<div>
    
    <h2 style="text-align: center;">Editar Tarea</h2>

    <form #f="ngForm" ngNativeValidate (ngSubmit)="editarTarea(f)" (keydown.enter)="$event.preventDefault()">
        <div mat-dialog-content>
    
            <mat-form-field appearance="legacy">
                <mat-label>Tarea</mat-label>
                <input matInput placeholder="Tarea" name="tarea" [(ngModel)]="tarea.name" required>
            </mat-form-field>
            
            <mat-form-field class="example-full-width">
                <mat-label>Notas (opcional)</mat-label>
                <textarea matInput placeholder="Notas" name="descripcion" [(ngModel)]="tarea.description"></textarea>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>Asignado a</mat-label>
                <mat-select [(ngModel)]="tarea.user_id" name="asignado" required>
                    <mat-option *ngFor="let usuario of usuarios" [value]="usuario.identifier">
                        {{usuario.user.name}}
                        {{usuario.user.lastnamepat}}
                        {{usuario.user.lastnamemat}}
                        <b>{{usuario.type}}</b>
                    </mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>Fecha</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="tarea.date" name="fecha">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="legacy">
                <mat-label>Hora (opcional)</mat-label>
                <input matInput placeholder="Hora" type="time" [(ngModel)]="tarea.hour" name="hour">
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>Prioridad</mat-label>
                <mat-select [(ngModel)]="tarea.priority" name="prioridad" required>
                    <mat-option value="critico">
                        Crítico
                    </mat-option>
                    <mat-option value="alto">
                        Alto
                    </mat-option>
                    <mat-option value="normal">
                        Normal
                    </mat-option>
                    <mat-option value="bajo">
                        Bajo
                    </mat-option>
                    <mat-option value="menor">
                        Menor
                    </mat-option>
    
                </mat-select>
            </mat-form-field>
        </div>
    
        <div mat-dialog-actions style="float: right;" *ngIf="!loader">
            
            <button class="btn-yes" mat-button mat-dialog-close="true" cdkFocusInitial> Cancelar </button>
            <button class="btn-no" mat-button cdkFocusInitial color="primary" type="submit"> Actualizar </button>
    
        </div>

        <div class="row" *ngIf="loader">
            <mat-spinner diameter="40"></mat-spinner>
        </div>
    </form>

</div>