import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { PrivateService } from 'src/app/services';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-privado',
  templateUrl: './privado.component.html',
  styleUrls: ['./privado.component.css']
})
export class PrivadoComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'id', 'sku', 'name', 'gender', 'phone', 'birth_date', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  url = environment.sourceUrl;

  loader: boolean = true;

  privados$: Subscription;
  deletePrivado$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _privateService: PrivateService,
    private _snackBar: MatSnackBar
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.obtenerPrivados();
  }

  buscarPrivado(event: Event) {

    this.dataSource = searchInTable( event, this.dataSource );

  }

  getAge(dateString)
  {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
      {
          age--;
      }
      return age;
  }

  obtenerPrivados() {
    this.dataSource = fillTable([], this.paginator, this.sort);
    this.privados$ = this._privateService.getPrivates().subscribe(
      response => {
        console.log(response)
        this.dataSource = fillTable(response.data.reverse(), this.paginator, this.sort);

      },
      error => {
        this.openError('Error al obtener pacientes privados', 'Ok')
      }
    )
  }

  cambiarActivacion(element) {
    console.log('element', element)
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: `¿Desea ${element.status == 'activado' ? 'inactivar' : 'activar'} a este paciente privado?`,
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this.deletePrivado$ = this._privateService.deletePrivate(element.identifier, false).subscribe(
          response => {
            this.obtenerPrivados();

          },
          error => {
            this.obtenerPrivados();
            this.openError('Error en la activación', 'Ok');
          }
        )

      } else {
        this.obtenerPrivados();
      }

    })
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  eliminarPrivado(id) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este paciente?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this.deletePrivado$ = this._privateService.deletePrivate(id, true).subscribe(
          response => {
            this.obtenerPrivados();
            this.openError('Paciente Eliminado', 'Ok');
          },
          error => {

          }
        )

      }
    })
  }

  ngOnDestroy(): void {
    if(this.privados$) this.privados$.unsubscribe();

    if(this.deletePrivado$) this.deletePrivado$.unsubscribe();
  }
}
