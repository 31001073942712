<h1 mat-dialog-title>Agregar Correo administrativo</h1>
<form #f="ngForm" ngNativeValidate (ngSubmit)="registrarCorreo(f)" (keydown.enter)="$event.preventDefault()">
    <div mat-dialog-content>
      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input required [(ngModel)]="data.name" name="name" matInput required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Correo Electrónico</mat-label>
        <input type="email" matInput required [(ngModel)]="data.email" name="email">
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()" type="button">Cancelar</button>
      <button mat-button type="submit">Registrar</button>
    </div>
</form>