import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { AdministrativeService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-administrativo',
  templateUrl: './administrativo.component.html',
  styleUrls: ['./administrativo.component.css']
})
export class AdministrativoComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'id', 'name', 'gender', 'birth_date', 'role_name', 'phone', 'telephone', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  url = environment.sourceUrl;

  loader: boolean = true;

  administrativos$: Subscription;
  deleteAdmin$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _adminService: AdministrativeService,
    private _snackBar: MatSnackBar
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerAdministrativos();
  }

  getAge(dateString)
  {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
      {
          age--;
      }
      return age;
  }

  buscarAdministrativo(event: Event) {
    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerAdministrativos() {
    this.loader = true;
    this.administrativos$ = this._adminService.getAdministratives().subscribe(
      response => {
        console.log(response)
        this.loader = false;

        this.dataSource = fillTable(response.data.reverse(), this.paginator, this.sort);

      },
      error => {
        this.openError("Error al obtener administrativos", 'OK');
      }
    )
  }

  cambiarActivacion(element) {
    console.log(element)
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea cambiar la activación del profesional radiólogo?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this.deleteAdmin$ = this._adminService.deleteAdministrative(element.identifier, false).subscribe(
          response => {
            this.obtenerAdministrativos();
          },
          error => {
            this.obtenerAdministrativos();
            this.openError('Error en el cambio de estado', 'Ok');
          }
        )

      } else {
        this.obtenerAdministrativos();
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  ngOnDestroy(): void {
    if(this.administrativos$) this.administrativos$.unsubscribe();

    if(this.deleteAdmin$) this.deleteAdmin$.unsubscribe();
  }

}
