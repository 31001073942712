import { Component, OnInit } from '@angular/core';
import { Task } from 'src/app/models/task';
import { MatDialog } from '@angular/material/dialog';
import { TaskService } from 'src/app/services/task.service';
import { SuccessComponent } from '../../success/success.component';
import { ErrorComponent } from '../../error/error.component';
import { AdministrativeService } from 'src/app/services/administrative.service';
import { ProfessionalService } from 'src/app/services/professional.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-new-task',
  templateUrl: './new-task.component.html',
  styleUrls: ['./new-task.component.css']
})
export class NewTaskComponent implements OnInit {

  tarea: Task;
  usuarios: any = [];
  loader = false;

  constructor(
    public dialog: MatDialog,
    private _taskService: TaskService,
    private _administrativeService: AdministrativeService,
    private _professionalService: ProfessionalService
  ) {
    this.tarea = new Task();
  }

  ngOnInit(): void {
    this.obtenerUsuarios();
  }

  registrarTarea(f: NgForm) {

    this.loader = true;
    console.log('tarea', this.tarea)
    this._taskService.saveTask(this.tarea).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('Tarea registrada');
        f.resetForm();
        this.dialog.closeAll();
      },
      error => {
        this.loader = false;
        this.mostrarErrorDialog('Error al registrar una tarea');
      }
    );

  }

  obtenerUsuarios() {
    this._administrativeService.getAdministratives().subscribe(
      administrativos => {
        this._professionalService.getProfessionals().subscribe(
          profesionales => {
            this.usuarios = administrativos.data.concat(profesionales.data)
            console.log('usaurios', this.usuarios)
          },
          error => {
            this.mostrarErrorDialog('No se encontraron usuarios');
          }
        )
      },
      error => {
        this.mostrarErrorDialog('No se encontraron usuarios');
      }
    )
  }

  mostrarExitoDialog(message) {
		const dialogRef = this.dialog.open(SuccessComponent, {
			width: '300px',
			data: message
		})

		dialogRef.afterClosed().subscribe(result => {
			if(result) {
			}
		})
	}

	mostrarErrorDialog(message) {
		const dialogRef = this.dialog.open(ErrorComponent, {
			width: '300px',
			data: message
		})

		dialogRef.afterClosed().subscribe(result => {
			if(result) {
			}
		})
	}

}
