<section>
    <div class="row">
        <div class="col s1"></div>
        <div class="col s10">

            <div class="vex_soluciones_tiles">
                <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
                <h2 class="vex_soluciones_subtitle">ATENCIÓN MÉDICA</h2>

                <button class="vex-souluciones-back" mat-raised-button (click)="volver()">{{buttonName.BACK}}</button>

            </div>

            <div>

                <mat-tab-group>
                    <mat-tab label="DATOS GENERALES">
                        <div class="row info-padding-top">
                            <div class="col s3 p-l-0">
                                <mat-card>
                                    <div class="center">
                                        <img class="vex-soluciones-img-profile" [src]="sourceUrl + imgPatient" alt="" width="120" height="120">
                                    </div>
                                    <h3 class="center m-b-0 vex-soluciones-name-title">{{atencion.patient_id}}</h3>
                                    <h4 class="center m-t-0 vex-soluciones-email-title">Paciente</h4>

                                </mat-card>
                                <br>
                                <mat-card class="card-paramedico">
                                    <div class="center">
                                        <img class="vex-soluciones-img-profile" [src]="sourceUrl + imgParamedic" alt="" width="120" height="120">
                                    </div>
                                    <h3 class="center m-b-0 vex-soluciones-name-title-white">{{atencion.paramedic_id}}</h3>
                                    <h4 class="center m-t-0 vex-soluciones-email-title-white">Paramédico</h4>

                                </mat-card>
                            </div>
                            <div class="col s9 p-r-0">
                                <mat-card>
                                    <h4>Atención médica</h4>
                                    <mat-divider></mat-divider>

                                    <div class="row info-padding-top">
                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Tipo de Paciente</mat-label>
                                                <mat-select [(ngModel)]="atencion.type_patient" name="type_patient" disabled>
                                                    <mat-option value="privado">
                                                        Privado
                                                    </mat-option>
                                                    <mat-option value="empresa">
                                                        Empresa
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Fecha y Hora</mat-label>
                                                <input [(ngModel)]="atencion.date" name="date" matInput [matDatepicker]="picker" disabled>
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>

                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Origen</mat-label>
                                                <input [(ngModel)]="origen" name="origin" matInput placeholder="Origen" disabled>
                                            </mat-form-field>

                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Destino</mat-label>
                                                <input matInput placeholder="Destino" [(ngModel)]="destino" name="intermediates" disabled>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Profesionales</mat-label>
                                                <textarea matInput placeholder="Profesionales" [(ngModel)]="atencion.professionals_id" name="professionals_id" disabled></textarea>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Paramédico</mat-label>
                                                <textarea matInput placeholder="Paramédico" [(ngModel)]="atencion.paramedic_id" name="paramedic_id" disabled></textarea>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Detalles de la atencion</mat-label>
                                                <textarea matInput placeholder="Detalles de la atención" [(ngModel)]="atencion.detail" name="detail" disabled></textarea>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Vehículo</mat-label>
                                                <textarea matInput placeholder="Vehículo" [(ngModel)]="atencion.vehicle_id" name="vehicle_id" disabled></textarea>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Servicios</mat-label>
                                                <textarea matInput placeholder="Servicios" [(ngModel)]="atencion.service_id" name="service_id" disabled></textarea>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Servicios adicionales</mat-label>
                                                <textarea matInput placeholder="Servicios adicionales" [(ngModel)]="atencion.aditional_service_id" name="aditional_service_id" disabled></textarea>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Estado</mat-label>
                                                <input matInput placeholder="Estado" [(ngModel)]="atencion.status" name="status" disabled>
                                            </mat-form-field>
                                        </div>

                                    </div>

                                </mat-card>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                          <div class="col s12">
                            <mat-card>
                              <h4>Recorrido en el mapa</h4>
                              <mat-divider></mat-divider>

                              <div class="row info-padding-top">
                                  <div class="col s12">
                                      <agm-map zoom="15" [latitude]="lat" [longitude]="lng">
                                          <agm-direction [origin]="waypoints[0].location" [waypoints]="intermedios" [destination]="waypoints[waypoints.length - 1].location">
                                          </agm-direction>
                                      </agm-map>
                                  </div>
                              </div>
                          </mat-card>
                          </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="" disabled>
                        <div class="row info-padding-top">
                            <div class="col s12 p-r-0 p-l-0">
                                <mat-card>
                                    <h4>Recorrido en el mapa</h4>
                                    <mat-divider></mat-divider>

                                    <div class="row info-padding-top">
                                        <div class="col s12">
                                            <agm-map zoom="15" [latitude]="lat" [longitude]="lng">
                                                <agm-direction [origin]="waypoints[0].location" [waypoints]="intermedios" [destination]="waypoints[waypoints.length - 1].location">
                                                </agm-direction>
                                            </agm-map>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </mat-tab>

                </mat-tab-group>
            </div>

        </div>
        <div class="col s1"></div>
    </div>

</section>
