import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.css']
})
export class EmpresasComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'identifier', 'name', 'affiliation_number', 'address', 'phone', 'representative_name', 'representative_phone', 'representative_address', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  sourceUrl = environment.sourceUrl;

  loader: boolean = false;

  empresas$: Subscription;
  deleteEmpresa$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _companyService: CompanyService,
    private _snackBar: MatSnackBar
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerEmpresas();
  }

  buscarEmpresa(event: Event) {
    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerEmpresas() {
    this.loader = true;
    this.empresas$ = this._companyService.getCompanies().subscribe(
      response => {
        this.loader = false;

        this.dataSource = fillTable(response.data, this.paginator, this.sort);
      },
      error => {
        this.loader = false;
        this.openError('Error al obtener empressas', 'Ok');
      }
    )
  }

  cambiarActivacion(empresa) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea cambiar la activacion de esta empresa?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this.deleteEmpresa$ = this._companyService.deleteCompany(empresa.identifier).subscribe(
          response => {
            this.obtenerEmpresas();
          },
          error => {
            this.obtenerEmpresas();
          }
        )

      }else {
        this.obtenerEmpresas();
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  ngOnDestroy(): void {
    if(this.empresas$) this.empresas$.unsubscribe();

    if(this.deleteEmpresa$) this.deleteEmpresa$.unsubscribe();
  }

}
