<section>

    <div class="vex_soluciones_tiles">
        <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
        <h2 class="vex_soluciones_subtitle">PARAMÉDICO</h2>
    </div>

    <div class="vex_soluciones_content">
        <div align="end">
            <mat-form-field>
              <mat-label>Buscador</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" name="searcher" #input>
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            &nbsp;
            <button mat-raised-button color="primary" [routerLink]="['/usuario/profesional/paramedicos-nuevo']">
              <mat-icon>add</mat-icon>
              Agregar
            </button>
        </div>
        <div class="mat-elevation-z0">
            <mat-card class="mat-elevation-z0 sc_card_more_options">
                Todos los paramédicos
                <button class="sc_more_options" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button
                        mat-menu-item
                        class="sc_more_options"
                        color="primary"
                        (click)="exporter.exportTable('xlsx', {fileName:'paramedicos'})">
                        <mat-icon>cloud_download</mat-icon>
                        <span>Exportar a Excel</span>
                    </button>

                </mat-menu>
            </mat-card>
            <table mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter">

                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-slide-toggle (click)="cambiarActivacion(row)" color="primary" [checked]="row.active == 'activado'"></mat-slide-toggle>

                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> PROFESIONAL </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CORREO ELECTRÓNICO </th>
                    <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CELULAR </th>
                    <td mat-cell *matCellDef="let row"> {{row.phone}} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pin-size-th"> ACCIONES </th>
                    <td mat-cell *matCellDef="let row">
                        <div>
                            <button color="primary" mat-icon-button [routerLink]="['/ver-profesional-paramedico', row.identifier]">
                                <mat-icon>visibility</mat-icon>
                            </button>
                            &nbsp;
                            <button color="primary" [routerLink]="['/usuario/profesional/paramedicos-editar', row.identifier]" mat-icon-button>
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="2"></td>

                    <td class="mat-cell" colspan="1" *ngIf="!loader">No se encontraron registros</td>

                    <td class="mat-cell" colspan="1" *ngIf="loader">
                        <mat-spinner diameter="40"></mat-spinner>
                    </td>

                    <td class="mat-cell" colspan="2"></td>
                </tr>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        </div>

    </div>

</section>
