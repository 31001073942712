<section>

    <vex-section-title title="ADMINISTRACIÓN" subtitle="NUEVO SERVICIO"></vex-section-title>

    <div class="vex_soluciones_content">
        <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarServicio(f)" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col s12 m6 l4">
                    <mat-card>
                        <h3>Datos</h3>
                        <mat-form-field appearance="legacy">
                            <mat-label>Nombre</mat-label>
                            <input [formControl]="nombreFormControl" [errorStateMatcher]="matcher" matInput placeholder="Nombre" name="name" [(ngModel)]="servicio.name" required>
                            <mat-error *ngIf="nombreFormControl.hasError('required')">
                                Nombre <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <mat-label>Descripción</mat-label>
                            <textarea [formControl]="descripcionFormControl" [errorStateMatcher]="matcher" matInput placeholder="Descripción" name="description" [(ngModel)]="servicio.description" required></textarea>
                            <mat-error *ngIf="descripcionFormControl.hasError('required')">
                                Descripción <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <div class="row">
                            <div class="col s12" *ngIf="!loader">
                                <button mat-raised-button (click)="volver()" type="button">{{buttonName.BACK}}</button>
                                &nbsp;
                                <button mat-raised-button color="primary" type="submit">{{buttonName.STORE}}</button>
                            </div>
                            <div class="col s12" *ngIf="loader">
                                <mat-spinner diameter="40"></mat-spinner>
                            </div>
                        </div>
                    </mat-card>
                </div>

            </div>

        </form>

    </div>
</section>
