<section>

    <div class="vex_soluciones_tiles">
        <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
        <h2 class="vex_soluciones_subtitle">NUEVO PACIENTE DE EMPRESA</h2>
    </div>

    <div class="vex_soluciones_content">

        <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarEmpleado(f)" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col s12 m4 l4 p-l-0">

                    <mat-card>
                        <h3>Datos Personales</h3>
                        <mat-form-field appearance="legacy">
                            <mat-label>Nombre</mat-label>
                            <input name="name" [formControl]="nombresFormControl" [errorStateMatcher]="matcher" matInput placeholder="Nombre" [(ngModel)]="empleado.name" required>
                            <mat-error *ngIf="nombresFormControl.hasError('required')">
                                Nombres <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Apellido Paterno</mat-label>
                            <input name="apePaterno" [formControl]="apeMaternoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Apellido Paterno" [(ngModel)]="empleado.lastnamepat" required>
                            <mat-error *ngIf="apeMaternoFormControl.hasError('required')">
                                Apellido Paterno <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Apellido Materno</mat-label>
                            <input name="apeMaterno" [formControl]="apePaternoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Apellido Materno" [(ngModel)]="empleado.lastnamemat" required>
                            <mat-error *ngIf="apePaternoFormControl.hasError('required')">
                                Apellido Materno <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Correo Electrónico</mat-label>
                            <input name="email" [formControl]="emailFormControl" [errorStateMatcher]="matcher" matInput placeholder="Correo Electrónico" [(ngModel)]="empleado.email" required>
                            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                Por favor ingrese un correo electrónico valido
                            </mat-error>
                            <mat-error *ngIf="emailFormControl.hasError('required')">
                                Correo electrónico <strong>requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Constraseña</mat-label>
                            <input autocomplete="new-password" name="password" [formControl]="passFormControl" [errorStateMatcher]="matcher" matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="empleado.password" required>
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="passFormControl.hasError('required')">
                                Contraseña <strong>Requerida</strong>
                            </mat-error>
                            <mat-error *ngIf="!passFormControl.hasError('minLength')">
                                Minimo caracteres <strong>6</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Confirmar Constraseña</mat-label>
                            <input name="password_confirmation" [formControl]="passConfFormControl" [errorStateMatcher]="matcher" matInput [type]="hideC ? 'password' : 'text'" [(ngModel)]="empleado.password_confirmation" required>
                            <button mat-icon-button matSuffix (click)="hideC = !hideC" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideC">
                            <mat-icon>{{hideC ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="passConfFormControl.hasError('required')">
                                Confirmación de Contraseña <strong>Requerida</strong>
                            </mat-error>
                            <mat-error *ngIf="passwordMatcher">
                                Confirmación de Contraseña <strong>No coincide</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Celular</mat-label>
                            <input name="cel" [formControl]="celFormControl" [errorStateMatcher]="matcher" matInput placeholder="Celular" [(ngModel)]="empleado.phone" required>
                            <mat-error *ngIf="celFormControl.hasError('required')">
                                Celular <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Teléfono</mat-label>
                            <input name="tel" [formControl]="telFormControl" [errorStateMatcher]="matcher" matInput placeholder="Teléfono" [(ngModel)]="empleado.telephone" required>
                            <mat-error *ngIf="telFormControl.hasError('required')">
                                Teléfono <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Dirección</mat-label>
                            <input name="address" [formControl]="direccionFormControl" [errorStateMatcher]="matcher" matInput placeholder="Dirección" [(ngModel)]="empleado.address" required>
                            <mat-error *ngIf="direccionFormControl.hasError('required')">
                                Dirección <strong>Requerida</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Fecha de nacimiento</mat-label>
                            <input name="dateBirth" [formControl]="dateBirthFormControl" [errorStateMatcher]="matcher" (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="empleado.birth_date" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="dateBirthFormControl.hasError('required')">
                                Fecha de nacimiento <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Género</mat-label>
                            <mat-select required name="gender">
                                <mat-option value="Masculino" (click)="empleado.gender = 'Masculino'">
                                    Masculino
                                </mat-option>
                                <mat-option value="Femenino" (click)="empleado.gender = 'Femenino'">
                                    Femenino
                                </mat-option>
                                <mat-option value="Otro" (click)="empleado.gender = 'Otro'">
                                  Otro
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card>

                </div>
                <div class="col s12 m4 l4">
                    <mat-card>
                        <h3>Foto (Opcional)</h3>

                        <div class="center">
                            <div class="img-content">
                                <img class="vex-soluciones-img-profile" [src]="(empleado.image)? empleado.image: sourceUrl + 'images/usuarios/default.png'" alt="" width="120">
                            </div>
                        </div>

                        <div class="center">
                            <button *ngIf="!empleado.image" color="primary" type="button" (click)="photoFile.click()" mat-stroked-button>Subir foto</button>

                            <input hidden (change)="subirImagen($event.target.files[0])" #photoFile type="file" accept="image/x-png,image/gif,image/jpeg,image/jpg">

                        </div>

                        <div class="center">
                            <button mat-raised-button *ngIf="empleado.image" type="button" (click)="cancelar()">Cancelar</button>

                        </div>

                    </mat-card>
                    <br>
                    <mat-card>
                        <h3>Datos de la empresa</h3>
                        <mat-form-field appearance="legacy">
                            <mat-label>Puesto de trabajo</mat-label>
                            <input name="job" [formControl]="jobFormControl" [errorStateMatcher]="matcher" matInput placeholder="Nombre" [(ngModel)]="empleado.job" required>
                            <mat-error *ngIf="jobFormControl.hasError('required')">
                                Puesto de trabajo <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Empresa</mat-label>
                            <mat-select name="business" [(ngModel)]="empleado.company_id" required>
                                <mat-option [value]="empresa.identifier" *ngFor="let empresa of empresas" >
                                    {{empresa.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Número de filiación</mat-label>
                            <input name="job" [formControl]="affiliationNumberFormControl" [errorStateMatcher]="matcher" matInput placeholder="Número de filiación" [(ngModel)]="empleado.affiliation_number" required>
                            <mat-error *ngIf="affiliationNumberFormControl.hasError('required')">
                                Número de filiación <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>
                    </mat-card>

                </div>
                <div class="col s12 m4 l4">
                    <mat-card>
                        <h3>Datos de primer Familiar</h3>

                        <mat-form-field appearance="legacy">
                            <mat-label>Nombre del Familiar</mat-label>
                            <input name="firstName" matInput placeholder="Nombre del Familiar" [(ngModel)]="empleado.name_first_familiar">

                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Correo Electrónico del Familiar</mat-label>
                            <input name="firstEmail" matInput placeholder="Correo Electrónico del Familiar" [(ngModel)]="empleado.email_first_familiar">

                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Teléfono del Familiar</mat-label>
                            <input name="firstTel" matInput placeholder="Teléfono del Familiar" [(ngModel)]="empleado.phone_first_familiar">

                        </mat-form-field>

                    </mat-card>
                    <br>
                    <mat-card>
                        <h3>Datos de segundo Familiar</h3>

                        <mat-form-field appearance="legacy">
                            <mat-label>Nombre del Familiar</mat-label>
                            <input name="secondName" matInput placeholder="Nombre del Familiar" [(ngModel)]="empleado.name_second_familiar">

                        </mat-form-field>
                        <mat-form-field appearance="legacy">
                            <mat-label>Correo Electrónico del Familiar</mat-label>
                            <input name="secondEmail" matInput placeholder="Correo Electrónico del Familiar" [(ngModel)]="empleado.email_second_familiar">

                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Teléfono del Familiar</mat-label>
                            <input name="secondTel" matInput placeholder="Teléfono del Familiar" [(ngModel)]="empleado.phone_second_familiar">

                        </mat-form-field>
                    </mat-card>

                </div>
            </div>

            <br>
            <div class="row">
                <div class="col s12 p-l-0" *ngIf="!loader">
                    <button type="button" mat-raised-button (click)="volver()">{{buttonName.BACK}}</button>
                    &nbsp;
                    <button type="submit" mat-raised-button color="primary">{{buttonName.STORE}}</button>
                </div>
                <div class="col s12" *ngIf="loader">
                    <mat-spinner diameter="40"></mat-spinner>
                </div>
            </div>
        </form>

    </div>
</section>
