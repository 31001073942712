<section>
  <vex-section-title title="ADMINISTRACIÓN" subtitle="NUEVA ATENCIÓN"></vex-section-title>

  <div class="vex_soluciones_content">
    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarAtencion(f)" (keydown.enter)="$event.preventDefault()">
      <mat-card>
        <div class="row">
          <div class="col s12 m12 l5">
            <h3>Datos de la atención</h3>

            <mat-form-field>
              <mat-label>Tipo de paciente</mat-label>
              <mat-select name="tipo_paciente" [(ngModel)]="atencion.type_patient"
                (selectionChange)="seleccionarTipo($event)" required>
                <mat-option value="privado">
                  Privado
                </mat-option>
                <mat-option value="empresa">
                  Empresa
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Empresa</mat-label>
              <mat-select name="empresa" [(ngModel)]="atencion.company_id" required
                (selectionChange)="seleccionarEmpresa($event.value)"
                [disabled]="!(this.atencion.type_patient == 'empresa')">
                <mat-option *ngFor="let empresa of empresas" [value]="empresa.identifier">
                  {{empresa.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Paciente</mat-label>
              <mat-select name="paciente" [(ngModel)]="atencion.patient_id" required
                (selectionChange)="seleccionarPaciente($event.value)">
                <mat-option *ngFor="let paciente of pacientes" [value]="paciente.identifier">
                  <span *ngIf="paciente.patient.status == 'activado'">
                    {{paciente.patient.user.name}} {{paciente.patient.user.lastnamepat}}
                    {{paciente.patient.user.lastnamemat}}
                  </span>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Fecha</mat-label>
              <input [min]="minDate" [formControl]="fechaFormControl" [errorStateMatcher]="matcher" name="fecha"
                (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="atencion.date" required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="fechaFormControl.hasError('required')">
                Fecha <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="legacy">
              <mat-label>Hora</mat-label>
              <input (focus)="abrirHora()" id="hora" [formControl]="horaFormControl"
                [errorStateMatcher]="matcher" name="hora" type="time" matInput placeholder="Hora"
                [(ngModel)]="atencion.hour" matTimepicker required>
              <mat-error *ngIf="horaFormControl.hasError('required')">
                Hora <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Servicio</mat-label>
              <mat-select name="servicio" [(ngModel)]="atencion.service_id" required>
                <mat-option *ngFor="let servicio of servicios" [value]="servicio.identifier">
                  {{servicio.name}}
                </mat-option>

              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Unidad Asignada</mat-label>
              <mat-select name="unidad_asignada" [(ngModel)]="atencion.vehicle_id" required>
                <ng-container *ngFor="let unidad of unidades">
                  <mat-option [value]="unidad.id" *ngIf="unidad.state == 'online'">
                    {{unidad.identifier}}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Paramédico</mat-label>
              <mat-select name="paramedic" [(ngModel)]="atencion.paramedic_id"
                >
                <mat-option *ngFor="let paramedic of paramedics" [value]="paramedic.identifier">
                  {{paramedic.user.name}} {{paramedic.user.lastnamepat}} {{paramedic.user.lastnamemat}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Profesional</mat-label>
              <mat-select name="profesional" [(ngModel)]="atencion.professionals_id" multiple>
                <mat-option *ngFor="let profesional of profesionales" [value]="profesional.identifier">
                  {{profesional.user.name}} {{profesional.user.lastnamepat}} - {{profesional.type}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>¿Requiere de servicio adicional?</mat-label>
              <mat-select name="aditional_service_id" multiple [(ngModel)]="atencion.aditional_service_id">
                <div *ngFor="let aditionalService of categorias">
                  <div *ngIf="aditionalService.servicios.length == 0">
                    <mat-option [value]="aditionalService.name">
                      {{aditionalService.name}}
                    </mat-option>
                  </div>
                  <div *ngIf="aditionalService.servicios.length > 0">
                    <mat-optgroup [label]="aditionalService.name">
                      <mat-option [value]="serv.name" *ngFor="let serv of aditionalService.servicios">
                        {{serv.name}}
                      </mat-option>

                    </mat-optgroup>
                  </div>
                </div>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip *ngFor="let correo of correos" [selectable]="selectable" [removable]="removable"
                  (removed)="remove(correo)">
                  {{correo.name}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Correos a notificar (separar por comas)" [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)" [(ngModel)]="atencion.emails" name="correos">
              </mat-chip-list>
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <mat-label>Detalle de servicio</mat-label>
              <textarea
                required
                name="detalle_de_servicio" matInput placeholder="Detalle de servicio"
                [(ngModel)]="atencion.detail"></textarea>
            </mat-form-field>

            <div>
              <mat-form-field appearance="outline">
                <mat-label>Cupón</mat-label>
                <input maxlength="10" matInput placeholder="Cupón">
                <mat-icon matSuffix>receipt</mat-icon>
                <mat-hint>Valido por 7 días</mat-hint>
              </mat-form-field>
              <div>
                <button color="primary" mat-flat-button type="button">APLICAR</button>
              </div>

            </div>
            <br>
            <mat-form-field appearance="outline">
                <mat-label>Costo aproximado</mat-label>
                <input required min="0" [(ngModel)]="atencion.cost" type="number" matInput placeholder="Costo aproximado" name="costo" readonly>
            </mat-form-field>


            <div *ngIf="isBilling">
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Datos de Facturación
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <mat-form-field appearance="legacy">
                    <mat-label>RFC (Opcional)</mat-label>
                    <input name="rfc" matInput placeholder="RFC (Opcional)" [(ngModel)]="billing.rfc">

                  </mat-form-field>

                  <mat-form-field appearance="legacy">
                    <mat-label>Razón Social (Opcional)</mat-label>
                    <input name="razon_social" matInput placeholder="Razón Social (Opcional)"
                      [(ngModel)]="billing.razon">
                  </mat-form-field>

                  <mat-form-field appearance="legacy">
                    <mat-label>Uso de CFDI (Opcional)</mat-label>
                    <input name="uso_de_cfdi" matInput placeholder="Uso de CFDI (Opcional)" [(ngModel)]="billing.cfdi">
                  </mat-form-field>

                  <mat-form-field appearance="legacy">
                    <mat-label>Método de Pago (Opcional)</mat-label>
                    <input name="metodo_pago" matInput placeholder="Método de Pago (Opcional)"
                      [(ngModel)]="billing.metodo">
                  </mat-form-field>
                </mat-expansion-panel>
              </mat-accordion>

            </div>

          </div>


          <div class="col s12 m12 l7">
            <mat-form-field>
              <mat-label>Dirección de la zona</mat-label>
              <input matInput name="address" (keydown.enter)="$event.preventDefault()"
                placeholder="Ingrese la dirección de la zona que busca" autocorrect="off" autocapitalize="off"
                spellcheck="off" type="text" #search>
              <button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="search.value=''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <div class="pav-buttons-action-map">
              <div>
                <button (click)="agregar()" type="button" mat-mini-fab [disabled]="!search.value" color="primary">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
              <br>
              <div>
                <button mat-raised-button color="primary" (click)="generarRuta()"
                  [disabled]="!(!rutaActivada && atencion.waypoints.length >= 2)">Generar Ruta</button>
              </div>
              <div>
                <button mat-raised-button color="warn" (click)="ocultarRuta()" [disabled]="!rutaActivada">Deshacer Ruta</button>
              </div>

            </div>
            <div class="row">
              <div class="col s12">
              </div>
            </div>

            <div class="row" style="margin-top: 15px;">

              <mat-list *ngIf="atencion.waypoints.length > 0">
                <div mat-subheader>Destinos</div>
                <mat-list-item class="vex-points-route" *ngFor="let direccion of atencion.waypoints; let i = index">
                  <mat-icon mat-list-icon>my_location</mat-icon>
                  <div mat-line>{{i + 1}} | {{direccion.name}}</div>
                  <div mat-line> Latitud: {{direccion.location.lat}} | Longitud: {{direccion.location.lng}} </div>
                  <button (click)="removeDireccion(i)" mat-icon-button type="button" name="removerDirecicon" [disabled]="rutaActivada">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-list-item>

              </mat-list>
            </div>
            <br>
            <div style="margin-top: 15px;">
              <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                <ng-container *ngIf="!rutaActivada">
                  <ng-container *ngFor="let direccion of atencion.waypoints; let i = index">
                    <agm-marker [latitude]="direccion.location.lat" [longitude]="direccion.location.lng" [markerDraggable]="true"
                      [label]='{
                      text: (i + 1).toString(),
                      fontWeight: "bold",
                      color: "#FFF"
                    }' (dragEnd)="markerDragEnd($event, i)"></agm-marker>
                  </ng-container>
                </ng-container>
                <agm-direction [origin]="puntoPasoInicio" [destination]="puntoPasoDestino" [waypoints]="puntosPaso"
                  *ngIf="rutaActivada">
                </agm-direction>
              </agm-map>
            </div>

          </div>
        </div>
      </mat-card>

      <br>

      <mat-card>
        <div class="row">
          <div class="col s12" *ngIf="!loader">
            <button type="button" mat-raised-button (click)="volver()">{{buttonName.BACK}}</button>
            &nbsp;
            <button mat-raised-button color="primary" type="submit">{{buttonName.STORE}}</button>
          </div>
          <div class="col s12" *ngIf="loader">
            <mat-spinner diameter="40"></mat-spinner>
          </div>
        </div>
      </mat-card>
    </form>
  </div>

</section>
