import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ServiceService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { Service } from 'src/app/models/service';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-servicio-editar',
  templateUrl: './servicio-editar.component.html',
  styleUrls: ['./servicio-editar.component.css']
})
export class ServicioEditarComponent implements OnInit {

  servicio: Service;
  servicioId: any;
  loader: boolean = false;


  nombreFormControl = new FormControl('', [
    Validators.required,
  ]);

  descripcionFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public location: Location,
    public dialog: MatDialog,
    private _service: ServiceService,
    private _activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar

  ) {
    this.servicio = new Service();
  }

  ngOnInit(): void {
    this.obtenerServicio();
  }

  volver(): void {
    this.location.back();
  }

  obtenerServicio() {
    this._activatedRoute.params.subscribe(
      params => {
        this.servicioId = params['id'];

        this._service.getService(this.servicioId).subscribe(
          response => {
            this.servicio.name = response.data.name;
            this.servicio.description = response.data.description;
          },
          error => {

          }
        )
      }
    )
  }

  editarServicio(f: NgForm) {
    this.loader = true;

    this._service.updateService(this.servicioId, this.servicio).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('Servicio actualizado');
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.loader = false;
        this.openError('Error al actualizar servicio', 'Ok');
      }
    )

  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
