import { Component, OnInit, DoCheck } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SidebarService } from '../services/shared/sidebar.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { UserService } from '../services/user.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  menu: any;
  user: any;
  role: any;
  sourceUrl = environment.sourceUrl;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private authService: AuthenticationService,
    private breakpointObserver: BreakpointObserver,
    private _sidebar: SidebarService,
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    private userService: UserService,
    private router: Router,
  ) {

    this.userId = JSON.parse(localStorage.getItem('currentUser')).id;
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.role = JSON.parse(localStorage.getItem('role'));

    this.menu = JSON.parse(localStorage.getItem('role')).access;
  }

  ngDoCheck() {
    this.now = new Date();
  }

  readonly VAPID_PUBLIC_KEY = "BGMMOMk4eui2DEJ8P4uF75PFlPvx036mFIUHEs5s-pS9K7t4pLmz6O4yQz2n2vqohFrcbZUfMF59T4vvG_tZ6gk";
  readonly VAPID_PRIVATE_KEY = "3c4_qeK2SdxoVKtUF57kOS6lxWeLoY3jYyayv22MBUU";

  userId: any;

  now: Date = new Date();

  ngOnInit(): void {

    if (this.swUpdate.isEnabled) {

      this.swUpdate.available.subscribe(() => {

          if(confirm("Nueva Versión de PIN DOCTOR Disponible. ¿Cargar nueva Versión?")) {

              window.location.reload();
          }
      });

    }

    this.subscribeToNotifications();

  }

  subscribeToNotifications() {

    this.swPush.requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY
    })
    .then(sub => {
      let data = {
        endpoint: sub.endpoint,
        expirationTime: sub.expirationTime,
        keys: {
          p256dh: btoa(String.fromCharCode(...new Uint8Array(sub.options.applicationServerKey)))
        }
      }

      this.userService.uploadSubscription(this.userId, data).subscribe()
    })
    .catch(err => console.error("Could not subscribe to notifications", err));
  }

  cerrarSesion() {
    this.authService.logout();
    this.router.navigate(['/iniciar-sesion'])
    console.log('cerrar sesion')
  }

  verificarMenu(array): boolean {
    if(array.length == 0) {
      return true;
    } else {
      let exist = false;

      array.forEach(element => {
        if(element.isActive) {
          exist = true;
        }
      });
      return exist;
    }
  }

}
