import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': "Bearer " + localStorage.getItem('token')
    })
};

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient,
    ) { }
    
    updatePassword(id, data): Observable<any> {

        return this.http.put<any>(`${environment.apiUrl}users/password/${id}`, data);

    }

    uploadImage(id, data): Observable<any> {

        return this.http.put<any>(`${environment.apiUrl}users/upload/${id}`, data);

    }

    uploadSubscription(id, data): Observable<any> {

        return this.http.put<any>(`${environment.apiUrl}users/subscription/${id}`, data);

    }

}
