<section>

    <vex-section-title title="ADMINISTRACIÓN" subtitle="ADMINISTRADORES"></vex-section-title>

    <div class="vex_soluciones_content">
        <div align="end">
            <mat-form-field>
              <mat-label>Buscador</mat-label>
              <input matInput (keyup)="buscarAdministrativo($event)" placeholder="Buscar">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            &nbsp;
            <button mat-raised-button color="primary" [routerLink]="['/usuario/administrativo-nuevo']">
              <mat-icon>add</mat-icon>
              Agregar
            </button>
        </div>

        <mat-card class="mat-elevation-z0 sc_card_more_options">
            Todos los administrativos
            <button class="sc_more_options" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    class="sc_more_options"
                    color="primary"
                    (click)="exporter.exportTable('xlsx', {fileName:'administrativos'})">
                    <mat-icon>cloud_download</mat-icon>
                    <span>Exportar a Excel</span>
                </button>

            </mat-menu>
        </mat-card>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort matTableExporter #exporter="matTableExporter">

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                <td mat-cell *matCellDef="let row">
                    <mat-slide-toggle color="primary" (click)="cambiarActivacion(row)" [checked]="row.status == 'disponible'"></mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ADMIN </th>
                <td mat-cell *matCellDef="let row">
                    <div class="user-info-content">
                        <div class="user-img">
                            <img class="img-profile" style="width: 100%; height: 100%;" [src]="url + row.image" alt="" width="42">
                        </div>
                        <div class="user-info">
                            <a [routerLink]="['/ver-administrativo', row.identifier]">{{row.user.name}}</a>
                            <p class="m-t-0 m-b-0">{{row.email}}</p>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="gender">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> SEXO </th>
                <td mat-cell *matCellDef="let row"> {{row.gender}} </td>
            </ng-container>

            <ng-container matColumnDef="birth_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> EDAD </th>
                <td mat-cell *matCellDef="let row"> {{getAge(row.birth_date)}} años </td>
            </ng-container>

            <ng-container matColumnDef="role_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ROL </th>
                <td mat-cell *matCellDef="let row"> {{row.role_name}} </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CELULAR </th>
                <td mat-cell *matCellDef="let row"> {{row.phone}} </td>
            </ng-container>

            <ng-container matColumnDef="telephone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> TELEFONO </th>
                <td mat-cell *matCellDef="let row"> {{row.telephone}} </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="pin-size-th"> ACCIONES </th>
                <td mat-cell *matCellDef="let row">
                    <button color="primary" mat-icon-button [routerLink]="['/ver-administrativo', row.identifier]">
                        <mat-icon>visibility</mat-icon>
                    </button>
                    &nbsp;
                    <button color="primary" [routerLink]="['/usuario/administrativo-editar', row.identifier]" mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>

                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>

                <td class="mat-cell" colspan="4"></td>

                <td class="mat-cell" colspan="1" *ngIf="!loader">No se encontraron registros</td>

                <td class="mat-cell" colspan="1" *ngIf="loader">
                    <mat-spinner diameter="40"></mat-spinner>
                </td>

                <td class="mat-cell" colspan="4"></td>
            </tr>
        </table>
        <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

    </div>

</section>
