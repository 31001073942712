import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NurseService } from 'src/app/services';
import { Location } from '@angular/common';
import { Nurse } from 'src/app/models/nurse';
import { MatDialog } from '@angular/material/dialog';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { environment } from '../../../../../../environments/environment';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-enfermero-editar',
  templateUrl: './enfermero-editar.component.html',
  styleUrls: ['./enfermero-editar.component.css']
})
export class EnfermeroEditarComponent implements OnInit {

  data: any;
  enfermero: Nurse;
  enfermeroId: number;
  photoTemp: any;
  ineTemp: any;
  titleTemp: any;
  cedulaTemp: any;
  loader: boolean = false;

  nombresFormControl = new FormControl('', [
    Validators.required,
  ])

  apePaternoFormControl = new FormControl('', [
    Validators.required,
  ])

  apeMaternoFormControl = new FormControl('', [
    Validators.required,
  ]);

  celFormControl = new FormControl('', [
    Validators.required,
  ])

  telFormControl = new FormControl('', [
    Validators.required,
  ])

  direccionFormControl = new FormControl('', [
    Validators.required,
  ])

  dateBirthFormControl = new FormControl('', [
    Validators.required,
  ])

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    private _nurseService: NurseService,
    private _router: Router,
    public location: Location,
    private _snackBar: MatSnackBar
    ) {
    this.photoTemp = "/assets/images/admin/upload-image.png";
    this.ineTemp = "/assets/images/admin/upload-image.png";
    this.titleTemp = "/assets/images/admin/upload-image.png";
    this.cedulaTemp = "/assets/images/admin/upload-image.png";
    this.enfermero = new Nurse();
  }

  ngOnInit(): void {

    this.obtenerEnfermero();

  }

  onFileSelectedPhoto(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.photoTemp = reader.result;
      this.enfermero.photo = reader.result;
    };

  }

  verFoto() {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.enfermero.photo
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {}
      })
  }

  onFileSelectedIne(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.ineTemp = reader.result;
      this.enfermero.ine = reader.result;
    };

  }

  verIne() {
    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: this.enfermero.ine
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  onFileSelectedTitle(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.titleTemp = reader.result;
      this.enfermero.title = reader.result;
    };

  }

  verTitle() {
    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: this.enfermero.title
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {}
    })
  }

  onFileSelectedCedula(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.cedulaTemp = reader.result;
      this.enfermero.cedula = reader.result;
    };

  }

  verCedula() {
    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: this.enfermero.cedula
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {}
    })
  }

  obtenerEnfermero() {
    this.activatedRoute.params.subscribe( params => {

      let id = params['id'];
      this.enfermeroId = id;

      this._nurseService.getNurse(id).subscribe(
        response => {
          console.log(response)
          this.enfermero.name = response.data.professional.user.name;
          this.enfermero.lastnamepat = response.data.professional.user.lastnamepat;
          this.enfermero.lastnamemat = response.data.professional.user.lastnamemat;
          this.enfermero.birth_date = response.data.professional.user.birth_date;
          this.enfermero.gender = response.data.professional.user.gender;
          this.enfermero.telephone = response.data.professional.user.telephone;
          this.enfermero.address = response.data.professional.user.address;
          this.enfermero.type_nurse = response.data.type;
          this.enfermero.institution = response.data.institution;
          this.enfermero.phone = response.data.professional.user.phone;
          this.enfermero.photo = `${environment.sourceUrl}${response.data.professional.user.image}`;
          this.enfermero.ine = `${environment.sourceUrl}${response.data.professional.ine}`;
          this.enfermero.title = `${environment.sourceUrl}${response.data.title}`;
          this.enfermero.cedula = `${environment.sourceUrl}${response.data.cedula}`;
        },
        error => {
          console.log(error)
        }
      )

    });
  }

  volver() {
    this._router.navigate(['/usuario/profesional/enfermero']);
  }

  editarEnfermero(f: NgForm) {
    this.loader = true;

    this._nurseService.updateNurse(this.enfermeroId, this.enfermero).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('DATOS DEL ENFERMERO ACTUALIZADOS');
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.loader = false;
        this.openError('ERROR AL ACTUALIZAR DATOS DEL ENFERMERO', 'Ok');
      }
    )

  }
  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
