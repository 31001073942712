import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryAditionalService } from 'src/app/models/CategoryAditionalService';
import { CategoryServService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-categorias-editar',
  templateUrl: './categorias-editar.component.html',
  styleUrls: ['./categorias-editar.component.css']
})
export class CategoriasEditarComponent implements OnInit {

  categoria: CategoryAditionalService;
  categoriaId: any;
  loader: boolean = false;

  nombreFormControl = new FormControl('', [
    Validators.required,
  ]);

  descripcionFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public location: Location,
    private _router: Router,
    public dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _categoryService: CategoryServService,
  ) {
    this.categoria = new CategoryAditionalService();
  }

  ngOnInit(): void {
    this.obtenerCategoriaServicioAdicional();
  }

  obtenerCategoriaServicioAdicional() {
    this._activatedRoute.params.subscribe(
      params => {
        this.categoriaId = params['id'];
        this._categoryService.getCategory(this.categoriaId).subscribe(
          response => {
            this.categoria.name = response.data.name;
            this.categoria.description = response.data.description;
            this.categoria.active = response.data.active;
          },
          error => {
            this.mostrarErrorDialog('Categoria de servicio adicional no encontrada');
          }
        )

      }
    )
  }

  editarCategoriaServicioAdicional(f: NgForm) {
    this.loader = true;

    this._categoryService.updateCategory(this.categoriaId, this.categoria).subscribe(
      response => {
        this.mostrarExitoDialog('Categoria adicional actualizada');
        this.loader = false;
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.mostrarErrorDialog('Error al editar categoria de servicio adicional');
        this.loader = false;
      }
    )

  }

  volver() {
    this._router.navigate(['/gestion-de-categoria-de-servicios-adicionales']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

}
