export class Vehicle {
  identifier: string;
  id: string;
  brand: string;
  model: string;
  plete: string;
  year: string;
  color: string;
  serie: string;

  photo1: any;
  photo2: any;
  photo3: any;
  photo4: any;

  insurance_carrier: string;
  insurance_agent: string;
  telephone: any;
  policy: string;
  expiration_date: any;

  gasoline_consumption_km: string;
  recieve_maintenance_km: string;
  type_equipment: string;
  type_vehicle: string;

  paramedic_id: string;
  paramedic: any;
  user_vehicle: any;
  user_paramedic: any;

  maintenances: any;
  state: string;

  email: string;
  password: string;

  constructor(
  ){}
}
