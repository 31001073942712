import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { Router } from '@angular/router';
import { CompanyService, EmployeeService } from 'src/app/services';
import { Location } from '@angular/common';
import { Employee } from 'src/app/models/employee';
import { Validators, FormControl, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { IButtonName, BUTTON_NAME } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-colaborador-nuevo',
  templateUrl: './colaborador-nuevo.component.html',
  styleUrls: ['./colaborador-nuevo.component.css']
})
export class ColaboradorNuevoComponent implements OnInit {

  sourceUrl = environment.sourceUrl;

  empleado: Employee;
  empresas: any = [];

  hide = true;
  hideC = true;

  loader: boolean = false;


  nombresFormControl = new FormControl('', [
    Validators.required,
  ])

  apePaternoFormControl = new FormControl('', [
    Validators.required,
  ])

  apeMaternoFormControl = new FormControl('', [
    Validators.required,
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6)
  ])

  passConfFormControl = new FormControl('', [
    Validators.required,
    this.passwordMatcher.bind(this)
  ])

  celFormControl = new FormControl('', [
    Validators.required,
  ])

  telFormControl = new FormControl('', [
    Validators.required,
  ])

  direccionFormControl = new FormControl('', [
    Validators.required,
  ])

  dateBirthFormControl = new FormControl('', [
    Validators.required,
  ])

  jobFormControl = new FormControl('', [
    Validators.required,
  ])

  affiliationNumberFormControl = new FormControl('', [
    Validators.required,
  ])

  firstNameFormControl = new FormControl('', [
    Validators.required,
  ])

  firstEmailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ])

  firstTelFormControl = new FormControl('', [
    Validators.required,
  ])

  secondNameFormControl = new FormControl('', [
    Validators.required,
  ])

  secondEmailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ])

  secondTelFormControl = new FormControl('', [
    Validators.required,
  ])

  matcher = new MyErrorStateMatcher();

  public passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (
        (control.value !== this.passFormControl.value)
    ) {
        return { passwordNotMatch: true };
    }
    return null;
  }

  buttonName: IButtonName = BUTTON_NAME;
  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _companyService: CompanyService,
    private _employeeService: EmployeeService,
    public location: Location,
    private _snackBar: MatSnackBar

  ) {
    this.empleado = new Employee();
  }


  ngOnInit(): void {
    this.obtenerEmpresas();
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.empleado.image = reader.result;
    };
  }

  cancelar() {
    this.empleado.image = null;
  }

  obtenerEmpresas() {
    this._companyService.getCompanies().subscribe(
      response => {
        this.empresas = response.data;
      },
      error => {
        this.openError('No se encontraron empresas','OK');
      }
    )
  }

  registrarEmpleado(f: NgForm) {
    this.loader = true;

    console.log('EMPLEADO', this.empleado);
    this._employeeService.saveEmployee(this.empleado).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('AFILIADO REGISTRADO');
        return;
        setTimeout(() => {
          this._router.navigate(['/usuario/paciente/empresas']);
        }, 1500);
      },
      error => {
        this.loader = false;
        if(error.error.code == 422) {
          this.openError('Correo existente, Ingrese otro.', 'Ok');
          return;
        }
        this.openError('Error al registrar afiliado', 'OK');
      }
    )


  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._router.navigate(['/usuario/paciente/empresas']);
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  volver() {
    this._router.navigate(['/usuario/paciente/empresas']);
  }

}
