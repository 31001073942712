import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { map } from 'rxjs/operators';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    usuario: any;
    token: any;

    constructor(
        private http: HttpClient,
    ) { 
        this.cargarStorage()
    }

    estaLogueado() {
        console.log('access_token', this.token)
        console.log('access_token tamaño', this.token.length)
        if(localStorage.getItem('access_token')) {
            
            return ( localStorage.getItem('access_token').length > 5 ) ? true : false;
        }
    }

    cargarStorage() {
        console.log('cargando storage')
        if ( localStorage.getItem('access_token')) {
            this.token = localStorage.getItem('access_token');
            this.usuario = JSON.parse( localStorage.getItem('currentUser') );
        } else {
            this.token = '';
            this.usuario = null;
        }

    }

    public getToken(): string {
        return localStorage.getItem('token');
    }

    login(employee: User): Observable<any> {
        
        return this.http.post<any>(`${environment.apiUrl}login`, employee)
            .pipe(map(data => {
                console.log(data);
                if (data && data.access_token) {
                    localStorage.setItem('currentUser', JSON.stringify(data.user));
                    localStorage.setItem('currentAdmin', JSON.stringify(data.admin));
                    localStorage.setItem('access_token', data.access_token);
                    localStorage.setItem('role', JSON.stringify(data.role));
                    return data;
                }
                return data;
            })
        );
    }

    logout() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentAdmin');
        localStorage.removeItem('access_token');
        localStorage.removeItem('role');
    }
}
