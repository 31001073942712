<section>
    <div class="row">
        <div class="col s1"></div>
        <div class="col s10">

            <div class="vex_soluciones_tiles">
                <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
                <h2 class="vex_soluciones_subtitle">VER UNIDAD</h2>

                <button class="vex-souluciones-back" mat-raised-button (click)="volver()">{{buttonName.BACK}}</button>
            </div>

            <div>

                <mat-tab-group>
                    <mat-tab label="DATOS GENERALES">
                        <div class="row padding-content">
                            <div class="col s3 p-l-0">
                                <mat-card>
                                    <div class="center">
                                        <div class="img-content">
                                            <img class="vex-soluciones-img-profile" [src]="vehicle.photo1" alt="" width="120">
                                        </div>
                                    </div>
                                    <h3 class="center m-b-0 vex-soluciones-name-title">{{vehicle.brand}}</h3>
                                    <h4 class="center m-t-0 vex-soluciones-email-title">{{vehicle.identifier}}</h4>

                                    <!-- <div class="center">
                                        <button *ngIf="tempImage == vehicle.photo1" mat-stroked-button color="primary" type="button" (click)="photoFile.click()">Cambiar Imagen</button>
                                        <input hidden (change)="subirImagen($event.target.files[0])" #photoFile type="file" accept="image/x-png,image/gif,image/jpeg,image/jpg">

                                    </div>

                                    <div class="center">
                                        <button mat-stroked-button *ngIf="tempImage != vehicle.photo1" type="button" (click)="cancelar()">Cancelar</button>
                                        &nbsp;
                                        <button mat-stroked-button *ngIf="tempImage != vehicle.photo1" type="button" (click)="guardarImagen()" color="primary">Guardar</button>
                                    </div> -->

                                </mat-card>
                            </div>
                            <div class="col s9 p-r-0">
                                <mat-card>
                                    <div>
                                        <h4 class="vex-soluciones-title-perfil">Datos del vehículo</h4>
                                    </div>
                                    <div class="row">

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Identificador</mat-label>
                                                <input matInput placeholder="Identificador" [(ngModel)]="vehicle.identifier" name="identifier" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Placa</mat-label>
                                                <input matInput placeholder="Placa" [(ngModel)]="vehicle.plete" name="plete" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Modelo</mat-label>
                                                <input matInput placeholder="Modelo" [(ngModel)]="vehicle.model" name="model" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label># Serie</mat-label>
                                                <input matInput placeholder="# Serie" [(ngModel)]="vehicle.serie" name="serie" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Año</mat-label>
                                                <input matInput placeholder="Año" [(ngModel)]="vehicle.year" name="year" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Marca</mat-label>
                                                <input matInput placeholder="Marca" [(ngModel)]="vehicle.brand" name="brand" readonly>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                </mat-card>
                            </div>
                        </div>
                        <div class="row padding-content">
                            <h3>Fotos de la Unidad</h3>
                            <div class="col s12 p-r-0 p-l-0">
                                <mat-card>

                                    <div class="row">
                                        <div class="col s12 m3 l3 vex_soluciones_colum">
                                            <div class="row">
                                                <div class="col s3 img-width">
                                                    <img [src]="vehicle.photo1" class="vex_soluciones_file" (click)="verImg1()">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col s12 m3 l3">

                                            <div class="row">
                                                <div class="col s3 img-width">
                                                    <img [src]="vehicle.photo2" class="vex_soluciones_file" (click)="verImg2()">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col s12 m3 l3">

                                            <div class="row">
                                                <div class="col s3 img-width">
                                                    <img [src]="vehicle.photo3" class="vex_soluciones_file" (click)="verImg3()">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col s12 m3 l3">

                                            <div class="row">
                                                <div class="col s3 img-width">
                                                    <img [src]="vehicle.photo4" class="vex_soluciones_file" (click)="verImg4()">
                                                </div>

                                            </div>

                                        </div>


                                    </div>

                                </mat-card>
                            </div>
                        </div>
                        <div class="row padding-content">
                            <h3>Seguro</h3>
                            <div class="col s12 p-r-0 p-l-0">
                                <mat-card>
                                    <div class="row">
                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Aseguradora</mat-label>
                                                <input [(ngModel)]="vehicle.insurance_carrier" name="insurance_carrier" matInput placeholder="Aseguradora" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Nombre Agente Aseguradora</mat-label>
                                                <input [(ngModel)]="vehicle.insurance_agent" name="insurance_agent" matInput placeholder="Placa" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Teléfono Agente Aseguradora</mat-label>
                                                <input [(ngModel)]="vehicle.telephone" name="telephone" matInput placeholder="Modelo" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label># Poliza</mat-label>
                                                <input [(ngModel)]="vehicle.policy" name="policy" matInput placeholder="# Serie" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Fecha de vencimiento</mat-label>
                                                <input [(ngModel)]="vehicle.expiration_date" name="expiration_date" matInput placeholder="Año" readonly>
                                            </mat-form-field>
                                        </div>

                                    </div>

                                </mat-card>
                            </div>
                        </div>
                        <div class="row padding-content">
                            <h3>Atributos del Vehículo</h3>
                            <div class="col s12 p-r-0 p-l-0">
                                <mat-card>
                                    <div class="row">
                                        <div class="col s12 m4 l4 vex_soluciones_colum">
                                            <mat-form-field appearance="legacy">
                                                <mat-label>Consumo de gasolina x KM</mat-label>
                                                <input [(ngModel)]="vehicle.gasoline_consumption_km" matInput placeholder="Consumo de gasolina x KM" name="gasoline_consumption_km" readonly>
                                                <mat-error>
                                                    Consumo de gasolina <strong>Requerido</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m4 l4">
                                            <mat-form-field>
                                                <mat-label>Tipo de vehículo</mat-label>
                                                <mat-select [(ngModel)]="vehicle.type_vehicle" name="type_vehicle" readonly>
                                                    <mat-option value="suv">
                                                        SUV
                                                    </mat-option>
                                                    <mat-option value="sedan">
                                                        Sedan
                                                    </mat-option>
                                                    <mat-option value="minivan">
                                                        Minivan
                                                    </mat-option>
                                                    <mat-option value="van">
                                                        Van
                                                    </mat-option>
                                                    <mat-option value="sprinter">
                                                        Sprinter
                                                    </mat-option>
                                                    <mat-option value="minibus">
                                                        Minibus
                                                    </mat-option>
                                                    <mat-option value="bus">
                                                        Bus
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m4 l4">

                                            <mat-form-field appearance="legacy">
                                                <mat-label>Recibe Mantenimiento Cada (km)</mat-label>
                                                <input [(ngModel)]="vehicle.recieve_maintenance_km" matInput placeholder="Recibe Mantenimiento Cada (km)" name="recieve_maintenance_km" readonly>
                                                <mat-error>
                                                    Dias entre mantenimientos <strong>Requerido</strong>
                                                </mat-error>
                                            </mat-form-field>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col s12 m4 l4">
                                            <mat-label class="example-radio-group">Equipamiento</mat-label>

                                            <mat-radio-group [(ngModel)]="vehicle.type_equipment" name="type_equipment" aria-label="Select an option" color="primary" readonly>
                                                <mat-radio-button value="A">A</mat-radio-button>
                                                &nbsp;
                                                <mat-radio-button value="B">B</mat-radio-button>
                                                &nbsp;
                                                <mat-radio-button value="C">C</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col s12 m4 l4">

                                            <mat-form-field>
                                                <mat-label>Estado</mat-label>
                                                <mat-select [(ngModel)]="vehicle.state" name="state" readonly>
                                                    <mat-option value="online">
                                                        Online
                                                    </mat-option>
                                                    <mat-option value="offline">
                                                        Offline
                                                    </mat-option>
                                                    <mat-option value="stand_by">
                                                        Stand By
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                </mat-card>
                            </div>
                        </div>
                        <div class="row padding-content">
                            <h3>Asignado a</h3>
                            <div class="col s12 p-r-0 p-l-0">
                                <mat-card>
                                    <div class="row">
                                        <div class="col s12 m4 l4 vex_soluciones_colum">

                                            <mat-form-field>
                                                <mat-label>Paramédico</mat-label>
                                                <mat-select [(ngModel)]="vehicle.paramedic_id" name="paramedic_id" required readonly>
                                                    <mat-option [value]="paramedico.identifier" *ngFor="let paramedico of paramedicos">
                                                        {{paramedico.professional.user.name}} {{paramedico.professional.user.lastnamepat}} {{paramedico.professional.user.lastnamemat}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                        </div>
                                    </div>

                                </mat-card>
                            </div>
                        </div>
                    </mat-tab>
                    <!-- <mat-tab label="FOTOS DE LA UNIDAD">
                        <div class="row padding-content">
                            <div class="col s12 p-r-0 p-l-0">
                                <mat-card>

                                    <div class="row">
                                        <div class="col s12 m3 l3 vex_soluciones_colum">
                                            <div class="row">
                                                <div class="col s3 img-width">
                                                    <img [src]="img1Temp" class="vex_soluciones_file" (click)="verImg1()">
                                                </div>
                                                <div class="col s9 button-width">
                                                    <button class="vex_soluciones_button_file" type="button" mat-raised-button (click)="Img1File.click()">Subir Primera Foto</button>
                                                    <input hidden (change)="onFileSelectedImg1($event.target.files[0])" #Img1File type="file">
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col s12 m3 l3">

                                            <div class="row">
                                                <div class="col s3 img-width">
                                                    <img [src]="img2Temp" class="vex_soluciones_file" (click)="verImg2()">
                                                </div>
                                                <div class="col s9 button-width">
                                                    <button class="vex_soluciones_button_file" type="button" mat-raised-button (click)="Img2File.click()">Subir Segunda Foto</button>
                                                    <input hidden (change)="onFileSelectedImg2($event.target.files[0])" #Img2File type="file">
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col s12 m3 l3">

                                            <div class="row">
                                                <div class="col s3 img-width">
                                                    <img [src]="img3Temp" class="vex_soluciones_file" (click)="verImg3()">
                                                </div>
                                                <div class="col s9 button-width">
                                                    <button class="vex_soluciones_button_file" type="button" mat-raised-button (click)="Img3File.click()">Subir Tercera Foto</button>
                                                    <input hidden (change)="onFileSelectedImg3($event.target.files[0])" #Img3File type="file">
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col s12 m3 l3">

                                            <div class="row">
                                                <div class="col s3 img-width">
                                                    <img [src]="img4Temp" class="vex_soluciones_file" (click)="verImg4()">
                                                </div>
                                                <div class="col s9 button-width">
                                                    <button class="vex_soluciones_button_file" type="button" mat-raised-button (click)="Img4File.click()">Subir Cuarta Foto</button>
                                                    <input hidden (change)="onFileSelectedImg4($event.target.files[0])" #Img4File type="file">
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                </mat-card>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="SEGURO">
                        <div class="row padding-content">
                            <h3>Seguro</h3>
                            <div class="col s12 p-r-0 p-l-0">
                                <mat-card>
                                    <div class="row">
                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Aseguradora</mat-label>
                                                <input matInput placeholder="Aseguradora" disabled>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Nombre Agente Aseguradora</mat-label>
                                                <input matInput placeholder="Placa" disabled>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Teléfono Agente Aseguradora</mat-label>
                                                <input matInput placeholder="Modelo" disabled>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label># Poliza</mat-label>
                                                <input matInput placeholder="# Serie" disabled>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Fecha de vencimiento</mat-label>
                                                <input matInput placeholder="Año" disabled>
                                            </mat-form-field>
                                        </div>

                                    </div>

                                </mat-card>
                            </div>
                        </div>

                    </mat-tab>
                    <mat-tab label="ATRIBUTOS DEL VEHICULO">

                        <div class="row padding-content">
                            <h3>Atributos del Vehículo</h3>
                            <div class="col s12 p-r-0 p-l-0">
                                <mat-card>
                                    <div class="row">
                                        <div class="col s12 m4 l4 vex_soluciones_colum">
                                            <mat-form-field appearance="legacy">
                                                <mat-label>Consumo de gasolina x KM</mat-label>
                                                <input matInput placeholder="Consumo de gasolina x KM" name="gasoline_consumption_km" required>
                                                <mat-error>
                                                    Consumo de gasolina <strong>Requerido</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m4 l4">
                                            <mat-form-field>
                                                <mat-label>Tipo de vehículo</mat-label>
                                                <mat-select name="type_vehicle" required>
                                                    <mat-option value="suv">
                                                        SUV
                                                    </mat-option>
                                                    <mat-option value="sedan">
                                                        Sedan
                                                    </mat-option>
                                                    <mat-option value="minivan">
                                                        Minivan
                                                    </mat-option>
                                                    <mat-option value="van">
                                                        Van
                                                    </mat-option>
                                                    <mat-option value="sprinter">
                                                        Sprinter
                                                    </mat-option>
                                                    <mat-option value="minibus">
                                                        Minibus
                                                    </mat-option>
                                                    <mat-option value="bus">
                                                        Bus
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m4 l4">

                                            <mat-form-field appearance="legacy">
                                                <mat-label>Recibe Mantenimiento Cada (km)</mat-label>
                                                <input matInput placeholder="Recibe Mantenimiento Cada (km)" name="recieve_maintenance_km" required>
                                                <mat-error>
                                                    Dias entre mantenimientos <strong>Requerido</strong>
                                                </mat-error>
                                            </mat-form-field>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col s12 m4 l4">
                                            <mat-label class="example-radio-group">Equipamiento</mat-label>

                                            <mat-radio-group name="type_equipment" aria-label="Select an option" color="primary" required>
                                                <mat-radio-button value="A">A</mat-radio-button>
                                                &nbsp;
                                                <mat-radio-button value="B">B</mat-radio-button>
                                                &nbsp;
                                                <mat-radio-button value="C">C</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div class="col s12 m4 l4">

                                            <mat-form-field>
                                                <mat-label>Estado</mat-label>
                                                <mat-select name="state" required>
                                                    <mat-option value="online">
                                                        Online
                                                    </mat-option>
                                                    <mat-option value="offline">
                                                        Offline
                                                    </mat-option>
                                                    <mat-option value="stand_by">
                                                        Stand By
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                </mat-card>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="ASIGNADO A">
                        <div class="row padding-content">
                            <h3>Asignado a</h3>
                            <div class="col s12 p-r-0 p-l-0">
                                <mat-card>
                                    <div class="row">
                                        <div class="col s12 m4 l4 vex_soluciones_colum">

                                            <mat-form-field>
                                                <mat-label>Paramédico</mat-label>
                                                <mat-select name="paramedic_id" required>
                                                    <mat-option [value]="paramedico.identifier" *ngFor="let paramedico of paramedicos">
                                                        {{paramedico.professional.user.name}} {{paramedico.professional.user.lastnamepat}} {{paramedico.professional.user.lastnamemat}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                        </div>
                                    </div>

                                </mat-card>
                            </div>
                        </div>
                    </mat-tab> -->
                </mat-tab-group>
            </div>

        </div>
        <div class="col s1"></div>
    </div>
</section>
