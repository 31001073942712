import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrivateService } from 'src/app/services';
import { Location } from '@angular/common';
import { Private } from 'src/app/models/private';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-privado-editar',
  templateUrl: './privado-editar.component.html',
  styleUrls: ['./privado-editar.component.css']
})
export class PrivadoEditarComponent implements OnInit {

  privado: Private;
  privateId: number;
  billing: any;

  dateExpiration: any = "05/23";

  hide = true;
  hideC = true;
  loader: boolean = false;

  sourceUrl: any = environment.sourceUrl;

  tempImage: any;

  nombresFormControl = new FormControl('', [
    Validators.required,
  ])

  apePaternoFormControl = new FormControl('', [
    Validators.required,
  ])

  apeMaternoFormControl = new FormControl('', [
    Validators.required,
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  celFormControl = new FormControl('', [
    Validators.required,
  ])

  telFormControl = new FormControl('', [
    Validators.required,
  ])

  direccionFormControl = new FormControl('', [
    Validators.required,
  ])

  dateBirthFormControl = new FormControl('', [
    Validators.required,
  ])

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public activatedRoute: ActivatedRoute,
    private _privateService: PrivateService,
    public location: Location,
    private _router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
    ) {

    this.privado = new Private();
  }

  ngOnInit(): void {
    this.obtenerPrivado();
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.tempImage = reader.result;
    };
  }

  cancelar() {
    this.tempImage = null;
  }

  formatDateExpiration() {
    if(this.dateExpiration.length == 2) {
      this.dateExpiration += '/';
    }
  }

  obtenerPrivado() {
    this.activatedRoute.params.subscribe( params => {

      let id = params['id'];
      this.privateId = id;

      this._privateService.getPrivate(id).subscribe(
        response => {
          console.log('USUARIO PRIVADO', response);
          console.log('FECHA', new Date(response.data.patient.user.birth_date))
          console.log('FECHA solo', response.data.patient.user.birth_date)
          console.log('FECHA type', typeof(response.data.patient.user.birth_date))
          this.privado.name = response.data.patient.user.name;
          this.privado.lastnamepat = response.data.patient.user.lastnamepat;
          this.privado.lastnamemat = response.data.patient.user.lastnamemat;
          this.privado.email = response.data.patient.user.email;
          this.privado.birth_date = new Date(response.data.patient.user.birth_date);
          this.privado.gender = response.data.patient.user.gender;
          this.privado.address = response.data.patient.user.address;
          this.privado.phone = response.data.patient.user.phone;
          this.privado.telephone = response.data.patient.user.telephone;
          this.privado.image = response.data.patient.user.image;

          if(response.data.familiars.length > 0) {
            this.privado.name_first_familiar = response.data.familiars[0].name;
            this.privado.email_first_familiar = response.data.familiars[0].email;
            this.privado.phone_first_familiar = response.data.familiars[0].phone;
            this.privado.name_second_familiar = response.data.familiars[1].name;
            this.privado.email_second_familiar = response.data.familiars[1].email;
            this.privado.phone_second_familiar = response.data.familiars[1].phone;
          }
          if(response.data.billing!= null) {
            this.billing = 'true';
            this.privado.rfc = response.data.billing.rfc;
            this.privado.business_name = response.data.billing.business_name;
            this.privado.cfdi = response.data.billing.cfdi;
            this.privado.payment_method = response.data.billing.payment_method;
          }else {
            this.billing = 'false';
          }

        },
        error => {
          console.log(error)
        }
      )

    });
  }

  actualizarPrivado(f: NgForm) {
    this.loader = true;

    this.privado.billing = (this.billing == 'true')? true: false;

    this.privado.type = 'paciente';

    console.log('privado', this.privado)
    this.loader = false;

    if(this.tempImage) {
      this.privado.image = this.tempImage;
    }

    this._privateService.updatePrivate(this.privateId, this.privado).subscribe(
      response => {
        if(response.code == 409) {
          this.openError(response.error, 'Ok');
        }
        this.mostrarExitoDialog('PACIENTE ACTUALIZADO');
        setTimeout(() => {
          this.location.back();
          this.loader = false;
        }, 1500);
      },
      error => {
        console.log('ERROR SS',)
        this.loader = false;
        if(error.error.code == 409){
          this.openError(error.error.error, 'OK');
          this.emailFormControl.setErrors(error.error.error);
        } else {
          this.openError('Error al actualizar paciente', 'Ok');
        }
      }
    )

  }

  volver() {
    this._router.navigate(['/usuario/paciente/privados']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        // this._router.navigate(['/usuario/paciente/privados']);
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
