import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDoctorService } from 'src/app/services';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { environment } from '../../../../../../environments/environment';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-medico-general',
  templateUrl: './medico-general.component.html',
  styleUrls: ['./medico-general.component.css']
})
export class MedicoGeneralComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'name', 'email', 'phone', 'action'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  srcUrl: any;

  loader: boolean = true;

  medicos$: Subscription;
  deleteMedico$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _generalDoctorService: GeneralDoctorService
  ) {

    this.srcUrl = environment.sourceUrl;

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerMedicosGenerales();
  }

  buscarMedicoGeneral(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerMedicosGenerales() {
    this.loader = true;
    this.medicos$ = this._generalDoctorService.getGeneralDoctors().subscribe(
      response => {
        this.dataSource = fillTable(response.data.reverse(), this.paginator, this.sort);

        this.loader = false;
      },
      error => {
        this.loader = false;

      }
    )
  }

  eliminarMedicoGeneral(){
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este profesional medico general?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {



      }
    })
  }

  verFoto(foto) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${foto}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  verIne(ine) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${ine}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  verTitulo(titulo) {

      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: `${environment.sourceUrl}${titulo}`
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })

  }

  verCedula(cedula) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${cedula}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

}

  cambiarActivacion(element) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea cambiar la activación del profesional medico general?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this.deleteMedico$ = this._generalDoctorService.deleteGeneralDoctor(element.identifier, false).subscribe(
          response => {
            this.obtenerMedicosGenerales();
          },
          error => {
            this.obtenerMedicosGenerales();
            this.mostrarErrorDialog('Error en la activación');
          }
        )

      } else {
        this.obtenerMedicosGenerales();
      }
    })
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  ngOnDestroy(): void {
    if(this.medicos$) this.medicos$.unsubscribe();

    if(this.deleteMedico$) this.deleteMedico$.unsubscribe();
  }
}
