<h1 mat-dialog-title>Carga de excel de empleados</h1>
<div mat-dialog-content>
    <button mat-button (click)="verModelo()" type="button">Descargar Formato</button>
    &nbsp;
    <!-- <button mat-button (click)="cargarExcel()" type="button" class="btn-upload-excel">Cargar excel</button> -->
    <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="onFileChange($event)" accept=".csv, .xlsx"/>
    <button mat-button (click)="csvInput.click()" type="button" class="btn-upload-excel">Cargar excel</button>

    <p>{{file.name}}</p>
</div>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderCharge"></mat-progress-bar>

<div mat-dialog-actions>
    <button mat-button color="primary" (click)="onNoClick()">Cancelar</button>

    <button mat-button type="button" (click)="subirExcel()">Subir Excel</button>
</div>
