import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { Router } from '@angular/router';
import { PrivateService } from 'src/app/services';
import { Private } from 'src/app/models/private';
import { Location } from '@angular/common';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-privado-nuevo',
  templateUrl: './privado-nuevo.component.html',
  styleUrls: ['./privado-nuevo.component.css'],
})
export class PrivadoNuevoComponent implements OnInit {

  sourceUrl = environment.sourceUrl;

  privado: Private;
  tieneFacturacion = false;
  listOfOptions = [
    {"name": "Si", ID: "si", "checked": true},
    {"name": "No", ID: "no", "checked": false}
  ];

  chosenItem = "No";
  loader: boolean = false;

  dateExpiration: any;


  hide = true;
  hideC = true;


  nombresFormControl = new FormControl('', [
    Validators.required,
  ])

  apePaternoFormControl = new FormControl('', [
    Validators.required,
  ])

  apeMaternoFormControl = new FormControl('', [
    Validators.required,
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6)
  ])

  passConfFormControl = new FormControl('', [
    Validators.required,
    this.passwordMatcher.bind(this)
  ])

  celFormControl = new FormControl('', [
    Validators.required,
  ])

  telFormControl = new FormControl('', [
    Validators.required,
  ])

  direccionFormControl = new FormControl('', [
    Validators.required,
  ])

  dateBirthFormControl = new FormControl('', [
    Validators.required,
  ])

  matcher = new MyErrorStateMatcher();

  public passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (
        (control.value !== this.passFormControl.value)
    ) {
        return { passwordNotMatch: true };
    }
    return null;
  }

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _privateService: PrivateService,
    public location: Location,
    private _snackBar: MatSnackBar
    ) {
    this.privado = new Private();
    this.privado.billing = 'false';

  }

  ngOnInit(): void {
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.privado.image = reader.result;
    };
  }

  cancelar() {
    this.privado.image = null;
  }

  formatDateExpiration() {
    if(this.dateExpiration.length == 2) {
      this.dateExpiration += '/';
    }
  }

  registrarPrivado(f: NgForm) {
    this.loader = true;

    this.privado.type = 'paciente';

    this._privateService.savePrivate(this.privado).subscribe(
      response => {
        this.mostrarExitoDialog('PACIENTE PRIVADO REGISTRADO');
        setTimeout(() => {
          this.loader = false;
          f.resetForm();
          this._router.navigate(['/usuario/paciente/privados']);
        }, 1500);
      },
      error => {
        this.loader = false;
        console.log('error',error)
        if(error.error.code == 422) {
          this.openError('Correo existente, Ingrese otro.', 'Ok');
          return;
        }
        this.openError('Error al registrar paciente', 'Ok');
      }
    );


  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._router.navigate(['/usuario/paciente/privados']);
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  volver() {
    this._router.navigate(['/usuario/paciente/privados']);
  }

}
