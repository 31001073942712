<section>

    <div class="vex_soluciones_tiles">
        <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
        <h2 class="vex_soluciones_subtitle">MENSAJES</h2>
    </div>

    <div class="vex_soluciones_content">
        <div align="end">
            <mat-form-field>
                <mat-label>Buscador</mat-label>
                <input matInput (keyup)="buscarMensaje($event)" placeholder="Buscar">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            &nbsp;
            <div>
                <mat-form-field>
                    <mat-label>Seleccionar Fecha</mat-label>
                    <input matInput [matDatepicker]="picker" (dateChange)="seleccionarFecha($event.value)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>


        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort>

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                <td mat-cell *matCellDef="let element">
                    <button (click)="eliminarInbox()" mat-icon-button color="warn" class="vex_soluciones_btn_delete">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="emisor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> EMISOR </th>
                <td mat-cell *matCellDef="let element"> {{element.userTransmitter.name}} {{element.userTransmitter.lastnamepat}} </td>
            </ng-container>

            <ng-container matColumnDef="receptor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> RECEPTOR </th>
                <td mat-cell *matCellDef="let element"> {{element.userReceiver.name}} {{element.userReceiver.lastnamepat}} </td>
            </ng-container>

            <ng-container matColumnDef="mensaje">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> MENSAJE </th>
                <td mat-cell *matCellDef="let element"> {{element.message}} </td>
            </ng-container>

            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA </th>
                <td mat-cell *matCellDef="let element">
                    {{element.date | date}}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ESTADO </th>
                <td mat-cell *matCellDef="let element">
                    {{element.status}}
                </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="pin-size-th"> ACCIONES </th>
                <td mat-cell *matCellDef="let element">
                    <button color="primary" mat-icon-button (click)="verChat(element.room)">
                        <mat-icon>visibility</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8">No se encontraron registros.</td>
            </tr>
        </table>
        <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

    </div>

</section>
