export class Administrative {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  type: string;
  lastnamepat: string;
  lastnamemat: string;
  birth_date: string;
  gender: string;
  address: string;
  phone: string;
  telephone: string;
  image: string;
  role_id: number;
  status: any;

  constructor(
  ){}
}
