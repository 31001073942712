import { Component, OnInit } from '@angular/core';
import { GeneralDoctor } from 'src/app/models/general_doctor';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralDoctorService, UserService } from 'src/app/services';
import { environment } from '../../../../../../environments/environment';
import { NgForm } from '@angular/forms';
import { Password } from 'src/app/admin/configuracion/configuracion.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { UploadImage } from 'src/app/models/upload_image';
import { Location } from '@angular/common';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-medico-general-ver',
  templateUrl: './medico-general-ver.component.html',
  styleUrls: ['./medico-general-ver.component.css']
})
export class MedicoGeneralVerComponent implements OnInit {

  hide = true;
  hideC = true;
  hideA = true;

  loaderUpdPwd = false;

  medico: GeneralDoctor;
  medicoId: any;

  userId: any;

  password: Password;

  sourceUrl = environment.sourceUrl;

  uploadImage: UploadImage;
  tempImage: any;

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _medicoService: GeneralDoctorService,
    private _userService: UserService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    public dialog: MatDialog,
    public location: Location,
  ) {
    this.medico = new GeneralDoctor();
    this.password = new Password('', '', '');
    this.uploadImage = new UploadImage();
  }

  ngOnInit(): void {
    this.obtenerMedico();
  }

  obtenerMedico(): void {
    this._activatedRoute.params.subscribe(
      params => {
        this.medicoId = params['id'];

        this._medicoService.getGeneralDoctor(this.medicoId).subscribe(
          response => {
            console.log('MEDICO', response)
            this.medico.name = response.data.professional.user.name;
            this.medico.lastnamepat = `${response.data.professional.user.lastnamepat}`;
            this.medico.email = response.data.professional.user.email;
            this.medico.telephone = response.data.professional.user.telephone;
            this.medico.birth_date = response.data.professional.user.birth_date;
            this.medico.address = response.data.professional.user.address;
            this.userId = response.data.professional.user.id;
            this.medico.photo = `${environment.sourceUrl}${response.data.professional.photo}`;
            this.medico.image = response.data.professional.user.image;
            this.tempImage = response.data.professional.user.image;
            this.medico.university = response.data.university;
            this.medico.cedula = response.data.cedula;
            this.medico.title = response.data.title;
            this.medico.date_admission = response.data.professional.created_at;
            this.medico.ine = response.data.professional.ine;
            this.medico.status = response.data.active;

          },
          error => {
            this.openError('Médico general no encontrado', 'Ok');
          }
        )
      }
    )
  }

  actualizarContrasenia(f: NgForm) {
    this.loaderUpdPwd = true;
    this._userService.updatePassword(this.userId, this.password).subscribe(
      response => {
        this.loaderUpdPwd = false;
        if(response.code == 404) {
          this.openError('Error al actualizar contraseña', 'Ok');
        }else {
          f.resetForm();
          this.mostrarExitoDialog('Contraseña actualizada');
        }
      },
      error => {
        this.loaderUpdPwd = false;
        this.openError('Error al actualizar contraseña', 'Ok');
      }
    )
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.uploadImage.image = reader.result;
      this.tempImage = reader.result;
    };
  }

  guardarImagen() {

    this._userService.uploadImage(this.userId, this.uploadImage).subscribe(
      response => {
        console.log('response', response);
        console.log('response', response.data.image)
        this.tempImage = response.data.image;
        this.uploadImage.image = null;
        this.medico.image = response.data.image;

        this.mostrarExitoDialog('Imagen actualizada')

      },
      error => {
        console.log('error', error);
      }
    )
  }

  cancelar() {
    this.tempImage = this.medico.image;
    this.uploadImage.image = null;
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  verDocumento(documento) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${documento}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  volver() {
    this._router.navigate(['/usuario/profesional/medico-general']);
  }
}
