import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': "Bearer " + localStorage.getItem('token')
    })
};

@Injectable({
    providedIn: 'root'
})
export class ServiceService {

    constructor(
        private http: HttpClient,
    ) { }
    
    getService(id): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}services/${id}`);

    }

    getServices(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}services`, httpOptions);

    }

    updateService(id, data): Observable<any> {

        return this.http.patch<any>(`${environment.apiUrl}services/${id}`, data, httpOptions);

    }

    saveService(data): Observable<any> {

        return this.http.post<any>(`${environment.apiUrl}services`, data, httpOptions);

    }

    deleteService(id): Observable<any> {

        return this.http.delete<any>(`${environment.apiUrl}services/${id}`, httpOptions);

    }

}
