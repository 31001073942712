<section>
    <div class="row">
        <div class="col s12">
            <div class="vex_soluciones_tiles">
                <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
                <h2 class="vex_soluciones_subtitle">EDITAR PACIENTE PRIVADO</h2>
            </div>

            <div class="vex_soluciones_content">
                <form #f="ngForm" ngNativeValidate (ngSubmit)="actualizarPrivado(f)" (keydown.enter)="$event.preventDefault()">
                    <div class="row">
                        <div class="col s12 m4 l4 p-l-0">
                            <mat-card>
                                <h3>Datos Personales</h3>

                                <mat-divider></mat-divider>

                                <br>
                                <mat-form-field appearance="legacy">
                                    <mat-label>Nombre</mat-label>
                                    <input name="name" [formControl]="nombresFormControl" [errorStateMatcher]="matcher" matInput placeholder="Nombre" [(ngModel)]="privado.name" required>
                                    <mat-error *ngIf="nombresFormControl.hasError('required')">
                                        Nombres <strong>Requerido</strong>
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="legacy">
                                    <mat-label>Apellido Paterno</mat-label>
                                    <input name="apePaterno" [formControl]="apeMaternoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Apellido Paterno" [(ngModel)]="privado.lastnamepat" required>
                                    <mat-error *ngIf="apeMaternoFormControl.hasError('required')">
                                        Apellido Paterno <strong>Requerido</strong>
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="legacy">
                                    <mat-label>Apellido Materno</mat-label>
                                    <input name="apeMaterno" [formControl]="apePaternoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Apellido Materno" [(ngModel)]="privado.lastnamemat" required>
                                    <mat-error *ngIf="apePaternoFormControl.hasError('required')">
                                        Apellido Materno <strong>Requerido</strong>
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="legacy">
                                    <mat-label>Correo Electrónico</mat-label>
                                    <input name="email" [formControl]="emailFormControl" [errorStateMatcher]="matcher" type="email" matInput placeholder="Correo Electrónico" [(ngModel)]="privado.email" required>
                                    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                        Por favor ingrese un correo electrónico valido
                                    </mat-error>
                                    <mat-error *ngIf="emailFormControl.hasError('required')">
                                        Correo electrónico <strong>requerido</strong>
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>Fecha de nacimiento</mat-label>
                                    <input name="dateBirth" [formControl]="dateBirthFormControl" [errorStateMatcher]="matcher" (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="privado.birth_date" required>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-error *ngIf="dateBirthFormControl.hasError('required')">
                                        Fecha de nacimiento <strong>Requerido</strong>
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="legacy">
                                    <mat-label>Celular</mat-label>
                                    <input name="cel" [formControl]="celFormControl" [errorStateMatcher]="matcher" type="tel" matInput placeholder="Celular" [(ngModel)]="privado.phone" required>
                                    <mat-error *ngIf="celFormControl.hasError('required')">
                                        Celular <strong>Requerido</strong>
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="legacy">
                                    <mat-label>Teléfono</mat-label>
                                    <input name="tel" [formControl]="telFormControl" [errorStateMatcher]="matcher" type="tel" matInput placeholder="Teléfono" [(ngModel)]="privado.telephone" required>
                                    <mat-error *ngIf="telFormControl.hasError('required')">
                                        Teléfono <strong>Requerido</strong>
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="legacy">
                                    <mat-label>Dirección</mat-label>
                                    <input name="address" [formControl]="direccionFormControl" [errorStateMatcher]="matcher" matInput placeholder="Dirección" [(ngModel)]="privado.address" required>
                                    <mat-error *ngIf="direccionFormControl.hasError('required')">
                                        Dirección <strong>Requerida</strong>
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>Género</mat-label>
                                    <mat-select name="gender" [(ngModel)]="privado.gender" required>
                                        <mat-option value="Masculino" >
                                            Masculino
                                        </mat-option>
                                        <mat-option value="Femenino">
                                            Femenino
                                        </mat-option>
                                        <mat-option value="Otro">
                                          Otro
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>
                        <div class="col s12 m4 l4">

                            <mat-card>
                                <h3>Foto (Opcional)</h3>

                                <div class="center">
                                    <div class="img-content">
                                        <img class="vex-soluciones-img-profile" [src]="(tempImage)? tempImage : sourceUrl + privado.image" alt="" width="120">
                                    </div>
                                </div>

                                <div class="center">
                                    <button *ngIf="!tempImage" color="primary" type="button" (click)="photoFile.click()" mat-stroked-button>Subir foto</button>

                                    <input hidden (change)="subirImagen($event.target.files[0])" #photoFile type="file" accept="image/x-png,image/gif,image/jpeg,image/jpg">
                                </div>

                                <div class="center">

                                    <button mat-raised-button *ngIf="tempImage" type="button" (click)="cancelar()">Cancelar</button>

                                </div>

                            </mat-card>
                            <br>

                            <mat-card>
                                <h3>Primer Familiar</h3>
                                <mat-divider></mat-divider>

                                <br>
                                <mat-form-field appearance="legacy">
                                    <mat-label>Nombre del Familiar</mat-label>
                                    <input name="first_name" matInput placeholder="Nombre del Familiar" [(ngModel)]="privado.name_first_familiar">

                                </mat-form-field>

                                <mat-form-field appearance="legacy">
                                    <mat-label>Correo Electrónico del Familiar</mat-label>
                                    <input name="first_email" type="email" matInput placeholder="Correo Electrónico del Familiar" [(ngModel)]="privado.email_first_familiar">

                                </mat-form-field>

                                <mat-form-field appearance="legacy">
                                    <mat-label>Teléfono del Familiar</mat-label>
                                    <input name="first_tel" type="tel" matInput placeholder="Teléfono del Familiar" [(ngModel)]="privado.phone_first_familiar">

                                </mat-form-field>
                            </mat-card>
                            <br>
                            <mat-card>
                                <h3>Segundo Familiar</h3>
                                <mat-divider></mat-divider>

                                <br>
                                <mat-form-field appearance="legacy">
                                    <mat-label>Nombre del Familiar</mat-label>
                                    <input name="second_name" matInput placeholder="Nombre del Familiar" [(ngModel)]="privado.name_second_familiar">

                                </mat-form-field>

                                <mat-form-field appearance="legacy">
                                    <mat-label>Correo Electrónico del Familiar</mat-label>
                                    <input name="second_email" type="email" matInput placeholder="Correo Electrónico del Familiar" [(ngModel)]="privado.email_second_familiar">

                                </mat-form-field>

                                <mat-form-field appearance="legacy">
                                    <mat-label>Teléfono del Familiar</mat-label>
                                    <input name="second_tel" type="tel" matInput placeholder="Teléfono del Familiar" [(ngModel)]="privado.phone_second_familiar">

                                </mat-form-field>
                            </mat-card>

                        </div>
                        <div class="col s12 m4 l4">
                            <mat-card>
                                <h3>Datos de Tarjeta</h3>
                                <mat-divider></mat-divider>

                                <br>
                                <mat-form-field appearance="legacy">
                                    <mat-label>Nombre de Tarjeta</mat-label>
                                    <input name="card_name" matInput placeholder="Nombre de Tarjeta">

                                </mat-form-field>

                                <mat-form-field appearance="legacy">
                                    <mat-label>Número de Tarjeta</mat-label>
                                    <input name="card_number" matInput placeholder="Número de Tarjeta">

                                </mat-form-field>

                                <div class="row">
                                    <div class="col s6">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>CVV</mat-label>
                                            <input name="cvv" matInput placeholder="CVV">

                                        </mat-form-field>
                                    </div>
                                    <div class="col s6">
                                        <mat-form-field>
                                            <mat-label>Fecha de expiración</mat-label>
                                            <input name="date_expired" type="text" maxlength="5" matInput placeholder="MM/YY" (keyup)="formatDateExpiration()">

                                        </mat-form-field>
                                    </div>
                                </div>

                            </mat-card>
                            <br>
                            <mat-card>
                                <h3>Datos de Facturación</h3>
                                <mat-divider></mat-divider>

                                <br>
                                <h4 class="m-t-0">¿Posee Datos de Facturación?</h4>

                                <mat-radio-group name="billing" [ngModel]="billing" color="primary">
                                    <mat-radio-button value="true" (click)="billing = 'true'">Si</mat-radio-button>
                                    &nbsp;
                                    <mat-radio-button value="false" (click)="billing = 'false'">No</mat-radio-button>
                                </mat-radio-group>
                                <br>
                                <div *ngIf="billing == 'true'" style="margin-top: 25px;">
                                    <mat-form-field appearance="legacy">
                                        <mat-label>RFC</mat-label>
                                        <input name="rfc" matInput placeholder="RFC" [(ngModel)]="privado.rfc">
                                        <mat-error *ngIf="direccionFormControl.hasError('required')">
                                            Dirección <strong>Requerida</strong>
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field appearance="legacy">
                                        <mat-label>Razón Social</mat-label>
                                        <input name="reason" matInput placeholder="Razón Social" [(ngModel)]="privado.business_name">
                                        <mat-error *ngIf="direccionFormControl.hasError('required')">
                                            Dirección <strong>Requerida</strong>
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field appearance="legacy">
                                        <mat-label>Uso de CFDI</mat-label>
                                        <input name="cfdi" matInput placeholder="Uso de CFDI" [(ngModel)]="privado.cfdi">
                                        <mat-error *ngIf="direccionFormControl.hasError('required')">
                                            Dirección <strong>Requerida</strong>
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field appearance="legacy">
                                        <mat-label>Método de Pago</mat-label>
                                        <input name="payment_method" matInput placeholder="Método de Pago" [(ngModel)]="privado.payment_method">
                                        <mat-error *ngIf="direccionFormControl.hasError('required')">
                                            Dirección <strong>Requerida</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col s12 p-l-0" *ngIf="!loader">
                            <button type="button" mat-raised-button (click)="volver()">{{buttonName.BACK}}</button>
                            &nbsp;
                            <button type="submit" mat-raised-button color="primary">{{buttonName.UPDATE}}</button>
                        </div>
                        <div class="col s12 p-l-0" *ngIf="loader">
                            <mat-spinner diameter="40"></mat-spinner>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</section>
