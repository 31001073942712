import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services';
import { Location } from '@angular/common';
import { Company } from 'src/app/models/company';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { IButtonName, BUTTON_NAME } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-empresas-nuevo',
  templateUrl: './empresas-nuevo.component.html',
  styleUrls: ['./empresas-nuevo.component.css']
})
export class EmpresasNuevoComponent implements OnInit {

  company: Company;
  loader: boolean = false;

  empresaFormControl = new FormControl('', [
    Validators.required,
  ]);

  numeroAfiliacionFormControl = new FormControl('', [
    Validators.required,
  ]);

  direccionFormControl = new FormControl('', [
    Validators.required,
  ]);

  telefonoFormControl = new FormControl('', [
    Validators.required,
  ]);

  celularFormControl = new FormControl('', [
    Validators.required,
  ]);

  correoElectronicoFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);

  nombreRepresentanteFormControl = new FormControl('', [
    Validators.required,
  ]);

  apellidoPaternoRepresentanteFormControl = new FormControl('', [
    Validators.required,
  ]);

  apellidoMaternoRepresentaFormControl = new FormControl('', [
    Validators.required,
  ]);

  fechaNacimientoRepresentanteFormControl = new FormControl('', [
    Validators.required,
  ]);

  correoElectronicoRepresentanteFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);

  telefonoRepresentanteFormControl = new FormControl('', [
    Validators.required,
  ]);

  direccionRepresentanteFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    private _companyService: CompanyService,
    public location: Location,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _router: Router
  ) {
    this.company = new Company();
  }

  ngOnInit(): void {
  }

  registrarEmpresa(f: NgForm) {
    this.loader = true;

    this._companyService.saveCompany(this.company).subscribe(
      response => {
        this.mostrarExitoDialog('Empresa registrada');
        this.loader = false;
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.openError('Error al registrar empresa', 'ok');
        this.loader = false;
      }
    )

  }

  volver() {
    this._router.navigate(['/empresas']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {}
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
