import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { LoginGuard } from '../guards/login.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrivadoComponent } from './usuarios/pacientes/privado/privado/privado.component';
import { PrivadoNuevoComponent } from './usuarios/pacientes/privado/privado-nuevo/privado-nuevo.component';
import { PrivadoEditarComponent } from './usuarios/pacientes/privado/privado-editar/privado-editar.component';
import { ParamedicoComponent } from './usuarios/profesionales/paramedico/paramedico/paramedico.component';
import { ParamedicoNuevoComponent } from './usuarios/profesionales/paramedico/paramedico-nuevo/paramedico-nuevo.component';
import { ParamedicoEditarComponent } from './usuarios/profesionales/paramedico/paramedico-editar/paramedico-editar.component';
import { MedicoGeneralComponent } from './usuarios/profesionales/medico-general/medico-general/medico-general.component';
import { MedicoGeneralNuevoComponent } from './usuarios/profesionales/medico-general/medico-general-nuevo/medico-general-nuevo.component';
import { MedicoGeneralEditarComponent } from './usuarios/profesionales/medico-general/medico-general-editar/medico-general-editar.component';
import { EnfermeroComponent } from './usuarios/profesionales/enfermero/enfermero/enfermero.component';
import { EnfermeroNuevoComponent } from './usuarios/profesionales/enfermero/enfermero-nuevo/enfermero-nuevo.component';
import { EnfermeroEditarComponent } from './usuarios/profesionales/enfermero/enfermero-editar/enfermero-editar.component';
import { FlebotomistaComponent } from './usuarios/profesionales/flebotomista/flebotomista/flebotomista.component';
import { FlebotomistaNuevoComponent } from './usuarios/profesionales/flebotomista/flebotomista-nuevo/flebotomista-nuevo.component';
import { FlebotomistaEditarComponent } from './usuarios/profesionales/flebotomista/flebotomista-editar/flebotomista-editar.component';
import { PsicologoComponent } from './usuarios/profesionales/psicologo/psicologo/psicologo.component';
import { PsicologoNuevoComponent } from './usuarios/profesionales/psicologo/psicologo-nuevo/psicologo-nuevo.component';
import { PsicologoEditarComponent } from './usuarios/profesionales/psicologo/psicologo-editar/psicologo-editar.component';
import { RadiologoComponent } from './usuarios/profesionales/radiologo/radiologo/radiologo.component';
import { RadiologoNuevoComponent } from './usuarios/profesionales/radiologo/radiologo-nuevo/radiologo-nuevo.component';
import { RadiologoEditarComponent } from './usuarios/profesionales/radiologo/radiologo-editar/radiologo-editar.component';
import { AdministrativoComponent } from './usuarios/administrativo/administrativo/administrativo.component';
import { AdministrativoNuevoComponent } from './usuarios/administrativo/administrativo-nuevo/administrativo-nuevo.component';
import { AdministrativoEditarComponent } from './usuarios/administrativo/administrativo-editar/administrativo-editar.component';
import { UnidadesComponent } from './unidades/unidades/unidades/unidades.component';
import { UnidadesNuevoComponent } from './unidades/unidades/unidades-nuevo/unidades-nuevo.component';
import { UnidadesEditarComponent } from './unidades/unidades/unidades-editar/unidades-editar.component';
import { PanelMonitoreoComponent } from './unidades/panel-monitoreo/panel-monitoreo.component';
import { MantenimientoComponent } from './unidades/mantenimiento/mantenimiento/mantenimiento.component';
import { MantenimientoNuevoComponent } from './unidades/mantenimiento/mantenimiento-nuevo/mantenimiento-nuevo.component';
import { MantenimientoEditarComponent } from './unidades/mantenimiento/mantenimiento-editar/mantenimiento-editar.component';
import { GestionServicioComponent } from './servicios/servicios/gestion-servicio/gestion-servicio.component';
import { ServicioNuevoComponent } from './servicios/servicios/servicio-nuevo/servicio-nuevo.component';
import { ServicioEditarComponent } from './servicios/servicios/servicio-editar/servicio-editar.component';
import { GestionCuponesComponent } from './servicios/cupones/gestion-cupones/gestion-cupones.component';
import { CuponesNuevoComponent } from './servicios/cupones/cupones-nuevo/cupones-nuevo.component';
import { CuponesEditarComponent } from './servicios/cupones/cupones-editar/cupones-editar.component';
import { HistorialComponent } from './historiales/historial/historial.component';
import { HistorialNuevoComponent } from './historiales/historial-nuevo/historial-nuevo.component';
import { HistorialEditarComponent } from './historiales/historial-editar/historial-editar.component';
import { HistorialVerComponent } from './historiales/historial-ver/historial-ver.component';
import { ReportesComponent } from './reportes/reportes.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { AyudaComponent } from './ayuda/ayuda.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { RolesComponent } from './roles/roles/roles.component';
import { ContactoComponent } from './contacto/contacto.component';
import { ChatComponent } from './mensajes/chat/chat.component';
import { InboxComponent } from './mensajes/inbox/inbox.component';
import { RolesEditarComponent } from './roles/roles-editar/roles-editar.component';
import { RolesNuevoComponent } from './roles/roles-nuevo/roles-nuevo.component';
import { InboxNuevoComponent } from './mensajes/inbox-nuevo/inbox-nuevo.component';
import { EmpresasComponent } from './empresas/empresas/empresas.component';
import { EmpresasNuevoComponent } from './empresas/empresas-nuevo/empresas-nuevo.component';
import { EmpresasEditarComponent } from './empresas/empresas-editar/empresas-editar.component';
import { ServiciosAdicionalesAdmComponent } from './servicios/servicios-adicionales/servicios-adicionales-adm/servicios-adicionales-adm.component';
import { ServiciosAdicionalesNuevoComponent } from './servicios/servicios-adicionales/servicios-adicionales-nuevo/servicios-adicionales-nuevo.component';
import { CategoriasAdmComponent } from './servicios/categorias/categorias-adm/categorias-adm.component';
import { CategoriasNuevoComponent } from './servicios/categorias/categorias-nuevo/categorias-nuevo.component';
import { CategoriasEditarComponent } from './servicios/categorias/categorias-editar/categorias-editar.component';
import { ServiciosAdicionalesEditarComponent } from './servicios/servicios-adicionales/servicios-adicionales-editar/servicios-adicionales-editar.component';
import { GasolineAdmComponent } from './unidades/gasoline/gasoline-adm/gasoline-adm.component';
import { GasolineNuevoComponent } from './unidades/gasoline/gasoline-nuevo/gasoline-nuevo.component';
import { GasolineEditarComponent } from './unidades/gasoline/gasoline-editar/gasoline-editar.component';
import { AdmTareasComponent } from './tareas/adm-tareas/adm-tareas.component';
import { NewTaskComponent } from '../shareds/dialogs/tasks/new-task/new-task.component';
import { EditarTareaComponent } from './tareas/editar-tarea/editar-tarea.component';
import { NuevaTareaComponent } from './tareas/nueva-tarea/nueva-tarea.component';
import { PrivadoVerComponent } from './usuarios/pacientes/privado/privado-ver/privado-ver.component';
import { ParamedicoVerComponent } from './usuarios/profesionales/paramedico/paramedico-ver/paramedico-ver.component';
import { MedicoGeneralVerComponent } from './usuarios/profesionales/medico-general/medico-general-ver/medico-general-ver.component';
import { EnfermeroVerComponent } from './usuarios/profesionales/enfermero/enfermero-ver/enfermero-ver.component';
import { FlebotomistaVerComponent } from './usuarios/profesionales/flebotomista/flebotomista-ver/flebotomista-ver.component';
import { PsicologoVerComponent } from './usuarios/profesionales/psicologo/psicologo-ver/psicologo-ver.component';
import { RadiologoVerComponent } from './usuarios/profesionales/radiologo/radiologo-ver/radiologo-ver.component';
import { AdministrativoVerComponent } from './usuarios/administrativo/administrativo-ver/administrativo-ver.component';
import { EmpresasVerComponent } from './empresas/empresas-ver/empresas-ver.component';
import { UnidadesVerComponent } from './unidades/unidades/unidades-ver/unidades-ver.component';
import { FacturacionComponent } from './facturacion/facturacion.component';
import { ColaboradorComponent, ColaboradorEditarComponent, ColaboradorNuevoComponent, ColaboradorVerComponent } from './usuarios/pacientes/colaborador';

const pagesRoutes: Routes = [
    {
        path: '',
        component: AdminComponent,
        canActivate: [LoginGuard],
        children: [
            { path: 'dashboard', component: DashboardComponent, data: {titulo: 'Dashboard'} },
            { path: 'usuario/paciente/privados', component: PrivadoComponent, data: {titulo: 'Privados'} },
            { path: 'ver-paciente-privado/:id', component: PrivadoVerComponent, data: {titulo: 'Privados'} },
            { path: 'usuario/paciente/privados-nuevo', component: PrivadoNuevoComponent, data: {titulo: 'Privados'} },
            { path: 'usuario/paciente/privados-editar/:id', component: PrivadoEditarComponent, data: {titulo: 'Privados'} },
            { path: 'usuario/paciente/empresas', component: ColaboradorComponent, data: {titulo: 'Empresas'} },
            { path: 'usuario/paciente/empresas-nuevo', component: ColaboradorNuevoComponent, data: {titulo: 'Empresas'} },
            { path: 'usuario/paciente/empresas-editar/:id', component: ColaboradorEditarComponent, data: {titulo: 'Empresas'} },
            { path: 'ver-paciente-empresa/:id', component: ColaboradorVerComponent, data: {titulo: 'Ver Paciente de Empresa'} },
            { path: 'usuario/profesional/paramedicos', component: ParamedicoComponent, data: {titulo: 'Paramédico'} },
            { path: 'usuario/profesional/paramedicos-nuevo', component: ParamedicoNuevoComponent, data: {titulo: 'Paramédico'} },
            { path: 'usuario/profesional/paramedicos-editar/:id', component: ParamedicoEditarComponent, data: {titulo: 'Paramédico'} },
            { path: 'ver-profesional-paramedico/:id', component: ParamedicoVerComponent, data: {titulo: 'Ver Paramédico'} },
            { path: 'usuario/profesional/medico-general', component: MedicoGeneralComponent, data: {titulo: 'Medico-General'} },
            { path: 'usuario/profesional/medico-general-nuevo', component: MedicoGeneralNuevoComponent, data: {titulo: 'Medico-General'} },
            { path: 'usuario/profesional/medico-general-editar/:id', component: MedicoGeneralEditarComponent, data: {titulo: 'Medico-General'} },
            { path: 'ver-profesional-medico-general/:id', component: MedicoGeneralVerComponent, data: {titulo: 'Ver Medico General'} },
            { path: 'usuario/profesional/enfermero', component: EnfermeroComponent, data: {titulo: 'Enfermero'} },
            { path: 'usuario/profesional/enfermero-nuevo', component: EnfermeroNuevoComponent, data: {titulo: 'Enfermero'} },
            { path: 'usuario/profesional/enfermero-editar/:id', component: EnfermeroEditarComponent, data: {titulo: 'Enfermero'} },
            { path: 'ver-profesional-enfermero/:id', component: EnfermeroVerComponent, data: {titulo: 'Ver Enfermero'} },
            { path: 'usuario/profesional/flebotomista', component: FlebotomistaComponent, data: {titulo: 'Flebotomista'} },
            { path: 'usuario/profesional/flebotomista-nuevo', component: FlebotomistaNuevoComponent, data: {titulo: 'Flebotomista'} },
            { path: 'usuario/profesional/flebotomista-editar/:id', component: FlebotomistaEditarComponent, data: {titulo: 'Flebotomista'} },
            { path: 'ver-profesional-flebotomista/:id', component: FlebotomistaVerComponent, data: {titulo: 'Ver Flebotomista'} },
            { path: 'usuario/profesional/psicologo', component: PsicologoComponent, data: {titulo: 'Psicólogo'} },
            { path: 'usuario/profesional/psicologo-nuevo', component: PsicologoNuevoComponent, data: {titulo: 'Psicólogo'} },
            { path: 'usuario/profesional/psicologo-editar/:id', component: PsicologoEditarComponent, data: {titulo: 'Psicólogo'} },
            { path: 'ver-profesional-psicologo/:id', component: PsicologoVerComponent, data: {titulo: 'Ver Psicólogo'} },
            { path: 'usuario/profesional/radiologo', component: RadiologoComponent, data: {titulo: 'Radiólogo'} },
            { path: 'usuario/profesional/radiologo-nuevo', component: RadiologoNuevoComponent, data: {titulo: 'Radiólogo'} },
            { path: 'usuario/profesional/radiologo-editar/:id', component: RadiologoEditarComponent, data: {titulo: 'Radiólogo'} },
            { path: 'ver-profesional-radiologo/:id', component: RadiologoVerComponent, data: {titulo: 'Ver Radiólogo'} },
            { path: 'usuario/administrativo', component: AdministrativoComponent, data: {titulo: 'Administrativo'} },
            { path: 'usuario/administrativo-nuevo', component: AdministrativoNuevoComponent, data: {titulo: 'Administrativo'} },
            { path: 'usuario/administrativo-editar/:id', component: AdministrativoEditarComponent, data: {titulo: 'Administrativo'} },
            { path: 'ver-administrativo/:id', component: AdministrativoVerComponent, data: {titulo: 'Ver Administrativo'} },
            { path: 'facturacion', component: FacturacionComponent, data: {titulo: 'Facturación'} },
            { path: 'unidades', component: UnidadesComponent, data: {titulo: 'Unidades'} },
            { path: 'unidades-nuevo', component: UnidadesNuevoComponent, data: {titulo: 'Unidades'} },
            { path: 'unidades-editar/:id', component: UnidadesEditarComponent, data: {titulo: 'Unidades'} },
            { path: 'unidades-ver/:id', component: UnidadesVerComponent, data: {titulo: 'Unidades'} },
            { path: 'unidades/monitoreo', component: PanelMonitoreoComponent, data: {titulo: 'Panel de Monitoreo'} },
            { path: 'administrar-combustible', component: GasolineAdmComponent, data: {titulo: 'Administrar Combustible'} },
            { path: 'registrar-combustible', component: GasolineNuevoComponent, data: {titulo: 'Nuevo Combustible'} },
            { path: 'editar-combustible/:id', component: GasolineEditarComponent, data: {titulo: 'Editar Combustible'} },
            { path: 'unidades/mantenimiento', component: MantenimientoComponent, data: {titulo: 'Mantenimiento'} },
            { path: 'unidades/mantenimiento-nuevo', component: MantenimientoNuevoComponent, data: {titulo: 'Mantenimiento'} },
            { path: 'unidades/mantenimiento-editar/:id', component: MantenimientoEditarComponent, data: {titulo: 'Mantenimiento'} },
            { path: 'gestion-de-servicios', component: GestionServicioComponent, data: {titulo: 'Gestion de Servicios'} },
            { path: 'nuevo-servicio', component: ServicioNuevoComponent, data: {titulo: 'Gestion de Servicios'} },
            { path: 'editar-servicio/:id', component: ServicioEditarComponent, data: {titulo: 'Gestion de Servicios'} },
            { path: 'gestion-de-servicios-adicionales', component: ServiciosAdicionalesAdmComponent, data: {titulo: 'Gestion de Servicios Adicionales'} },
            { path: 'nuevo-servicio-adicional', component: ServiciosAdicionalesNuevoComponent, data: {titulo: 'Nuevo servicio adicional'} },
            { path: 'editar-servicio-adicional/:id', component: ServiciosAdicionalesEditarComponent, data: {titulo: 'Editar servicio adicional'} },
            { path: 'gestion-de-categoria-de-servicios-adicionales', component: CategoriasAdmComponent, data: {titulo: 'Gestion de categoria de servicio adicional'} },
            { path: 'nueva-categoria-de-servicio-adicional', component: CategoriasNuevoComponent, data: {titulo: 'Nueva categoria de servicio adicional'} },
            { path: 'editar-categoria-de-servicio-adicional/:id', component: CategoriasEditarComponent, data: {titulo: 'Editar categoria de servicio adicional'} },
            { path: 'servicios/gestion-de-cupones', component: GestionCuponesComponent, data: {titulo: 'Gestion de Cupones'} },
            { path: 'servicios/cupones-nuevo', component: CuponesNuevoComponent, data: {titulo: 'Gestion de Cupones'} },
            { path: 'servicios/cupones-editar/:id', component: CuponesEditarComponent, data: {titulo: 'Gestion de Cupones'} },
            { path: 'historial-de-atenciones', component: HistorialComponent, data: {titulo: 'Historial'} },
            { path: 'nueva-atencion', component: HistorialNuevoComponent, data: {titulo: 'Nueva atención'} },
            { path: 'editar-atencion/:id', component: HistorialEditarComponent, data: {titulo: 'Editar atención'} },
            { path: 'ver-atencion/:id', component: HistorialVerComponent, data: {titulo: 'Ver atención'} },
            { path: 'empresas', component: EmpresasComponent, data: {titulo: 'Empresas'} },
            { path: 'nueva-empresa', component: EmpresasNuevoComponent, data: {titulo: 'Nueva empresa'} },
            { path: 'editar-empresa/:id', component: EmpresasEditarComponent, data: {titulo: 'Editar empresa'} },
            { path: 'ver-empresa/:id', component: EmpresasVerComponent, data: {titulo: 'Ver empresa'} },
            { path: 'reportes-y-analitica', component: ReportesComponent, data: {titulo: 'Reportes y Analítica'} },
            { path: 'configuracion', component: ConfiguracionComponent, data: {titulo: 'Configuración'} },
            { path: 'ayuda', component: AyudaComponent, data: {titulo: 'Ayuda'} },
            { path: 'mensajes/bandeja-de-entrada', component: InboxComponent, data: {titulo: 'Bandeja de entrada'} },
            { path: 'mensajes/nueva-bandeja-de-entrada', component: InboxNuevoComponent, data: {titulo: 'Nueva Bandeja de entrada'} },
            { path: 'mensajes/chat-general', component: ChatComponent, data: {titulo: 'Chat general'} },
            { path: 'notificaciones', component: NotificacionesComponent, data: {titulo: 'Notificaciones'} },
            { path: 'roles-y-permisos', component: RolesComponent, data: {titulo: 'Roles y Permiso'} },
            { path: 'editar-roles-y-permisos/:id', component: RolesEditarComponent, data: {titulo: 'Editar Roles y Permiso'} },
            { path: 'nuevo-rol-y-permiso', component: RolesNuevoComponent, data: {titulo: 'Nuevo Rol y Permiso'} },
            { path: 'contacto', component: ContactoComponent, data: {titulo: 'Constacto'} },
            { path: 'administrar-tareas', component: AdmTareasComponent, data: {titulo: 'Administrar tareas'} },
            { path: 'nueva-tarea', component: NuevaTareaComponent, data: {titulo: 'Nueva tarea'} },
            { path: 'editar-tarea/:id', component: EditarTareaComponent, data: {titulo: 'Editar tarea'} },

            { path: '', redirectTo: '/iniciar-sesion', pathMatch: 'full' },
        ]
    }

];

export const ADMIN_ROUTES = RouterModule.forChild( pagesRoutes )
