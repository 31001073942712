<section>
    <div class="row">
        <div class="col s12">

            <div class="vex_soluciones_tiles">
                <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
                <h2 class="vex_soluciones_subtitle">PACIENTES DE EMPRESAS</h2>
            </div>

            <div class="vex_soluciones_content">
                <div align="end">
                    <mat-form-field>
                      <mat-label>Buscador</mat-label>
                      <input matInput (keyup)="buscarEmpresa($event)" placeholder="Buscar">
                      <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field appearance="legacy" class="filter_by_company">
                        <mat-label>Seleccionar Empresa</mat-label>
                        <mat-select (selectionChange)="obtenerEmpleadosPorEmpresa($event.value)">
                            <mat-option value="0">Todos</mat-option>
                            <mat-option *ngFor="let empresa of empresas" [value]="empresa.identifier">
                                {{empresa.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    &nbsp;
                    <button mat-raised-button color="primary" [routerLink]="['/usuario/paciente/empresas-nuevo']">
                      <mat-icon>add</mat-icon>
                      Agregar
                    </button>

                </div>

                &nbsp;
                <mat-card class="mat-elevation-z0 sc_card_more_options">
                    Todos los pacientes
                    <button class="sc_more_options" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            mat-menu-item
                            class="sc_more_options"
                            color="primary"
                            (click)="exporter.exportTable('xlsx', {fileName:'empresas'})">
                            <mat-icon>cloud_download</mat-icon>
                            <span>Exportar a Excel</span>
                        </button>

                    </mat-menu>
                </mat-card>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort matTableExporter #exporter="matTableExporter">

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-slide-toggle (click)="cambiarActivacion(row)" color="primary" [checked]="row.status == 'activado'"></mat-slide-toggle>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                      <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                    </ng-container>

                    <ng-container matColumnDef="sku">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU </th>
                      <td mat-cell *matCellDef="let row"> {{row.sku}} </td>
                    </ng-container>

                    <ng-container matColumnDef="name_company">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> EMPRESA </th>
                      <td mat-cell *matCellDef="let row"> {{row.name_company}} </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> PACIENTE </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="user-info-content">
                                <div class="user-img">
                                    <img class="img-profile" style="width: 100%; height: 100%;" [src]="url + row.image" alt="" width="42">
                                </div>
                                <div class="user-info">
                                    <a [routerLink]="['/ver-paciente-empresa', row.identifier]">{{row.name}}</a>
                                    <p class="m-t-0 m-b-0">{{row.email}}</p>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="gender">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> GENERO </th>
                        <td mat-cell *matCellDef="let row"> {{row.gender}} </td>
                    </ng-container>

                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> CELULAR </th>
                        <td mat-cell *matCellDef="let row"> {{row.phone}} </td>
                    </ng-container>

                    <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> DIRECCION </th>
                        <td mat-cell *matCellDef="let row"> {{row.address}} </td>
                    </ng-container>

                    <ng-container matColumnDef="telephone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> TELEFONO </th>
                        <td mat-cell *matCellDef="let row"> {{row.telephone}} </td>
                    </ng-container>

                    <ng-container matColumnDef="acciones">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="pin-size-th"> ACCIONES </th>
                        <td mat-cell *matCellDef="let row">
                            <button color="primary" mat-icon-button [routerLink]="['/ver-paciente-empresa', row.identifier]">
                                <mat-icon>visibility</mat-icon>
                            </button>
                            &nbsp;
                            <button color="primary" [routerLink]="['/usuario/paciente/empresas-editar', row.identifier]" mat-icon-button>
                                <mat-icon>edit</mat-icon>
                            </button>
                            &nbsp;

                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="5"></td>

                        <td class="mat-cell" colspan="1" *ngIf="!loader">No se encontraron registros</td>

                        <td class="mat-cell" colspan="1" *ngIf="loader">
                            <mat-spinner diameter="40"></mat-spinner>
                        </td>

                        <td class="mat-cell" colspan="4"></td>
                    </tr>

                </table>
                <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

            </div>

        </div>
    </div>
</section>

