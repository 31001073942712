import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': "Bearer " + localStorage.getItem('token')
    })
};

@Injectable({
    providedIn: 'root'
})
export class PatientService {

    constructor(
        private http: HttpClient,
    ) { }
    
    getPatient(id): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}patients/${id}`);

    }

    getPatients(params): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}patients?${params}`, httpOptions);

    }

    updatePatient(id, data): Observable<any> {

        return this.http.patch<any>(`${environment.apiUrl}patients/${id}`, data, httpOptions);

    }

    savePatient(data): Observable<any> {

        return this.http.post<any>(`${environment.apiUrl}patients`, data, httpOptions);

    }

    deletePatient(id): Observable<any> {

        return this.http.delete<any>(`${environment.apiUrl}patients/${id}`, httpOptions);

    }

}
