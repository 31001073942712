export const BUTTON_NAME: IButtonName = {
  STORE: 'Registrar',
  ADD: 'Agregar',
  UPDATE: 'Guardar Cambios',
  BACK: 'Volver'
}

export interface IButtonName {
  STORE: string;
  ADD: string;
  UPDATE: string;
  BACK: string;
}
