<section>
    <vex-section-title title="ADMINISTRACIÓN" subtitle="REPORTES Y ANALÍTICA"></vex-section-title>

    <div class="row">
        <div class="col s12 m6 l6" style="margin-bottom: 20px;">
            <mat-card>
                <div id="chartAttention"> </div>
            </mat-card>
        </div>

        <div class="col s12 m6 l6" style="margin-bottom: 20px;">
            <mat-card>
                <div id="chartPatient"> </div>
            </mat-card>
        </div>

        <div class="col s12 m6 l6" style="margin-bottom: 20px;">
            <mat-card>
                <div id="chartBussines"> </div>
            </mat-card>

        </div>


        <div class="col s12 m6 l6" style="margin-bottom: 20px;">
            <mat-card>
                <div id="chartMaintenances"> </div>
            </mat-card>

        </div>

        <div class="col s12 m6 l6" style="margin-bottom: 20px;">
            <mat-card>
                <div id="chartGasoline"> </div>
            </mat-card>

        </div>
    </div>

</section>
