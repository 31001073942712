<div>
    
    <h2 style="text-align: center;">Nueva tarea</h2>

    <div mat-dialog-content>
        <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarTarea(f)" (keydown.enter)="$event.preventDefault()">

            <mat-form-field appearance="legacy">
                <mat-label>Tarea</mat-label>
                <input matInput placeholder="Tarea" [(ngModel)]="tarea.name" required name="task">
            </mat-form-field>
            
            <mat-form-field class="example-full-width">
                <mat-label>Notas (opcional)</mat-label>
                <textarea matInput placeholder="Notas" [(ngModel)]="tarea.description" name="description"></textarea>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>Asignado a</mat-label>
                <mat-select [(ngModel)]="tarea.user_id" required name="assigned">
                    <mat-option *ngFor="let usuario of usuarios" [value]="usuario.identifier">
                        {{usuario.user.name}}
                        {{usuario.user.lastnamepat}}
                        {{usuario.user.lastnamemat}}
                        <b>{{usuario.type}}</b>
                    </mat-option>
                    
                </mat-select>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>Fecha (opcional)</mat-label>
                <input (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="tarea.date" name="date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="legacy">
                <mat-label>Hora (opcional)</mat-label>
                <input matInput placeholder="Hora" type="time" [(ngModel)]="tarea.hour" name="hour">
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>Prioridad</mat-label>
                <mat-select [(ngModel)]="tarea.priority" required name="priority">
                    <mat-option value="critico">
                        Crítico
                    </mat-option>
                    <mat-option value="alto">
                        Alto
                    </mat-option>
                    <mat-option value="normal">
                        Normal
                    </mat-option>
                    <mat-option value="bajo">
                        Bajo
                    </mat-option>
                    <mat-option value="menor">
                        Menor
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div mat-dialog-actions style="float: right;" *ngIf="!loader">
                <button class="btn-yes" mat-button mat-dialog-close="true" cdkFocusInitial> Cancelar </button>
                <button class="btn-no" mat-button cdkFocusInitial color="primary" type="submit"> Registrar </button>

                
            </div>
            <div class="row">
                <mat-spinner diameter="40" *ngIf="loader"></mat-spinner>
            </div>
        </form>

    </div>


</div>