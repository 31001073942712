
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
    <h1 mat-dialog-title>Administrar mantenimiento</h1>
    <div mat-dialog-content>
      <div class="row">
          <div class="col s12">
            <mat-form-field appearance="legacy">
                <mat-label>Unidad</mat-label>
                <mat-select disabled name="vehiculo" [(ngModel)]="mantenimiento.vehicle_id" required>
                <mat-option *ngFor="let unidad of unidades" [value]="unidad.id">
                    {{unidad.identifier}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Mantenimiento</mat-label>
                <textarea name="mantenimiento" matInput placeholder="Descripción del mantenimiento" [(ngModel)]="mantenimiento.description" required></textarea>
            </mat-form-field>

            <mat-form-field appearance="legacy">
                <mat-label>Fecha de mantenimiento</mat-label>
                <input name="fecha_mantenimiento" (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="mantenimiento.date" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

          </div>
      </div>
    </div>
    <div mat-dialog-actions>
    <button mat-button (click)="cancelar()" type="button">Cancelar</button>
    <button mat-button cdkFocusInitial type="submit" color="primary">{{data.isNew? 'Registrar': 'Actualizar'}}</button>
    </div>

</form>
