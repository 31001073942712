<section>
    <div class="row">
        <div class="col s1"></div>
        <div class="col s10">

            <div class="vex_soluciones_tiles">
                <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
                <h2 class="vex_soluciones_subtitle">VER EMPRESA</h2>
                <button class="vex-souluciones-back" mat-raised-button (click)="volver()">{{buttonName.BACK}}</button>
            </div>

            <div>

                <mat-tab-group>
                    <mat-tab label="DATOS GENERALES">
                        <div class="row padding-content">
                            <div class="col s3 p-l-0">
                                <mat-card>
                                    <div class="center">
                                        <div class="img-content">
                                            <img class="vex-soluciones-img-profile" [src]="(uploadImage.image)? tempImage: sourceUrl + tempImage" alt="" width="120">
                                        </div>
                                    </div>
                                    <h3 class="center m-b-0 vex-soluciones-name-title">{{empresa.name}}</h3>

                                    <div class="center">
                                        <button *ngIf="tempImage == empresa.image" mat-stroked-button color="primary" type="button" (click)="photoFile.click()">Cambiar Imagen</button>
                                        <input hidden (change)="subirImagen($event.target.files[0])" #photoFile type="file" accept="image/x-png,image/gif,image/jpeg,image/jpg">

                                    </div>

                                    <button mat-stroked-button *ngIf="tempImage != empresa.image" type="button" (click)="cancelar()">Cancelar</button>
                                    &nbsp;
                                    <button mat-stroked-button *ngIf="tempImage != empresa.image" type="button" (click)="guardarImagen()" color="primary">Guardar</button>


                                </mat-card>

                            </div>
                            <div class="col s9 p-r-0">
                                <mat-card>

                                    <div class="row">
                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Nombre</mat-label>
                                                <input matInput placeholder="Nombre" [(ngModel)]="empresa.name" readonly>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Número de afiliación</mat-label>
                                                <input matInput placeholder="Apellidos" [(ngModel)]="empresa.affiliation_number" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Dirección</mat-label>
                                                <input matInput placeholder="Correo Electrónico" [(ngModel)]="empresa.address" readonly>
                                            </mat-form-field>

                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Teléfono</mat-label>
                                                <input matInput placeholder="Teléfono" [(ngModel)]="empresa.phone" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Celular</mat-label>
                                                <input matInput placeholder="Domicilio Fiscal" [(ngModel)]="empresa.celphone" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Correo electrónico</mat-label>
                                                <input matInput placeholder="Domicilio Fiscal" [(ngModel)]="empresa.email" readonly>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="DATOS REPRESENTANTE">
                        <div class="row padding-content">
                            <div class="col s3 p-l-0">
                                <mat-card>
                                    <div class="center">
                                        <div class="img-content">
                                            <img class="vex-soluciones-img-profile" [src]="(uploadImageRep.image)? tempImageRep: sourceUrl + tempImageRep" alt="" width="120">
                                        </div>
                                    </div>
                                    <h3 class="center m-b-0 vex-soluciones-name-title">{{empresa.representative_name}} {{empresa.representative_lastnamepat}}</h3>

                                    <div class="center">
                                        <button *ngIf="tempImageRep == empresa.representative_image" mat-stroked-button color="primary" type="button" (click)="photoFileRep.click()">Cambiar Imagen</button>
                                        <input hidden (change)="subirImagenRep($event.target.files[0])" #photoFileRep type="file" accept="image/x-png,image/gif,image/jpeg,image/jpg">

                                    </div>

                                    <button mat-stroked-button *ngIf="tempImageRep != empresa.representative_image" type="button" (click)="cancelarRep()">Cancelar</button>
                                    &nbsp;
                                    <button mat-stroked-button *ngIf="tempImageRep != empresa.representative_image" type="button" (click)="guardarImagenRep()" color="primary">Guardar</button>

                                </mat-card>

                            </div>
                            <div class="col s9 p-r-0">
                                <mat-card>

                                    <div class="row">
                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Nombre</mat-label>
                                                <input matInput placeholder="Nombre" [(ngModel)]="empresa.representative_name" readonly>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Apellido Paterno</mat-label>
                                                <input matInput placeholder="Apellidos" [(ngModel)]="empresa.representative_lastnamepat" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Apellido Materno</mat-label>
                                                <input matInput placeholder="Correo Electrónico" [(ngModel)]="empresa.representative_lastnamemat" readonly>
                                            </mat-form-field>

                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Fecha de nacimiento</mat-label>
                                                <input matInput placeholder="Teléfono" [(ngModel)]="empresa.representative_birth_date" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Correo electrónico</mat-label>
                                                <input matInput placeholder="Domicilio Fiscal" [(ngModel)]="empresa.representative_email" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Teléfono</mat-label>
                                                <input matInput placeholder="Domicilio Fiscal" [(ngModel)]="empresa.representative_phone" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Dirección</mat-label>
                                                <input matInput placeholder="Domicilio Fiscal" [(ngModel)]="empresa.representative_address" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Género</mat-label>
                                                <input matInput placeholder="Domicilio Fiscal" [(ngModel)]="empresa.representative_gender" readonly>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="AFILIADOS">
                        <div class="row padding-content">
                            <div class="col s12 p-r-0 p-l-0">
                                <mat-form-field class="searcher-atention" style="width: auto">
                                  <mat-label>Buscar</mat-label>
                                  <input matInput (keyup)="applyFilter($event)" placeholder="Buscar Atención" #input>
                                  <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>


                                <button mat-stroked-button color="primary" class="pindoctor-btn-excel" type="button" (click)="cargarExcel()"><mat-icon>description</mat-icon>Cargar Excel</button>
                                &nbsp;
                                <button mat-stroked-button color="primary" class="pindoctor-btn-excel" type="button" [routerLink]="['/usuario/paciente/empresas-nuevo']">
                                  <mat-icon>add</mat-icon>
                                  Agregar
                                </button>
                                &nbsp;

                                <div class="mat-elevation-z4">
                                    <table mat-table [dataSource]="dataSource" matSort>

                                        <!-- ID Column -->
                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                                        </ng-container>

                                        <!-- Progress Column -->
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                            <td mat-cell *matCellDef="let row"> {{row.patient.user.name}} {{row.patient.user.lastnamepat}} </td>
                                        </ng-container>

                                        <!-- Name Column -->
                                        <ng-container matColumnDef="email">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Correo electrónico </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.patient.user.email}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="job">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Puesto de trabajo </th>
                                            <td mat-cell *matCellDef="let row"> {{row.job}}</td>
                                        </ng-container>
                                        <!-- Color Column -->
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acción </th>
                                            <td mat-cell *matCellDef="let row" [style.color]="row.date">
                                                <button color="primary" mat-icon-button [routerLink]="['/ver-paciente-empresa', row.id]">
                                                    <mat-icon>visibility</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                                        <!-- Row shown when there is no matching data. -->
                                        <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro "{{input.value}}"</td>
                                        </tr>
                                    </table>

                                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="ATENCIONES">

                    </mat-tab>
                    <mat-tab label="FACTURACIÓN">

                    </mat-tab>
                </mat-tab-group>

            </div>

        </div>
        <div class="col s1"></div>
    </div>
</section>
