<section>

    <vex-section-title title="ADMINISTRACIÓN" subtitle="PANEL DE MONITOREO"></vex-section-title>

    <div class="vex_soluciones_content">
      <mat-card class="vex_soluciones_card">
        <h3>Unidades Activas</h3>
        <agm-map #AgmMap [fullscreenControl]="true" minZoom="3" [zoom]="13" [fitBounds]="true" [latitude]="lat" [longitude]="lng">
          <ng-container *ngFor="let paramedic of paramedicos">
            <agm-marker [agmFitBounds]="true"
                *ngIf="paramedic.vehicle[0]?.state == 'online' && paramedic.vehicle[0]?.status == 'disponible' "
                [latitude]="paramedic.professional.user.latitude"
                [longitude]="paramedic.professional.user.longitude"
                [label]="{ color: 'black', marginTop: '10px', fontFamily: '', fontSize: '14px', fontWeight: 'bold', text: 'PIN00' + paramedic.identifier}"
                [iconUrl]="icon">
                <agm-info-window>
                    <mat-list>
                        <div mat-subheader>Detalle del vehículo {{'PIN00' + paramedic.identifier}}</div>

                        <!-- <mat-list-item>
                            <mat-icon mat-list-icon>task_alt</mat-icon>
                            <div mat-line>ID</div>
                            <div mat-line> {{'PIN 00' + paramedic.identifier}} </div>
                        </mat-list-item> -->

                        <mat-list-item>
                            <mat-icon mat-list-icon>directions_car</mat-icon>
                            <div mat-line> {{paramedic.vehicle[0].plete}} </div>
                            <div mat-line>Placa </div>

                        </mat-list-item>

                        <mat-list-item>
                            <mat-icon mat-list-icon>home_repair_service</mat-icon>
                            <div mat-line>"{{paramedic.vehicle[0].type_equipment}}" </div>
                            <div mat-line>Equipamiento </div>

                        </mat-list-item>


                        <mat-list-item>
                          <mat-icon mat-list-icon>person_pin</mat-icon>
                          <div mat-line>{{paramedic['professional']['user']['name'] + ' ' + paramedic['professional']['user']['lastnamepat']}}</div>
                          <div mat-line> Paramédico </div>
                      </mat-list-item>
                    </mat-list>
                </agm-info-window>
            </agm-marker>
          </ng-container>
        </agm-map>

    </mat-card>

    </div>

</section>
