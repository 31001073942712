import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { ServiceService } from 'src/app/services';
import { Service } from 'src/app/models/service';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-servicio-nuevo',
  templateUrl: './servicio-nuevo.component.html',
  styleUrls: ['./servicio-nuevo.component.css']
})
export class ServicioNuevoComponent implements OnInit {

  servicio: Service;
  servicioId: any;
  loader: boolean = false;

  nombreFormControl = new FormControl('', [
    Validators.required,
  ]);

  descripcionFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public location: Location,
    private _router: Router,
    private _serviceService: ServiceService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.servicio = new Service();
  }

  ngOnInit(): void {

  }

  registrarServicio(f: NgForm) {
    this.loader = true;

    this._serviceService.saveService(this.servicio).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('Servicio registrado exitosamente');
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.loader = false;
        this.openError('Error al registrar servicio', 'Ok');
      }
    )

  }

  volver() {
    this._router.navigate(['/gestion-de-servicios']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
