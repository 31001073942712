import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { RoleService } from 'src/app/services';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['identifier', 'name', 'description', 'activacion', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  roles: any;

  loader: boolean;

  roles$: Subscription;
  deleteRole$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _roleService: RoleService,
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.obtenerRoles();
  }

  buscarRol(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerRoles() {
    this.loader = true;
    this._roleService.getRoles().subscribe(
      response => {
        console.log(response)
        this.dataSource = fillTable(response.data, this.paginator, this.sort);

        this.loader = false;
      },
      error => {
        this.loader = false;
        console.log('error', error);
      }
    )
  }

  cambiarActivacion(rol) {
    let message = "Desea activar este rol";
    if(rol.status == "activado"){
      message = "Desea desactivar este rol"
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {
        this._roleService.deleteRole(rol.identifier).subscribe(
          response => {
            this.mostrarExitoDialog('Cambio de activacion realizada');
          },
          error => {
            this.mostrarErrorDialog('Error en el cambio de activación');

          }
        )
      }

      this.obtenerRoles();
    })

  }

  eliminarRol(id) {
    this._roleService.deleteRole(id).subscribe(
      response => {
        this.mostrarExitoDialog("Rol eliminado");
        this.obtenerRoles();
      },
      error => {
        this.mostrarErrorDialog("Error al eliminar rol");
      }
    )
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  ngOnDestroy(): void {
    if(this.roles$) this.roles$.unsubscribe();

    if(this.deleteRole$) this.deleteRole$.unsubscribe();
  }

}
