import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';

import { ADMIN_ROUTES } from './admin.routes';

import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shareds/shared.module';
import { AdminComponent } from './admin.component';
import { LoginGuard } from '../guards/login.guard';
import { LayoutModule } from '@angular/cdk/layout';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';   // agm-direction
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGrigPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/daygrid'; // for dateClick
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PrivadoComponent, PrivadoNuevoComponent, PrivadoEditarComponent, PrivadoVerComponent } from './usuarios/pacientes/privado';
import { ParamedicoComponent, ParamedicoNuevoComponent, ParamedicoEditarComponent, ParamedicoVerComponent } from './usuarios/profesionales/paramedico';
import { MedicoGeneralComponent, MedicoGeneralNuevoComponent, MedicoGeneralEditarComponent, MedicoGeneralVerComponent } from './usuarios/profesionales/medico-general';
import { EnfermeroComponent, EnfermeroNuevoComponent, EnfermeroEditarComponent, EnfermeroVerComponent } from './usuarios/profesionales/enfermero';
import { FlebotomistaComponent, FlebotomistaNuevoComponent, FlebotomistaEditarComponent, FlebotomistaVerComponent } from './usuarios/profesionales/flebotomista';
import { PsicologoComponent, PsicologoNuevoComponent, PsicologoEditarComponent, PsicologoVerComponent } from './usuarios/profesionales/psicologo';
import { RadiologoComponent, RadiologoNuevoComponent, RadiologoEditarComponent, RadiologoVerComponent } from './usuarios/profesionales/radiologo';
import { AdministrativoComponent, AdministrativoNuevoComponent, AdministrativoEditarComponent, AdministrativoVerComponent } from './usuarios/administrativo';
import { UnidadesComponent, UnidadesNuevoComponent, UnidadesEditarComponent, UnidadesVerComponent } from './unidades/unidades';
import { PanelMonitoreoComponent } from './unidades/panel-monitoreo/panel-monitoreo.component';
import { MantenimientoComponent, MantenimientoNuevoComponent, MantenimientoEditarComponent } from './unidades/mantenimiento/';
import { GestionServicioComponent, ServicioNuevoComponent, ServicioEditarComponent } from './servicios/servicios';
import { GestionCuponesComponent, CuponesNuevoComponent, CuponesEditarComponent } from './servicios/cupones';
import { HistorialComponent, HistorialNuevoComponent, HistorialEditarComponent, HistorialVerComponent } from './historiales';
import { ReportesComponent } from './reportes/reportes.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { AyudaComponent } from './ayuda/ayuda.component';
import { MensajesComponent } from './mensajes/mensajes.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { RolesComponent } from './roles/roles/roles.component';
import { ContactoComponent } from './contacto/contacto.component';
import { PipesModule } from '../pipes/pipes.module';
import { RolesEditarComponent, RolesNuevoComponent } from './roles';
import { InboxNuevoComponent, InboxEditarComponent, InboxComponent, ChatComponent } from './mensajes';
import { EmpresasComponent, EmpresasEditarComponent, EmpresasNuevoComponent, EmpresasVerComponent } from './empresas';
import { CategoriasAdmComponent, CategoriasEditarComponent, CategoriasNuevoComponent } from './servicios/categorias';
import { ServiciosAdicionalesAdmComponent, ServiciosAdicionalesNuevoComponent, ServiciosAdicionalesEditarComponent } from './servicios/servicios-adicionales';
import { GasolineAdmComponent, GasolineEditarComponent, GasolineNuevoComponent } from './unidades/gasoline';
import { ChartsModule } from 'ng2-charts';
import { NuevaTareaComponent, EditarTareaComponent, AdmTareasComponent } from './tareas';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";
import { MatTableExporterModule } from "mat-table-exporter";
import { NgApexchartsModule } from "ng-apexcharts";
import { FacturacionComponent } from "./facturacion/facturacion.component";
import { ColaboradorComponent, ColaboradorEditarComponent, ColaboradorNuevoComponent, ColaboradorVerComponent } from "./usuarios/pacientes/colaborador";

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
    dayGrigPlugin,
    interactionPlugin
]);

const config: SocketIoConfig = { url: 'https://socket.pindoctor.mx/', options: {} };

@NgModule({
    declarations: [
        DashboardComponent,
        AdminComponent,
        PrivadoComponent,
        PrivadoNuevoComponent,
        PrivadoEditarComponent,
        ColaboradorComponent,
        ColaboradorNuevoComponent,
        ColaboradorEditarComponent,
        ColaboradorVerComponent,
        ParamedicoComponent,
        ParamedicoNuevoComponent,
        ParamedicoEditarComponent,
        MedicoGeneralComponent,
        MedicoGeneralNuevoComponent,
        MedicoGeneralEditarComponent,
        EnfermeroComponent,
        EnfermeroNuevoComponent,
        EnfermeroEditarComponent,
        FlebotomistaComponent,
        FlebotomistaNuevoComponent,
        FlebotomistaEditarComponent,
        PsicologoComponent,
        PsicologoNuevoComponent,
        PsicologoEditarComponent,
        RadiologoComponent,
        RadiologoNuevoComponent,
        RadiologoEditarComponent,
        AdministrativoComponent,
        AdministrativoNuevoComponent,
        AdministrativoEditarComponent,
        UnidadesComponent,
        PanelMonitoreoComponent,
        MantenimientoComponent,
        GestionServicioComponent,
        GestionCuponesComponent,
        HistorialComponent,
        ReportesComponent,
        ConfiguracionComponent,
        AyudaComponent,
        MensajesComponent,
        NotificacionesComponent,
        RolesComponent,
        ContactoComponent,
        UnidadesNuevoComponent,
        UnidadesEditarComponent,
        MantenimientoNuevoComponent,
        MantenimientoEditarComponent,
        CuponesNuevoComponent,
        CuponesEditarComponent,
        HistorialNuevoComponent,
        HistorialEditarComponent,
        ServicioNuevoComponent,
        ServicioEditarComponent,
        InboxComponent,
        ChatComponent,
        RolesEditarComponent,
        RolesNuevoComponent,
        InboxNuevoComponent,
        InboxEditarComponent,
        EmpresasComponent,
        EmpresasNuevoComponent,
        EmpresasEditarComponent,
        CategoriasAdmComponent,
        CategoriasNuevoComponent,
        CategoriasEditarComponent,
        ServiciosAdicionalesAdmComponent,
        ServiciosAdicionalesNuevoComponent,
        ServiciosAdicionalesEditarComponent,
        GasolineAdmComponent,
        GasolineNuevoComponent,
        GasolineEditarComponent,
        NuevaTareaComponent,
        EditarTareaComponent,
        AdmTareasComponent,
        PrivadoVerComponent,
        AdministrativoVerComponent,
        EnfermeroVerComponent,
        FlebotomistaVerComponent,
        MedicoGeneralVerComponent,
        ParamedicoVerComponent,
        PsicologoVerComponent,
        RadiologoVerComponent,
        HistorialVerComponent,
        EmpresasVerComponent,
        UnidadesVerComponent,
        FacturacionComponent
    ],
    exports: [
        DashboardComponent,
        AdminComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        SharedModule,
        FormsModule,
        ADMIN_ROUTES,
        MaterialModule,
        LayoutModule,
        ReactiveFormsModule,
        NgApexchartsModule,
        SocketIoModule.forRoot(config),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAvr3i8Jk1d19Ul7CwozyzpWyyUGuBJiqY',
            // apiKey: 'AIzaSyC8RiBdKnFHgl7KBwENhPr8fCoYSRbPXX4',
            // apiKey: 'AIzaSyA56-oj2oRA4dtXdE4e6mnm_j2Sj6YstS0',
            libraries: ["places"]
            // nuevo apiKey: 'AIzaSyCF8nNsPMhQkUMWLlmMCJwtthTrV-MOUfM'
            // apiKey: 'AIzaSyDTDyKeKBUMtXHMShLNVX85OpRpOiJ0rOo'
        }),
        AgmDirectionModule,
        BrowserAnimationsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        PipesModule,
        ChartsModule,
        FullCalendarModule,
        MatTableExporterModule
    ],
    entryComponents: [
    ],
    providers: [ LoginGuard ]
})

export class AdminModule {}
