export class Private {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  type: string;
  lastnamepat: string;
  lastnamemat: string;
  birth_date: any;
  gender: string;
  address: string;
  phone: string;
  telephone: string;
  image: any;
  name_first_familiar: string;
  email_first_familiar: string;
  phone_first_familiar: string;
  name_second_familiar: string;
  email_second_familiar: string;
  phone_second_familiar: string;
  billing: any;
  rfc: string;
  business_name: string;
  cfdi: string;
  payment_method: string;
  status: any;
    constructor(
    ){}
}
