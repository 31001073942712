<section>

    <div class="vex_soluciones_tiles">
        <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
        <h2 class="vex_soluciones_subtitle">NUEVO ADMINISTRATIVO</h2>
    </div>

    <div class="vex_soluciones_content">
        <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarAdministrativo(f)" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col s12 m4 l4 p-l-0">
                    <mat-card>
                        <h3>Datos Personales</h3>

                        <mat-form-field appearance="legacy">
                            <mat-label>Nombres</mat-label>
                            <input name="name" [formControl]="nombresFormControl" [errorStateMatcher]="matcher"  matInput placeholder="Nombres" [(ngModel)]="administrative.name" required>
                            <mat-error *ngIf="nombresFormControl.hasError('required')">
                                Nombres <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Apellido Paterno</mat-label>
                            <input name="apePaterno" [formControl]="apeMaternoFormControl" [errorStateMatcher]="matcher"  matInput placeholder="Apellido Paterno" [(ngModel)]="administrative.lastnamepat" required>
                            <mat-error *ngIf="apeMaternoFormControl.hasError('required')">
                                Apellido Paterno <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Apellido Materno</mat-label>
                            <input name="apeMaterno" [formControl]="apePaternoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Apellido Materno" [(ngModel)]="administrative.lastnamemat" required>
                            <mat-error *ngIf="apePaternoFormControl.hasError('required')">
                                Apellido Materno <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Correo Electrónico</mat-label>
                            <input name="email" [formControl]="emailFormControl" [errorStateMatcher]="matcher" type="email" matInput placeholder="Correo Electrónico" [(ngModel)]="administrative.email" required>

                            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                Por favor ingrese un correo electrónico valido
                            </mat-error>
                            <mat-error *ngIf="emailFormControl.hasError('required')">
                                Correo electrónico <strong>requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Constraseña</mat-label>
                            <input autocomplete="new-password" name="password" [formControl]="passFormControl" [errorStateMatcher]="matcher" matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="administrative.password" required>
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>

                            <mat-error *ngIf="passFormControl.hasError('required')">
                                Contraseña <strong>Requerida</strong>
                            </mat-error>
                            <mat-error *ngIf="!passFormControl.hasError('minLength')">
                                Minimo caracteres <strong>6</strong>
                            </mat-error>

                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Confirmar Constraseña</mat-label>
                            <input name="password_confirmation" [formControl]="passConfFormControl" [errorStateMatcher]="matcher" matInput [type]="hideC ? 'password' : 'text'" [(ngModel)]="administrative.password_confirmation" required>
                            <button mat-icon-button matSuffix (click)="hideC = !hideC" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideC">
                                <mat-icon>{{hideC ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="passConfFormControl.hasError('required')">
                                Confirmación de Contraseña <strong>Requerida</strong>
                            </mat-error>
                            <mat-error *ngIf="passwordMatcher">
                                Confirmación de Contraseña <strong>No coincide</strong>
                            </mat-error>

                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Celular</mat-label>
                            <span matPrefix>+52 &nbsp;</span>
                            <input name="cel" [formControl]="celFormControl" [errorStateMatcher]="matcher" matInput placeholder="93-843-451" [(ngModel)]="administrative.phone" required>
                            <mat-error *ngIf="celFormControl.hasError('required')">
                                Celular <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Teléfono</mat-label>
                            <span matPrefix>+52 &nbsp;</span>
                            <input name="tel" [formControl]="telFormControl" [errorStateMatcher]="matcher" matInput placeholder="665-478" [(ngModel)]="administrative.telephone" required>
                            <mat-error *ngIf="telFormControl.hasError('required')">
                                Teléfono <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Dirección</mat-label>
                            <input name="address" [formControl]="direccionFormControl" [errorStateMatcher]="matcher" matInput placeholder="Direcciòn" [(ngModel)]="administrative.address" required>
                            <mat-error *ngIf="direccionFormControl.hasError('required')">
                                Dirección <strong>Requerida</strong>
                            </mat-error>

                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Fecha de nacimiento</mat-label>
                            <input name="dateBirth" [formControl]="dateBirthFormControl" [errorStateMatcher]="matcher" (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="administrative.birth_date" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="dateBirthFormControl.hasError('required')">
                                Fecha de nacimiento <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <br>
                        <label id="example-radio-group-label">Género</label>
                        <br>
                        <br>
                        <mat-radio-group name="gender" color="primary" [(ngModel)]="administrative.gender" aria-labelledby="example-radio-group-label" required>
                            <mat-radio-button value="Masculino" checked>Masculino</mat-radio-button>
                            <mat-radio-button value="Femenino">Femenino</mat-radio-button>
                        </mat-radio-group>
                    </mat-card>
                </div>
                <div class="col s12 m5 l5">
                    <mat-card>
                        <h3>Foto (Opcional)</h3>

                        <div class="center">
                            <div class="img-content">
                                <img class="vex-soluciones-img-profile" [src]="(administrative.image)? administrative.image: sourceUrl + 'images/usuarios/default.png'" alt="" width="120">
                            </div>
                        </div>

                        <div class="center">
                            <button *ngIf="!administrative.image" color="primary" type="button" (click)="photoFile.click()" mat-stroked-button>Subir foto</button>

                            <input hidden (change)="subirImagen($event.target.files[0])" #photoFile type="file" accept="image/x-png,image/gif,image/jpeg,image/jpg">

                        </div>

                        <div class="center">
                            <button mat-raised-button *ngIf="administrative.image" type="button" (click)="cancelar()">Cancelar</button>

                        </div>

                    </mat-card>
                    <br>
                    <mat-card>
                        <h3>Rol</h3>

                        <mat-radio-group name="role" color="primary" [(ngModel)]="administrative.role_id" required>
                            <mat-radio-button [value]="role.identifier" *ngFor="let role of roles">{{role.name}}</mat-radio-button>
                        </mat-radio-group>
                    </mat-card>

                </div>

            </div>
            <br>
            <div class="row">
                <div class="col s12 p-l-0" *ngIf="!loader">
                    <button type="button" mat-raised-button (click)="volver()">{{buttonName.BACK}}</button>
                    &nbsp;
                    <button type="submit" mat-raised-button color="primary">{{buttonName.STORE}}</button>
                </div>
                <div class="col s12" *ngIf="loader">
                    <mat-spinner diameter="40"></mat-spinner>
                </div>
            </div>
        </form>

    </div>
</section>
