<section>

    <div class="vex_soluciones_tiles">
        <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
        <h2 class="vex_soluciones_subtitle">EDITAR ENFERMERO</h2>
    </div>

    <div class="vex_soluciones_content">
        <form #f="ngForm" ngNativeValidate (ngSubmit)="editarEnfermero(f)" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col s12 m4 l4 p-l-0">
                    <mat-card>
                        <h3>Datos Personales</h3>

                        <mat-form-field appearance="legacy">
                            <mat-label>Nombre</mat-label>
                            <input name="name" [formControl]="nombresFormControl" [errorStateMatcher]="matcher" matInput placeholder="Nombre" [(ngModel)]="enfermero.name" required>
                            <mat-error *ngIf="nombresFormControl.hasError('required')">
                                Nombres <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Apellido Paterno</mat-label>
                            <input name="apePaterno" [formControl]="apeMaternoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Apellido Paterno" [(ngModel)]="enfermero.lastnamepat" required>
                            <mat-error *ngIf="apeMaternoFormControl.hasError('required')">
                                Apellido Paterno <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Apellido Materno</mat-label>
                            <input name="apeMaterno" [formControl]="apePaternoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Apellido Materno" [(ngModel)]="enfermero.lastnamemat" required>
                            <mat-error *ngIf="apePaternoFormControl.hasError('required')">
                                Apellido Materno <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Fecha de nacimiento</mat-label>
                            <input name="dateBirth" [formControl]="dateBirthFormControl" [errorStateMatcher]="matcher" (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="enfermero.birth_date" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="dateBirthFormControl.hasError('required')">
                                Fecha de nacimiento <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Género</mat-label>
                            <mat-select name="gender" [(ngModel)]="enfermero.gender" required>
                                <mat-option value="Masculino">
                                    Masculino
                                </mat-option>
                                <mat-option value="Femenino">
                                    Femenino
                                </mat-option>
                                <mat-option value="Otro">
                                  Otro
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Telefono</mat-label>
                            <input name="tel" [formControl]="telFormControl" [errorStateMatcher]="matcher" matInput placeholder="Telefono" [(ngModel)]="enfermero.telephone" required>
                            <mat-error *ngIf="telFormControl.hasError('required')">
                                Teléfono <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Dirección</mat-label>
                            <input name="address" [formControl]="direccionFormControl" [errorStateMatcher]="matcher" matInput placeholder="Dirección" [(ngModel)]="enfermero.address" required>
                            <mat-error *ngIf="direccionFormControl.hasError('required')">
                                Dirección <strong>Requerida</strong>
                            </mat-error>
                        </mat-form-field>
                    </mat-card>

                </div>
                <div class="col s12 m4 l4">

                    <mat-card>
                        <h3>Datos Profesionales</h3>

                        <mat-form-field>
                            <mat-label>Tipo</mat-label>
                            <mat-select name="type" [(ngModel)]="enfermero.type_nurse" required>
                                <mat-option value="licensia" (click)="enfermero.type_nurse = 'licensia'">
                                    Licencia
                                </mat-option>
                                <mat-option value="tecnico" (click)="enfermero.type_nurse = 'tecnico'">
                                    Técnico
                                </mat-option>
                                <mat-option value="cuidadora" (click)="enfermero.type_nurse = 'cuidadora'">
                                    Cuidadora
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Institución</mat-label>
                            <input name="institution" matInput placeholder="Institución" [(ngModel)]="enfermero.institution" required>
                            <mat-error *ngIf="direccionFormControl.hasError('required')">
                                Dirección <strong>Requerida</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Celular</mat-label>
                            <input name="cel" [formControl]="celFormControl" [errorStateMatcher]="matcher" matInput placeholder="Celular" [(ngModel)]="enfermero.phone" required>
                            <mat-error *ngIf="celFormControl.hasError('required')">
                                Celular <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>
                    </mat-card>

                </div>
                <div class="col s12 m4 l4">

                    <mat-card>
                        <h3>Archivos</h3>

                        <div class="row">
                            <div class="col s3 img-width">
                                <img [src]="enfermero.photo" class="vex_soluciones_file" (click)="verFoto()">
                            </div>
                            <div class="col s9 button-width">
                                <button class="vex_soluciones_button_file" type="button" mat-raised-button (click)="photoFile.click()">Subir Foto</button>
                                <input hidden (change)="onFileSelectedPhoto($event.target.files[0])" #photoFile type="file">
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col s3 img-width">
                                <img [src]="enfermero.ine" class="vex_soluciones_file" (click)="verIne()">
                            </div>
                            <div class="col s9 button-width">
                                <button class="vex_soluciones_button_file" type="button" mat-raised-button (click)="ineFile.click()">Subir INE</button>
                                <input hidden (change)="onFileSelectedIne($event.target.files[0])" #ineFile type="file">
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col s3 img-width">
                                <img [src]="enfermero.title" class="vex_soluciones_file" (click)="verTitle()">
                            </div>
                            <div class="col s9 button-width">
                                <button class="vex_soluciones_button_file" type="button" mat-raised-button (click)="titleFile.click()">Subir Título</button>
                                <input hidden (change)="onFileSelectedTitle($event.target.files[0])" #titleFile type="file">
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col s3 img-width">
                                <img [src]="enfermero.cedula" class="vex_soluciones_file" (click)="verCedula()">
                            </div>
                            <div class="col s9 button-width">
                                <button class="vex_soluciones_button_file" type="button" mat-raised-button (click)="cedulaFile.click()">Subir Cedula</button>
                                <input hidden (change)="onFileSelectedCedula($event.target.files[0])" #cedulaFile type="file">
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col s12 p-l-0" *ngIf="!loader">
                    <button type="button" mat-raised-button (click)="volver()">{{buttonName.BACK}}</button>
                    &nbsp;
                    <button type="submit" mat-raised-button color="primary">{{buttonName.UPDATE}}</button>
                </div>
                <div class="col s12" *ngIf="loader">
                    <mat-spinner diameter="40"></mat-spinner>
                </div>
            </div>
        </form>

    </div>
</section>
