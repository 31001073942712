<section>
    <vex-section-title title="ADMINISTRACIÓN" subtitle="NUEVO ROL Y PERMISO"></vex-section-title>

    <div class="vex_soluciones_content">
        <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarRol(f)" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col s12 m4 l4">
                    <mat-card>
                        <h3>Datos</h3>

                        <mat-form-field appearance="legacy">
                            <mat-label>Rol</mat-label>
                            <input [formControl]="rolFormControl" [errorStateMatcher]="matcher" matInput placeholder="Rol" name="name" [(ngModel)]="role.name" required>
                            <mat-error *ngIf="rolFormControl.hasError('required')">
                                Rol <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>
                        <br>
                        <mat-form-field appearance="legacy">
                            <mat-label>Descripción</mat-label>
                            <input [formControl]="descripcionFormControl" [errorStateMatcher]="matcher" matInput placeholder="Descripción" name="description" [(ngModel)]="role.description" required>
                            <mat-error *ngIf="descripcionFormControl.hasError('required')">
                                Descripcion <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>
                    </mat-card>

                </div>
                <div class="col s12 m8 l8">

                    <mat-card>
                        <h3>Permisos</h3>
                        <div *ngFor="let access of role.access">
                          <div *ngIf="access.submenus.length == 0">
                            <mat-checkbox color="primary" [(ngModel)]="access.isActive" [name]="access.title">{{access.title}}</mat-checkbox>
                          </div>
                          <div *ngIf="access.submenus.length > 0">
                            <h4>{{access.title}}</h4>
                            <div *ngFor="let submenu of access.submenus">
                              <div *ngIf="submenu.submenus.length == 0">
                                <mat-checkbox color="primary" [(ngModel)]="submenu.isActive" [name]="submenu.title">{{submenu.title}}</mat-checkbox>
                              </div>
                              <div *ngIf="submenu.submenus.length > 0">
                                <h4>{{submenu.title}}</h4>
                                <div *ngFor="let sub of submenu.submenus">
                                  <mat-checkbox color="primary" [(ngModel)]="sub.isActive" [name]="sub.title">{{sub.title}}</mat-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </mat-card>

                </div>

            </div>

            <div class="row">
                <div class="col s12">
                    <button mat-raised-button (click)="volver()" type="button">{{buttonName.BACK}}</button>
                    &nbsp;
                    <button mat-raised-button color="primary" type="submit">{{buttonName.STORE}}</button>
                </div>
            </div>
        </form>

    </div>
</section>
