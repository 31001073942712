import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategorySupport } from 'src/app/models/category_support';
import { Support } from 'src/app/models/support';
import { SupportService } from 'src/app/services/support.service';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';

@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.css']
})
export class AyudaComponent implements OnInit {

  panelOpenState = false;
  loaderSupport = false;

  soporte: Support;
  categoria: CategorySupport;
  categorias = [];
  soportes = [];

  constructor(
    private _supportService: SupportService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.soporte = new Support();
    this.categoria = new CategorySupport();
  }

  ngOnInit(): void {
    this.obtenerSoportes();
    this.obtenerCategorias();
  }

  obtenerCategorias() {
    this._supportService.getCategorySupports().subscribe(
      response => {
        console.log('categorias', response)
        this.categorias = response.data
      },
      _ => this.openError('Error al obtener categorias de soporte', 'Ok')
    )
  }

  obtenerSoportes() {
    this._supportService.getSupports().subscribe(
      response => {
        console.log('soportes', response)
        this.soportes = response.data
      },
      _ => this.openError('Error al obtener soportes','Ok')
    )
  }

  registrarSoporte(f: NgForm) {
    console.log('soporte', this.soporte);
    this._supportService.saveSupport(this.soporte).subscribe(
      response => {
        this.mostrarExitoDialog('Soporte registrado');
        this.obtenerCategorias();
        this.obtenerSoportes();
        f.resetForm();
      },
      error => this.openError('Error al registrar soporte', 'Ok')
    )
  }

  eliminarSoporte(id) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este paciente de empresa?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this._supportService.deleteSupport(id).subscribe(
          response => {
            this.mostrarExitoDialog('Soporte eliminado');
            this.obtenerCategorias();
            this.obtenerSoportes();
          },
          error => {
            this.openError('Error al eliminar soporte', 'Ok');
          }
        )

      }
    })
  }

  mostrarExitoDialog(message) {
		const dialogRef = this.dialog.open(SuccessComponent, {
			width: '300px',
			data: message
		})

		dialogRef.afterClosed().subscribe(result => {
			if(result) {
			}
		})
	}

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
