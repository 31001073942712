<img class="wave" src="assets/images/login/wave.png">
<div class="container">
    <div class="img">
        <img src="assets/images/login/bg.svg">
    </div>
    <div class="login-content">
        <form (ngSubmit)="iniciarSesion()">
            <img src="assets/images/login/logo.png">
            <h2 class="title">ADMINISTRADOR</h2>
            <div class="input-div one">
                <div class="i">
                    <i class="fas fa-user"></i>
                </div>
                <div class="div">
                    <h5>Usuario</h5>
                    <input (keyup)="errorMessage=false" type="text" class="input" name="email" [(ngModel)]="user.email">
                </div>
            </div>
            <div class="input-div pass">
                <div class="i">
                    <i class="fas fa-lock"></i>
                </div>
                <div class="div">
                    <h5>Contraseña</h5>
                    <input (keyup)="errorMessage=false" type="password" class="input" name="password" [(ngModel)]="user.password">
                </div>
            </div>

            <span class="error-message" *ngIf="errorMessage">Accesos denegados</span>

            <input *ngIf="!loading" type="submit" class="btn" value="INICIAR SESION">
            <button *ngIf="loading" type="button" class="btn">
                <mat-spinner diameter="30"></mat-spinner>
            </button>
        </form>
    </div>
</div>
