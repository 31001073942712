import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SidebarService {

    menuMasterAdmin: any = [
        {
            title: 'Dashboard',
            icon: 'dashboard',
            url: '/dashboard',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: false,
        },
        {
            title: 'Nueva atención',
            icon: 'add_box',
            url: '/nueva-atencion',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: false,
        },
        {
            title: 'Historial de atenciones',
            icon: 'history',
            url: '/historial-de-atenciones',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: false,
        },
        {
            title: 'Usuarios',
            icon: 'people_alt',
            submenus: [
                {
                    title: 'Pacientes',
                    icon: 'person',
                    submenus: [
                        {
                            title: 'Privados',
                            icon: 'double_arrow',
                            url: '/usuario/paciente/privados',
                            isExpanded: true,
                            showSubmenu: false,
                            isShowing: false,
                            isActive: false,
                        },
                        {
                            title: 'Pacientes de empresas',
                            icon: 'double_arrow',
                            url: '/usuario/paciente/empresas',
                            isExpanded: true,
                            showSubmenu: false,
                            isShowing: false,
                            isActive: false,
                        },
                    ],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false,
                    isActive: true,
                },
                {
                    title: 'Profesionales',
                    icon: 'person',
                    submenus: [
                        {
                            title: 'Paramédicos',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/paramedicos',
                            isExpanded: true,
                            showSubmenu: false,
                            isShowing: false,
                            isActive: false,
                        },
                        {
                            title: 'Médico General',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/medico-general',
                            isExpanded: true,
                            showSubmenu: false,
                            isShowing: false,
                            isActive: false,
                        },
                        {
                            title: 'Enfermera',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/enfermero',
                            isExpanded: true,
                            showSubmenu: false,
                            isShowing: false,
                            isActive: false,
                        },
                        {
                            title: 'Flebotomista',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/flebotomista',
                            isExpanded: true,
                            showSubmenu: false,
                            isShowing: false,
                            isActive: false,
                        },
                        {
                            title: 'Psicólogo',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/psicologo',
                            isExpanded: true,
                            showSubmenu: false,
                            isShowing: false,
                            isActive: false,
                        },
                        {
                            title: 'Radiólogo',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/radiologo',
                            isExpanded: true,
                            showSubmenu: false,
                            isShowing: false,
                            isActive: false,
                        },
                    ],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false,
                    isActive: true,
                },
                {
                    title: 'Administrativos',
                    icon: 'person',
                    url: '/usuario/administrativo',
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false,
                    submenus: [],
                    isActive: false,
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: true,
        },
        {
            title: 'Unidades',
            icon: 'directions_car',
            submenus: [
                {
                    title: 'Flotas',
                    icon: 'double_arrow',
                    url: '/unidades',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Monitoreo',
                    icon: 'double_arrow',
                    url: '/unidades/monitoreo',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Gasolina',
                    icon: 'double_arrow',
                    url: '/administrar-combustible',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Mantenimiento',
                    icon: 'double_arrow',
                    url: '/unidades/mantenimiento',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: true,
        },
        {
            title: 'Servicios',
            icon: 'widgets',
            submenus: [
                {
                    title: 'Gestión de servicios',
                    icon: 'double_arrow',
                    url: '/gestion-de-servicios',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Categorias de serv. adic.',
                    icon: 'double_arrow',
                    url: '/gestion-de-categoria-de-servicios-adicionales',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Servicios adicionales',
                    icon: 'double_arrow',
                    url: '/gestion-de-servicios-adicionales',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Gestión de cupones',
                    icon: 'double_arrow',
                    url: '/servicios/gestion-de-cupones',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },

            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: true,
        },
        {
            title: 'Mensajes',
            icon: 'message',
            submenus: [
                {
                    title: 'Bandeja de entrada',
                    icon: 'double_arrow',
                    url: '/mensajes/bandeja-de-entrada',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Chat general',
                    icon: 'double_arrow',
                    url: '/mensajes/chat-general',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },

            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: true,
        },
        {
            title: 'Empresas',
            icon: 'business',
            url: '/empresas',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: false,
        },
        {
            title: 'Reportes y analítica',
            icon: 'bar_chart',
            url: '/reportes-y-analitica',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: false,
        },
        {
            title: 'Configuración',
            icon: 'settings',
            url: '/configuracion',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: false,
        },
        {
            title: 'Ayuda',
            icon: 'help',
            url: '/ayuda',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: false,
        },
        {
            title: 'Notificaciones',
            icon: 'notifications',
            url: '/notificaciones',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: false,
        },
        {
            title: 'Roles y permisos',
            icon: 'accessibility_new',
            url: '/roles-y-permisos',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: false,
        },
        {
            title: 'Contacto',
            icon: 'contact_mail',
            url: '/contacto',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false,
            isActive: false,
        },
    ]

    menuSuperUser: any = [
        {
            title: 'Dashboard',
            icon: 'dashboard',
            url: '/dashboard',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Usuarios',
            icon: 'people_alt',
            submenus: [
                {
                    title: 'Pacientes',
                    icon: 'person',
                    submenus: [
                        {
                            title: 'Privados',
                            icon: 'double_arrow',
                            url: '/usuario/paciente/privados'
                        },
                        {
                            title: 'Pacientes de empresas',
                            icon: 'double_arrow',
                            url: '/usuario/paciente/empresas'
                        },
                    ],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Profesionales',
                    icon: 'person',
                    submenus: [
                        {
                            title: 'Paramédicos',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/paramedicos'
                        },
                        {
                            title: 'Médico General',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/medico-general'
                        },
                        {
                            title: 'Enfermera',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/enfermero'
                        },
                        {
                            title: 'Flebotomista',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/flebotomista'
                        },
                        {
                            title: 'Psicólogo',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/psicologo'
                        },
                        {
                            title: 'Radiólogo',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/radiologo'
                        },
                    ],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Administrativos',
                    icon: 'person',
                    url: '/usuario/administrativo',
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false,
                    submenus: []
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Unidades',
            icon: 'directions_car',
            submenus: [
                {
                    title: 'Flotas',
                    icon: 'double_arrow',
                    url: '/unidades',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Monitoreo',
                    icon: 'double_arrow',
                    url: '/unidades/monitoreo',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Gasolina',
                    icon: 'double_arrow',
                    url: '/unidades/gasolina',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Mantenimiento',
                    icon: 'double_arrow',
                    url: '/unidades/mantenimiento',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Servicios',
            icon: 'widgets',
            submenus: [
                {
                    title: 'Gestión de servicios',
                    icon: 'double_arrow',
                    url: '/servicios/gestion-de-servicios',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Gestión de cupones',
                    icon: 'double_arrow',
                    url: '/servicios/gestion-de-cupones',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Mensajes',
            icon: 'message',
            submenus: [
                {
                    title: 'Bandeja de entrada',
                    icon: 'double_arrow',
                    url: '/mensajes/bandeja-de-entrada',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Chat general',
                    icon: 'double_arrow',
                    url: '/mensajes/chat-general',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },

            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Empresas',
            icon: 'business',
            url: '/empresas',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Reportes y analítica',
            icon: 'bar_chart',
            url: '/reportes-y-analitica',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Configuración',
            icon: 'settings',
            url: '/configuracion',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Ayuda',
            icon: 'help',
            url: '/ayuda',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Notificaciones',
            icon: 'notifications',
            url: '/notificaciones',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },

        {
            title: 'Contacto',
            icon: 'contact_mail',
            url: '/contacto',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
    ]

    menuAdmin: any = [
        {
            title: 'Dashboard',
            icon: 'dashboard',
            url: '/dashboard',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Usuarios',
            icon: 'people_alt',
            submenus: [
                {
                    title: 'Pacientes',
                    icon: 'person',
                    submenus: [
                        {
                            title: 'Privados',
                            icon: 'double_arrow',
                            url: '/usuario/paciente/privados'
                        },
                        {
                            title: 'Pacientes de empresas',
                            icon: 'double_arrow',
                            url: '/usuario/paciente/empresas'
                        },
                    ],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Profesionales',
                    icon: 'person',
                    submenus: [
                        {
                            title: 'Paramédicos',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/paramedicos'
                        },
                        {
                            title: 'Médico General',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/medico-general'
                        },
                        {
                            title: 'Enfermera',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/enfermero'
                        },
                        {
                            title: 'Flebotomista',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/flebotomista'
                        },
                        {
                            title: 'Psicólogo',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/psicologo'
                        },
                        {
                            title: 'Radiólogo',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/radiologo'
                        },
                    ],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Administrativos',
                    icon: 'person',
                    url: '/usuario/administrativo',
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false,
                    submenus: []
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Unidades',
            icon: 'directions_car',
            submenus: [
                {
                    title: 'Flotas',
                    icon: 'double_arrow',
                    url: '/unidades',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Monitoreo',
                    icon: 'double_arrow',
                    url: '/unidades/monitoreo',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Gasolina',
                    icon: 'double_arrow',
                    url: '/unidades/gasolina',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Mantenimiento',
                    icon: 'double_arrow',
                    url: '/unidades/mantenimiento',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Servicios',
            icon: 'widgets',
            submenus: [
                {
                    title: 'Gestión de servicios',
                    icon: 'double_arrow',
                    url: '/servicios/gestion-de-servicios',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Gestión de cupones',
                    icon: 'double_arrow',
                    url: '/servicios/gestion-de-cupones',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Mensajes',
            icon: 'message',
            submenus: [
                {
                    title: 'Bandeja de entrada',
                    icon: 'double_arrow',
                    url: '/mensajes/bandeja-de-entrada',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Chat general',
                    icon: 'double_arrow',
                    url: '/mensajes/chat-general',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },

            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Empresas',
            icon: 'business',
            url: '/empresas',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Reportes y analítica',
            icon: 'bar_chart',
            url: '/reportes-y-analitica',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Configuración',
            icon: 'settings',
            url: '/configuracion',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Ayuda',
            icon: 'help',
            url: '/ayuda',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Notificaciones',
            icon: 'notifications',
            url: '/notificaciones',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },

        {
            title: 'Contacto',
            icon: 'contact_mail',
            url: '/contacto',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
    ]

    menuOperativo: any = [
        {
            title: 'Dashboard',
            icon: 'dashboard',
            url: '/dashboard',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Usuarios',
            icon: 'people_alt',
            submenus: [
                {
                    title: 'Pacientes',
                    icon: 'person',
                    submenus: [
                        {
                            title: 'Privados',
                            icon: 'double_arrow',
                            url: '/usuario/paciente/privados'
                        },
                        {
                            title: 'Pacientes de empresas',
                            icon: 'double_arrow',
                            url: '/usuario/paciente/empresas'
                        },
                    ],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Profesionales',
                    icon: 'person',
                    submenus: [
                        {
                            title: 'Paramédicos',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/paramedicos'
                        },
                        {
                            title: 'Médico General',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/medico-general'
                        },
                        {
                            title: 'Enfermera',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/enfermero'
                        },
                        {
                            title: 'Flebotomista',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/flebotomista'
                        },
                        {
                            title: 'Psicólogo',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/psicologo'
                        },
                        {
                            title: 'Radiólogo',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/radiologo'
                        },
                    ],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Administrativos',
                    icon: 'person',
                    url: '/usuario/administrativo',
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false,
                    submenus: []
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Unidades',
            icon: 'directions_car',
            submenus: [
                {
                    title: 'Flotas',
                    icon: 'double_arrow',
                    url: '/unidades',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Monitoreo',
                    icon: 'double_arrow',
                    url: '/unidades/monitoreo',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Gasolina',
                    icon: 'double_arrow',
                    url: '/unidades/gasolina',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Mantenimiento',
                    icon: 'double_arrow',
                    url: '/unidades/mantenimiento',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Servicios',
            icon: 'widgets',
            submenus: [
                {
                    title: 'Gestión de servicios',
                    icon: 'double_arrow',
                    url: '/servicios/gestion-de-servicios',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Mensajes',
            icon: 'message',
            submenus: [
                {
                    title: 'Bandeja de entrada',
                    icon: 'double_arrow',
                    url: '/mensajes/bandeja-de-entrada',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Chat general',
                    icon: 'double_arrow',
                    url: '/mensajes/chat-general',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },

            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Empresas',
            icon: 'business',
            url: '/empresas',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Reportes y analítica',
            icon: 'bar_chart',
            url: '/reportes-y-analitica',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Configuración',
            icon: 'settings',
            url: '/configuracion',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Ayuda',
            icon: 'help',
            url: '/ayuda',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Notificaciones',
            icon: 'notifications',
            url: '/notificaciones',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },

        {
            title: 'Contacto',
            icon: 'contact_mail',
            url: '/contacto',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
    ]

    menuRecursosHumanos: any = [
        {
            title: 'Dashboard',
            icon: 'dashboard',
            url: '/dashboard',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Usuarios',
            icon: 'people_alt',
            submenus: [
                {
                    title: 'Profesionales',
                    icon: 'person',
                    submenus: [
                        {
                            title: 'Paramédicos',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/paramedicos'
                        },
                        {
                            title: 'Médico General',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/medico-general'
                        },
                        {
                            title: 'Enfermera',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/enfermero'
                        },
                        {
                            title: 'Flebotomista',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/flebotomista'
                        },
                        {
                            title: 'Psicólogo',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/psicologo'
                        },
                        {
                            title: 'Radiólogo',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/radiologo'
                        },
                    ],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Administrativos',
                    icon: 'person',
                    url: '/usuario/administrativo',
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false,
                    submenus: []
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
    ]

    menuCallCenter: any = [
        {
            title: 'Dashboard',
            icon: 'dashboard',
            url: '/dashboard',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Usuarios',
            icon: 'people_alt',
            submenus: [
                {
                    title: 'Pacientes',
                    icon: 'person',
                    submenus: [
                        {
                            title: 'Privados',
                            icon: 'double_arrow',
                            url: '/usuario/paciente/privados'
                        },
                        {
                            title: 'Pacientes de empresas',
                            icon: 'double_arrow',
                            url: '/usuario/paciente/empresas'
                        },
                    ],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Servicios',
            icon: 'widgets',
            submenus: [
                {
                    title: 'Gestión de servicios',
                    icon: 'double_arrow',
                    url: '/servicios/gestion-de-servicios',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Gestión de cupones',
                    icon: 'double_arrow',
                    url: '/servicios/gestion-de-cupones',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },

            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Empresas',
            icon: 'business',
            url: '/empresas',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
    ]

    menuMasterRH: any = [

        {
            title: 'Usuarios',
            icon: 'people_alt',
            submenus: [
                {
                    title: 'Administrativos',
                    icon: 'person',
                    url: '/usuario/administrativo',
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false,
                    submenus: []
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
    ]

    menuPreparador: any = [
        {
            title: 'Dashboard',
            icon: 'dashboard',
            url: '/dashboard',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },

        {
            title: 'Unidades',
            icon: 'directions_car',
            submenus: [
                {
                    title: 'Flotas',
                    icon: 'double_arrow',
                    url: '/unidades',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Monitoreo',
                    icon: 'double_arrow',
                    url: '/unidades/monitoreo',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Gasolina',
                    icon: 'double_arrow',
                    url: '/unidades/gasolina',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Mantenimiento',
                    icon: 'double_arrow',
                    url: '/unidades/mantenimiento',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
    ]

    menuMonitoreo: any = [
        {
            title: 'Dashboard',
            icon: 'dashboard',
            url: '/dashboard',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Usuarios',
            icon: 'people_alt',
            submenus: [

                {
                    title: 'Profesionales',
                    icon: 'person',
                    submenus: [
                        {
                            title: 'Paramédicos',
                            icon: 'double_arrow',
                            url: '/usuario/profesional/paramedicos'
                        },
                    ],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Administrativos',
                    icon: 'person',
                    url: '/usuario/administrativo',
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false,
                    submenus: []
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        // {
        //     title: 'Unidades',
        //     icon: 'directions_car',
        //     submenus: [

        //         {
        //             title: 'Monitoreo',
        //             icon: 'double_arrow',
        //             url: '/unidades/monitoreo',
        //             submenus: [],
        //             isExpanded: true,
        //             showSubmenu: false,
        //             isShowing: false
        //         },

        //     ],
        //     isExpanded: true,
        //     showSubmenu: false,
        //     isShowing: false
        // },
    ]

    menuFacturacion: any = [
        {
            title: 'Dashboard',
            icon: 'dashboard',
            url: '/dashboard',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Facturación',
            icon: 'monetization_on',
            url: '/facturacion',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
    ]

    menuPits: any = [
        {
            title: 'Dashboard',
            icon: 'dashboard',
            url: '/dashboard',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Unidades',
            icon: 'directions_car',
            submenus: [
                {
                    title: 'Flotas',
                    icon: 'double_arrow',
                    url: '/unidades',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Monitoreo',
                    icon: 'double_arrow',
                    url: '/unidades/monitoreo',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Gasolina',
                    icon: 'double_arrow',
                    url: '/administrar-combustible',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
                {
                    title: 'Mantenimiento',
                    icon: 'double_arrow',
                    url: '/unidades/mantenimiento',
                    submenus: [],
                    isExpanded: true,
                    showSubmenu: false,
                    isShowing: false
                },
            ],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
    ]

    menuFinanzas: any = [
        {
            title: 'Dashboard',
            icon: 'dashboard',
            url: '/dashboard',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
        {
            title: 'Reportes y analítica',
            icon: 'bar_chart',
            url: '/reportes-y-analitica',
            submenus: [],
            isExpanded: true,
            showSubmenu: false,
            isShowing: false
        },
    ]

    constructor() { }

}
