import { Component, OnInit } from '@angular/core';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { MatDialog } from '@angular/material/dialog';
import { AditionalServService, CategoryServService } from 'src/app/services';
import { AditionalService } from 'src/app/models/AditionalService';
import { Location } from '@angular/common';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-servicios-adicionales-nuevo',
  templateUrl: './servicios-adicionales-nuevo.component.html',
  styleUrls: ['./servicios-adicionales-nuevo.component.css']
})
export class ServiciosAdicionalesNuevoComponent implements OnInit {

  servicioAdicional: AditionalService;
  categorias: any = [];
  loader: boolean = false;

  nombreFormControl = new FormControl('', [
    Validators.required,
  ]);

  descripcionFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;


  constructor(
    public location: Location,
    private _router: Router,
    public dialog: MatDialog,
    private _aditionalService: AditionalServService,
    private _categoryService: CategoryServService,
    private _snackBar: MatSnackBar
    ) {
    this.servicioAdicional = new AditionalService();
  }

  ngOnInit(): void {
    this.obtenerCategorias();
  }

  obtenerCategorias() {
    this._categoryService.getCategories().subscribe(
      response => {
        this.categorias = response.data;
      },
      error => {
        this.openError('No se encontraron categorias', 'Ok');
      }
    )
  }

  registrarServicioAdicional(f: NgForm) {
    this.loader = true;

    this._aditionalService.saveAditional(this.servicioAdicional).subscribe(
      response => {
        this.mostrarExitoDialog('Servicio adicional registrado');
        this.loader = false;
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.openError('Error al registrar servicio adicional', 'Ok');
        this.loader = false;
      }
    )

  }

  volver() {
    this._router.navigate(['/gestion-de-servicios-adicionales']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
