<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">
        <mat-toolbar class="mat-elevation-z4" style="background-color: white !important;">
            <img src="assets/images/admin/logotipo.png" alt="" width="200px" style="padding-left: 5px;">
        </mat-toolbar>
        <mat-nav-list>
            <div class="row">
                <div class="col s12">
                    <div>
                        <br>
                        <div class="center user-img">
                            <img appImgBroken class="img-profile" [src]="sourceUrl + user.image" alt="" width="64" height="64">
                        </div>
                        <a class="center m-b-0 name-info" routerLink="/configuracion">{{user.name}} {{user.lastnamepat}} {{user.lastnamemat}}</a>
                        <h4 class="center m-t-0 email-info">{{role.name}}</h4>
                    </div>

                    <mat-divider></mat-divider>
                    <br>
                    <div class="row">
                        <div class="col s12">
                            <div class="center">
                                <a href="/downloads/app.apk"
                                download="app.apk" mat-stroked-button color="primary" type="button">Descargar App</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngFor="let menu of menu">
                <mat-list-item (click)="menu.showSubmenu = !menu.showSubmenu" class="parent" [routerLink]="menu.url" *ngIf="menu.isActive && verificarMenu(menu.submenus)">
                    <span class="full-width" *ngIf="menu.isExpanded || menu.isShowing">{{menu.title}}</span>
                    <mat-icon mat-list-icon class="mat-icons">{{menu.icon}}</mat-icon>
                    <span class="spacer"></span>
                    <div *ngIf="menu.submenus.length > 0">
                        <mat-icon class="menu-button" [ngClass]="{'rotated' : menu.showSubmenu}" *ngIf="menu.isExpanded || menu.isShowing">expand_more</mat-icon>
                    </div>
                </mat-list-item>
                <div *ngIf="menu.submenus.length > 0">
                    <div class="submenu" [ngClass]="{'expanded' : menu.showSubmenu}" *ngIf="menu.isShowing || menu.isExpanded">

                        <div *ngFor="let submenu of menu.submenus">
                            <mat-list-item (click)="submenu.showSubmenu = !submenu.showSubmenu" class="parent" [routerLink]="submenu.url" *ngIf="submenu.isActive && verificarMenu(submenu.submenus)">
                                <span class="full-width" *ngIf="submenu.isExpanded || submenu.isShowing">{{submenu.title}}</span>
                                <mat-icon mat-list-icon class="vex_soluciones_icon_paciente">{{submenu.icon}}</mat-icon>
                                <span class="spacer"></span>
                                <div *ngIf="submenu.submenus.length > 0">
                                    <mat-icon class="menu-button" [ngClass]="{'rotated' : submenu.showSubmenu}" *ngIf="submenu.isExpanded || submenu.isShowing">expand_more</mat-icon>
                                </div>
                            </mat-list-item>
                            <div *ngIf="submenu.submenus.length > 0">
                                <div class="submenu" [ngClass]="{'expanded' : submenu.showSubmenu}" *ngIf="submenu.isShowing || submenu.isExpanded">
                                  <ng-container *ngFor="let item of submenu.submenus">
                                    <a class="subitem" mat-list-item [routerLink]="item.url" *ngIf="item.isActive"> <mat-icon>double_arrow</mat-icon> <span>{{item.title}}</span> </a>
                                  </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content style="background: #EAF0F9;">
        <mat-toolbar color="primary" class="mat-elevation-z4">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            &nbsp;
            <span>{{now | date: 'M/d/yy, h:mm a'}}</span>
            <span class="spacer"></span>

            <button mat-icon-button [matMenuTriggerFor]="atenciones">
                <mat-icon matBadge="2" matBadgeColor="warn" style="cursor: pointer;">local_hospital</mat-icon>
            </button>
            <mat-menu #atenciones="matMenu" xPosition="before">
                <h4 style="padding: 0 15px; margin-top: 5px; color: #263238;">Atenciones</h4>
                <button [routerLink]="['/editar-atencion/1']" mat-menu-item>
                    <mat-icon>medication</mat-icon>
                    <span>Atención 1</span>
                </button>
                <mat-divider></mat-divider>
                <button [routerLink]="['/editar-atencion/1']" mat-menu-item>
                    <mat-icon>medication</mat-icon>
                    <span>Atención 2</span>
                </button>
                <mat-divider></mat-divider>
                <button [routerLink]="['/editar-atencion/1']" mat-menu-item>
                    <mat-icon>medication</mat-icon>
                    <span>Atención 3</span>
                </button>
                <mat-divider></mat-divider>
                <button [routerLink]="['/historial-de-atenciones']" mat-menu-item class="vex-soluciones-btn-show-all">
                    <span>VER ATENCIONES</span>
                </button>
            </mat-menu>


            <button mat-icon-button [matMenuTriggerFor]="contacts" style="margin-left: 25px;">
                <mat-icon matBadge="1" matBadgeColor="warn" style="cursor: pointer;">task</mat-icon>
            </button>
            <mat-menu #contacts="matMenu" xPosition="before">
                <h4 style="padding: 0 15px; margin-top: 5px; color: #263238;">Tareas</h4>
                <button [routerLink]="['/editar-tarea/2']" mat-menu-item>
                    <mat-icon>task_alt</mat-icon>
                    <span>Tarea 1</span>
                </button>
                <mat-divider></mat-divider>
                <button [routerLink]="['/editar-tarea/2']" mat-menu-item>
                    <mat-icon>task_alt</mat-icon>
                    <span>Tarea 2</span>
                </button>
                <mat-divider></mat-divider>
                <button [routerLink]="['/editar-tarea/2']" mat-menu-item>
                    <mat-icon>task_alt</mat-icon>
                    <span>Tarea 3</span>
                </button>
                <mat-divider></mat-divider>

                <button [routerLink]="['/administrar-tareas']" mat-menu-item class="vex-soluciones-btn-show-all">
                    <span>VER TAREAS</span>
                </button>
            </mat-menu>

            <button mat-icon-button style="margin-left: 25px;" [matMenuTriggerFor]="notification">
                <mat-icon  matBadge="1" matBadgeColor="warn" style="cursor: pointer;">chat</mat-icon>
            </button>

            <mat-menu #notification="matMenu" xPosition="before">
                <h4 style="padding: 0 15px; margin-top: 5px; color: #263238;">Mensajes Chat</h4>
                <button [routerLink]="['/mensajes/chat-general']" mat-menu-item>
                    <mat-icon>person</mat-icon>
                    <span>Nuevo Correo de Paciente</span>
                </button>
                <mat-divider></mat-divider>
                <button [routerLink]="['/mensajes/chat-general']" mat-menu-item>
                    <mat-icon>person</mat-icon>
                    <span>Viaje Finalizado</span>
                </button>
                <mat-divider></mat-divider>
                <button [routerLink]="['/mensajes/chat-general']" mat-menu-item>
                    <mat-icon>person</mat-icon>
                    <span>Nuevo mensaje en bandeja</span>
                </button>
                <mat-divider></mat-divider>
                <button [routerLink]="['/mensajes/bandeja-de-entrada']" mat-menu-item class="vex-soluciones-btn-show-all">
                    <span>VER MENSAJES</span>
                </button>
            </mat-menu>

            <mat-menu>
                <button mat-menu-item>
                    <mat-icon>dialpad</mat-icon>
                    <span>Redial</span>
                </button>
                <button mat-menu-item disabled>
                    <mat-icon>voicemail</mat-icon>
                    <span>Check voice mail</span>
                </button>
                <button mat-menu-item>
                    <mat-icon>notifications_off</mat-icon>
                    <span>Disable alerts</span>
                </button>
            </mat-menu>

            <button mat-button (click)="cerrarSesion()" style="margin-left: 45px;">
                <mat-icon class="example-icon" aria-hidden="false" aria-label="Example delete icon">power_settings_new</mat-icon>
                CERRAR SESION
            </button>

        </mat-toolbar>

        <section>
            <router-outlet></router-outlet>
        </section>

    </mat-sidenav-content>
</mat-sidenav-container>
