import { Component, OnInit, ViewChild } from '@angular/core';
import { NewTaskComponent } from 'src/app/shareds/dialogs/tasks/new-task/new-task.component';
import { MatDialog } from '@angular/material/dialog';
import { NewServiceComponent } from 'src/app/shareds/dialogs/calendars/new-service/new-service.component';
import { AgmInfoWindow, AgmMap } from '@agm/core';
import { TaskService, AttentionService, ParamedicService } from 'src/app/services';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
import { EditTaskComponent } from 'src/app/shareds/dialogs/tasks/edit-task/edit-task.component';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Email } from 'src/app/models/email';
import { NgForm } from '@angular/forms';
import { EmailService } from 'src/app/services/email.service';
import { DetailServiceComponent } from 'src/app/shareds/dialogs/calendars/detail-service/detail-service.component';
import { Dashboard } from 'src/app/models/dashboard';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { SocketService } from 'src/app/services/socket.service';
import { Router } from '@angular/router';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexPlotOptions, ApexTitleSubtitle, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import ApexCharts from 'apexcharts';

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	yaxis: ApexYAxis;
	xaxis: ApexXAxis;
	fill: ApexFill;
	title: ApexTitleSubtitle;
};

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

	TASK_PENDING = 'pendiente';
  	TASK_COMPLETED = 'completada';
  	TASK_CANCELED = 'cancelada';

	dashboard: Dashboard;
	paramedicos = [];

	visible = true;
	selectable = true;
	removable = true;
	addOnBlur = true;
	readonly separatorKeysCodes: number[] = [ENTER, COMMA];
	correos: any[] = [];
	loaderSendEmail: boolean = false;
	add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		// Add our fruit
		if ((value || '').trim()) {
		this.correos.push({name: value.trim()});
		}

		// Reset the input value
		if (input) {
		input.value = '';
		}
	}

	remove(correo: any): void {
		const index = this.correos.indexOf(correo);

		if (index >= 0) {
		this.correos.splice(index, 1);
		}
	}

	sendEmail: Email;

	texto : string = 'Wenceslau Braz - Cuidado com as cargas';
	lat: number = 19.485166;
	lng: number = -99.134715;
	zoom: number = 10;

	public chart0: any = null;
	public chart1: any = null;

	tareas: any = [];

	icon = {
		url: 'assets/images/admin/icon-clinica.png',
		scaledSize: {
			width: 60,
			height: 60
		}
	}

	infoList: AgmInfoWindow;
	locations: any;

	calendarOptions: CalendarOptions = {
		initialView: 'dayGridMonth',
		buttonText: {
			today:    'Hoy',
			month:    'Mes',
			week:     'Semana',
			day:      'Día',
			list:     'Lista'
		},
		locale: 'es',
		events: [],
		eventClick: (info) => {
			const dialogRef = this.dialog.open(DetailServiceComponent, {
				width: '400px',
				height: 'auto',
				data: {props: info.event.extendedProps},
				panelClass: 'dialogs'
			});

			dialogRef.afterClosed().subscribe(result => {
				console.log('The dialog was closed');
				console.log('RESULT', result)

			});
		},

	};

	@ViewChild('AgmMap') agmMap: AgmMap;

	@ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

	constructor(
		public dialog: MatDialog,
		private _taskService: TaskService,
		private _snackBar: MatSnackBar,
		private _emailService: EmailService,
		private _attentionService: AttentionService,
		private _dashboardService: DashboardService,
		private _paramedicService: ParamedicService,
		private _socketService: SocketService,
		private _router: Router
	) {
		this.dashboard = new Dashboard();
		this.sendEmail = new Email();

		this.chartOptions = {
			series: [
			  {
				name: "Atenciones por mes",
				data: [4, 3, 4, 10, 4, 3, 3]
			  }
			],
			chart: {
			  height: 350,
			  type: "bar"
			},
			plotOptions: {
			  bar: {
				dataLabels: {
				  position: "top" // top, center, bottom
				}
			  }
			},
			dataLabels: {
			  enabled: true,
			  formatter: function(val) {
				return val + "";
			  },
			  offsetY: -20,
			  style: {
				fontSize: "12px",
				colors: ["#304758"]
			  }
			},

			xaxis: {
			  categories: [
				"Lun",
				"Mar",
				"Mie",
				"Jue",
				"Vie",
				"Sab",
				"Dom",
			  ],
			  position: "top",
			  labels: {
				offsetY: -18
			  },
			  axisBorder: {
				show: false
			  },
			  axisTicks: {
				show: false
			  },

			  tooltip: {
				enabled: true,
				offsetY: -35
			  }
			},

			yaxis: {
			  axisBorder: {
				show: false
			  },
			  axisTicks: {
				show: false
			  },
			  labels: {
				show: false,
				formatter: function(val) {
				  return val + " Atenciones";
				}
			  }
			},
			title: {
			  text: "Atenciones realizadas",
			  floating: false,
			  offsetY: 330,
			  align: "center",
			  style: {
				color: "#444"
			  }
			}
		};
	}

	public barChartOptions = {
		scaleShowVerticalLines: false,
		responsive: true
	};

	public barChartLabels = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul'];
	public barChartType = 'bar';
	public barChartLegend = true;
	public barChartData = [
		{data: [65, 59, 80, 81, 56, 55, 36], label: 'Viajes', backgroundColor: '#0099cc', hoverBorderColor: '#99ff00', borderColor: '#000'},
	];

	public barChartData2 = [
		{data: [65, 37, 80, 81, 56, 63, 40], label: 'Objetivos', backgroundColor: '#0099cc', hoverBorderColor: '#99ff00', borderColor: '#000'},
	];

	socketResponse: any;

	ngOnInit(): void {
		this.obtenerTareas();
		this.obtenerAtencionesMedicas();
		this.obtenerDasboard();
		this.obtenerParamedicos();
		this.obtenerCambiosDePosicion();
	}

	verMensaje() {
		this._router.navigate(['/mensajes/chat-general'])
	}

	obtenerCambiosDePosicion() {
		this._socketService.getLocation().subscribe(
			response => {
        console.log('==== RECIBIDO ====', response);

				for (let x = 0; x < this.paramedicos.length; x++) {
					console.log('paramedico', this.paramedicos[x])

					if (this.paramedicos[x].professional.user.id == response['user_id']) {
						console.log('ingreso')
						this.paramedicos[x].professional.user.latitude = response['latitude'];
						this.paramedicos[x].professional.user.longitude = response['longitude'];

						console.log('this.paramedicos', this.paramedicos)
						break;
					}
				}

			},
			error => {
				console.log('Error de socket')
			}
		)
	}

	obtenerParamedicos(): void {
		this._paramedicService.getParamedics().subscribe(
			response => {
				console.log('RESPONSE paramedicos', response)
				let paramedicosOnline = [];

        response.data.forEach(element => {

          paramedicosOnline.push(element);

        });
				for (let index = 0; index < response.data.length; index++) {
					const element = response.data[index];
					console.log('status', element.professional.status)

				}

				this.paramedicos = paramedicosOnline;

				let markers = [];

				for (let index = 0; index < response.data.length; index++) {
					const element = response.data[index];
					markers.push({lat: element.professional.user.latitude, lng: element.professional.user.longitude});

				}

				// this.agmMap.mapReady.subscribe(map => {
				// 	const bounds: any = new google.maps.LatLngBounds();
				// 	for (const mm of markers) {
				// 	  bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
				// 	}
				// 	map.fitBounds(bounds);
				// });

			},
			error => {
				console.log('ERROR PARAMEDICS', error)
			}
		)
	}

	obtenerDasboard(): void {
		this._dashboardService.getDashboards().subscribe(
			response => {
				console.log('dashboard', response)
				this.dashboard.attentions = response.attentions;
				this.dashboard.vehicles = response.vehicles;
				this.dashboard.professionals = response.professionals;
				this.dashboard.patients = response.patients;
				this.dashboard.users = response.users;

				var chartAttention = new ApexCharts(document.querySelector("#chartAttention"), this.chartOptions);
				chartAttention.render();
				chartAttention.updateOptions(
					{
						series: [
						  response.attentions_chart.series
						],
						chart: {
						  height: 198,
						  type: "bar"
						},
						plotOptions: {
						  bar: {
							dataLabels: {
							  position: "top" // top, center, bottom
							}
						  }
						},
						dataLabels: {
						  enabled: true,
						  formatter: function(val) {
							return val + "";
						  },
						  offsetY: -20,
						  style: {
							fontSize: "12px",
							colors: ["#304758"]
						  }
						},

						xaxis: {
						  categories: response.attentions_chart.categories,
						  position: "top",
						  labels: {
							offsetY: -18
						  },
						  axisBorder: {
							show: false
						  },
						  axisTicks: {
							show: false
						  },

						  tooltip: {
							enabled: true,
							offsetY: -35
						  }
						},

						yaxis: {
						  axisBorder: {
							show: false
						  },
						  axisTicks: {
							show: false
						  },
						  labels: {
							show: false,
							formatter: function(val) {
							  return val + " Atenciones";
							}
						  }
						},
						title: {
						  text: "Atenciones realizadas Por Mes",
						  floating: false,
						  offsetY: 178,
						  align: "center",
						  style: {
							color: "#444"
						  }
						}
					}
				)

				var chartPatient = new ApexCharts(document.querySelector("#chartPatient"), this.chartOptions);
				chartPatient.render();
				chartPatient.updateOptions({
					series: [
					  response.patients_chart.series
					],
					chart: {
					  height: 198,
					  type: "bar"
					},
					plotOptions: {
					  bar: {
						dataLabels: {
						  position: "top" // top, center, bottom
						}
					  }
					},
					dataLabels: {
					  enabled: true,
					  formatter: function(val) {
						return val + "";
					  },
					  offsetY: -20,
					  style: {
						fontSize: "12px",
						colors: ["#304758"]
					  }
					},

					xaxis: {
					  categories: response.patients_chart.categories,
					  position: "top",
					  labels: {
						offsetY: -18
					  },
					  axisBorder: {
						show: false
					  },
					  axisTicks: {
						show: false
					  },

					  tooltip: {
						enabled: true,
						offsetY: -35
					  }
					},

					yaxis: {
					  axisBorder: {
						show: false
					  },
					  axisTicks: {
						show: false
					  },
					  labels: {
						show: false,
						formatter: function(val) {
						  return val + " Pacientes";
						}
					  }
					},
					title: {
					  text: "Nro De Pacientes por Mes",
					  floating: false,
					  offsetY: 178,
					  align: "center",
					  style: {
						color: "#444"
					  }
					}
				})
			},
			error => {
				this.openError('Error al obtener contadores', 'OK');
			}
		)
	}

	obtenerAtencionesMedicas(): void {
		this._attentionService.getAttentions().subscribe(
			response => {
				console.log('atenciones', response);
				let atenciones = []
				response.data.forEach(element => {
					console.log('FECHA', element.date)
					atenciones.push({
						title: element.service.description,
						description: `${element.service.description} ${element.aditional_service}`,
						editable: true,
						start: element.date,
						end: element.date,
						allDay: true,
						backgroundColor: 'rgb(255,66,0, 1)',
						borderColor: 'red',
						className: 'event-reservation',
						extendedProps: element,
					});
				});

				this.calendarOptions.events = atenciones;

			},
			error => {
				console.log('error', error)
			}
		)
	}

	enviarEmail(f: NgForm) {
		this.loaderSendEmail = true;
		console.log('CORREOS',this.correos);
		if(this.correos.length == 0) {
			this.openError('Establecer al menos un correo', 'Ok');
		} else {
			this.sendEmail.para = this.correos;
			console.log('EMAIL OBJECT',this.sendEmail);
			this._emailService.enviarEmail(this.sendEmail).subscribe(
				response => {
					this.loaderSendEmail = false;
					this.mostrarExitoDialog('Correo enviado')
					f.resetForm();
					console.log(response);
					this.correos = [];
				},
				error => {
					this.loaderSendEmail = false;
					this.openError('Error al enviar correo', 'Ok')
				}
			);
		}
	}

	nuevaTarea() {
		const dialogRef = this.dialog.open(NewTaskComponent, {
			width: '300px',
			data: 'Nueva tarea',
			disableClose: true
		})

		dialogRef.afterClosed().subscribe(result => {
			if(result == 'true') {

			}else {
				this.obtenerTareas();
			}
		})
	}

	editarTarea(id) {
		const dialogRef = this.dialog.open(EditTaskComponent, {
			width: '300px',
			data: id,
			disableClose: true
		})

		dialogRef.afterClosed().subscribe(result => {
			if(result == 'true') {
				this.obtenerTareas();
			}else {
				this.obtenerTareas();
			}
		})
	}

	eliminarTarea(id) {
		const dialogRef = this.dialog.open(ConfirmationComponent, {
			width: '300px',
			data: '¿Desea eliminar esta tarea?',
			disableClose: true
		})

		dialogRef.afterClosed().subscribe(result => {
			if(result == 'true') {

				this._taskService.deleteTask(id).subscribe(
					response => {
						this.obtenerTareas();
						this.mostrarExitoDialog('Tarea Eliminada');
					},
					error => {
						this.openError('Error al Eliminar Tarea', 'Ok');
					}

				)

			}
		})
	}

	nuevoServicio() {
		const dialogRef = this.dialog.open(NewServiceComponent, {
			width: '100%',
      height: '600px',
			data: 'Agendar nueva atención',
			disableClose: true
		})

		dialogRef.afterClosed().subscribe(result => {
			if(result == 'true') {
				this.obtenerAtencionesMedicas();
			} else {
				this.obtenerAtencionesMedicas();
			}
		})
	}

	obtenerTareas() {
		this._taskService.getTasks().subscribe(
			response => {
				this.tareas = response.data;
				console.log(this.tareas);
			},
			error => {
				this.openError('No se encontraron tareas', 'Ok')
			}
		)
	}

	cambiarEstado(id, event) {
		console.log('CAMBIAR ESTADO', event.checked);
		let data = {};
		if(event.checked) {
		  data = {status: this.TASK_COMPLETED};
		} else {
		  data = {status: this.TASK_PENDING};
		}

		this._taskService.updateTask(id, data).subscribe(
		  response => {
			(event.checked)? this.infoSnack('TAREA COMPLETADA', 'OK', 'snackbar-success'): this.infoSnack('TAREA PENDIENTE', 'OK', 'snackbar-alert');
			this.obtenerTareas();
		  },
		  error => {
			(event.checked)? this.openError('ERROR AL COMPLETAR TAREA', 'OK'): this.openError('ERROR AL DESCOMPLETAR TAREA', 'OK');
		  }
		)
	  }

	mostrarExitoDialog(message) {
		const dialogRef = this.dialog.open(SuccessComponent, {
			width: '300px',
			data: message
		})

		dialogRef.afterClosed().subscribe(result => {
			if(result) {
			}
		})
	}

	openError(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 4000,
		});
	}

	infoSnack(message: string, action: string, style: string) {
		this._snackBar.open(message, action, {
		  duration: 4000,
		  panelClass: [style]
		});

	}

}
