import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { NurseService } from 'src/app/services';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { environment } from '../../../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-enfermero',
  templateUrl: './enfermero.component.html',
  styleUrls: ['./enfermero.component.css']
})
export class EnfermeroComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'name', 'email', 'phone', 'action'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  srcUrl: any;

  loader: boolean = true;

  enfermeros$: Subscription;
  deleteEnfermero$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _nurseService: NurseService,
    private _snackBar: MatSnackBar
  ) {

    this.srcUrl = environment.sourceUrl;

    this.dataSource = fillTable([], this.paginator, this.sort)

  }

  ngOnInit(): void {
    this.obtenerEnfermeros();
  }

  buscarEnfermero(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerEnfermeros() {
    this.loader = true;
    this.enfermeros$ = this._nurseService.getNurses().subscribe(
      response => {
        console.log('enfermeros', response)
        this.dataSource = fillTable(response.data.reverse(), this.paginator, this.sort);

        this.loader = false;
      },
      error => {
        this.openError('Error al obtener enfermeros', 'Ok');
        this.loader = false;
      }
    )
  }

  eliminarEnfermero(){
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este profesional enfermero?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {}
    })
  }

  verFoto(foto) {

      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: `${environment.sourceUrl}${foto}`
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {}
      })

  }

  verIne(ine) {

      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: `${environment.sourceUrl}${ine}`
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {}
      })

  }

  verTitulo(titulo) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${titulo}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {}
    })

}

verCedula(cedula) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${cedula}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {}
    })

}

  cambiarActivacion(element) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea cambiar la activación del profesional enfermero?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this.deleteEnfermero$ = this._nurseService.deleteNurse(element.identifier, false).subscribe(
          response => {
            this.obtenerEnfermeros();
          },
          error => {
            this.obtenerEnfermeros();
            this.openError('Error en la activación', 'Ok');
          }
        )

      } else {
        this.obtenerEnfermeros();
      }
    })
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  ngOnDestroy(): void {
    if(this.enfermeros$) this.enfermeros$.unsubscribe();

    if(this.deleteEnfermero$) this.deleteEnfermero$.unsubscribe();
  }
}
