import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { TaskService } from 'src/app/services';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-adm-tareas',
  templateUrl: './adm-tareas.component.html',
  styleUrls: ['./adm-tareas.component.css']
})
export class AdmTareasComponent implements OnInit, OnDestroy {

  tareas: any = [];

  displayedColumns: string[] = ['estado', 'id', 'nombre', 'descripcion', 'fecha', 'responsable', 'tiempo', 'prioridad', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  TASK_PENDING = 'pendiente';
  TASK_COMPLETED = 'completada';
  TASK_CANCELED = 'cancelada';

  loader: boolean = true;

  tareas$: Subscription;
  deleteTarea$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _taskService: TaskService
  ) { }

  ngOnInit(): void {
    this.obtenerTareas();
  }

  buscarTarea(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerTareas() {
    this.tareas$ = this._taskService.getTasks().subscribe(
      response => {

        console.log('TAREAS', response);
        this.dataSource = fillTable(response.data, this.paginator, this.sort)

      },
      error => {
        this.mostrarErrorDialog('No se encontraron tareas')
      }
    )
  }

  cambiarEstado(id, event) {
    console.log('CAMBIAR ESTADO', event.checked);
    let data = {};
    if(event.checked) {
      data = {status: this.TASK_COMPLETED};
    } else {
      data = {status: this.TASK_PENDING};
    }

    this._taskService.updateTask(id, data).subscribe(
      response => {
        (event.checked)? this.infoSnack('TAREA COMPLETADA', 'OK'): this.infoSnack('TAREA PENDIENTE', 'OK');
        this.obtenerTareas();
      },
      error => {
        (event.checked)? this.openError('ERROR AL COMPLETAR TAREA', 'OK'): this.openError('ERROR AL DESCOMPLETAR TAREA', 'OK');
      }
    )
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  eliminarTarea(id) {
		const dialogRef = this.dialog.open(ConfirmationComponent, {
			width: '300px',
			data: '¿Desea eliminar esta tarea?',
			disableClose: true
		})

		dialogRef.afterClosed().subscribe(result => {
			if(result == 'true') {

				this.deleteTarea$ = this._taskService.deleteTask(id).subscribe(
					response => {
						this.obtenerTareas();
						this.mostrarExitoDialog('Tarea Eliminada');
					},
					error => {
						this.openError('Error al Eliminar Tarea', 'Ok');
					}

				)

			}
		})
  }

  openError(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 4000,
		});
  }

  infoSnack(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000
    });
  }

  ngOnDestroy(): void {
    if(this.tareas$) this.tareas$.unsubscribe();
    if(this.deleteTarea$) this.deleteTarea$.unsubscribe();
  }
}
