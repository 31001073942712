import { Component, Input, OnDestroy, OnInit } from "@angular/core";

@Component({
    selector: 'vex-section-title',
    templateUrl: './vex-section-title.component.html',
    styleUrls: ['./vex-section-title.component.scss']
})
export class VexSectionTitleComponent implements OnInit, OnDestroy {

    @Input() title: string;
    @Input() subtitle: string;

    constructor() {}

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }
}
