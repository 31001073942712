import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CompanyService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-empresas-editar',
  templateUrl: './empresas-editar.component.html',
  styleUrls: ['./empresas-editar.component.css']
})
export class EmpresasEditarComponent implements OnInit {

  company: any = {};
  companyId: number;
  loader: boolean = false;

  empresaFormControl = new FormControl('', [
    Validators.required,
  ]);

  numeroAfiliacionFormControl = new FormControl('', [
    Validators.required,
  ]);

  direccionFormControl = new FormControl('', [
    Validators.required,
  ]);

  telefonoFormControl = new FormControl('', [
    Validators.required,
  ]);

  celularFormControl = new FormControl('', [
    Validators.required,
  ]);

  correoFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);

  nombreRepresentanteFormControl = new FormControl('', [
    Validators.required,
  ]);

  apellidoPaternoRepresentanteFormControl = new FormControl('', [
    Validators.required,
  ]);

  apellidoMaternoRepresentanteFormControl = new FormControl('', [
    Validators.required,
  ]);

  fechaNacimientoRepresentanteFormControl = new FormControl('', [
    Validators.required,
  ]);

  correoRepresentanteFormControl = new FormControl('', [
    Validators.required,
  ]);

  telefonoRepresentanteFormControl = new FormControl('', [
    Validators.required,
  ]);

  direccionRepresentanteFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public location: Location,
    private _companyService: CompanyService,
    private _activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() : void {
    this.obtenerEmpresa();
  }

  obtenerEmpresa() : void {
    this._activatedRoute.params.subscribe(
      params => {
        this._companyService.getCompany(params['id']).subscribe(
          response => {
            this.company = response.data;
            this.companyId = params['id'];
          },
          error => {
            this.openError('No se encontro a la empresa', 'Ok');
          }
        )
      }
    )
  }

  editarEmpresa(f: NgForm) : void {
    this.loader = true;

      this._companyService.updateCompany(this.companyId, this.company).subscribe(
        response => {
          this.loader = false;
          this.mostrarExitoDialog('Empresa actualizada');
          setTimeout(() => {
            this.location.back();
          }, 1500);
        },
        error => {
          this.loader = false;
          this.openError('Error al actualizar empresa', 'Ok');
        }
      )

  }

  volver() : void {
    this.location.back();
  }

  mostrarExitoDialog(message) : void {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {}
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
