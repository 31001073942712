export class Configuration {
  name: string;
  address: string
  email: string;
  phone: string;
  google_map_key: string;
  facebook_auth_key: string;
  google_auth_key: string;

  facebook: string;
  twitter: string;
  instagram: string;
  web: string;
  constructor(
  ){}
}
