import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': "Bearer " + localStorage.getItem('token')
    })
};

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {

    constructor(
        private http: HttpClient,
    ) { }
    
    getEmployee(id): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}employees/${id}`);

    }

    getEmployees(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}employees`, httpOptions);

    }

    getEmployeesByCompany(id): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}employees/company/${id}`, httpOptions);

    }

    updateEmployee(id, data): Observable<any> {

        return this.http.patch<any>(`${environment.apiUrl}employees/${id}`, data, httpOptions);

    }

    saveEmployee(data): Observable<any> {

        return this.http.post<any>(`${environment.apiUrl}employees`, data, httpOptions);

    }

    deleteEmployee(id, value): Observable<any> {

        return this.http.delete<any>(`${environment.apiUrl}employees/${id}?delete=${value}`, httpOptions);

    }

}
