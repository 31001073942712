import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { VehicleService, ParamedicService, MaintenanceService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { Vehicle } from 'src/app/models/vehicle';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { environment } from '../../../../../environments/environment';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MantenimientoDialogFormComponent } from 'src/app/shareds/dialogs/app/unidades/manteniento/mantenimiento-form/mantenimiento-form.component';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-unidades-editar',
  templateUrl: './unidades-editar.component.html',
  styleUrls: ['./unidades-editar.component.css']
})
export class UnidadesEditarComponent implements OnInit {

  unidad: Vehicle;
  unidadId: any;
  paramedicos: any = [];
  mantenimientos: any = [];
  loader: boolean = false;


  identificadorFormControl = new FormControl('', [
    Validators.required,
  ]);

  placaFormControl = new FormControl('', [
    Validators.required,
  ]);

  modeloFormControl = new FormControl('', [
    Validators.required,
  ]);

  colorFormControl = new FormControl('', [
    Validators.required,
  ]);

  serieFormControl = new FormControl('', [
    Validators.required,
  ]);

  anioFormControl = new FormControl('', [
    Validators.required,
  ]);

  marcaFormControl = new FormControl('', [
    Validators.required,
  ]);

  aseguradoraFormControl = new FormControl('', [
    Validators.required,
  ]);

  nombreAgenteFormControl = new FormControl('', [
    Validators.required,
  ]);

  telefonoAgenteFormControl = new FormControl('', [
    Validators.required,
  ]);

  polizaFormControl = new FormControl('', [
    Validators.required,
  ]);

  fechaVencimientoFormControl = new FormControl('', [
    Validators.required,
  ]);

  consumoGasolinaFormControl = new FormControl('', [
    Validators.required,
  ]);

  recibeMantenimientoFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  displayedColumnsMantenimiento: string[] = ['id', 'mantenimiento', 'fecha', 'acciones'];
  dataSourceMantenimiento: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginatorMantenimiento: MatPaginator;
  @ViewChild(MatSort, {static: true}) sortMantenimiento: MatSort;


  constructor(
    public location: Location,
    private _vehicleService: VehicleService,
    private _activatedRoute: ActivatedRoute,
    private _paramedicService: ParamedicService,
    private _maintenanceService: MaintenanceService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
    ) {
    this.unidad = new Vehicle();
    this.unidad.maintenances = [];
  }

  ngOnInit(): void {
    this.obtenerUnidad();
    this.obtenerParamedicos();
  }


  onFileSelectedImg1(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.unidad.photo1 = reader.result;
    };

  }

  onFileSelectedImg2(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.unidad.photo2 = reader.result;
    };

  }

  onFileSelectedImg3(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.unidad.photo3 = reader.result;
    };

  }

  onFileSelectedImg4(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.unidad.photo4 = reader.result;
    };

  }

  verImg1() {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: this.unidad.photo1
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  verImg2() {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: this.unidad.photo2
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  verImg3() {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: this.unidad.photo3
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  verImg4() {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: this.unidad.photo4
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  volver(): void {
    this.location.back();
  }

  obtenerUnidad() {
    this._activatedRoute.params.subscribe(

      params => {
        this.unidadId = params['id'];
        this._vehicleService.getVehicle(this.unidadId).subscribe(
          response => {
            console.log('UNIDAD', response);
            this.unidad.identifier = response.data.identifier;
            this.unidad.id = response.data.id;
            this.unidad.plete = response.data.plete;
            this.unidad.model = response.data.model;
            this.unidad.color = response.data.color;
            this.unidad.serie = response.data.serie;
            this.unidad.year = response.data.year;
            this.unidad.brand = response.data.brand;
            this.unidad.gasoline_consumption_km = response.data.gasoline_consumption_km;
            this.unidad.state = response.data.state;
            this.unidad.photo1 = `${environment.sourceUrl}${response.data.photo1}`;
            this.unidad.photo2 = (response.data.photo2)?`${environment.sourceUrl}${response.data.photo2}`: `/assets/images/admin/upload-image.png`;
            this.unidad.photo3 = (response.data.photo3)?`${environment.sourceUrl}${response.data.photo3}`: `/assets/images/admin/upload-image.png`;
            this.unidad.photo4 = (response.data.photo4)?`${environment.sourceUrl}${response.data.photo4}`: `/assets/images/admin/upload-image.png`;
            this.unidad.insurance_carrier = response.data.insurance.insurance_carrier;
            this.unidad.insurance_agent = response.data.insurance.insurance_agent;
            this.unidad.policy = response.data.insurance.nro_policy;
            this.unidad.telephone = response.data.insurance.telephone;
            this.unidad.expiration_date = response.data.insurance.date_expired;
            this.unidad.gasoline_consumption_km = response.data.gasoline_consumption_km;
            this.unidad.type_vehicle = response.data.type_vehicle;
            this.unidad.recieve_maintenance_km = response.data.recieve_maintenance_km;
            this.unidad.type_equipment = response.data.type_equipment;
            this.unidad.state = response.data.state;
            this.unidad.paramedic_id = response.data.paramedic_id;
            this.unidad.paramedic = response.data.paramedic;
            this.unidad.user_vehicle = response.data.user_vehicle;
            this.unidad.user_paramedic = response.data.user_paramedic;
            this.unidad.email = response.data.user_vehicle.email;
            this.mantenimientos = response.data.maintenances;

            this.dataSourceMantenimiento = fillTable(this.mantenimientos, this.paginatorMantenimiento, this.sortMantenimiento);

          },
          error => {
            this.openError('Error al obtener Unidad', 'Ok');
          }
        )

      },

    )
  }

  editarUnidad(f: NgForm) {

    this.loader = true;
    console.log('editar', this.unidad)

    this._vehicleService.updateVehicle(this.unidadId, this.unidad).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('Unidad actualizada');

      },
      error => {
        this.loader = false;
        this.openError('Error al actualizar la unidad', 'Ok');
      }
    )

  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  obtenerParamedicos() {
    this._paramedicService.getParamedics().subscribe(
      response => {
        this.paramedicos = response.data;
      },
      error => {
        this.openError('Error al encontrar paramédicos', 'Ok');
      }
    )
  }

  agregarMantenimiento() {
    const dialogRef = this.dialog.open(MantenimientoDialogFormComponent, {
      width: '300px',
      data: {vehicleId: this.unidad.id, isNew: true, mantenimiento: null},
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == "true") {
        this.obtenerUnidad();
        this.mostrarExitoDialog('Mantenimiento agregado');
      }
    })
  }

  editarMantenimiento(mantenimiento) {
    const dialogRef = this.dialog.open(MantenimientoDialogFormComponent, {
      width: '300px',
      data: {vehicleId: this.unidad.id, isNew: false, mantenimiento},
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == "true") {
        this.obtenerUnidad();
        this.mostrarExitoDialog('Mantenimiento editado');
      }
    })
  }

  buscarMantenimiento(event: Event) {

    this.dataSourceMantenimiento = searchInTable(event, this.dataSourceMantenimiento )

  }

  eliminarMantenimiento(id) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este mantenimiento?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this._maintenanceService.deleteMaintenance(id).subscribe(
          response => {
            this.mostrarExitoDialog('Mantenimiento eliminado');
            this.obtenerUnidad();
          },
          error => {
            this.openError('Error al eliminar mantenimiento', 'Ok');
          }
        )

      }
    })
  }

  eliminar(i) {
    let newArray = [];

    this.unidad.maintenances.forEach((element, index) => {
      if(i != index) {
        newArray.push(element);
      }
    });

    this.unidad.maintenances = newArray;
  }
}
