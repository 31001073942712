import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatTableDataSource } from '@angular/material/table';
import { GasolineService } from 'src/app/services';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-gasoline-adm',
  templateUrl: './gasoline-adm.component.html',
  styleUrls: ['./gasoline-adm.component.css']
})
export class GasolineAdmComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'identifier', 'fuel_station', 'amount', 'quantity', 'description', 'vehicle_name', 'paramedic_name', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  loader: boolean = true;

  combustibles$: Subscription;
  deleteCombustible$: Subscription;

  constructor(
    private _gasolineService: GasolineService,
    public dialog: MatDialog
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerCombustibles();
  }

  obtenerCombustibles() {
    this.loader = true;
    this.combustibles$ = this._gasolineService.getGasolines().subscribe(
      response => {
        this.loader = false;

        this.dataSource = fillTable(response.data, this.paginator, this.sort);

      },
      error => {
        this.loader = false;
        this.mostrarErrorDialog('No se encontro el combustible');
      }
    )
  }

  buscarMantenimiento(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  eliminarCombustible(id) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este registro de combustible?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this.deleteCombustible$ = this._gasolineService.deleteGasoline(id).subscribe(
          response => {
            this.mostrarExitoDialog('Registro de combustible eliminado');
            this.obtenerCombustibles();
          },
          error => {
            this.mostrarErrorDialog('Error al eliminar registro de combustible');
          }
        )

      }
    })
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  ngOnDestroy(): void {
    if(this.combustibles$) this.combustibles$.unsubscribe();

    if(this.deleteCombustible$) this.deleteCombustible$.unsubscribe();
  }
}
