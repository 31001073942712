import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { ServiceService } from 'src/app/services';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-gestion-servicio',
  templateUrl: './gestion-servicio.component.html',
  styleUrls: ['./gestion-servicio.component.css']
})
export class GestionServicioComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'id', 'name', 'description', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  loader: boolean = true;

  servicios$: Subscription;
  deleteServicio$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _service: ServiceService
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerServicios();
  }

  buscarServicio(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  eliminarServicio(id) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este servicio?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {
        this.deleteServicio$ = this._service.deleteService(id).subscribe(
          response => {
            this.mostrarExitoDialog('Servicio eliminado')
            this.obtenerServicios();
          },
          error => {
            this.mostrarErrorDialog('Este servicio se encuentra registrado en una atención médiica.')
          }
        )
      }
    })
  }

  obtenerServicios() {
    this.loader = true;
    this.servicios$ = this._service.getServices().subscribe(
      response => {
        this.loader = false;

        this.dataSource = fillTable(response.data, this.paginator, this.sort);
      },
      error => {
        this.loader = false;
        this.mostrarErrorDialog('Error al obtener servicios');
      }
    )
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  ngOnDestroy(): void {
    if(this.servicios$) this.servicios$.unsubscribe();
    if(this.deleteServicio$) this.deleteServicio$.unsubscribe();
  }
}
