<section>

    <vex-section-title title="ADMINISTRACIÓN" subtitle="UNIDADES"></vex-section-title>

    <div class="vex_soluciones_content">
        <div align="end">
            <mat-form-field>
              <mat-label>Buscador</mat-label>
              <input matInput (keyup)="buscarUnidades($event)" placeholder="Buscar">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            &nbsp;
            <button mat-raised-button color="primary" routerLink="/unidades-nuevo">
              <mat-icon>add</mat-icon>
              Agregar
            </button>
        </div>
        <mat-card class="vex-table-header mat-elevation-z0 sc_card_more_options">
            Todas las unidades
            <button class="sc_more_options" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    class="sc_more_options"
                    color="primary"
                    (click)="exporter.exportTable('xlsx', {fileName:'unidades'})">
                    <mat-icon>cloud_download</mat-icon>
                    <span>Exportar a Excel</span>
                </button>

            </mat-menu>
        </mat-card>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort matTableExporter #exporter="matTableExporter">

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                <td mat-cell *matCellDef="let element">
                    <mat-slide-toggle color="primary" (click)="eliminarUnidad(element.id)" [checked]="element.status == 'disponible'"></mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="identifier">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let element; let i = index;"> {{element.identifier}} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="brand">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> MARCA </th>
              <td mat-cell *matCellDef="let element"> {{element.brand}} </td>
            </ng-container>

            <ng-container matColumnDef="model">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> MODELO </th>
              <td mat-cell *matCellDef="let element"> {{element.model}} </td>
            </ng-container> -->

            <!-- <ng-container matColumnDef="year">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> AÑO </th>
              <td mat-cell *matCellDef="let element"> {{element.year}} </td>
            </ng-container> -->

            <ng-container matColumnDef="type_equipment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> EQUIPAMIENTO </th>
                <td mat-cell *matCellDef="let element"> {{element.type_equipment}} </td>
              </ng-container>

            <ng-container matColumnDef="plete">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> PLACA </th>
                <td mat-cell *matCellDef="let element"> {{element.plete}} </td>
            </ng-container>

            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ESTADO </th>
                <td mat-cell *matCellDef="let element">
                    <mat-chip-list aria-label="Fish selection">
                        <mat-chip *ngIf="element.status == 'disponible'" color="primary" selected>{{element.status}}</mat-chip>
                        <mat-chip *ngIf="element.status == 'no disponible'" color="warn" selected>{{element.status}}</mat-chip>
                    </mat-chip-list>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> STATUS </th>
              <td mat-cell *matCellDef="let element"> {{element.state}} </td>
            </ng-container>

            <ng-container matColumnDef="asignado_a">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ASIGNADO A </th>
                <td mat-cell *matCellDef="let element"> {{element.asignado_a}} </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="pin-size-th"> ACCIONES </th>
                <td mat-cell *matCellDef="let element">
                    <button color="primary" [routerLink]="['/unidades-ver', element.id]" mat-icon-button>
                        <mat-icon>visibility</mat-icon>
                    </button>
                    &nbsp;
                    <button color="primary" [routerLink]="['/unidades-editar', element.id]" mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="3"></td>

                <td class="mat-cell" colspan="1" *ngIf="!loader">No se encontraron registros</td>

                <td class="mat-cell" colspan="1" *ngIf="loader">
                    <mat-spinner diameter="40"></mat-spinner>
                </td>

                <td class="mat-cell" colspan="3"></td>
            </tr>
        </table>
        <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

    </div>

</section>
