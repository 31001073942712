<div>
    
    <h2 style="text-align: center;">Confirmación del Sistema</h2>

    <div mat-dialog-content>

        <p>{{message}}</p>
        
    </div>

    <div mat-dialog-actions style="float: right;">
        
        <button class="btn-yes" mat-button mat-dialog-close="true" cdkFocusInitial> Si </button>
        <button class="btn-no" mat-button mat-dialog-close="false" cdkFocusInitial> No </button>

    </div>

</div>