import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { Vehicle } from 'src/app/models/vehicle';
import { VehicleService, ParamedicService } from 'src/app/services';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-unidades-nuevo',
  templateUrl: './unidades-nuevo.component.html',
  styleUrls: ['./unidades-nuevo.component.css']
})
export class UnidadesNuevoComponent implements OnInit {

  img1Temp: any;
  img2Temp: any;
  img3Temp: any;
  img4Temp: any;

  unidad: Vehicle;

  paramedicos: any = [];

  loader: boolean = false;

  name = 'Angular';
  myForm: FormGroup;

  entradas = [];

  identificadorFormControl = new FormControl('', [

  ]);

  placaFormControl = new FormControl('', [
    Validators.required,
  ]);

  modeloFormControl = new FormControl('', [
    Validators.required,
  ]);

  colorFormControl = new FormControl('', [
    Validators.required,
  ]);

  serieFormControl = new FormControl('', [
    Validators.required,
  ]);

  anioFormControl = new FormControl('', [
    Validators.required,
  ]);

  marcaFormControl = new FormControl('', [
    Validators.required,
  ]);

  aseguradoraFormControl = new FormControl('', [
    Validators.required,
  ]);

  nombreAgenteFormControl = new FormControl('', [
    Validators.required,
  ]);

  telefonoAgenteFormControl = new FormControl('', [
    Validators.required,
  ]);

  polizaFormControl = new FormControl('', [
    Validators.required,
  ]);

  fechaVencimientoFormControl = new FormControl('', [
    Validators.required,
  ]);

  consumoGasolinaFormControl = new FormControl('', [
    Validators.required,
  ]);

  recibeMantenimientoFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public location: Location,
    public dialog: MatDialog,
    private _router: Router,
    private _vehicleService: VehicleService,
    private _paramedicService: ParamedicService,

    private fb: FormBuilder,

    private _snackBar: MatSnackBar
  ) {

    this.unidad = new Vehicle();
    // this.unidad.identifier = this.makeid(8);
    this.unidad.maintenances = [];
    this.img1Temp = "/assets/images/admin/upload-image.png";
    this.img2Temp = "/assets/images/admin/upload-image.png";
    this.img3Temp = "/assets/images/admin/upload-image.png";
    this.img4Temp = "/assets/images/admin/upload-image.png";
  }

  prueba() {
    console.log(this.things)
  }

  ngOnInit(): void {
    this.obtenerParamedicos();

    this.createForm();

  }

  onEnter() {
    this.addThing();
  }

  get things() {
    return this.myForm.get('things') as FormArray;
  }

  private createForm() {
    this.myForm = this.fb.group({
      things: this.fb.array([
        // create an initial item
        {
          mantenimiento: this.fb.control(''),
          fecha: this.fb.control('')
        }
      ])
    });
  }

  private addThing() {
    this.things.push(this.fb.control(''));
  }

  onFileSelectedImg1(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.img1Temp = reader.result;
      this.unidad.photo1 = reader.result;
    };

  }

  onFileSelectedImg2(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.img2Temp = reader.result;
      this.unidad.photo2 = reader.result;
    };

  }

  onFileSelectedImg3(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.img3Temp = reader.result;
      this.unidad.photo3 = reader.result;
    };

  }

  onFileSelectedImg4(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.img4Temp = reader.result;
      this.unidad.photo4 = reader.result;
    };

  }

  verImg1() {

    if(this.img1Temp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.img1Temp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  verImg2() {

    if(this.img2Temp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.img2Temp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  verImg3() {

    if(this.img3Temp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.img3Temp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  verImg4() {

      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.img4Temp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })

  }

  volver() {
    this._router.navigate(['/unidades']);
  }

  registrarUnidad(f: NgForm) {
    this.loader = true;

    this.unidad.maintenances = this.entradas;

    console.log('unidad', this.unidad)
    if(this.unidad.photo1 == "") {
      this.openError('La primera imagen es obligatorio', 'Ok');
      this.loader = false;
      return;
    }
    this._vehicleService.saveVehicle(this.unidad).subscribe(
      response => {
        this.mostrarExitoDialog('Unidad registrada');
        this.loader = false;
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.openError('Error al registrar unidad', 'Ok');
        this.loader = false;
      }
    )


  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  makeid(length): string {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  obtenerParamedicos() {
    this._paramedicService.getParamedics().subscribe(
      response => {
        this.paramedicos = response.data;
      },
      error => {
        this.openError('Error al encontrar paramédicos', 'Ok');
      }
    )
  }

  eliminarFila(i) {
    this.things.removeAt(i);
  }

  eliminarFilaTest(i) {

    let newArray = [];

    this.entradas.forEach((element, index) => {
      if(i != index) {
        newArray.push(element);
      }
    });

    this.entradas = newArray;
  }

  adicionartest() {
    this.entradas.push({mantenimiento: '', fecha: ''});
  }

  pruebatest() {
    console.log(this.entradas)
  }
}
