import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralDoctorService } from 'src/app/services';
import { Location } from '@angular/common';
import { GeneralDoctor } from 'src/app/models/general_doctor';
import { MatDialog } from '@angular/material/dialog';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { environment } from '../../../../../../environments/environment';
import { Validators, FormControl, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-medico-general-editar',
  templateUrl: './medico-general-editar.component.html',
  styleUrls: ['./medico-general-editar.component.css']
})
export class MedicoGeneralEditarComponent implements OnInit {

  data: any;
  medicoGeneral: GeneralDoctor;
  medicoGeneralId: number;
  photoTemp: any;
  ineTemp: any;
  titleTemp: any;
  cedulaTemp: any;
  loader: boolean = false;

  buttonName: IButtonName = BUTTON_NAME;


  nombresFormControl = new FormControl('', [
    Validators.required,
  ])

  apePaternoFormControl = new FormControl('', [
    Validators.required,
  ])

  apeMaternoFormControl = new FormControl('', [
    Validators.required,
  ]);

  celFormControl = new FormControl('', [
    Validators.required,
  ])

  telFormControl = new FormControl('', [
    Validators.required,
  ])

  direccionFormControl = new FormControl('', [
    Validators.required,
  ])

  dateBirthFormControl = new FormControl('', [
    Validators.required,
  ])

  matcher = new MyErrorStateMatcher();

  constructor(
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    private _generalDoctorService: GeneralDoctorService,
    private _router: Router,
    public location: Location,
    private _snackBar: MatSnackBar
    ) {
    this.photoTemp = "/assets/images/admin/upload-image.png";
    this.ineTemp = "/assets/images/admin/upload-image.png";
    this.titleTemp = "/assets/images/admin/upload-image.png";
    this.cedulaTemp = "/assets/images/admin/upload-image.png";
    this.medicoGeneral = new GeneralDoctor();
  }

  ngOnInit(): void {
    this.obtenerMedicoGeneral();
  }

  onFileSelectedPhoto(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.photoTemp = reader.result;
      this.medicoGeneral.photo = reader.result;
    };

  }

  verFoto() {

    if(this.photoTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.photoTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedIne(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.ineTemp = reader.result;
      this.medicoGeneral.ine = reader.result;
    };

  }

  verIne() {

    if(this.ineTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.ineTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedTitle(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.titleTemp = reader.result;
      this.medicoGeneral.title = reader.result;
    };

  }

  verTitle() {

    if(this.titleTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.titleTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedCedula(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.cedulaTemp = reader.result;
      this.medicoGeneral.cedula = reader.result;
    };

  }

  verCedula() {

    if(this.cedulaTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.cedulaTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  obtenerMedicoGeneral() {
    this.activatedRoute.params.subscribe( params => {

      let id = params['id'];
      this.medicoGeneralId = id;

      this._generalDoctorService.getGeneralDoctor(id).subscribe(
        response => {
          console.log(response)
          this.medicoGeneral.name = response.data.professional.user.name;
          this.medicoGeneral.lastnamepat = response.data.professional.user.lastnamepat;
          this.medicoGeneral.lastnamemat = response.data.professional.user.lastnamemat;
          this.medicoGeneral.birth_date = response.data.professional.user.birth_date;
          this.medicoGeneral.gender = response.data.professional.user.gender;
          this.medicoGeneral.telephone = response.data.professional.user.telephone;
          this.medicoGeneral.address = response.data.professional.user.address;
          this.medicoGeneral.university = response.data.university;
          this.medicoGeneral.phone = response.data.professional.user.phone;
          this.medicoGeneral.photo = `${environment.sourceUrl}${response.data.professional.user.image}`;
          this.medicoGeneral.ine = `${environment.sourceUrl}${response.data.professional.ine}`;
          this.medicoGeneral.title = `${environment.sourceUrl}${response.data.title}`;
          this.medicoGeneral.cedula = `${environment.sourceUrl}${response.data.cedula}`;
        },
        error => {
          console.log(error)
        }
      )

    });
  }

  editarMedicoGeneral(f: NgForm) {
    this.loader = true;

    this._generalDoctorService.updateGeneralDoctor(this.medicoGeneralId, this.medicoGeneral).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('DATOS DEL MEDICO GENERAL ACTUALIZADO');
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.loader = false;
        this.openError('ERROR AL ACTUALIZAR DATOS DEL MEDICO GENERAL', 'Ok');
      }
    )

  }

  volver() {
    this._router.navigate(['/usuario/profesional/medico-general']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
