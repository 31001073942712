import { NgModule } from '@angular/core';
import { ImagenPipe } from './imagen.pipe';
import { HorasMinutosPipe } from './horas-minutos.pipe';



@NgModule({
  declarations: [ImagenPipe, HorasMinutosPipe],
  imports: [],
  exports: [
    ImagenPipe,
    HorasMinutosPipe
  ]
})
export class PipesModule { }
