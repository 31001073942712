import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { AditionalServService } from 'src/app/services';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-servicios-adicionales-adm',
  templateUrl: './servicios-adicionales-adm.component.html',
  styleUrls: ['./servicios-adicionales-adm.component.css']
})
export class ServiciosAdicionalesAdmComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'identifier', 'category_name', 'name', 'description', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  loader: boolean = true;

  adicionales$: Subscription;
  deleteAdicional$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _service: AditionalServService
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerServiciosAdicionales();
  }

  buscarServicio(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  eliminarServicio(id) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este servicio adicional?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {
        this.deleteAdicional$ = this._service.deleteAditional(id).subscribe(
          response => {
            this.mostrarExitoDialog('Servicio adicional eliminado');
            this.obtenerServiciosAdicionales();
          },
          error => {
            this.mostrarErrorDialog('Error al eliminar servicio adicional')
          }
        )
      }
    })
  }

  obtenerServiciosAdicionales() {
    this.loader = true;
    this.adicionales$ = this._service.getAditionals().subscribe(
      response => {
        this.loader = false;
        console.log('LISTADO', response)
        this.dataSource = fillTable(response.data, this.paginator, this.sort);

      },
      error => {
        this.loader = false;
        this.mostrarErrorDialog('Error al obtener servicios');
      }
    )
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  ngOnDestroy(): void {
    if(this.adicionales$) this.adicionales$.unsubscribe();
    if(this.deleteAdicional$) this.deleteAdicional$.unsubscribe();
  }
}
