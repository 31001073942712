import { Component, OnInit } from '@angular/core';
import { Administrative } from 'src/app/models/administrative';
import { AdministrativeService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { RoleService } from 'src/app/services/role.service';
import { Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { environment } from 'src/environments/environment';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-administrativo-nuevo',
  templateUrl: './administrativo-nuevo.component.html',
  styleUrls: ['./administrativo-nuevo.component.css']
})
export class AdministrativoNuevoComponent implements OnInit {

  administrative: any;
  roles = [];
  hide = true;
  hideC = true;
  loader: boolean = false;

  sourceUrl: any = environment.sourceUrl;

  nombresFormControl = new FormControl('', [
    Validators.required,
  ])

  apePaternoFormControl = new FormControl('', [
    Validators.required,
  ])

  apeMaternoFormControl = new FormControl('', [
    Validators.required,
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6)
  ])

  passConfFormControl = new FormControl('', [
    Validators.required,
    this.passwordMatcher.bind(this)
  ])

  celFormControl = new FormControl('', [
    Validators.required,
  ])

  telFormControl = new FormControl('', [
    Validators.required,
  ])

  direccionFormControl = new FormControl('', [
    Validators.required,
  ])

  dateBirthFormControl = new FormControl('', [
    Validators.required,
  ])

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _administrativeService: AdministrativeService,
    public location: Location,
    private _roleService: RoleService,
    private _snackBar: MatSnackBar
  ) {
    this.administrative = new Administrative();
  }

  public passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (
        (control.value !== this.passFormControl.value)
    ) {
        return { passwordNotMatch: true };
    }
    return null;
  }

  ngOnInit(): void {
    this.obtenerRoles();
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.administrative.image = reader.result;
    };
  }

  cancelar() {
    this.administrative.image = null;
  }

  registrarAdministrativo(f: NgForm) {

      console.log('ADMINISTRADOR', this.administrative);

      this.loader = true;

      this.administrative.type = "administrador";
      console.log(this.administrative);

      this._administrativeService.saveAdministrative(this.administrative).subscribe(
        response => {
          this.loader = false;
          this.mostrarExitoDialog('ADMINISTRADOR REGISTRADO');
          setTimeout(() => {
            this.location.back();
          }, 1500);
        },
        error => {
          this.loader = false;
          if(error.error.code == 422) {
            this.openError('Correo existente, Ingrese otro.', 'Ok');
            return;
          }
          this.openError(error, 'Ok');
        }
      );
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._router.navigate(['/roles-y-permisos']);
      }
    })
  }

  volver() {
    this._router.navigate(['/usuario/administrativo']);
  }

  obtenerRoles() {
    this._roleService.getRoles().subscribe(
      response => {
        console.log('response', response);
        this.roles = response.data;
      },
      error => {
        console.log('error', error);
      }
    )
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
