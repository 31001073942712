<section>
    <vex-section-title title="ADMINISTRACIÓN" subtitle="GESTIÓN DE TAREAS"></vex-section-title>

    <div class="vex_soluciones_content">
        <div align="end">
            <mat-form-field>
              <mat-label>Buscador</mat-label>
              <input matInput (keyup)="buscarTarea($event)" placeholder="Buscar">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            &nbsp;
            <button mat-raised-button color="primary" [routerLink]="['/nueva-tarea']">
              <mat-icon>add</mat-icon>
              Agregar
            </button>

        </div>

        <mat-card class="mat-elevation-z0 sc_card_more_options">
            Todas las tareas
            <button class="sc_more_options" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    class="sc_more_options"
                    color="primary"
                    (click)="exporter.exportTable('xlsx', {fileName:'tareas'})">
                    <mat-icon>cloud_download</mat-icon>
                    <span>Exportar a Excel</span>
                </button>

            </mat-menu>
        </mat-card>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort matTableExporter #exporter="matTableExporter">

            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ESTADO </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox color="primary" (change)="cambiarEstado(element.identifier, $event)" [checked]="element.isCompleted" class="example-margin">
                        <div>
                            {{element.status}}
                        </div>
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let element"> {{element.identifier}} </td>
            </ng-container>

            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> NOTAS </th>
                <td mat-cell *matCellDef="let element"> {{(element.description)? element.description: 'Sin Notas'}} </td>
            </ng-container>

            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA </th>
                <td mat-cell *matCellDef="let element"> {{(element.date)? (element.date | date) : 'Sin Fecha'}} </td>
            </ng-container>

            <ng-container matColumnDef="tiempo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> HORA </th>
                <td mat-cell *matCellDef="let element"> {{(element.hour)? element.hour: 'Sin Hora'}} </td>
            </ng-container>

            <ng-container matColumnDef="responsable">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> RESPONSABLE </th>
                <td mat-cell *matCellDef="let element"> {{element.user.name}} {{element.user.lastnamepat}} {{element.user.lastnamemat}} </td>
            </ng-container>

            <ng-container matColumnDef="prioridad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> PRIORIDAD </th>
                <td mat-cell *matCellDef="let element"> {{element.priority.toUpperCase()}} </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="pin-size-th"> ACCIONES </th>
                <td mat-cell *matCellDef="let element">
                    <button color="primary" [routerLink]="['/editar-tarea', element.identifier]" mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>
                    &nbsp;
                    <button (click)="eliminarTarea(element.identifier)" mat-icon-button color="warn" class="vex_soluciones_btn_delete">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9">No se encontraron registros.</td>
            </tr>
        </table>
        <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

    </div>

</section>
