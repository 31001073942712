import { Component, OnInit } from '@angular/core';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AditionalServService, CategoryServService } from 'src/app/services';
import { AditionalService } from 'src/app/models/AditionalService';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-servicios-adicionales-editar',
  templateUrl: './servicios-adicionales-editar.component.html',
  styleUrls: ['./servicios-adicionales-editar.component.css']
})
export class ServiciosAdicionalesEditarComponent implements OnInit {

  servicioAdicional: AditionalService;
  servicioAdicionalId: any;
  categorias: any = [];
  loader: boolean = false;

  nombreFormControl = new FormControl('', [
    Validators.required,
  ]);

  descripcionFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;
  constructor(
    public location: Location,
    public dialog: MatDialog,
    private _router: Router,
    private _aditionalService: AditionalServService,
    private _activatedService: ActivatedRoute,
    private _categoryService: CategoryServService,
    private _snackBar: MatSnackBar
    ) {
      this.servicioAdicional = new AditionalService();
    }

  ngOnInit(): void {
    this.obtenerServicioAdicional();
    this.obtenerCategorias();
  }

  obtenerCategorias() {
    this._categoryService.getCategories().subscribe(
      response => {
        this.categorias = response.data;
        console.log(response)
      },
      error => {
        this.openError('No se encontraron categorias', 'Ok');
      }
    )
  }

  obtenerServicioAdicional() {
    this._activatedService.params.subscribe(
      params => {
        this.servicioAdicionalId = params['id'];
        this._aditionalService.getAditional(this.servicioAdicionalId).subscribe(
          response => {
            this.servicioAdicional.name = response.data.name;
            this.servicioAdicional.description = response.data.description;
            this.servicioAdicional.active = response.data.active;
            this.servicioAdicional.category_id = response.data.category.id;
          },
          error => {
            this.openError('Servicio adicional no encontrado', 'Ok')
          }
        )
      }
    )
  }

  editarServicioAdicional(f: NgForm) {
    this.loader = true;

    this._aditionalService.updateAditional(this.servicioAdicionalId, this.servicioAdicional).subscribe(
      response => {
        this.mostrarExitoDialog('Servicio adicional editado')
        this.loader = false;
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.openError('Error al actualizar servicio adicional', 'Ok');
        this.loader = false;
      }
    )

  }

  volver() {
    this._router.navigate(['/gestion-de-servicios-adicionales']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
