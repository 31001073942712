<section>
    <vex-section-title title="ADMINISTRACIÓN" subtitle="EDITAR EMPRESA"></vex-section-title>


    <div class="vex_soluciones_content">
        <form #f="ngForm" ngNativeValidate (ngSubmit)="editarEmpresa(f)" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col s12 m4 l4 vex_soluciones_colum">
                    <mat-card>
                        <h3>Datos Empresa</h3>
                        <mat-form-field appearance="legacy">
                            <mat-label>Empresa</mat-label>
                            <input name="empresa" [formControl]="empresaFormControl" [errorStateMatcher]="matcher" matInput placeholder="Empresa" [(ngModel)]="company.name" required>
                            <mat-error *ngIf="empresaFormControl.hasError('required')">
                                Nombre Empresa <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Número de afiliación</mat-label>
                            <input [formControl]="numeroAfiliacionFormControl" [errorStateMatcher]="matcher" matInput placeholder="Nombre" [(ngModel)]="company.affiliation_number" required>
                            <mat-error *ngIf="numeroAfiliacionFormControl.hasError('required')">
                                Número Afiliación <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Dirección</mat-label>
                            <input [formControl]="direccionFormControl" [errorStateMatcher]="matcher" matInput placeholder="Dirección" [(ngModel)]="company.address" required>
                            <mat-error *ngIf="direccionFormControl.hasError('required')">
                                Dirección <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="legacy">
                            <mat-label>Teléfono</mat-label>
                            <input [formControl]="telefonoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Teléfono" [(ngModel)]="company.phone" required>
                            <mat-error *ngIf="telefonoFormControl.hasError('required')">
                                Teléfono <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="legacy">
                            <mat-label>Celular</mat-label>
                            <input [formControl]="celularFormControl" [errorStateMatcher]="matcher" matInput placeholder="Celular" [(ngModel)]="company.celphone" required>
                            <mat-error *ngIf="celularFormControl.hasError('required')">
                                Celular <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Correo Electrónico</mat-label>
                            <input [formControl]="correoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Correo Electrónico" [(ngModel)]="company.email" required>
                            <mat-error *ngIf="correoFormControl.hasError('required')">
                                Correo Electrónico <strong>Requerido</strong>
                            </mat-error>
                            <mat-error *ngIf="correoFormControl.hasError('email') && !correoFormControl.hasError('required')">
                                Por favor ingrese un correo electrónico válido
                            </mat-error>
                        </mat-form-field>
                    </mat-card>

                </div>
                <div class="col s12 m4 l4">

                    <mat-card>
                        <h3>Datos del Representante</h3>
                        <mat-form-field appearance="legacy">
                            <mat-label>Nombre</mat-label>
                            <input name="name" [formControl]="nombreRepresentanteFormControl" [errorStateMatcher]="matcher" matInput placeholder="Nombre" [(ngModel)]="company.representative_name" required>
                            <mat-error *ngIf="nombreRepresentanteFormControl.hasError('required')">
                                Nombre de Representante <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Apellido Paterno</mat-label>
                            <input name="apellido_paterno" [formControl]="apellidoPaternoRepresentanteFormControl" [errorStateMatcher]="matcher" matInput placeholder="Apellido Paterno" [(ngModel)]="company.representative_lastnamepat" required>
                            <mat-error *ngIf="apellidoPaternoRepresentanteFormControl.hasError('required')">
                                Apellido Paterno de Representante <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Apellido Materno</mat-label>
                            <input name="apellido_materno" [formControl]="apellidoMaternoRepresentanteFormControl" [errorStateMatcher]="matcher" matInput placeholder="Apellido Materno" [(ngModel)]="company.representative_lastnamemat" required>
                            <mat-error *ngIf="apellidoMaternoRepresentanteFormControl.hasError('required')">
                                Apellido Materno de Representante <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Fecha de nacimiento</mat-label>
                            <input name="fecha_nacimiento" [formControl]="fechaNacimientoRepresentanteFormControl" [errorStateMatcher]="matcher" (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="company.representative_birth_date" required>
                            <mat-error *ngIf="fechaNacimientoRepresentanteFormControl.hasError('required')">
                                Fecha de nacimiento de Representante <strong>Requerido</strong>
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Correo electrónico</mat-label>
                            <input name="correo" [formControl]="correoRepresentanteFormControl" [errorStateMatcher]="matcher" matInput placeholder="Correo electrónico" [(ngModel)]="company.representative_email" required>
                            <mat-error *ngIf="correoRepresentanteFormControl.hasError('required')">
                                Correo Electrónico de Representante <strong>Requerido</strong>
                            </mat-error>
                            <mat-error *ngIf="correoRepresentanteFormControl.hasError('email') && !correoRepresentanteFormControl.hasError('required')">
                                Por favor ingrese un correo electrónico válido
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Teléfono</mat-label>
                            <input name="telefono" [formControl]="telefonoRepresentanteFormControl" [errorStateMatcher]="matcher" matInput placeholder="Teléfono" [(ngModel)]="company.representative_phone" required>
                            <mat-error *ngIf="telefonoRepresentanteFormControl.hasError('required')">
                                Teléfono de Representante <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Dirección</mat-label>
                            <input name="direccion" [formControl]="direccionRepresentanteFormControl" [errorStateMatcher]="matcher" matInput placeholder="Dirección" [(ngModel)]="company.representative_address" required>
                            <mat-error *ngIf="direccionRepresentanteFormControl.hasError('required')">
                                Dirección de Representante <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                          <mat-label>Género</mat-label>
                          <mat-select name="gender" [(ngModel)]="company.representative_gender" required>
                            <mat-option value="Masculino" (click)="company.representative_gender = 'Masculino'">
                              Masculino
                            </mat-option>
                            <mat-option value="Femenino" (click)="company.representative_gender = 'Femenino'">
                              Femenino
                            </mat-option>
                            <mat-option value="Otro" (click)="company.representative_gender = 'Otro'">
                              Otro
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </mat-card>

                </div>
            </div>

            <div class="row">
                <div class="col s12" *ngIf="!loader">
                    <button mat-raised-button (click)="volver()" type="button">{{buttonName.BACK}}</button>
                    &nbsp;
                    <button mat-raised-button color="primary" type="submit">{{buttonName.UPDATE}}</button>
                </div>
                <div class="col s12" *ngIf="loader">
                    <mat-spinner diameter="40"></mat-spinner>
                </div>
            </div>
        </form>

    </div>
</section>
