<section>

    <div class="vex_soluciones_tiles">
        <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
        <h2 class="vex_soluciones_subtitle">NUEVO MÉDICO GENERAL</h2>
    </div>

    <div class="vex_soluciones_content">
        <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarMedicoGeneral(f)" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col s12 m4 l4 p-l-0">
                    <mat-card>
                        <h3>Datos Personales</h3>

                        <mat-form-field appearance="legacy">
                            <mat-label>Nombre</mat-label>
                            <input name="name" [formControl]="nombresFormControl" [errorStateMatcher]="matcher" matInput placeholder="Nombre" [(ngModel)]="medicoGeneral.name" required>
                            <mat-error *ngIf="nombresFormControl.hasError('required')">
                                Nombres <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Apellido Paterno</mat-label>
                            <input name="apePaterno" [formControl]="apeMaternoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Apellido Paterno" [(ngModel)]="medicoGeneral.lastnamepat" required>
                            <mat-error *ngIf="apeMaternoFormControl.hasError('required')">
                                Apellido Paterno <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Apellido Materno</mat-label>
                            <input name="apeMaterno" [formControl]="apePaternoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Apellido Materno" [(ngModel)]="medicoGeneral.lastnamemat" required>
                            <mat-error *ngIf="apePaternoFormControl.hasError('required')">
                                Apellido Materno <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Fecha de nacimiento</mat-label>
                            <input name="dateBirth" [formControl]="dateBirthFormControl" [errorStateMatcher]="matcher" [formControl]="dateBirthFormControl" [errorStateMatcher]="matcher" (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="medicoGeneral.birth_date" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="dateBirthFormControl.hasError('required')">
                                Fecha de nacimiento <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Género</mat-label>
                            <mat-select name="gender" [(ngModel)]="medicoGeneral.gender" required>
                                <mat-option value="Masculino">
                                    Masculino
                                </mat-option>
                                <mat-option value="Femenino">
                                    Femenino
                                </mat-option>
                                <mat-option value="Otro">
                                  Otro
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Telefono</mat-label>
                            <input name="tel" [formControl]="telFormControl" [errorStateMatcher]="matcher" matInput placeholder="Telefono" [(ngModel)]="medicoGeneral.telephone" required>
                            <mat-error *ngIf="telFormControl.hasError('required')">
                                Teléfono <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Dirección</mat-label>
                            <input name="address" [formControl]="direccionFormControl" [errorStateMatcher]="matcher" matInput placeholder="Dirección" [(ngModel)]="medicoGeneral.address" required>
                            <mat-error *ngIf="direccionFormControl.hasError('required')">
                                Dirección <strong>Requerida</strong>
                            </mat-error>
                        </mat-form-field>
                    </mat-card>

                </div>
                <div class="col s12 m4 l4">

                    <mat-card>
                        <h3>Datos Profesionales</h3>

                        <mat-form-field appearance="legacy">
                            <mat-label>Universidad</mat-label>
                            <input name="university" matInput placeholder="Institución" [(ngModel)]="medicoGeneral.university" required>
                            <mat-error *ngIf="direccionFormControl.hasError('required')">
                                Dirección <strong>Requerida</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Correo Institucional</mat-label>
                            <input name="email" [formControl]="emailFormControl" [errorStateMatcher]="matcher" matInput placeholder="Correo Institucional" [(ngModel)]="medicoGeneral.email" required>
                            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                Por favor ingrese un correo electrónico valido
                            </mat-error>
                            <mat-error *ngIf="emailFormControl.hasError('required')">
                                Correo electrónico <strong>requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Constraseña</mat-label>
                            <input autocomplete="new-password" name="password" matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="medicoGeneral.password" required>
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="passFormControl.hasError('required')">
                                Contraseña <strong>Requerida</strong>
                            </mat-error>
                            <mat-error *ngIf="!passFormControl.hasError('minLength')">
                                Minimo caracteres <strong>6</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Confirmar Constraseña</mat-label>
                            <input name="password_confirmation" [formControl]="passConfFormControl" [errorStateMatcher]="matcher" name="password" [formControl]="passFormControl" [errorStateMatcher]="matcher" matInput [type]="hideC ? 'password' : 'text'" [(ngModel)]="medicoGeneral.password_confirmation" required>
                            <button mat-icon-button matSuffix (click)="hideC = !hideC" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideC">
                            <mat-icon>{{hideC ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="passConfFormControl.hasError('required')">
                                Confirmación de Contraseña <strong>Requerida</strong>
                            </mat-error>
                            <mat-error *ngIf="passwordMatcher">
                                Confirmación de Contraseña <strong>No coincide</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Celular</mat-label>
                            <input name="cel" [formControl]="celFormControl" [errorStateMatcher]="matcher" matInput placeholder="Celular" [(ngModel)]="medicoGeneral.phone" required>
                            <mat-error *ngIf="celFormControl.hasError('required')">
                                Celular <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>
                    </mat-card>

                </div>
                <div class="col s12 m4 l4">

                    <mat-card>
                        <h3>Archivos</h3>

                        <div class="row">
                            <div class="col s3 img-width">
                                <img [src]="photoTemp" class="vex_soluciones_file" (click)="verFoto()">
                            </div>
                            <div class="col s9 button-width">
                                <button class="vex_soluciones_button_file" type="button" mat-raised-button (click)="photoFile.click()">Subir Foto</button>
                                <input hidden (change)="onFileSelectedPhoto($event.target.files[0])" #photoFile type="file">
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col s3 img-width">
                                <img [src]="ineTemp" class="vex_soluciones_file" (click)="verIne()">
                            </div>
                            <div class="col s9 button-width">
                                <button class="vex_soluciones_button_file" type="button" mat-raised-button (click)="ineFile.click()">Subir INE</button>
                                <input hidden (change)="onFileSelectedIne($event.target.files[0])" #ineFile type="file">
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col s3 img-width">
                                <img [src]="titleTemp" class="vex_soluciones_file" (click)="verTitle()">
                            </div>
                            <div class="col s9 button-width">
                                <button class="vex_soluciones_button_file" type="button" mat-raised-button (click)="titleFile.click()">Subir Título</button>
                                <input hidden (change)="onFileSelectedTitle($event.target.files[0])" #titleFile type="file">
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col s3 img-width">
                                <img [src]="cedulaTemp" class="vex_soluciones_file" (click)="verCedula()">
                            </div>
                            <div class="col s9 button-width">
                                <button class="vex_soluciones_button_file" type="button" mat-raised-button (click)="cedulaFile.click()">Subir Cedula</button>
                                <input hidden (change)="onFileSelectedCedula($event.target.files[0])" #cedulaFile type="file">
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col s12 p-l-0" *ngIf="!loader">
                    <button type="button" mat-raised-button (click)="volver()">{{buttonName.BACK}}</button>
                    &nbsp;
                    <button type="submit" mat-raised-button color="primary">{{buttonName.STORE}}</button>
                </div>
                <div class="col s12" *ngIf="loader">
                    <mat-spinner diameter="40"></mat-spinner>
                </div>
            </div>
        </form>

    </div>
</section>
