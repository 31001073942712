export class Employee {
  public name :string;
  public email :string;
  public password :string;
  public password_confirmation :string;
  public lastnamepat :string;
  public lastnamemat :string;
  public birth_date :string;
  public gender :string;
  public address :string;
  public phone :string;
  public telephone :string;
  public image :any;
  public job: string;
  public company_id: string;
  public affiliation_number: string;
  public name_first_familiar :string;
  public email_first_familiar :string;
  public phone_first_familiar :string;
  public name_second_familiar :string;
  public email_second_familiar :string;
  public phone_second_familiar :string;
  status: any;
    constructor(
    ) {}
}
