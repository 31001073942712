import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ThemeService } from 'ng2-charts';
import { ConfigurationService } from 'src/app/services/configuration.service';

class DataEmail {
  constructor(
    public name: any,
    public email: any
  ){}
}
@Component({
  selector: 'app-add-email',
  templateUrl: './add-email.component.html',
  styleUrls: ['./add-email.component.css']
})
export class AddEmailComponent implements OnInit {

  data: DataEmail;

  constructor(
    public dialogRef: MatDialogRef<AddEmailComponent>,
    private _configService: ConfigurationService
  ) { 
    this.data = new DataEmail(null, null);
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  registrarCorreo(f: NgForm) {
    
    this._configService.saveEmail(this.data).subscribe(
      response => {
        this.dialogRef.close("true");
      },
      error => {
      
      }
    );

  }

}
