import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';
import { Company } from 'src/app/models/company';
import { UploadImage } from 'src/app/models/upload_image';
import { CompanyService } from 'src/app/services';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { UploadExcelEmployeeComponent } from 'src/app/shareds/dialogs/upload-excel-employee/upload-excel-employee.component';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-empresas-ver',
  templateUrl: './empresas-ver.component.html',
  styleUrls: ['./empresas-ver.component.css']
})
export class EmpresasVerComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'email', 'job', 'action'];
  dataSource: MatTableDataSource<any>;

  sourceUrl = environment.sourceUrl;

  uploadImage: UploadImage;
  tempImage: any;

  uploadImageRep: UploadImage;
  tempImageRep: any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  empresa: Company;
  companyId: any;

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    private _companyService: CompanyService,
    private _activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _router: Router,
    public dialog: MatDialog
  ) {
    this.empresa = new Company();
    this.uploadImage = new UploadImage();
    this.uploadImageRep = new UploadImage();
  }

  ngOnInit(): void {

    this.obtenerEmpresa();

  }

  applyFilter(event: Event) {
    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerEmpresa() {
    this._activatedRoute.params.subscribe(
      params => {

        this.companyId = params['id'];

        this._companyService.getCompany(this.companyId).subscribe(

          response => {
            console.log('EMPRESA',response)
            this.empresa.id = response.data.identifier;
            this.empresa.name = response.data.name;
            this.empresa.affiliation_number = response.data.affiliation_number;
            this.empresa.address = response.data.address;
            this.empresa.phone = response.data.phone;
            this.empresa.celphone = response.data.celphone;
            this.empresa.email = response.data.email;
            this.empresa.representative_name = response.data.representative_name;
            this.empresa.representative_lastnamepat = response.data.representative_lastnamepat;
            this.empresa.representative_lastnamemat = response.data.representative_lastnamemat;
            this.empresa.representative_birth_date = response.data.representative_birth_date;
            this.empresa.representative_email = response.data.representative_email;
            this.empresa.representative_phone = response.data.representative_phone;
            this.empresa.representative_address = response.data.representative_address;
            this.empresa.representative_gender = response.data.representative_gender;
            this.empresa.image = response.data.image;
            this.empresa.representative_image = response.data.representative_image;

            this.tempImage = response.data.image;
            this.tempImageRep = response.data.representative_image;

            this.dataSource = fillTable(response.data.employees, this.paginator, this.sort);

          },
          error => {
            this.openError('Error al encontrar empresa', 'Ok');
          }
        )

      }
    )
  }

  cargarExcel() {
    const dialogRef = this.dialog.open(UploadExcelEmployeeComponent, {
      width: '330px',
      data: this.companyId
    })

    dialogRef.afterClosed().subscribe(result => {
      this.obtenerEmpresa();

    })
  }

  verModelo() {
    window.open("https://docs.google.com/spreadsheets/d/1SLdQ7yZuDSECluv1YwvESmwcyFqWkr8fHGShe-w1n2A/edit#gid=0",'_blank');
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.uploadImage.image = reader.result;
      this.tempImage = reader.result;
    };
  }

  guardarImagen() {

    this._companyService.uploadImageCompany(this.empresa.id, this.uploadImage).subscribe(
      response => {
        console.log('response', response);
        console.log('response', response.data.image)
        this.tempImage = response.data.image;
        this.uploadImage.image = null;
        this.empresa.image = response.data.image;

        this.mostrarExitoDialog('Imagen actualizada')

      },
      error => {
        this.openError('Error al guardar imagen', 'Ok');
      }
    )
  }

  cancelar() {
    this.tempImage = this.empresa.image;
    this.uploadImage.image = null;
  }

  subirImagenRep(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.uploadImageRep.image = reader.result;
      this.tempImageRep = reader.result;
    };
  }

  guardarImagenRep() {

    this._companyService.uploadImageRepresentative(this.empresa.id, this.uploadImageRep).subscribe(
      response => {
        this.tempImageRep = response.data.representative_image;
        this.uploadImageRep.image = null;
        this.empresa.representative_image = response.data.representative_image;

        this.mostrarExitoDialog('Imagen actualizada')

      },
      error => {
        this.openError('Error al guardar imagen', 'Ok');
      }
    )
  }

  cancelarRep() {
    this.tempImageRep = this.empresa.representative_image;
    this.uploadImageRep.image = null;
  }

  volver() {
    this._router.navigate(['/empresas']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
