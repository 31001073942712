export { AditionalServService } from './aditional-service.service';
export { AdministrativeService } from './administrative.service';
export { AttentionService } from './attentions.service';
export { AuthenticationService } from './authentication.service';
export { CategoryServService } from './category_service.service';
export { ConfigurationService } from './configuration.service';
export { CouponService } from './coupon.service';
export { CompanyService } from './company.service';
export { EmployeeService } from './employee.service';
export { GasolineService } from './gasoline.service';
export { GeneralDoctorService } from './general_doctor.service';
export { InboxService } from './inbox.service';
export { InsuranceService } from './insurance.service';
export { MaintenanceService } from './maintenance.service';
export { NoteService } from './note.service';
export { NotificationService } from './notification.service';
export { NurseService } from './nurse.service';
export { PageService } from './page.service';
export { ParamedicService } from './paramedic.service';
export { PatientService } from './patient.service';
export { PhlebotomistService } from './phlebotomist.service';
export { PrivateService } from './private.service';
export { ProfessionalService } from './professional.service';
export { PsychologistService } from './psychologist.service';
export { RadiologistService } from './radiologist.service';
export { RoleService } from './role.service';
export { ServiceService } from './service.service';
export { TaskService } from './task.service';
export { VehicleService } from './vehicle.service';
export { UserService } from './user.service';