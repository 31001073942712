<section>
    <div class="row">
        <div class="col s1"></div>
        <div class="col s10">

            <div class="vex_soluciones_tiles">
                <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
                <h2 class="vex_soluciones_subtitle">VER PACIENTE PRIVADO</h2>

                <button class="vex-souluciones-back" mat-raised-button (click)="volver()">{{buttonName.BACK}}</button>
            </div>
            <div>

                <mat-tab-group>
                    <mat-tab label="GENERAL">
                        <div class="row padding-content">
                            <div class="col s3 p-l-0">
                                <mat-card>
                                    <div class="center">
                                        <div class="img-content">
                                            <img class="vex-soluciones-img-profile" [src]="(uploadImage.image)? tempImage: sourceUrl + tempImage" alt="" width="120">
                                        </div>
                                    </div>
                                    <h3 class="center m-b-0 vex-soluciones-name-title">{{private.name}} {{private.lastnamepat}}</h3>
                                    <!-- <h4 class="center m-t-0 vex-soluciones-email-title">{{private.email}}</h4> -->
                                    <div class="vex-user-status-container">
                                      <div [ngClass]="{'active': private.status == 'activado', 'inactive': private.status != 'activado'}">
                                        {{private.status == 'activado' ? 'Activo': 'Cesado'}}
                                      </div>
                                    </div>
                                    <!-- <div class="center">
                                        <button *ngIf="tempImage == private.image" mat-stroked-button color="primary" type="button" (click)="photoFile.click()">Cambiar Imagen</button>
                                        <input hidden (change)="subirImagen($event.target.files[0])" #photoFile type="file" accept="image/x-png,image/gif,image/jpeg,image/jpg">

                                    </div> -->

                                    <div class="center">
                                        <button mat-stroked-button *ngIf="tempImage != private.image" type="button" (click)="cancelar()">Cancelar</button>
                                        &nbsp;
                                        <button mat-stroked-button *ngIf="tempImage != private.image" type="button" (click)="guardarImagen()" color="primary">Guardar</button>

                                    </div>

                                </mat-card>
                            </div>
                            <div class="col s9 p-r-0">
                                <mat-card>
                                    <h4>Perfil</h4>

                                    <div class="row">
                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Nombre</mat-label>
                                                <input matInput placeholder="Nombre" [(ngModel)]="private.name" readonly>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Apellidos</mat-label>
                                                <input matInput placeholder="Apellidos" [(ngModel)]="private.lastnamepat" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Correo Electrónico</mat-label>
                                                <input matInput placeholder="Correo Electrónico" [(ngModel)]="private.email" readonly>
                                            </mat-form-field>

                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Teléfono</mat-label>
                                                <input matInput placeholder="Teléfono" [(ngModel)]="private.telephone" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Fecha de nacimiento</mat-label>
                                                <input matInput placeholder="Placeholder" [(ngModel)]="private.birth_date" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Dirección</mat-label>
                                                <input matInput placeholder="Dirección" [(ngModel)]="private.address" readonly>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="ATENCIONES">
                        <div class="row padding-content">
                            <div class="col s12 p-r-0 p-l-0">
                                <mat-card>
                                    <h4>Mis Atenciones Médicas</h4>
                                    <mat-divider></mat-divider>
                                    <div class="row info-padding-top info-padding-bottom">
                                        <div class="col s12">
                                            <mat-form-field class="searcher-atention">
                                              <mat-label>Buscar</mat-label>
                                              <input matInput (keyup)="applyFilter2($event)" placeholder="Buscar Atención" #input>
                                              <mat-icon matSuffix>search</mat-icon>
                                            </mat-form-field>
                                            <div class="mat-elevation-z1">
                                                <table mat-table [dataSource]="dataSource2" matSort>

                                                    <!-- ID Column -->
                                                    <ng-container matColumnDef="id">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                                    <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                                                    </ng-container>

                                                    <!-- Progress Column -->
                                                    <ng-container matColumnDef="service">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Servicios </th>
                                                    <td mat-cell *matCellDef="let row"> {{row.service.name}} </td>
                                                    </ng-container>

                                                    <!-- Name Column -->
                                                    <ng-container matColumnDef="professional">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Profesionales </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        <mat-chip-list aria-label="Fish selection">
                                                            <mat-chip *ngFor="let profesional of row.professionals">{{profesional.type}} - {{profesional.user.name}} {{profesional.user.lastnamepat}}</mat-chip>
                                                        </mat-chip-list>
                                                    </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="paramedic">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Paramédico </th>
                                                        <td mat-cell *matCellDef="let row">
                                                            <mat-chip-list aria-label="Fish selection">
                                                                <mat-chip>{{row.paramedic}} </mat-chip>
                                                            </mat-chip-list>

                                                        </td>
                                                        </ng-container>

                                                    <!-- Color Column -->
                                                    <ng-container matColumnDef="date">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                                                    <td mat-cell *matCellDef="let row" [style.color]="row.date"> {{row.date | date: 'dd/MM/yyyy HH:mm:ss'}} </td>
                                                    </ng-container>

                                                    <!-- Color Column -->
                                                    <ng-container matColumnDef="action">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acción </th>
                                                        <td mat-cell *matCellDef="let row" [style.color]="row.date">
                                                            <button mat-raised-button color="primary" [routerLink]="['/ver-atencion', row.id]">Ver</button>
                                                        </td>
                                                        </ng-container>

                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

                                                    <!-- Row shown when there is no matching data. -->
                                                    <tr class="mat-row" *matNoDataRow>
                                                    <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro "{{input.value}}"</td>
                                                    </tr>
                                                </table>

                                                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                                            </div>
                                        </div>

                                    </div>
                                    <mat-divider></mat-divider>
                                    <div class="info-padding-top">
                                        <button mat-raised-button color="primary">Nueva Atención</button>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="SEGURIDAD">
                        <div class="row padding-content">
                            <div class="col s12 p-r-0 p-l-0">
                                <form #updatePassword="ngForm" ngNativeValidate (ngSubmit)="actualizarContrasenia(updatePassword)" (keydown.enter)="$event.preventDefault()">
                                    <mat-card>
                                        <h4>Cambiar Contraseña</h4>
                                        <mat-divider></mat-divider>
                                        <div class="row info-padding-top">
                                            <div class="col s12 m4">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Contraseña Actual</mat-label>
                                                    <input matInput [type]="hideA ? 'password' : 'text'" [(ngModel)]="password.current_password" name="current_password" required>
                                                    <button mat-icon-button matSuffix (click)="hideA = !hideA" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideA">
                                                    <mat-icon>{{hideA ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m4">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Nueva Contraseña</mat-label>
                                                    <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="password.new_password" name="new_password" required>
                                                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m4">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Confirmar Contraseña</mat-label>
                                                    <input matInput [type]="hideC ? 'password' : 'text'" [(ngModel)]="password.new_password_confirm" name="new_password_confirm" required>
                                                    <button mat-icon-button matSuffix (click)="hideC = !hideC" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideC">
                                                    <mat-icon>{{hideC ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="info-padding-top">
                                            <button *ngIf="!loaderUpdPwd" mat-raised-button color="primary" type="submit">Actualizar contraseña</button>
                                            <mat-spinner *ngIf="loaderUpdPwd" diameter="35"></mat-spinner>
                                        </div>
                                    </mat-card>
                                </form>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>

        </div>
        <div class="col s1"></div>
    </div>
</section>
