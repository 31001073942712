export class Coupon {
  code              : string;
  quantity          : number;
  discount          : string;
  date_in           : string;
  date_out          : string;
  status            : string;
  administrative_id : string;

  constructor(

  ){}
}
