import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor(
        private http: HttpClient
    ) {}

    obtenerUsuarioActual() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    establecerNombreCompleto(nombre, apePaterno, apeMaterno) {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        const userModifier = {
            ...user,
            name: nombre,
            lastnamepat: apePaterno,
            lastnamemat: apeMaterno
        }

        localStorage.setItem('currentUser', JSON.stringify(userModifier));
    }
}
