import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CategoryServService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-categorias-adm',
  templateUrl: './categorias-adm.component.html',
  styleUrls: ['./categorias-adm.component.css']
})
export class CategoriasAdmComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'identifier', 'name', 'description', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  loader: boolean = true;

  categorias$: Subscription;
  deleteCategoria$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _service: CategoryServService
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerCategorias();
  }

  buscarServicio(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  eliminarServicio(id) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este categoria de cupon?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {
        this._service.deleteCategory(id).subscribe(
          response => {
            this.mostrarExitoDialog('Categoria eliminada');
            this.obtenerCategorias();
          },
          error => {
            this.mostrarErrorDialog('Error al eliminar categoria');
          }
        )
      }
    })
  }

  obtenerCategorias() {
    this.loader = true;
    this._service.getCategories().subscribe(
      response => {
        this.loader = false;

        this.dataSource = fillTable(response.data, this.paginator, this.sort);
      },
      error => {
        this.loader = false;
        this.mostrarErrorDialog('Error al obtener servicios');
      }
    )
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  ngOnDestroy(): void {
    if(this.categorias$) this.categorias$.unsubscribe();
    if(this.deleteCategoria$) this.deleteCategoria$.unsubscribe();
  }
}
