import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdministrativeService } from 'src/app/services';
import { Location } from '@angular/common';
import { Administrative } from 'src/app/models/administrative';
import { RoleService } from 'src/app/services/role.service';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-administrativo-editar',
  templateUrl: './administrativo-editar.component.html',
  styleUrls: ['./administrativo-editar.component.css']
})
export class AdministrativoEditarComponent implements OnInit {

  administrative: Administrative;
  administrativeId: number;
  roles = [];
  loader: boolean = false;

  tempImage: any;

  sourceUrl = environment.sourceUrl;

  nombresFormControl = new FormControl('', [
    Validators.required,
  ])

  apePaternoFormControl = new FormControl('', [
    Validators.required,
  ])

  apeMaternoFormControl = new FormControl('', [
    Validators.required,
  ]);

  celFormControl = new FormControl('', [
    Validators.required,
  ])

  telFormControl = new FormControl('', [
    Validators.required,
  ])

  direccionFormControl = new FormControl('', [
    Validators.required,
  ])

  dateBirthFormControl = new FormControl('', [
    Validators.required,
  ])

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;


  constructor(
    public activatedRoute: ActivatedRoute,
    private _administrativeService: AdministrativeService,
    public location: Location,
    private _roleService: RoleService,
    public dialog: MatDialog,
    private _router: Router,
    private _snackBar: MatSnackBar
    ) {
    this.administrative = new Administrative();
  }

  ngOnInit(): void {
    this.obtenerAdministrador();
    this.obtenerRoles();
  }

  obtenerAdministrador() {
    this.activatedRoute.params.subscribe( params => {

      let id = params['id'];
      this.administrativeId = id;

      this._administrativeService.getAdministrative(id).subscribe(
        response => {
          this.administrative.name = response.data.user.name;
          this.administrative.lastnamepat = response.data.user.lastnamepat;
          this.administrative.lastnamemat = response.data.user.lastnamemat;
          this.administrative.birth_date = response.data.user.birth_date;
          this.administrative.gender = response.data.user.gender;
          this.administrative.address = response.data.user.address;
          this.administrative.phone = response.data.user.phone;
          this.administrative.telephone = response.data.user.telephone;
          this.administrative.role_id = response.data.role.id;
          this.administrative.image = response.data.user.image;
        },
        error => {
          console.log(error)
        }
      )

    });
  }

  volver() {
    this._router.navigate(['/usuario/administrativo']);
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.tempImage = reader.result;
    };
  }

  cancelar() {
    this.administrative.image = null;
  }

  obtenerRoles() {
    this._roleService.getRoles().subscribe(
      response => {
        console.log('response', response);
        this.roles = response.data;
      },
      error => {
        console.log('error', error);
      }
    )
  }

  editarAdministrador(f: NgForm) {
    this.loader = true;

    console.log(this.administrative);

    this._administrativeService.updateAdministrative(this.administrativeId, this.administrative).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('Administrador actualizado')
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.loader = false;
        this.openError('Error al actualizar al administrador', 'Ok')
      }
    )

  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
