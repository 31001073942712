<h1 mat-dialog-title>Editar Correo administrativo</h1>
<form #f="ngForm" ngNativeValidate (ngSubmit)="editarCorreo(f)" (keydown.enter)="$event.preventDefault()">
    <div mat-dialog-content>
      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input matInput required value="Prueba">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Correo Electrónico</mat-label>
        <input type="email" matInput required value="prueba@email.com">
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()" type="button">Cancelar</button>
      <button mat-button type="submit">Guardar cambios</button>
    </div>
</form>