<section>

    <div class="vex_soluciones_tiles">
        <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
        <h2 class="vex_soluciones_subtitle">EDITAR ADMINISTRATIVO</h2>
    </div>
    <vex-section-title title="ADMINISTRACIÓN" subtitle="EDITAR ADMINISTRADOR"></vex-section-title>
    <div class="vex_soluciones_content">
        <form #f="ngForm" ngNativeValidate (ngSubmit)="editarAdministrador(f)" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col s12 m4 l4 p-l-0">
                    <mat-card>
                        <h3>Datos Empresa</h3>

                        <mat-form-field appearance="legacy">
                            <mat-label>Nombre</mat-label>
                            <input name="name" [formControl]="nombresFormControl" [errorStateMatcher]="matcher" matInput placeholder="Nombre" [(ngModel)]="administrative.name" required>
                            <mat-error *ngIf="nombresFormControl.hasError('required')">
                                Nombres <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Apellido Paterno</mat-label>
                            <input name="apePaterno" [formControl]="apeMaternoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Apellido Paterno" [(ngModel)]="administrative.lastnamepat" required>
                            <mat-error *ngIf="apeMaternoFormControl.hasError('required')">
                                Apellido Paterno <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Apellido Materno</mat-label>
                            <input name="apeMaterno" [formControl]="apePaternoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Apellido Materno" [(ngModel)]="administrative.lastnamemat" required>
                            <mat-error *ngIf="apePaternoFormControl.hasError('required')">
                                Apellido Materno <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Celular</mat-label>
                            <span matPrefix>+52 &nbsp;</span>
                            <input name="cel" [formControl]="celFormControl" [errorStateMatcher]="matcher" matInput placeholder="Celular" [(ngModel)]="administrative.phone" required>
                            <mat-error *ngIf="celFormControl.hasError('required')">
                                Celular <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Teléfono</mat-label>
                            <span matPrefix>+52 &nbsp;</span>
                            <input name="tel" [formControl]="telFormControl" [errorStateMatcher]="matcher" matInput placeholder="Celular" [(ngModel)]="administrative.telephone" required>
                            <mat-error *ngIf="telFormControl.hasError('required')">
                                Teléfono <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Dirección</mat-label>
                            <input name="tel" [formControl]="telFormControl" [errorStateMatcher]="matcher" matInput placeholder="Celular" [(ngModel)]="administrative.address" required>
                            <mat-error *ngIf="direccionFormControl.hasError('required')">
                                Dirección <strong>Requerida</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Fecha de nacimiento</mat-label>
                            <input name="dateBirth" [formControl]="dateBirthFormControl" [errorStateMatcher]="matcher" (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="administrative.birth_date" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="dateBirthFormControl.hasError('required')">
                                Fecha de nacimiento <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                          <mat-label>Género</mat-label>
                          <mat-select name="gender" [(ngModel)]="administrative.gender" required>
                            <mat-option value="Masculino">
                              Masculino
                            </mat-option>
                            <mat-option value="Femenino">
                              Femenino
                            </mat-option>
                            <mat-option value="Otro">
                              Otro
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                    </mat-card>

                </div>
                <div class="col s12 m5 l5">
                    <mat-card>
                        <h3>Foto (Opcional)</h3>

                        <div class="center">
                            <div class="img-content">
                                <img class="vex-soluciones-img-profile" [src]="(tempImage)? tempImage: sourceUrl + administrative.image" alt="" width="120">
                            </div>
                        </div>

                        <div class="center">
                            <button *ngIf="!tempImage" color="primary" type="button" (click)="photoFile.click()" mat-stroked-button>Subir foto</button>

                            <input hidden (change)="subirImagen($event.target.files[0])" #photoFile type="file" accept="image/x-png,image/gif,image/jpeg,image/jpg">

                        </div>

                        <div class="center">
                            <button mat-raised-button *ngIf="tempImage" type="button" (click)="cancelar()">Cancelar</button>

                        </div>

                    </mat-card>
                    <br>
                    <mat-card>
                        <h3>Rol</h3>

                        <mat-radio-group color="primary" [(ngModel)]="administrative.role_id" name="role">
                            <mat-radio-button [value]="role.identifier" *ngFor="let role of roles">{{role.name}}</mat-radio-button>
                        </mat-radio-group>
                    </mat-card>

                </div>

            </div>
            <br>
            <div class="row">
                <div class="col s12 p-l-0" *ngIf="!loader">
                    <button type="button" mat-raised-button (click)="volver()">{{buttonName.BACK}}</button>
                    &nbsp;
                    <button type="submit" mat-raised-button color="primary">{{buttonName.UPDATE}}</button>
                </div>
                <div class="col s12" *ngIf="loader">
                    <mat-spinner diameter="40"></mat-spinner>
                </div>
            </div>
        </form>

    </div>
</section>
