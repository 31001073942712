export class Attention {
  type_patient: any;
  date: string;
  hour: string;
  waypoints: Array<any>;
  aditional_service_id: any;
  detail: string;
  emails: any;
  patient_id: any;
  paramedic_id: string;
  professionals_id: any;
  vehicle_id: string;
  service_id: string;
  company_id: string;
  status: string;
  cost: number;

  constructor(
  ){
    this.waypoints = [];
    this.cost = 0;
  }
}
