import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': "Bearer " + localStorage.getItem('token')
    })
};

@Injectable({
    providedIn: 'root'
})
export class InsuranceService {

    constructor(
        private http: HttpClient,
    ) { }
    
    getInsurance(id): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}insurances/${id}`);

    }

    getInsurances(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}insurances`, httpOptions);

    }

    updateInsurance(id, data): Observable<any> {

        return this.http.patch<any>(`${environment.apiUrl}insurances/${id}`, data, httpOptions);

    }

    saveInsurance(data): Observable<any> {

        return this.http.post<any>(`${environment.apiUrl}insurances`, data, httpOptions);

    }

    deleteInsurance(id, value): Observable<any> {

        return this.http.delete<any>(`${environment.apiUrl}insurances/${id}?hard=${value}`, httpOptions);

    }

}
