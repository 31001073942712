<section>

    <vex-section-title title="ADMINISTRACIÓN" subtitle="EDITAR CUPÓN"></vex-section-title>

    <div class="vex_soluciones_content">
        <form #f="ngForm" ngNativeValidate (ngSubmit)="editarCupon(f)" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col s12 m6 l4 vex_soluciones_colum">
                    <mat-card>
                        <h3>Datos</h3>

                        <mat-form-field class="example-full-width">
                          <mat-label>Código</mat-label>
                          <input matInput placeholder="Código" required [(ngModel)]="coupon.code" name="code">
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                          <mat-label>Cantidad</mat-label>
                          <input matInput placeholder="Cantidad" required [(ngModel)]="coupon.quantity" name="quantity">
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Descuento</mat-label>
                            <input name="descuento" [formControl]="descuentoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Descuento" [(ngModel)]="coupon.discount" required>
                            <mat-icon matSuffix>%</mat-icon>
                            <mat-error *ngIf="descuentoFormControl.hasError('required')">
                                Descuento <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Fecha de Inicio</mat-label>
                            <input name="fecha_inicio" [formControl]="fechaInicioFormControl" [errorStateMatcher]="matcher" (click)="fechaInicio.open()" matInput [matDatepicker]="fechaInicio" [(ngModel)]="coupon.date_in" required>
                            <mat-datepicker-toggle matSuffix [for]="fechaInicio"></mat-datepicker-toggle>
                            <mat-datepicker #fechaInicio></mat-datepicker>
                            <mat-error *ngIf="fechaInicioFormControl.hasError('required')">
                                Fecha de Inicio <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Fecha de Culminación</mat-label>
                            <input name="fecha_culminacion" [formControl]="fechaCulminacionFormControl" [errorStateMatcher]="matcher" (click)="fechaCulminacion.open()" matInput [matDatepicker]="fechaCulminacion" [(ngModel)]="coupon.date_out" required>
                            <mat-datepicker-toggle matSuffix [for]="fechaCulminacion"></mat-datepicker-toggle>
                            <mat-datepicker #fechaCulminacion></mat-datepicker>
                            <mat-error *ngIf="fechaCulminacionFormControl.hasError('required')">
                                Fecha de Culminación <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <div class="row">
                            <div class="col s12" *ngIf="!loader">
                                <button mat-raised-button (click)="volver()" type="button">{{buttonName.BACK}}</button>
                                &nbsp;
                                <button mat-raised-button color="primary" type="submit">{{buttonName.UPDATE}}</button>
                            </div>
                            <div class="col s12" *ngIf="loader">
                                <mat-spinner diameter="40"></mat-spinner>
                            </div>
                        </div>
                    </mat-card>
                </div>

            </div>

        </form>

    </div>
</section>
