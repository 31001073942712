import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Maintenance } from 'src/app/models/maintenance';
import { MaintenanceService, VehicleService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-mantenimiento-editar',
  templateUrl: './mantenimiento-editar.component.html',
  styleUrls: ['./mantenimiento-editar.component.css']
})
export class MantenimientoEditarComponent implements OnInit {

  mantenimiento: Maintenance;
  mantenimientoId: number;
  vehiculos: any = [];
  loader: boolean = false;

  mantenimientoFormControl = new FormControl('', [
    Validators.required,
  ]);

  fechaMantenimientoFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public location: Location,
    public dialog: MatDialog,
    private _maintenanceService: MaintenanceService,
    private _vehicleService: VehicleService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _snackBar: MatSnackBar
    ) {
    this.mantenimiento = new Maintenance();
  }

  ngOnInit(): void {
    this.obtenerMantenimiento();
    this.obtenerVehiculos();
  }

  volver() {
    this._router.navigate(['/unidades/mantenimiento']);
  }

  obtenerVehiculos() {
    this._vehicleService.getVehicles().subscribe(
      response => {
        console.log('response', response)
        this.vehiculos = response.data;
      },
      error => {
        this.openError('ERROR NO SE ENCONTRARON VEHICULOS', 'Ok');
      }
    )
  }

  obtenerMantenimiento() {
    this._activatedRoute.params.subscribe(
      params => {
        this.mantenimientoId = params['id'];

        this._maintenanceService.getMaintenance(this.mantenimientoId).subscribe(
          response => {
            this.mantenimiento.description = response.data.description;
            this.mantenimiento.date = response.data.date;
            this.mantenimiento.vehicle_id = response.data.vehicle.id;

          },
          error => {
            this.openError('ERROR AL ENCONTRAR MANTENIMIENTOS', 'Ok');
          }
        )
      }
    )
  }

  editarMantenimiento(f: NgForm) {
    this.loader = true;

    this._maintenanceService.updateMaintenance(this.mantenimientoId, this.mantenimiento).subscribe(
      response => {
        this.mostrarExitoDialog('MANTENIMIENTO ACTUALIZADO');
        this.loader = false;
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.openError('ERROR AL EDITAR EL MANTENIMIENTO DE UN VEHICULO', 'Ok');
        this.loader = false;
      }
    )

  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
