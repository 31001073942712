import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': "Bearer " + localStorage.getItem('token')
    })
};

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    constructor(
        private http: HttpClient,
    ) { }
    

    getConfiguration(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}configurations`, httpOptions);

    }

    updateConfiguration(data): Observable<any> {

        return this.http.patch<any>(`${environment.apiUrl}configurations/1`, data, httpOptions);

    }

    getParameters(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}parameters/cost`, httpOptions);

    }

    saveParameter(data): Observable<any> {

        return this.http.post<any>(`${environment.apiUrl}parameters/cost`, data, httpOptions);

    }

    deleteParameter(id): Observable<any> {

        return this.http.delete<any>(`${environment.apiUrl}parameters/cost/${id}`, httpOptions);

    }

    getEmails(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}emails`, httpOptions);

    }

    saveEmail(data): Observable<any> {

        return this.http.post<any>(`${environment.apiUrl}emails`, data, httpOptions);

    }

    deleteEmail(id): Observable<any> {

        return this.http.delete<any>(`${environment.apiUrl}emails/${id}`, httpOptions);

    }

}
