export class Paramedic {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  type: string;
  lastnamepat: string;
  lastnamemat: string;
  birth_date: string;
  gender: string;
  address: string;
  phone: string;
  telephone: string;
  image: string;
  latitude: string;
  longitude: string;
  photo: any;
  ine: any;
  institution: any;
  license: any;
  certificate: any;
  date_admission: any;
  status: any;
  constructor(
  ){
    this.latitude = "19.409443";
    this.longitude = "-99.158322";
  }
}
