import { Component, OnInit } from '@angular/core';
import { ParamedicService, UserService, VehicleService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Password } from 'src/app/admin/configuracion/configuracion.component';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { UploadImage } from 'src/app/models/upload_image';
import { Location } from '@angular/common';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { Vehicle } from 'src/app/models/vehicle';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-unidades-ver',
  templateUrl: './unidades-ver.component.html',
  styleUrls: ['./unidades-ver.component.css']
})
export class UnidadesVerComponent implements OnInit {

  hide = true;
  hideC = true;
  hideA = true;

  loaderUpdPwd = false;

  vehicle: Vehicle;
  vehicleId: any;

  userId: any;

  password: Password;

  sourceUrl = environment.sourceUrl;

  uploadImage: UploadImage;
  tempImage: any;

  img1Temp: any;
  img2Temp: any;
  img3Temp: any;
  img4Temp: any;

  paramedicos: any = [];

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    private _vehicleService: VehicleService,
    private _userService: UserService,
    private _paramedicService: ParamedicService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public location: Location
  ) {
    this.vehicle = new Vehicle();
    this.password = new Password('', '', '');
    this.uploadImage = new UploadImage();

    this.img1Temp = "/assets/images/admin/upload-image.png";
    this.img2Temp = "/assets/images/admin/upload-image.png";
    this.img3Temp = "/assets/images/admin/upload-image.png";
    this.img4Temp = "/assets/images/admin/upload-image.png";
  }

  ngOnInit(): void {
    this.obtenerUnidad();
  }

  obtenerParamedicos() {
    this._paramedicService.getParamedics().subscribe(
      response => {
        console.log('PARAMEDICOS', response)
        this.paramedicos = response.data;
      },
      error => {
        this.openError('Error al obtener paramedicos', 'OK');
      }
    )
  }

  onFileSelectedImg1(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.img1Temp = reader.result;
      this.vehicle.photo1 = reader.result;
    };

  }

  onFileSelectedImg2(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.img2Temp = reader.result;
      this.vehicle.photo2 = reader.result;
    };

  }

  onFileSelectedImg3(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.img3Temp = reader.result;
      this.vehicle.photo3 = reader.result;
    };

  }

  onFileSelectedImg4(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.img4Temp = reader.result;
      this.vehicle.photo4 = reader.result;
    };

  }

  verImg1() {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: this.vehicle.photo1
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })


  }

  verImg2() {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: this.vehicle.photo2
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  verImg3() {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: this.vehicle.photo3
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  verImg4() {

      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.vehicle.photo4
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })

  }

  obtenerUnidad() {
    this._activatedRoute.params.subscribe(
      params => {
        this.vehicleId = params['id'];

        this._vehicleService.getVehicle(this.vehicleId).subscribe(
          response => {
            console.log('UNIDAD', response)
            this.vehicle.identifier = `PIN00${response.data.id}`;
            this.vehicle.brand = response.data.brand;
            this.vehicle.model = response.data.model;
            this.vehicle.plete = response.data.plete;
            this.vehicle.year = response.data.year;
            this.vehicle.color = response.data.color;
            this.vehicle.serie = response.data.serie;
            this.vehicle.photo1 = environment.sourceUrl + response.data.photo1;
            this.vehicle.photo2 = (response.data.photo2)?`${environment.sourceUrl}${response.data.photo2}`: `/assets/images/admin/upload-image.png`;;
            this.vehicle.photo3 = (response.data.photo3)?`${environment.sourceUrl}${response.data.photo3}`: `/assets/images/admin/upload-image.png`;
            this.vehicle.photo4 = (response.data.photo4)?`${environment.sourceUrl}${response.data.photo4}`: `/assets/images/admin/upload-image.png`;
            this.vehicle.insurance_carrier = response.data.insurance.insurance_carrier;
            this.vehicle.insurance_agent = response.data.insurance.insurance_agent;
            this.vehicle.telephone = response.data.insurance.telephone;
            this.vehicle.policy = response.data.insurance.nro_policy;
            this.vehicle.expiration_date = response.data.insurance.date_expired;
            this.vehicle.gasoline_consumption_km = response.data.gasoline_consumption_km;
            this.vehicle.recieve_maintenance_km = response.data.recieve_maintenance_km;
            this.vehicle.type_equipment = response.data.type_equipment;
            this.vehicle.type_vehicle = response.data.type_vehicle;
            this.vehicle.paramedic_id = response.data.paramedic_id;
            this.vehicle.maintenances = response.data.maintenances;
            this.vehicle.state = response.data.state;
            this.img1Temp = response.data.photo1;
            this.obtenerParamedicos();
          },
          error => {
            this.openError('Enfermero no encontrado', 'Ok');
          }
        )
      }
    )
  }

  actualizarContrasenia(f: NgForm) {
    this.loaderUpdPwd = true;
    this._userService.updatePassword(this.userId, this.password).subscribe(
      response => {
        this.loaderUpdPwd = false;
        if(response.code == 404) {
          this.openError('Error al actualizar contraseña', 'Ok');
        }else {
          f.resetForm();
          this.mostrarExitoDialog('Contraseña actualizada');
        }
      },
      error => {
        this.loaderUpdPwd = false;
        this.openError('Error al actualizar contraseña', 'Ok');
      }
    )
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.uploadImage.image = reader.result;
      this.tempImage = reader.result;
    };
  }

  guardarImagen() {

    this._userService.uploadImage(this.userId, this.uploadImage).subscribe(
      response => {
        console.log('response', response);
        console.log('response', response.data.image)
        this.tempImage = response.data.image;
        this.uploadImage.image = null;
        // this.nurse.image = response.data.image;

        this.mostrarExitoDialog('Imagen actualizada')

      },
      error => {
        console.log('error', error);
      }
    )
  }

  verDocumento(documento) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${documento}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  guardarCambios(row) {

  }

  cancelar() {
    // this.tempImage = this.nurse.image;
    this.uploadImage.image = null;
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  volver() {
    this._router.navigate(['/unidades']);
  }

}
