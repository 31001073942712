import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { CompanyService, EmployeeService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-colaborador',
  templateUrl: './colaborador.component.html',
  styleUrls: ['./colaborador.component.css']
})
export class ColaboradorComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'id', 'sku', 'name_company', 'name', 'gender', 'phone', 'address', 'telephone', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  empresas: any = [];

  loader: boolean = true;

  url = environment.sourceUrl;

  empresas$: Subscription;
  empleados$: Subscription;
  deleteEmpleado$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _employeeService: EmployeeService,
    private _companyService: CompanyService,
    private _snackBar: MatSnackBar
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerEmpleados();
    this.obtenerEmpresas();
  }

  obtenerEmpresas() {
    this.empresas$ = this._companyService.getCompanies().subscribe(
      response => {
        this.empresas = response.data;
      },
      error => {
        this.openError('No se encontraron empresas', 'Ok');
      }
    )
  }

  buscarEmpresa(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerEmpleados() {
    this.empleados$ = this._employeeService.getEmployees().subscribe(
      response => {
        console.log('EMPLEADOS', response)

        this.dataSource = fillTable(response.data.reverse(), this.paginator, this.sort);

      },
      error => {
        console.log(error)
      }
    )
  }

  obtenerEmpleadosPorEmpresa(id) {
    if(id == 0) {
      this.obtenerEmpleados();
    } else {

      this.empleados$ = this._employeeService.getEmployeesByCompany(id).subscribe(
        response => {
          console.log('response', response)

          this.dataSource = fillTable(response.data, this.paginator, this.sort);
        },
        error => {
          this.openError('Error al obtener empleados por empresa', 'Ok');
        }
      )

    }
  }

  cambiarActivacion(empleado) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Deseas cambiar la activación de este empleado?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this.deleteEmpleado$ = this._employeeService.deleteEmployee(empleado.identifier, false).subscribe(
          response => {
            this.obtenerEmpleados();
          },
          error => {
            this.obtenerEmpleados();
            this.openError('Error en la activación del empleado', 'Ok');
          }
        );

      } else {
        this.obtenerEmpleados();
      }
    })
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  eliminarEmpleado() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este paciente de empresa?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {



      }
    })
  }

  ngOnDestroy(): void {
    if(this.empleados$) this.empleados$.unsubscribe();

    if(this.empresas$) this.empresas$.unsubscribe();

    if(this.deleteEmpleado$) this.deleteEmpleado$.unsubscribe();
  }

}
