<section>

  <vex-section-title title="ADMINISTRACIÓN" subtitle="NUEVA UNIDAD"></vex-section-title>

  <div class="vex_soluciones_content">
    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarUnidad(f)" (keydown.enter)="$event.preventDefault()">
      <mat-card>
        <div class="row">

          <h3 style="padding-left: 10px;">Datos Generales</h3>

          <div class="col s12 m4 l4">
            <mat-form-field appearance="legacy">
              <mat-label>Identificador</mat-label>
              <input name="identifier" [formControl]="identificadorFormControl" matInput placeholder="Identificador"
                [(ngModel)]="unidad.identifier" required>
            </mat-form-field>
          </div>

          <div class="col s12 m4 l4">
            <mat-form-field appearance="legacy">
              <mat-label>Placa</mat-label>
              <input [formControl]="placaFormControl" [errorStateMatcher]="matcher" matInput placeholder="Placa"
                name="plete" [(ngModel)]="unidad.plete" required>
              <mat-error *ngIf="placaFormControl.hasError('required')">
                Placa <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col s12 m4 l4">
            <mat-form-field appearance="legacy">
              <mat-label>Modelo</mat-label>
              <input [formControl]="modeloFormControl" [errorStateMatcher]="matcher" matInput placeholder="Modelo"
                name="model" [(ngModel)]="unidad.model" required>
              <mat-error *ngIf="modeloFormControl.hasError('required')">
                Modelo <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col s12 m4 l4">
            <mat-form-field appearance="legacy">
              <mat-label># Serie</mat-label>
              <input [formControl]="serieFormControl" [errorStateMatcher]="matcher" matInput placeholder="# Serie"
                name="serie" [(ngModel)]="unidad.serie" required>
              <mat-error *ngIf="serieFormControl.hasError('required')">
                Serie <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col s12 m4 l4">

            <mat-form-field appearance="legacy">
              <mat-label>Año</mat-label>
              <input [formControl]="anioFormControl" [errorStateMatcher]="matcher" matInput placeholder="Año"
                name="year" [(ngModel)]="unidad.year" required>
              <mat-error *ngIf="anioFormControl.hasError('required')">
                Año <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>

          </div>

          <div class="col s12 m4 l4">
            <mat-form-field appearance="legacy">
              <mat-label>Marca</mat-label>
              <input [formControl]="marcaFormControl" [errorStateMatcher]="matcher" matInput placeholder="Marca"
                name="brand" [(ngModel)]="unidad.brand" required>
              <mat-error *ngIf="marcaFormControl.hasError('required')">
                Marca <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>

        </div>
      </mat-card>
      <br>
      <mat-card>
        <div class="row">

          <h3 style="padding-left: 10px;">Fotos de la Unidad</h3>

          <div class="col s12 m3 l3 vex_soluciones_colum">
            <div class="row">
              <div class="col s3 img-width">
                <img [src]="img1Temp" class="vex_soluciones_file" (click)="verImg1()">
              </div>
              <div class="col s9 button-width">
                <b>(Obligatorio)</b>
                <button class="vex_button_file_obligatorio" type="button" mat-raised-button
                  (click)="Img1File.click()">Subir Primera Foto</button>
                <input hidden (change)="onFileSelectedImg1($event.target.files[0])" #Img1File type="file" name="file1">
              </div>
            </div>

          </div>
          <div class="col s12 m3 l3">

            <div class="row">
              <div class="col s3 img-width">
                <img [src]="img2Temp" class="vex_soluciones_file" (click)="verImg2()">
              </div>
              <div class="col s9 button-width">
                <button class="vex_soluciones_button_file" type="button" mat-raised-button
                  (click)="Img2File.click()">Subir Segunda Foto</button>
                <input hidden (change)="onFileSelectedImg2($event.target.files[0])" #Img2File type="file">
              </div>
            </div>

          </div>
          <div class="col s12 m3 l3">

            <div class="row">
              <div class="col s3 img-width">
                <img [src]="img3Temp" class="vex_soluciones_file" (click)="verImg3()">
              </div>
              <div class="col s9 button-width">
                <button class="vex_soluciones_button_file" type="button" mat-raised-button
                  (click)="Img3File.click()">Subir Tercera Foto</button>
                <input hidden (change)="onFileSelectedImg3($event.target.files[0])" #Img3File type="file">
              </div>
            </div>

          </div>
          <div class="col s12 m3 l3">

            <div class="row">
              <div class="col s3 img-width">
                <img [src]="img4Temp" class="vex_soluciones_file" (click)="verImg4()">
              </div>
              <div class="col s9 button-width">
                <button class="vex_soluciones_button_file" type="button" mat-raised-button
                  (click)="Img4File.click()">Subir Cuarta Foto</button>
                <input hidden (change)="onFileSelectedImg4($event.target.files[0])" #Img4File type="file">
              </div>
            </div>

          </div>
        </div>
      </mat-card>
      <br>
      <mat-card>
        <div class="row">

          <h3 style="padding-left: 10px;">Seguro </h3>

          <div class="col s12 m4 l4">
            <mat-form-field appearance="legacy">
              <mat-label>Aseguradora</mat-label>
              <input [formControl]="aseguradoraFormControl" [errorStateMatcher]="matcher" matInput
                placeholder="Aseguradora" name="insurance_carrier" [(ngModel)]="unidad.insurance_carrier" required>
              <mat-error *ngIf="aseguradoraFormControl.hasError('required')">
                Aseguradora <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col s12 m4 l4">
            <mat-form-field appearance="legacy">
              <mat-label>Nombre Agente Aseguradora</mat-label>
              <input [formControl]="nombreAgenteFormControl" [errorStateMatcher]="matcher" matInput
                placeholder="Nombre Agente Aseguradora" name="insurance_agent" [(ngModel)]="unidad.insurance_agent"
                required>
              <mat-error *ngIf="nombreAgenteFormControl.hasError('required')">
                Nombre <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col s12 m4 l4">
            <mat-form-field appearance="legacy">
              <mat-label>Teléfono Agente Aseguradora</mat-label>
              <input [formControl]="telefonoAgenteFormControl" [errorStateMatcher]="matcher" matInput
                placeholder="Teléfono agenete aseguradora" name="policy" [(ngModel)]="unidad.telephone" required>
              <mat-error *ngIf="telefonoAgenteFormControl.hasError('required')">
                Teléfono <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col s12 m4 l4">
            <mat-form-field appearance="legacy">
              <mat-label># Poliza</mat-label>
              <input [formControl]="polizaFormControl" [errorStateMatcher]="matcher" matInput placeholder="# Poliza"
                name="policy" [(ngModel)]="unidad.policy" required>
              <mat-error *ngIf="polizaFormControl.hasError('required')">
                # Poliza <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>


          <div class="col s12 m4 l4">
            <mat-form-field>
              <mat-label>Fecha de vencimiento</mat-label>
              <input [formControl]="fechaVencimientoFormControl" [errorStateMatcher]="matcher" name="fecha_vencimiento"
                (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="unidad.expiration_date" required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="fechaVencimientoFormControl.hasError('required')">
                Fecha de vencimiento <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>

        </div>
      </mat-card>
      <br>
      <mat-card>
        <div class="row">

          <h3 style="padding-left: 10px;">Atributos del Vehiculo</h3>

          <div class="col s12 m4 l4 vex_soluciones_colum">
            <mat-form-field appearance="legacy">
              <mat-label>Consumo de gasolina x KM</mat-label>
              <input [formControl]="consumoGasolinaFormControl" [errorStateMatcher]="matcher" matInput
                placeholder="Consumo de gasolina x KM" name="gasoline_consumption_km"
                [(ngModel)]="unidad.gasoline_consumption_km" required>
              <mat-error *ngIf="consumoGasolinaFormControl.hasError('required')">
                Consumo de gasolina <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col s12 m4 l4">
            <mat-form-field>
              <mat-label>Tipo de vehículo</mat-label>
              <mat-select name="type_vehicle" [(ngModel)]="unidad.type_vehicle" required>
                <mat-option value="suv">
                  SUV
                </mat-option>
                <mat-option value="sedan">
                  Sedan
                </mat-option>
                <mat-option value="minivan">
                  Minivan
                </mat-option>
                <mat-option value="van">
                  Van
                </mat-option>
                <mat-option value="sprinter">
                  Sprinter
                </mat-option>
                <mat-option value="minibus">
                  Minibus
                </mat-option>
                <mat-option value="bus">
                  Bus
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col s12 m4 l4">

            <mat-form-field appearance="legacy">
              <mat-label>Recibe Mantenimiento Cada (km)</mat-label>
              <input [formControl]="recibeMantenimientoFormControl" [errorStateMatcher]="matcher" matInput
                placeholder="Recibe Mantenimiento Cada (km)" name="recieve_maintenance_km"
                [(ngModel)]="unidad.recieve_maintenance_km" required>
              <mat-error *ngIf="recibeMantenimientoFormControl.hasError('required')">
                Dias entre mantenimientos <strong>Requerido</strong>
              </mat-error>
            </mat-form-field>

          </div>
          <div class="col s12 m4 l4">
            <mat-label class="example-radio-group">Equipamiento</mat-label>

            <mat-radio-group name="type_equipment" [(ngModel)]="unidad.type_equipment" aria-label="Select an option"
              color="primary" required>
              <mat-radio-button value="A">A</mat-radio-button>
              <mat-radio-button value="B">B</mat-radio-button>
              <mat-radio-button value="C">C</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col s12 m4 l4">

            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select name="state" [(ngModel)]="unidad.state" required>
                <mat-option value="online">
                  Online
                </mat-option>
                <mat-option value="offline">
                  Offline
                </mat-option>
                <mat-option value="stand_by">
                  Stand By
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <br>
      <mat-card>
        <div class="row">

          <h3 style="padding-left: 10px;">Asignado a </h3>

          <div class="col s12 m4 l4 vex_soluciones_colum">

            <mat-form-field>
              <mat-label>Paramédico</mat-label>
              <mat-select name="paramedic_id" [(ngModel)]="unidad.paramedic_id">
                <mat-option [value]="paramedico.identifier" *ngFor="let paramedico of paramedicos">
                  {{paramedico.professional.user.name}} {{paramedico.professional.user.lastnamepat}}
                  {{paramedico.professional.user.lastnamemat}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>

        </div>
      </mat-card>
      <br>
      <mat-card>
        <div class="row">
          <h3 style="padding-left: 10px;">Usuario</h3>

          <div class="col s12 m4 l4">
            <mat-form-field appearance="legacy">
              <mat-label>Correo electrónico</mat-label>
              <input type="text" matInput placeholder="Correo electrónico" name="email" [(ngModel)]="unidad.email" required>

            </mat-form-field>
          </div>
          <div class="col s12 m4 l4">
            <mat-form-field appearance="legacy">
              <mat-label>Contraseña</mat-label>
              <input type="password" matInput placeholder="Contraseña" name="password" [(ngModel)]="unidad.password"
                required>

            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <br>
      <!-- <mat-card>

                <div class="row">
                    <h3 style="padding-left: 10px;">Mantenimiento</h3>
                    <div class="col s12">

                        <div class="row">
                            <div *ngFor="let entrada of entradas; let i=index">
                                <div class="col s12 m4 l4" style="padding-left: 0px;">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Descripción del mantenimiento {{i}}:</mat-label>
                                        <textarea [name]="'mantenimiento' + i" [id]="'mantenimiento' + i" matInput [placeholder]=" + i" [(ngModel)]="entrada.mantenimiento"></textarea>
                                    </mat-form-field>
                                </div>
                                <br>
                                <div class="col s12 m4 l4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Fecha de mantenimiento</mat-label>
                                        <input [name]="'fecha' + i" [id]="'fecha' + i" matInput [placeholder]="'Fecha de mantenimiento' + i" type="date" [(ngModel)]="entrada.fecha">
                                    </mat-form-field>

                                </div>
                                <div class="col s12 m4 l4">
                                    <div *ngIf="i>0">
                                        <button mat-mini-fab color="warn" (click)="eliminarFilaTest(i)">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col s12 m4 l4">
                        <button mat-button (click)="pruebatest()">Prueba test</button>
                        <button mat-button cdkFocusInitial color="primary" (click)="adicionartest()">Adicionar</button>
                    </div>
                </div>
            </mat-card>
             -->
      <br>
      <div class="row">
        <div class="col s12" *ngIf="!loader">
          <button mat-raised-button (click)="volver()" type="button">{{buttonName.BACK}}</button>
          &nbsp;
          <button type="submit" mat-raised-button color="primary">{{buttonName.STORE}}</button>
        </div>
        <div class="col s12" *ngIf="loader">
          <mat-spinner diameter="40"></mat-spinner>
        </div>
      </div>

    </form>

  </div>
</section>
