import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'identifier', 'title', 'body', 'date', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  notificaciones$: Subscription;
  deleteNotificacion$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _notificationService: NotificationService
  ) {

  }

  ngOnInit(): void {
    this.obtenerNotificaciones();
  }

  buscarNotificacion(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerNotificaciones() {
    this.notificaciones$ = this._notificationService.getNotifications().subscribe(
      response => {

        this.dataSource = fillTable(response.data, this.paginator, this.sort);

      },
      errro => {
        this.mostrarErrorDialog('Error al obtener notificaciones')
      }
    )
  }

  eliminarNotificacion(id) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar esta notificación?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {
        this.deleteNotificacion$ = this._notificationService.deleteNotification(id).subscribe(
          response => {
            this.obtenerNotificaciones();
          },
          error => {
            this.mostrarErrorDialog('Error al eliminar notificacion')
          }
        )
      }
    })
  }

  verNotificacion() {

  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  ngOnDestroy(): void {
    if(this.notificaciones$) this.notificaciones$.unsubscribe();

    if(this.deleteNotificacion$) this.deleteNotificacion$.unsubscribe();
  }
}
