import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AttentionService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.css']
})
export class HistorialComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['identifier', 'date', 'patient_name', 'company', 'professional_name', 'vehicle_identifier', 'service_name', 'status', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  historial$: Subscription;

  constructor(
    private _attentionService: AttentionService,
    public dialog: MatDialog
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerAtenciones();
  }

  buscarHistorial(event: Event) {
    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerAtenciones() {
    let date = new Date();
    this.historial$ = this._attentionService.getAttentions().subscribe(
      response => {
        console.log('atenciones',response)
        this.dataSource = fillTable(response.data, this.paginator, this.sort);

      },
      error => {
        this.mostrarErrorDialog('No se encontraron atenciones');
      }
    )
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  ngOnDestroy(): void {
    if(this.historial$) this.historial$.unsubscribe();
  }
}
