import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { Paramedic } from 'src/app/models/paramedic';
import { ParamedicService } from 'src/app/services';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { Location } from '@angular/common';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-paramedico-nuevo',
  templateUrl: './paramedico-nuevo.component.html',
  styleUrls: ['./paramedico-nuevo.component.css']
})
export class ParamedicoNuevoComponent implements OnInit {

  paramedico: Paramedic;
  photoTemp: any;
  ineTemp: any;
  licenseTemp: any;
  certificateTemp: any;
  hide = true;
  hideC = true;
  loader: boolean = false;

  nombresFormControl = new FormControl('', [
    Validators.required,
  ])

  apePaternoFormControl = new FormControl('', [
    Validators.required,
  ])

  apeMaternoFormControl = new FormControl('', [
    Validators.required,
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6)
  ])

  passConfFormControl = new FormControl('', [
    Validators.required,
    this.passwordMatcher.bind(this)
  ])

  celFormControl = new FormControl('', [
    Validators.required,
  ])

  telFormControl = new FormControl('', [
    Validators.required,
  ])

  direccionFormControl = new FormControl('', [
    Validators.required,
  ])

  dateBirthFormControl = new FormControl('', [
    Validators.required,
  ])

  matcher = new MyErrorStateMatcher();

  public passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (
        (control.value !== this.passFormControl.value)
    ) {
        return { passwordNotMatch: true };
    }
    return null;
  }

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _paramedicService: ParamedicService,
    public location: Location,
    private _snackBar: MatSnackBar
    ) {
    this.photoTemp = "/assets/images/admin/upload-image.png";
    this.ineTemp = "/assets/images/admin/upload-image.png";
    this.licenseTemp = "/assets/images/admin/upload-image.png";
    this.certificateTemp = "/assets/images/admin/upload-image.png";
    this.paramedico = new Paramedic();
  }

  ngOnInit(): void {
  }

  onFileSelectedPhoto(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.photoTemp = reader.result;
      this.paramedico.photo = reader.result;
    };

  }

  verFoto() {

    if(this.photoTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.photoTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedIne(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.ineTemp = reader.result;
      this.paramedico.ine = reader.result;
    };

  }

  verIne() {

    if(this.ineTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.ineTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedLicense(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.licenseTemp = reader.result;
      this.paramedico.license = reader.result;
    };

  }

  verLicencia() {

    if(this.licenseTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.licenseTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedCertificate(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.certificateTemp = reader.result;
      this.paramedico.certificate = reader.result;
    };

  }

  verCertificado() {

    if(this.certificateTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.certificateTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  registrarParamedico(f: NgForm) {

    if(this.photoTemp == '/assets/images/admin/upload-image.png') {
      this.openError('Subir una foto', 'Ok');
      return;
    } else if(this.ineTemp == '/assets/images/admin/upload-image.png') {
      this.openError('Subir Documento INE', 'Ok');
      return;
    } else if(this.licenseTemp == '/assets/images/admin/upload-image.png') {
      this.openError('Subir Documento de Licencia', 'Ok');
      return;
    } else if(this.certificateTemp == '/assets/images/admin/upload-image.png') {
      this.openError('Subir Documento de Certificado', 'Ok');
      return;
    }

    this.loader = true;

    this.paramedico.type = 'profesional';

    this._paramedicService.saveParamedic(this.paramedico).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('PROFESIONAL PARAMEDICO REGISTRADO')
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.loader = false;
        if(error.error.code == 422) {
          this.openError('Correo existente, Ingrese otro.', 'Ok');
          return;
        }
        this.openError('ERROR AL REGISTRAR PARAMÉDICO', 'Ok');
      }
    );

  }

  volver() {
    this._router.navigate(['/usuario/profesional/paramedicos']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
