import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { Location } from '@angular/common';
import { Attention } from 'src/app/models/Attention';
import { ServiceService, VehicleService, ProfessionalService, CategoryServService, CompanyService, EmployeeService, ParamedicService } from 'src/app/services';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { MatDialog } from '@angular/material/dialog';
import { AttentionService } from 'src/app/services/attentions.service';
import { PrivateService } from 'src/app/services/private.service';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { IButtonName, BUTTON_NAME } from 'src/app/data/constants/button-names.constant';

export interface Direction {
  origin: string;
  destination: string;
  renderOptions: any;
}

declare var google;

@Component({
  selector: 'app-historial-nuevo',
  templateUrl: './historial-nuevo.component.html',
  styleUrls: ['./historial-nuevo.component.css']
})
export class HistorialNuevoComponent implements OnInit {

  title: string = 'AGM project';
  latitude: number;
  longitude: number;
  address: string;
  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
      });
    }
  }

  markerDragEnd($event: MouseEvent, index) {
    console.log($event);
    this.latitude = $event["coords"].lat;
    this.longitude = $event["coords"].lng;

    const direccion = this.atencion.waypoints[index];
    direccion.location.lat = $event["coords"].lat;
    direccion.location.lng = $event["coords"].lng;
    console.log('DIRECCION', direccion)

    this.getAddress(this.latitude, this.longitude, index);
  }

  getAddress(latitude, longitude, index) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.atencion.waypoints[index]["name"] = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  distance: number = 0;
  time: number = 0;

  zoom: number = 15;

  directions = [];

  direcciones: any[] = [];

  atencion: Attention;
  empresas: any = [];
  pacientes: any = [];
  servicios: any = [];
  unidades: any = [];
  paramedics: any = [];
  profesionales: any = [];
  categorias: any = [];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  correos: any[] = [];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.correos.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(correo: any): void {
    const index = this.correos.indexOf(correo);

    if (index >= 0) {
      this.correos.splice(index, 1);
    }
  }

  loader: boolean = false;

  isBilling: boolean = false;

  billing = { rfc: "", razon: "", cfdi: "", metodo: "" };

  fechaFormControl = new FormControl('', [
    Validators.required,
  ]);

  horaFormControl = new FormControl('', [
    Validators.required,
  ]);

  lugarPartidaFormControl = new FormControl('', [
    Validators.required,
  ]);

  detalleServicioFormControl = new FormControl('', [
    Validators.required,
  ]);

  correoNotificarFormControl = new FormControl('', [
    Validators.required,
  ]);

  rfcFormControl = new FormControl('', [
    Validators.required,
  ]);

  razonSocialFormControl = new FormControl('', [
    Validators.required,
  ]);

  cfdiFormControl = new FormControl('', [
    Validators.required,
  ]);

  metodoPagoFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  minDate: Date = new Date();

  panelOpenState = false;

  buttonName: IButtonName = BUTTON_NAME;

  rutaActivada: boolean = false;

  constructor(
    public location: Location,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _privateService: PrivateService,
    private _employeeService: EmployeeService,
    private _companyService: CompanyService,
    private _serviceService: ServiceService,
    private _vehicleService: VehicleService,
    private _professionalService: ProfessionalService,
    private _categoryService: CategoryServService,
    private _router: Router,
    private _attentionService: AttentionService,
    private _privatedService: PrivateService,
    private _paramedicService: ParamedicService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) {
    this.atencion = new Attention();
  }
  name = 'Angular';

  puntosPaso = [];
  puntoPasoInicio: any;
  puntoPasoDestino: any;

  generarRuta() {
    if (this.atencion.waypoints.length >= 2) {
      console.log('direcciones', this.atencion.waypoints)
      let direcciones = [];

      this.atencion.waypoints.forEach((item, index) => {
        console.log('index', index)
        console.log('valid', index != 0 || index != this.atencion.waypoints.length - 1)
        if (index != 0 && index != this.atencion.waypoints.length - 1) {
          direcciones.push(item);
        }
      })

      console.log('LIMPIADO', direcciones)
      this.puntosPaso = direcciones.map(item => {
        return {
          location: { lat: item.location.lat, lng: item.location.lng },
          stopover: true
        }
      })
      this.puntoPasoInicio = this.atencion.waypoints[0];
      this.puntoPasoInicio = { location: this.puntoPasoInicio["location"] }
      this.puntoPasoDestino = this.atencion.waypoints[this.atencion.waypoints.length - 1];
      this.puntoPasoDestino = { location: this.puntoPasoDestino["location"] }
      this.rutaActivada = true;
      console.log('punto paso inicio', { location: this.puntoPasoInicio["location"] })
      console.log('punto paso destino', { location: this.puntoPasoDestino["location"] })

    }
  }

  ocultarRuta() {
    this.rutaActivada = false;
  }

  removeDireccion(index: any): void {

    const direcciones = this.atencion.waypoints.filter((item, i) => index != i)

    this.atencion.waypoints = direcciones;

    console.log('DIRECCIONES DESPUES DE ELIMINAR', direcciones)
  }

  abrirHora() {
    console.log('focus')
  }


  agregar(): void {
    this.atencion.waypoints.push({ location: { lat: this.latitude, lng: this.longitude }, name: this.searchElementRef.nativeElement.value })
  }


  ngOnInit(): void {

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });

    this.setCurrentLocation();
    this.obtenerServicios();
    this.obtenerEmpresas();
    this.obtenerUnidades();
    this.obtenerParamedicos();
    this.obtenerProfesionales();
    this.obtenerCategorias();

    const hora = document.getElementById('hora');
    console.log('INPUT HORA', hora)
  }

  seleccionarTipo(event) {

    if (event.value == 'privado') {
      this.atencion.company_id = null;
      this.pacientes = [];
      this.isBilling = true;
      this._privateService.getPrivates().subscribe(
        response => {
          console.log('PRIVATE PATIENTS', response);
          this.pacientes = response.data;
        },
        error => {
          console.log('ERROR PRIVATE', error);
        }
      )
    } else {
      this.isBilling = false;
      this.pacientes = [];
    }

  }

  seleccionarPaciente(id) {
    if (this.atencion.type_patient == "privado") {
      this._privatedService.getPrivateByPatient(id).subscribe(
        response => {
          if (response.data.billing != null) {
            this.billing.rfc = response.data.billing.rfc;
            this.billing.razon = response.data.billing.business_name;
            this.billing.cfdi = response.data.billing.cfdi;
            this.billing.metodo = response.data.billing.payment_method;
          } else {
            this.billing.rfc = "";
            this.billing.razon = "";
            this.billing.cfdi = "";
            this.billing.metodo = "";
          }
        },
        error => {

        }
      )
    }
  }

  seleccionarEmpresa(id) {

    this._employeeService.getEmployeesByCompany(id).subscribe(
      response => {
        console.log('RESPONSE EMPLOYEE', response);
        this.pacientes = response.data;

      },
      error => {
        console.log('ERROR EMPLOYEE', error)
      }
    )
    console.log('id empresa', id)
    return true;

  }

  seleccionarParamedico(id) {
    this.atencion.vehicle_id = "1";
    return;
    this._paramedicService.getParamedic(id).subscribe(
      response => {
        console.log('PARAMEDICO SELECCIONADO', response)
        this.atencion.vehicle_id = response.data.vehicle_id;
      },
      error => {
        this.openError('Error al obtener paramedico', 'OK');
      }
    )
  }

  obtenerEmpresas() {
    this._companyService.getCompanies().subscribe(
      response => {
        console.log('EMPRESAS', response);
        this.empresas = response.data;
      },
      error => {
        console.log('ERROR', error);
      }
    )
  }

  obtenerServicios() {
    this._serviceService.getServices().subscribe(
      response => {
        this.servicios = response.data;
      },
      error => {

      }
    );
  }

  obtenerUnidades() {
    this._vehicleService.getVehicles().subscribe(
      response => {
        this.unidades = response.data;
        console.log('UNIDADES', response)
      },
      error => {

      }
    );
  }

  obtenerParamedicos() {
    this._professionalService.getProfessionals().subscribe(
      response => {
        this.paramedics = response.data;
        let paramedicos = [];
        response.data.forEach(element => {
          if (element.type == 'paramedico') {
            paramedicos.push(element);
          }
        });
        this.paramedics = paramedicos;
      },
      error => {

      }
    );
  }

  obtenerProfesionales() {
    this._professionalService.getProfessionals().subscribe(
      response => {
        this.profesionales = response.data;
        console.log('PROFESIONALES', this.profesionales);
        let profesionales = [];
        response.data.forEach(element => {
          if (element.type != 'paramedico') {
            profesionales.push(element);
          }
        });
        this.profesionales = profesionales;
      },
      error => {

      }
    );
  }

  obtenerCategorias() {
    this._categoryService.getCategories().subscribe(
      response => {
        this.categorias = response.data;
      },
      error => {
        this.openError('No se encontraron categorias', 'ok');
      }
    )
  }

  registrarAtencion(f: NgForm) {

    console.log('ATENCION', this.atencion);
    console.log('direcciones', this.atencion.waypoints)

    this.loader = true;
    const payload = {
      type_patient: this.atencion.type_patient,
      date: this.atencion.date,
      hour: this.atencion.hour,
      waypoints: JSON.stringify(this.atencion.waypoints),
      aditional_service_id: JSON.stringify({ additionals: this.atencion.aditional_service_id }),
      detail: this.atencion.detail,
      emails: JSON.stringify({ correos: this.correos }),
      patient_id: this.atencion.patient_id,
      paramedic_id: this.atencion.paramedic_id,
      professionals_id: JSON.stringify({ professionals: this.atencion.professionals_id }),
      vehicle_id: this.atencion.vehicle_id,
      service_id: this.atencion.service_id,
      company_id: this.atencion.company_id,
      status: this.atencion.status
    }

    console.log('PARAMS', payload);

    this._attentionService.saveAttention(payload).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('Nueva Atencion Medica Registrada');
        setTimeout(() => {
          this.location.back();
        }, 1500);
        f.resetForm()
      },
      error => {
        this.loader = false;
        this.openError('Error al registrar Atencion Medica', 'ok');
      }
    )
  }

  volver() {
    this._router.navigate(['/historial-de-atenciones']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
