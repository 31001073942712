import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { Coupon } from 'src/app/models/coupon';
import { CouponService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-cupones-editar',
  templateUrl: './cupones-editar.component.html',
  styleUrls: ['./cupones-editar.component.css']
})
export class CuponesEditarComponent implements OnInit {

  coupon: Coupon;
  couponId: number;
  loader: boolean = false;

  descuentoFormControl = new FormControl('', [
    Validators.required,
  ]);

  fechaInicioFormControl = new FormControl('', [
    Validators.required,
  ]);

  fechaCulminacionFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public location: Location,
    public dialog: MatDialog,
    private _router: Router,
    private _couponService: CouponService,
    private _activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {
    this.coupon = new Coupon();
  }

  ngOnInit(): void {
    this.obtenerCupon();
  }

  volver() {
    this._router.navigate(['/servicios/gestion-de-cupones']);
  }

  obtenerCupon() {
    this._activatedRoute.params.subscribe(
      params => {
        this.couponId = params['id'];

        this._couponService.getCoupon(this.couponId).subscribe(
          response => {
            console.log('cupon', response)
            this.coupon.code = response.data.code;
            this.coupon.quantity = response.data.quantity;
            this.coupon.discount = response.data.discount;
            this.coupon.date_in = response.data.date_in;
            this.coupon.date_out = response.data.date_out;
          },
          errror => {
            this.openError('NO SE ENCONTRO AL CUPON', 'ok');
            this.location.back();
          }
        )
      }
    )
  }

  editarCupon(f: NgForm) {
    this.loader = true;

    this._couponService.updateCoupon(this.couponId, this.coupon).subscribe(
      response => {
        this.mostrarExitoDialog('DATOS DE CUPON ACTUALIZADO');
        this.loader = false;
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.openError('ERROR AL ACTUALIZAR DATOS DEL CUPON', 'ok');
        this.loader = false
      }
    )

  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
