import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Coupon } from 'src/app/models/coupon';
import { CouponService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-cupones-nuevo',
  templateUrl: './cupones-nuevo.component.html',
  styleUrls: ['./cupones-nuevo.component.css']
})
export class CuponesNuevoComponent implements OnInit {

  coupon: Coupon;
  loader: boolean;

  descuentoFormControl = new FormControl('', [
    Validators.required,
  ]);

  fechaInicioFormControl = new FormControl('', [
    Validators.required,
  ]);

  fechaCulminacionFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public location: Location,
    private _couponService: CouponService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.coupon = new Coupon();
  }

  ngOnInit(): void {
  }

  volver(): void {
    this.location.back();
  }

  generarCodigo(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  registrarCupon(f: NgForm) {
    this.loader = true;

    this.coupon.administrative_id = (JSON.parse(localStorage.getItem('currentUser'))).id;
    this._couponService.saveCoupon(this.coupon).subscribe(
      response => {
        this.mostrarExitoDialog('NUEVO CUPON REGISTRADO');
        this.loader = false;
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.openError('ERROR AL REGISTRAR CUPON', 'ok');
        this.loader = false;
      }
    )


  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
