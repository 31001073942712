export class Company {
  id: string;
  name : string;
  affiliation_number : string;
  address  : string;
  phone  : string;
  celphone  : string;
  email  : string;
  representative_name  : string;
  representative_lastnamepat : string;
  representative_lastnamemat : string;
  representative_birth_date : string;
  representative_email : string;
  representative_phone : string;
  representative_address : string;
  representative_gender : string
  representative_image : string;
  image: string;

  constructor(
  ) {}
}
