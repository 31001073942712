import { Component, OnInit } from '@angular/core';
import { Phlebotomist } from 'src/app/models/phlebotomist';
import { PhlebotomistService, UserService } from 'src/app/services';
import { environment } from '../../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Password } from 'src/app/admin/configuracion/configuracion.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { UploadImage } from 'src/app/models/upload_image';
import { Location } from '@angular/common';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-flebotomista-ver',
  templateUrl: './flebotomista-ver.component.html',
  styleUrls: ['./flebotomista-ver.component.css']
})
export class FlebotomistaVerComponent implements OnInit {

  hide = true;
  hideC = true;
  hideA = true;

  loaderUpdPwd = false;

  flebotomista: Phlebotomist;
  flebotomistaId: any;

  userId: any;

  password: Password;

  sourceUrl = environment.sourceUrl;

  uploadImage: UploadImage;
  tempImage: any;

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    private _phlebotomistService: PhlebotomistService,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _router: Router,
    public dialog: MatDialog,
    public location: Location
  ) {
    this.flebotomista = new Phlebotomist();
    this.password = new Password('', '', '');
    this.uploadImage = new UploadImage();
  }

  ngOnInit(): void {
    this.obtenerFlebotomista();
  }

  obtenerFlebotomista(): void {
    this._activatedRoute.params.subscribe(
      params => {
        this.flebotomistaId = params['id'];

        this._phlebotomistService.getPhlebotomist(this.flebotomistaId).subscribe(
          response => {
            console.log(response)
            this.flebotomista.name = response.data.professional.user.name;
            this.flebotomista.lastnamepat = `${response.data.professional.user.lastnamepat}`;
            this.flebotomista.email = response.data.professional.user.email;
            this.flebotomista.telephone = response.data.professional.user.telephone;
            this.flebotomista.birth_date = response.data.professional.user.birth_date;
            this.flebotomista.address = response.data.professional.user.address;
            this.userId = response.data.professional.user.id;
            this.flebotomista.photo = `${environment.sourceUrl}${response.data.professional.photo}`;
            this.flebotomista.image = response.data.professional.user.image;
            this.tempImage = response.data.professional.user.image;
            this.flebotomista.institution = response.data.institution;
            this.flebotomista.date_admission = response.data.professional.created_at;
            this.flebotomista.cedula = response.data.cedula;
            this.flebotomista.certificate = response.data.certificate;
            this.flebotomista.ine = response.data.professional.ine;
            this.flebotomista.status = response.data.active;
          },
          error => {
            this.openError('Flebotomista no encontrado', 'Ok');
          }
        )
      }
    )
  }

  actualizarContrasenia(f: NgForm) {
    this.loaderUpdPwd = true;
    this._userService.updatePassword(this.userId, this.password).subscribe(
      response => {
        this.loaderUpdPwd = false;
        if(response.code == 404) {
          this.openError('Error al actualizar contraseña', 'Ok');
        }else {
          f.resetForm();
          this.mostrarExitoDialog('Contraseña actualizada');
        }
      },
      error => {
        this.loaderUpdPwd = false;
        this.openError('Error al actualizar contraseña', 'Ok');
      }
    )
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.uploadImage.image = reader.result;
      this.tempImage = reader.result;
    };
  }

  guardarImagen() {

    this._userService.uploadImage(this.userId, this.uploadImage).subscribe(
      response => {
        console.log('response', response);
        console.log('response', response.data.image)
        this.tempImage = response.data.image;
        this.uploadImage.image = null;
        this.flebotomista.image = response.data.image;

        this.mostrarExitoDialog('Imagen actualizada')

      },
      error => {
        console.log('error', error);
      }
    )
  }

  cancelar() {
    this.tempImage = this.flebotomista.image;
    this.uploadImage.image = null;
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  verDocumento(documento) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${documento}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  volver() {
    this._router.navigate(['/usuario/profesional/flebotomista']);
  }

}
