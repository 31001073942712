import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';

declare function init_plugins_login();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user: User = new User();
  loading = false;
  errorMessage = false;

  constructor(
    private router: Router,
    private _authService: AuthenticationService
  ) { }

  ngOnInit(): void {

    init_plugins_login();

  }

  iniciarSesion(): void {
    this.loading = true;
    this._authService.login(this.user).subscribe(
      response => {
        console.log('response', response)
        this.loading = false;
        this.router.navigate(['/dashboard'])
      },
      error => {
        console.log('error', error)
        this.errorMessage = true;
        this.loading = false;
      }
    )

  }

}
