<section>

    <vex-section-title title="ADMINISTRACIÓN" subtitle="EMPRESAS"></vex-section-title>

    <div class="vex_soluciones_content">
        <div align="end">
            <mat-form-field>
              <mat-label>Buscador</mat-label>
              <input matInput (keyup)="buscarEmpresa($event)" placeholder="Buscar">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            &nbsp;
            <button mat-raised-button color="primary" [routerLink]="['/nueva-empresa']">
                <mat-icon>add</mat-icon>
                Agregar
            </button>
        </div>

        <mat-card class="mat-elevation-z0 sc_card_more_options">
            Todas las empresas
            <button class="sc_more_options" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    class="sc_more_options"
                    color="primary"
                    (click)="exporter.exportTable('xlsx', {fileName:'empresas'})">
                    <mat-icon>cloud_download</mat-icon>
                    <span>Exportar a Excel</span>
                </button>

            </mat-menu>
        </mat-card>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort matTableExporter #exporter="matTableExporter">

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                <td mat-cell *matCellDef="let element">
                    <mat-slide-toggle color="primary" (click)="cambiarActivacion(element)" [checked]="(element.status == 1)? true: false "></mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="identifier">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let element"> {{element.identifier}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> EMPRESA </th>
                <td mat-cell *matCellDef="let element">
                    <div class="user-info-content">
                        <div class="user-img">
                            <img class="img-profile" [src]="sourceUrl + element.image" alt="">
                        </div>
                        <div class="user-info">
                            <a [routerLink]="['/ver-empresa', element.identifier]">{{element.name}}</a>
                            <p class="m-t-0 m-b-0">{{element.email}}</p>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="affiliation_number">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> NUMERO DE AFILIACION </th>
              <td mat-cell *matCellDef="let element"> {{element.affiliation_number}} </td>
            </ng-container>

            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> DIRECCION </th>
              <td mat-cell *matCellDef="let element"> {{element.address}} </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> TELEFONO </th>
                <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
            </ng-container>

            <ng-container matColumnDef="representative_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE REPRESENTANTE </th>
                <td mat-cell *matCellDef="let element">
                    <div class="user-info-content">
                        <div class="user-img">
                            <img class="img-profile" [src]="sourceUrl + element.representative_image" alt="">
                        </div>
                        <div class="user-info">
                            <a [routerLink]="['/ver-empresa', element.identifier]">{{element.representative_name}} {{element.representative_lastnamepat}}</a>
                            <p class="m-t-0 m-b-0">{{element.representative_email}}</p>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="representative_phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> TELEFONO REPRESENTANTE </th>
                <td mat-cell *matCellDef="let element"> {{element.representative_phone}} </td>
            </ng-container>

            <ng-container matColumnDef="representative_address">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> DIRECCION REPRESENTANTE </th>
                <td mat-cell *matCellDef="let element"> {{element.representative_address}} </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="pin-size-th"> ACCIONES </th>
                <td mat-cell *matCellDef="let element">

                    <button color="primary" [routerLink]="['/ver-empresa', element.identifier]" mat-icon-button>
                        <mat-icon>visibility</mat-icon>
                    </button>
                    &nbsp;
                    <button color="primary" [routerLink]="['/editar-empresa', element.identifier]" mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4"></td>

                <td class="mat-cell" colspan="1" *ngIf="!loader">No se encontraron registros</td>

                <td class="mat-cell" colspan="1" *ngIf="loader">
                    <mat-spinner diameter="40"></mat-spinner>
                </td>

                <td class="mat-cell" colspan="5"></td>
            </tr>
        </table>
        <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

    </div>
</section>

