import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AdministrativeService } from 'src/app/services/administrative.service';
import { InboxService } from 'src/app/services/inbox.service';
import { SocketService } from 'src/app/services/socket.service';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { environment } from 'src/environments/environment';

export class ChatSelected {
  constructor(
    public id: string,
    public image: string,
    public name: string,
    public message: string,
    public timeAgo: string
  ){}
}

export interface Contacto {
  id: any;
  name: string;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  @ViewChild('scroll') private myScrollContainer: ElementRef;

  chatEmisors =  [1,2,2]
  chatTransmitters =  [1,2,3]

  userId: any;
  imageUser: any;

  chats = [];
  allChats = [];
  messages = [];

  chatSelected: ChatSelected;

  url = environment.sourceUrl;

  mensaje: String;

  message: any;

  myControl = new FormControl();
  options: Contacto[] = [
    {id: 1, name: 'Mary'},
    {id: 2, name: 'Shelley'},
    {id: 3, name: 'Igor'}
  ];
  filteredOptions: Observable<Contacto[]>;

  personNameChat: any;

  room: any;

  administradores: any[];

  constructor(
    private _inboxService: InboxService,
    private _administrativeService: AdministrativeService,
    private _snackBar: MatSnackBar,
    private _socketService: SocketService,
    public dialog: MatDialog
  ) {
    this.chatSelected = new ChatSelected('', '', '', '', '');
  }

  ngOnInit(): void {

    this.userId = JSON.parse(localStorage.getItem('currentUser')).id;
    this.imageUser = JSON.parse(localStorage.getItem('currentUser')).image;

    this.loadData();

    this.personNameChat = {name: "Seleccione un usuario"};

    this.obtenerMensajesTiempoReal();

    this._socketService.newMessage.subscribe(
      response => {
        console.log('new message', response)
        this.obtenerChats();
      },
      error => {
        console.log('ERRor', error)
      }
    )
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      }, 1);
    } catch(err) { }
  }

  loadData(): void {
    this._administrativeService.getAdministratives().subscribe(
      response => {
        this.administradores = response.data;
        console.log('Administradores', response)
        let filtrado = response.data.map(administrador => {
          return {id: administrador.identifier, name: `${administrador.name} - ${administrador.role_name}`, role: administrador.role.field }
        })
        this.options = filtrado;
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(name) : this.options.slice())
        );
        console.log("FILTRADO", filtrado)

      },error => {
        this.openError("Error al obtener administradores", "OK");
      }
    )
  }

  obtenerMensajesTiempoReal(): void {

    this._socketService.getMessage().subscribe(
      response => {
        console.log('new message', response)
        this.obtenerChats();
      },
      error => {
        console.log('ERRor', error)
      }
    )
  }

  onSelectionChange(admin) {

    this.personNameChat = admin;
    this.room = this.generateRoom();
    this.obtenerChats();
  }

  generateRoom(): string {
    let userTransmitter = JSON.parse(localStorage.getItem('currentUser'));
    let roleUserTransmitter = JSON.parse(localStorage.getItem('role'));

    const hierarchy = ["masteradmin", "superadmin", "admin", "callcenter", "recursoshumanos", "monitoreo", "facturacion", "pits"];

    let positionTransmitter = 0;
    let positionReciever = 0;

    hierarchy.forEach((element, key) => {
      if(element == roleUserTransmitter.field) {
        positionTransmitter = key;
      }
      if(element == this.personNameChat["role"]["field"]) {
        positionReciever = key;
      }
    });

    let room = (positionTransmitter > positionReciever)?  `${this.personNameChat["role"]["field"]}#${this.personNameChat["id"]}_${roleUserTransmitter.field}#${userTransmitter.id}`: `${roleUserTransmitter.field}#${userTransmitter.id}_${this.personNameChat["role"]["field"]}#${this.personNameChat["id"]}`

    console.log('ROOM', room)
    return room;
  }


  enviarMensaje(f: NgForm) {
    let userTransmitter = JSON.parse(localStorage.getItem('currentUser'));

    console.log('MESSAGE', this.message)

    if(this.personNameChat) {
      const payloadMessage = {
        message: this.message,
        room: this.room,
        transmitter: userTransmitter.id,
        receiver: this.personNameChat["id"],
      }
      console.log('paylod', payloadMessage)
      this._inboxService.saveInbox(payloadMessage).subscribe(
        async response => {
          f.resetForm();
          const resp = await this._socketService.sendMessage(payloadMessage);
          this.obtenerChats();
          // this.scrollToBottom();
        },
        error => {
          this.openError("Error al enviar un mensaje", "OK")
        }
      )

    } else {
      this.openError("Seleccionar administrador", "OK");
    }

  }

  displayFn(user: Contacto): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): Contacto[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  obtenerChats() {
    this._inboxService.getUsersChat({room: this.room}).subscribe(
      response => {
        console.log('USERS chat', response)
        this.messages = response.data;
        this.allChats = this.chats;
        // this.scrollToBottom();
      },
      error => {
        this.openError('Error al obtener chats', 'Ok');
      }
    )
  }

  seleccionarChat(receiver, messages) {

    this.messages = messages;

    this.chatSelected.image = receiver.image;
    this.chatSelected.name = receiver.name + ' ' + receiver.lastnamepat;
    this.chatSelected.id = receiver.id;

    if(messages.length > 0) {

      this.chatSelected.message = messages[messages.length -1].message;
      this.chatSelected.timeAgo = messages[messages.length -1].timeAgo;
    }

    return;
    this._inboxService.getMessagesChat(receiver).subscribe(
      response => {
        console.log('USERS CHAT', response)
        this.messages = response;
        this.chatSelected.image = response.receiver.image;
        this.chatSelected.name = response.receiver.name + ' ' + response.receiver.lastnamepat;
        this.chatSelected.message = response.message;
        this.chatSelected.timeAgo = response.timeAgo;
      },
      error => {
        this.openError('Error al encontrar mensajes en el chat', 'Ok')
      }
    )
  }

  // enviarMensaje(f: NgForm) {

  //   console.log('IDSS',this.chatSelected.id.length)
  //   if(this.chatSelected.id.length == 0) {
  //     this.openError('Seleccionar Chat', 'OK');
  //     return;
  //   }

  //   let params = {
  //     message: this.mensaje,
  //     room: `Anro${this.userId}_Pnro${this.chatSelected.id}`,
  //     status: 'enviado',
  //     transmitter: this.userId,
  //     receiver:  this.chatSelected.id
  //   };

  //   console.log('paramsss', params);

  //   this._inboxService.saveInbox(params).subscribe(
  //     response => {
  //       this.messages.push(response.data);
  //     },
  //     error => {
  //       this.openError('Error al enviar mensaje', 'OK')
  //     }
  //   )
  //   f.resetForm();

  // }

  buscarChat(key) {

    console.log('LLAVE', key);

    let chats = [];

    this.allChats.forEach(element => {

      if (element.receiver.name.includes(key)) {
        chats.push(element);
      }

    });

    if(chats.length == 0) {

      this.chats = this.allChats;

    } else {
      this.chats = chats;
    }

  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
