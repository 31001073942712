import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { Nurse } from 'src/app/models/nurse';
import { NurseService } from 'src/app/services';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { Location } from '@angular/common';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-enfermero-nuevo',
  templateUrl: './enfermero-nuevo.component.html',
  styleUrls: ['./enfermero-nuevo.component.css']
})
export class EnfermeroNuevoComponent implements OnInit {

  enfermero: Nurse;
  photoTemp: any;
  ineTemp: any;
  titleTemp: any;
  cedulaTemp: any;
  hide = true;
  hideC = true;
  loader: boolean = false;


  nombresFormControl = new FormControl('', [
    Validators.required,
  ])

  apePaternoFormControl = new FormControl('', [
    Validators.required,
  ])

  apeMaternoFormControl = new FormControl('', [
    Validators.required,
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6)
  ])

  passConfFormControl = new FormControl('', [
    Validators.required,
    this.passwordMatcher.bind(this)
  ])

  celFormControl = new FormControl('', [
    Validators.required,
  ])

  telFormControl = new FormControl('', [
    Validators.required,
  ])

  direccionFormControl = new FormControl('', [
    Validators.required,
  ])

  dateBirthFormControl = new FormControl('', [
    Validators.required,
  ])

  matcher = new MyErrorStateMatcher();

  public passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (
        (control.value !== this.passFormControl.value)
    ) {
        return { passwordNotMatch: true };
    }
    return null;
  }

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _nurseService: NurseService,
    public location: Location,
    private _snackBar: MatSnackBar
    ) {
    this.photoTemp = "/assets/images/admin/upload-image.png";
    this.ineTemp = "/assets/images/admin/upload-image.png";
    this.titleTemp = "/assets/images/admin/upload-image.png";
    this.cedulaTemp = "/assets/images/admin/upload-image.png";
    this.enfermero = new Nurse();
  }

  ngOnInit(): void {
  }

  onFileSelectedPhoto(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.photoTemp = reader.result;
      this.enfermero.photo = reader.result;
    };

  }

  verFoto() {

    if(this.photoTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.photoTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedIne(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.ineTemp = reader.result;
      this.enfermero.ine = reader.result;
    };

  }

  verIne() {

    if(this.ineTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.ineTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedTitle(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.titleTemp = reader.result;
      this.enfermero.title = reader.result;
    };

  }

  verTitle() {

    if(this.titleTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.titleTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedCedula(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.cedulaTemp = reader.result;
      this.enfermero.cedula = reader.result;
    };

  }

  verCedula() {

    if(this.cedulaTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.cedulaTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  registrarEnfermero(f: NgForm) {
    this.loader = true;

    this.enfermero.type = 'profesional';

    this._nurseService.saveNurse(this.enfermero).subscribe(
      response => {
        this.mostrarExitoDialog('PROFESIONAL ENFERMERO REGISTRADO')
        this.loader = false;
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.loader = false;
        if(error.error.code == 422) {
          this.openError('Correo existente, Ingrese otro.', 'Ok');
          return;
        }
        this.openError('ERROR AL REGISTRAR ENFERMERO', 'Ok');
      }
    )

  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  volver() {
    this._router.navigate(['/usuario/profesional/enfermero']);
  }

}
