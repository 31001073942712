import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { Gasoline } from 'src/app/models/gasoline';
import { Location } from '@angular/common';
import { GasolineService, VehicleService, ParamedicService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-gasoline-editar',
  templateUrl: './gasoline-editar.component.html',
  styleUrls: ['./gasoline-editar.component.css']
})
export class GasolineEditarComponent implements OnInit {

  gasoline: Gasoline;
  gasolineId: number;
  vehiculos: any = [];
  paramedicos: any = [];
  loader: boolean = false;
  inputResponsable: boolean = false;

  responsableFormControl = new FormControl('', [
    Validators.required,
  ]);

  estacionFormControl = new FormControl('', [
    Validators.required,
  ]);

  montoFormControl = new FormControl('', [
    Validators.required,
  ]);

  cantidadFormControl = new FormControl('', [
    Validators.required,
  ]);

  descripcionFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public dialog: MatDialog,
    public location: Location,
    private _gasolineService: GasolineService,
    private _vehicleService: VehicleService,
    private _activatedRoute: ActivatedRoute,
    private _paramedicService: ParamedicService,
    private _snackBar: MatSnackBar
    ) {
    this.gasoline = new Gasoline();
  }

  ngOnInit(): void {
    this.obtenerUnidades();
    this.obtenerCombustible();

  }

  seleccionarResponble(opcion) {
    console.log('opcion', opcion)
    if(opcion == 0) {
      this.inputResponsable = true;
    } else {
      this.inputResponsable = false;
      this.gasoline.responsible = "";
    }
  }

  obtenerParamedicos() {
    this._paramedicService.getParamedics().subscribe(
      response => {
        this.paramedicos = response.data;
      },
      error => {
        this.openError('Error al obtener paramedicos', 'Ok');
      }
    )
  }

  obtenerUnidades() {
    this._vehicleService.getVehicles().subscribe(
      response => {
        this.vehiculos = response.data;
      },
      error => {
        this.openError('Erro al obtener unidades', 'Ok');

      }
    )
  }

  obtenerCombustible() {
    this._activatedRoute.params.subscribe(
      params => {
        console.log('id', params['id']);
        this.gasolineId = params['id'];

        this._gasolineService.getGasoline(this.gasolineId).subscribe(
          response => {
            this.gasoline.fuel_station = response.data.fuel_station;
            this.gasoline.amount = response.data.amount;
            this.gasoline.quantity = response.data.quantity;
            this.gasoline.description = response.data.description;
            this.gasoline.vehicle_id = response.data.vehicle.id;
            if (response.data.paramedic_id == null)  {
              this.gasoline.paramedic_id = "0";
              this.inputResponsable = true;
              this.gasoline.responsible = response.data.user.name;
            } else {
              this.gasoline.paramedic_id = response.data.paramedic_id;
            }
            this.obtenerParamedicos();
          },
          error => {
            this.openError('Erro al obtener combustible', 'Ok');
          }
        )
      },

    )
  }

  editarCombustible(f: NgForm) {
    this.loader = true;

    this._gasolineService.updateGasoline(this.gasolineId, this.gasoline).subscribe(
      response => {
        this.mostrarExitoDialog('Datos de combustible actualizado');
        this.loader = false;
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.openError('Error al actualizar datos del combustible', 'Ok');
        this.loader = false;
      }
    )

  }

  volver () {
    this.location.back();
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
