<div>
    
    <h2 style="text-align: center;">Documento</h2>

    <div mat-dialog-content>

        <img [src]="image" alt="">
        
    </div>

    <div mat-dialog-actions style="float: right;">
        
        <button class="btn-yes" mat-button mat-dialog-close="true" cdkFocusInitial> Ok </button>

    </div>

</div>