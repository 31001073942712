import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': "Bearer " + localStorage.getItem('token')
    })
};

@Injectable({
    providedIn: 'root'
})
export class SupportService {

    constructor(
        private http: HttpClient,
    ) { }
    
    getSupports(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}supports`);

    }

    getSupport(id): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}supports/${id}`);

    }

    saveSupport(data): Observable<any> {

        return this.http.post<any>(`${environment.apiUrl}supports`, data);

    }

    updateSupport(id, data): Observable<any> {

        return this.http.put<any>(`${environment.apiUrl}supports/${id}`, data);

    }

    deleteSupport(id): Observable<any> {

        return this.http.delete<any>(`${environment.apiUrl}supports/${id}`);

    }

    getCategorySupports(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}category-supports`);

    }

    getCategorySupport(id): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}category-supports/${id}`);

    }

    saveCategorySupport(data): Observable<any> {

        return this.http.post<any>(`${environment.apiUrl}category-supports`, data);

    }

    updateCategorySupport(id, data): Observable<any> {

        return this.http.put<any>(`${environment.apiUrl}category-supports/${id}`, data);

    }

    deleteCategorySupport(id): Observable<any> {

        return this.http.delete<any>(`${environment.apiUrl}category-supports/${id}`);

    }

}
