import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { Maintenance } from "src/app/models/maintenance";
import { MaintenanceService } from "src/app/services/maintenance.service";
import { VehicleService } from "src/app/services/vehicle.service";

@Component({
    selector: 'mantenimiento-form-dialog',
    templateUrl: './mantenimiento-form.component.html',
    styleUrls: ['./mantenimiento-form.component.scss']
})
export class MantenimientoDialogFormComponent implements OnInit, OnDestroy {

    uid: any;
    sendForm$: Subscription;
    unidades$: Subscription;

    mantenimiento: Maintenance;

    unidades: any[] = [];

    constructor(
        public dialogRef: MatDialogRef<MantenimientoDialogFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _mantenimientoService: MaintenanceService,
        private _vehicleService: VehicleService,
        private _snackBar: MatSnackBar
    ) {
        this.mantenimiento = new Maintenance();
        this.mantenimiento.vehicle_id = data.vehicleId;
        if(data.mantenimiento) {
            this.mantenimiento.description = data.mantenimiento.description;
            this.mantenimiento.date = data.mantenimiento.date;
            this.uid = data.mantenimiento.id;
        }
    }

    ngOnInit(): void {
        this.loadMaestros();
    }

    loadMaestros(): void {
        this.unidades$ = this._vehicleService.getVehicles().subscribe(
            unidades => {
                this.unidades = unidades.data;
            },
            error => {
                this.openError('Error al obtener unidades', 'Ok');
            }
        )
    }

    enviarFormulario(f: NgForm): void {
        (this.data.isNew) ? this.agregarMantenimiento(f): this.actualizarMantenimiento(f);
    }

    agregarMantenimiento(f: NgForm): void {
        this.sendForm$ = this._mantenimientoService.saveMaintenance(this.mantenimiento).subscribe(
            response => {
                this.dialogRef.close('true');
            },
            error => {
                this.openError('Error al registrar mantenimiento', 'Ok');
            }
        )
    }

    actualizarMantenimiento(f: NgForm): void {
        this.sendForm$ = this._mantenimientoService.updateMaintenance(this.uid, this.mantenimiento).subscribe(
            response => {
                this.dialogRef.close('true');
            },
            error => {
                this.openError('Error al actualizar mantenimiento', 'Ok');
            }
        )
    }

    openError(message: string, action: string) {
        this._snackBar.open(message, action, {
          duration: 4000,
        });
    }

    cancelar(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
      if(this.sendForm$) this.sendForm$.unsubscribe();
    }
}
