export class Psychologist {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  type: string;
  lastnamepat: string;
  lastnamemat: string;
  birth_date: string;
  gender: string;
  address: string;
  phone: string;
  telephone: string;
  image: string;
  photo: any;
  ine: any;
  institution: string;
  cedula: any;
  certificate: any;
  date_admission: any;
  status: any;

  constructor(

  ){}
}
