<section>
    <div class="row">
        <div class="col s1"></div>
        <div class="col s10">

            <div class="vex_soluciones_tiles">
                <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
                <h2 class="vex_soluciones_subtitle">VER PSICOLOGO</h2>

                <button class="vex-souluciones-back" mat-raised-button (click)="volver()">{{buttonName.BACK}}</button>
            </div>
            <div>
                <mat-tab-group>
                    <mat-tab label="DATOS PERSONALES">
                        <div class="row padding-content">
                            <div class="col s3 p-l-0">
                                <mat-card>
                                    <div class="center">
                                        <div class="img-content">
                                            <img class="vex-soluciones-img-profile" [src]="(uploadImage.image)? tempImage: sourceUrl + tempImage" alt="" width="120">
                                        </div>
                                    </div>
                                    <h3 class="center m-b-0 vex-soluciones-name-title">{{psicologo.name}} {{psicologo.lastnamepat}}</h3>
                                    <!-- <h4 class="center m-t-0 vex-soluciones-email-title">{{psicologo.email}}</h4> -->
                                    <div class="vex-user-status-container">
                                      <div [ngClass]="{'active': psicologo.status == 'activado', 'inactive': psicologo.status != 'activado'}">
                                        {{psicologo.status == 'activado' ? 'Activo': 'No disponible'}}
                                      </div>
                                    </div>
                                    <!-- <div class="center">
                                        <button *ngIf="tempImage == psicologo.image" mat-stroked-button color="primary" type="button" (click)="photoFile.click()">Cambiar Imagen</button>
                                        <input hidden (change)="subirImagen($event.target.files[0])" #photoFile type="file" accept="image/x-png,image/gif,image/jpeg,image/jpg">

                                    </div> -->

                                    <div class="center">
                                        <button mat-stroked-button *ngIf="tempImage != psicologo.image" type="button" (click)="cancelar()">Cancelar</button>
                                        &nbsp;
                                        <button mat-stroked-button *ngIf="tempImage != psicologo.image" type="button" (click)="guardarImagen()" color="primary">Guardar</button>
                                    </div>

                                </mat-card>
                            </div>
                            <div class="col s9 p-r-0">
                                <mat-card>
                                    <div>
                                        <h4 class="vex-soluciones-title-perfil">Perfil</h4>

                                    </div>
                                    <div class="row">


                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Correo Electrónico</mat-label>
                                                <input matInput placeholder="Correo Electrónico" [(ngModel)]="psicologo.email" readonly>
                                            </mat-form-field>

                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Teléfono</mat-label>
                                                <input matInput placeholder="Teléfono" [(ngModel)]="psicologo.telephone" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Domicilio Personal</mat-label>
                                                <input matInput placeholder="Domicilio Personal" [(ngModel)]="psicologo.address" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Fecha de nacimiento</mat-label>
                                                <input matInput placeholder="Fecha de nacimiento" [(ngModel)]="psicologo.birth_date" readonly>
                                            </mat-form-field>
                                        </div>



                                    </div>

                                    <h4>Datos de Contacto Familiar</h4>

                                    <div class="row">

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Nombres</mat-label>
                                                <input matInput placeholder="Apellidos" value="Ninguno" readonly>
                                            </mat-form-field>

                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Apellidos</mat-label>
                                                <input matInput placeholder="Apellidos" value="Ninguno" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Teléfono</mat-label>
                                                <input matInput placeholder="Teléfono" value="Ninguno" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Correo electrónico</mat-label>
                                                <input matInput placeholder="Correo electrónico" value="Ninguno" readonly>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <h4>Datos Profesionales</h4>

                                    <div class="row">
                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Institución</mat-label>
                                                <input matInput placeholder="Institución" [(ngModel)]="psicologo.institution" readonly>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Fecha de ingreso</mat-label>
                                                <input matInput placeholder="Fecha de ingreso" value="{{psicologo.date_admission | date}}" readonly>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s12">
                                            <button mat-stroked-button color="primary" (click)="verDocumento(psicologo.cedula)">Ver Cédula</button>
                                            &nbsp;
                                            <button mat-stroked-button color="primary" (click)="verDocumento(psicologo.certificate)">Ver Certificado</button>
                                            &nbsp;
                                            <button mat-stroked-button color="primary" (click)="verDocumento(psicologo.ine)">Ver INE</button>

                                        </div>

                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="SEGURIDAD">
                        <div class="row padding-content">
                            <div class="col s12 p-r-0 p-l-0">
                                <form #updatePassword="ngForm" ngNativeValidate (ngSubmit)="actualizarContrasenia(updatePassword)" (keydown.enter)="$event.preventDefault()">
                                    <mat-card>
                                        <h4>Cambiar Contraseña</h4>
                                        <mat-divider></mat-divider>
                                        <div class="row info-padding-top">
                                            <div class="col s12 m4">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Contraseña Actual</mat-label>
                                                    <input matInput [type]="hideA ? 'password' : 'text'" [(ngModel)]="password.current_password" name="current_password" required>
                                                    <button mat-icon-button matSuffix (click)="hideA = !hideA" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideA">
                                                    <mat-icon>{{hideA ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m4">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Nueva Contraseña</mat-label>
                                                    <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="password.new_password" name="new_password" required>
                                                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m4">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Confirmar Contraseña</mat-label>
                                                    <input matInput [type]="hideC ? 'password' : 'text'" [(ngModel)]="password.new_password_confirm" name="new_password_confirm" required>
                                                    <button mat-icon-button matSuffix (click)="hideC = !hideC" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideC">
                                                    <mat-icon>{{hideC ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="info-padding-top">
                                            <button *ngIf="!loaderUpdPwd" mat-raised-button color="primary" type="submit">Actualizar contraseña</button>
                                            <mat-spinner *ngIf="loaderUpdPwd" diameter="35"></mat-spinner>
                                        </div>
                                    </mat-card>
                                </form>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>

        </div>
        <div class="col s1"></div>
    </div>
</section>
