import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyService } from 'src/app/services/company.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-upload-excel-employee',
  templateUrl: './upload-excel-employee.component.html',
  styleUrls: ['./upload-excel-employee.component.css']
})
export class UploadExcelEmployeeComponent implements OnInit {

  jsonData: any;
  loaderCharge: boolean;
  file: any;
  constructor(
    public dialogRef: MatDialogRef<UploadExcelEmployeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _companyService: CompanyService,
    private _snackBar: MatSnackBar
  ) {

  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  verModelo(): void {
    window.open("https://docs.google.com/spreadsheets/d/1hYGLA2t3BthxqVMhZCordVsa9JSDsKtpYpnU1Gi82B4/edit?usp=sharing",'_blank');
  }

  cargarExcel(): void {

  }

  subirExcel(): void {

    if(this.jsonData.Sheet1[0].contrasenia) {

      const payload = {
        companyId: this.data,
        employees: this.jsonData.Sheet1
      }
      console.log('payload', payload)
      this._companyService.uploadMasiveEmployee(payload).subscribe(
        response => {
          console.log('response', response)
          this.dialogRef.close();
        },
        error => {
          console.log('error', error)
          this.openError("Correo Electrónico ya existente", "OK");
        }
      )
    } else {
      this.openError('Es necesario subir el formato adecuado, Por favor descargar el formato adecuado desde la opción "Descargar Formato".', 'OK');
    }
  }

  onFileChange(ev) {
    this.loaderCharge = true;
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    console.log('file', file)
    reader.onload = (event) => {
      this.file = file;
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      this.jsonData = jsonData;
      console.log('jsonData', jsonData)
      this.loaderCharge = false;
      // console.log(jsonData)
      // document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
    }
    reader.readAsBinaryString(file);
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
