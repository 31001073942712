import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleService } from 'src/app/services';
import { Role } from 'src/app/models/role';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-roles-editar',
  templateUrl: './roles-editar.component.html',
  styleUrls: ['./roles-editar.component.css']
})
export class RolesEditarComponent implements OnInit {

  role: Role = new Role();
  roleId: number;
  loader: boolean = false;

  rolFormControl = new FormControl('', [
    Validators.required,
  ]);

  descripcionFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _roleService: RoleService,
    public location: Location,
    private _router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.obtenerRol();
  }

  obtenerRol() {
    this._activatedRoute.params.subscribe(
      params => {
        this.roleId = params['id'];
        this._roleService.getRole(params['id']).subscribe(
          response => {
            console.log('response', response)
            this.role = response.data;
          },
          error => {
            console.log('error', error)
          }
        )
      }
    )
  }

  volver() {
    this._router.navigate(['/roles-y-permisos']);
  }

  editarRol(f: NgForm) {

    console.log('rol', this.role);
    this.loader = true;

    this._roleService.updateRole(this.roleId, this.role).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('Rol actualizado');
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.openError('Error al actualizar', 'ok')
        this.loader = false;
      }
    )

  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
