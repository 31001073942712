import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';
import { Attention } from 'src/app/models/Attention';
import { AttentionService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-historial-ver',
  templateUrl: './historial-ver.component.html',
  styleUrls: ['./historial-ver.component.css']
})
export class HistorialVerComponent implements OnInit {

  distance: number = 0;
  time: number = 0;

  atencionId: number;
  atencion: Attention;
  imgPatient: string;
  imgParamedic: string;

  origin = { lat: 19.485166, lng: -99.134715 };
  destination = { lat: 19.485166, lng: -99.134715 };
  public lat: Number = 19.485166
  public lng: Number = -99.134715

  tipo = 'privado';

  sourceUrl = environment.sourceUrl;

  buttonName: IButtonName = BUTTON_NAME;

  waypoints: any;

  origen: string;
  destino: string;

  intermedios: Array<any> = [];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _attentionService: AttentionService,
    private _router: Router,
    public location: Location
  ) {

    this.atencion = new Attention();

  }

  ngOnInit(): void {
    this.obtenerAtencion();

  }



  obtenerAtencion() {
    this._activatedRoute.params.subscribe(
      params => {
        this.atencionId = params['id'];

        this._attentionService.getAttention(this.atencionId).subscribe(
          response => {
            console.log('historial', response)
            this.atencion.type_patient = response.data.type_patient;
            this.atencion.date = response.data.date;
            this.atencion.status = response.data.status;

            this.waypoints = JSON.parse(response.data.waypoints);
            console.log('waypoints', this.waypoints);
            this.lat = this.waypoints[0].location.lat;
            this.lng = this.waypoints[0].location.lng;
            this.origen = this.waypoints[0].name;
            this.destino = this.waypoints[this.waypoints.length - 1].name;

            let puntosMedios = [];

            this.waypoints.forEach((point, index) => {
              console.log('index', index)
              if(index != 0 && index != this.waypoints.length -1) puntosMedios.push(point);
            });

            puntosMedios.forEach(point => {
              this.intermedios.push({location: point.location});
            })

            let profesionales = "";
            console.log('profesionales', response.data.professionals);

            for (let index = 0; index < response.data.professionals.length; index++) {
              console.log('entro')
              const element = response.data.professionals[index];
              profesionales += `${element.type} - ${element.user.name} ${element.user.lastnamepat}, `;
            }

            console.log('profesionales', profesionales)

            this.atencion.professionals_id = profesionales;
            this.atencion.patient_id = `${response.data.patient.user.name} ${response.data.patient.user.lastnamepat}`
            this.imgPatient = response.data.patient.user.image;
            this.imgParamedic = response.data.paramedic.user.image;
            this.atencion.paramedic_id = `${response.data.paramedic.user.name} ${response.data.paramedic.user.lastnamepat}`;
            this.atencion.vehicle_id = `${response.data.vehicle.brand} - ${response.data.vehicle.model} - ${response.data.vehicle.plete} `;
            this.atencion.service_id = response.data.service.name;
            this.atencion.aditional_service_id = (response.data.aditional_service)? JSON.parse(response.data.aditional_service)['additionals'][0]: 'sin adicionales';
            this.atencion.detail = response.data.detail;

          },
          error => {
            console.log('historial', error)

          }
        )
      }
    )
  }

  volver() {
    this._router.navigate(['/historial-de-atenciones']);
  }

}
