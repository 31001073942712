import { Component, OnInit } from '@angular/core';
import { Task } from 'src/app/models/task';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { MatDialog } from '@angular/material/dialog';
import { TaskService } from 'src/app/services/task.service';
import { Location } from '@angular/common';
import { ProfessionalService, AdministrativeService } from 'src/app/services';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-nueva-tarea',
  templateUrl: './nueva-tarea.component.html',
  styleUrls: ['./nueva-tarea.component.css']
})
export class NuevaTareaComponent implements OnInit {

  tarea: Task;
  usuarios: any = [];
  loader = false;

  nombreFormControl = new FormControl('', [
    Validators.required,
  ]);

  descripcionFormControl = new FormControl('', [
    Validators.required,
  ]);

  fechaFormControl = new FormControl('', [
    Validators.required,
  ]);

  tiempoRequeridoFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public dialog: MatDialog,
    public location: Location,
    private _taskService: TaskService,
    private _professionalService: ProfessionalService,
    private _administrativeService: AdministrativeService,
    private _router: Router,
    private _snackBar: MatSnackBar
    ) {
    this.tarea = new Task();
  }

  ngOnInit(): void {
    this.obtenerUsuarios();
  }

  obtenerUsuarios() {
    this._administrativeService.getAdministratives().subscribe(
      administrativos => {
        this._professionalService.getProfessionals().subscribe(
          profesionales => {
            this.usuarios = administrativos.data.concat(profesionales.data)
            console.log('usaurios', this.usuarios)
          },
          error => {
            this.openError('No se encontraron usuarios', 'Ok');
          }
        )
      },
      error => {
        this.openError('No se encontraron usuarios', 'Ok');
      }
    )
  }

  registrarTarea(f: NgForm) {
    this.loader = true;
    console.log('tarea', this.tarea);
    this._taskService.saveTask(this.tarea).subscribe(
      response => {
        this.loader = false;
        f.resetForm();
        this.mostrarExitoDialog('Nueva tarea registrada');
        this.location.back();
      },
      error => {
        this.loader = false;
        this.openError('Error al registrar tarea', 'Ok');
      }
    )

  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  volver() {
    this._router.navigate(['/administrar-combustible']);
  }

}
