    <mat-card>

        <section>
            <!-- <div class="row">
                <div class="col s5 m5 l3" style="padding: 0px; height: 90vh;border-right: 1px solid rgba(0, 0, 0, 0.12);">
                    <div class="section-1">

                        <div class="header-left">
                            <h3>Chats</h3>
                            <div style="flex-grow: 1;"></div>
                            <mat-icon class="icon-controls">settings</mat-icon>
                            &nbsp;
                            <mat-icon class="icon-controls">edit</mat-icon>
                        </div>

                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>Buscar contacto</mat-label>
                                <input matInput placeholder="Buscar contacto" #key (keyup)="buscarChat(key.value)">
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>

                            <mat-selection-list [multiple]="false">
                                <mat-list-option value="shoe" style="padding-top: 8px; padding-bottom: 8px;" *ngFor="let chat of chats" (click)="seleccionarChat(chat.receiver, chat.messages)">
                                    <div class="user-chat-container">

                                        <div class="user-chat-image-container">
                                            <div class="user-chat-image-container-div">
                                                <img [src]="url + chat.receiver.image" alt="">
                                            </div>
                                        </div>

                                        <div class="user-chat-text-container">
                                            <span>
                                                {{chat.receiver.name}} {{chat.receiver.lastnamepat}}
                                            </span>

                                            <p *ngIf="chat.messages.length == 0">
                                                Sin mensajes
                                            </p>
                                            <p *ngIf="chat.messages.length > 0">
                                                {{chat.messages[chat.messages.length -1].message}}... -
                                                {{chat.messages[chat.messages.length -1].timeAgo}}
                                            </p>
                                        </div>
                                    </div>
                                </mat-list-option>
                            </mat-selection-list>
                        </div>

                    </div>
                </div>
                <div class="col s7 m7 l9" style="padding: 0px; height: 90vh">
                    <div>
                        <div class="section-2">
                            <div class="header-chat">

                                <div class="header-chat-container" *ngIf="chatSelected.name != ''">

                                    <div class="header-chat-img-container">
                                        <div class="header-chat-img">
                                            <img [src]="url + chatSelected.image" alt="">
                                        </div>
                                    </div>

                                    <div class="header-chat-tex-container">
                                        <span>
                                            {{chatSelected.name}}
                                        </span>
                                        <p>
                                            {{chatSelected.message}}... -
                                                {{chatSelected.timeAgo}}
                                        </p>
                                    </div>
                                </div>

                                <div class="header-chat-container" *ngIf="chatSelected.name == ''">

                                    <div class="header-chat-img-container">
                                        Seleccionar un chat

                                    </div>

                                    <div class="header-chat-tex-container">
                                        <span>
                                        </span>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="chat-container">
                            <div class="chat-container-section">
                                <mat-card class="no-messages" *ngIf="messages.length == 0">No existen mensajes</mat-card>
                                <div *ngFor="let message of messages">

                                    <div class="chat-box" *ngIf="message.transmitter.id != userId">

                                        <div class="chat-box-emisor">
                                            <div class="chat-box-emisor-img">
                                                <img [src]="url + chatSelected.image" alt="">
                                            </div>
                                            <div class="chat-box-emisor-text-container">
                                                <div class="chat-box-emisor-text">
                                                    <p>
                                                        {{chatSelected.name}}
                                                    </p>
                                                    <div>
                                                        <p [matTooltip]="message.timeAgo">
                                                            {{message.message}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div></div>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="chat-box" *ngIf="message.transmitter.id == userId">

                                        <div class="chat-box-transmitter">
                                            <div class="chat-box-transmitter-img">
                                                <img [src]="url + message.transmitter.image" alt="">
                                            </div>
                                            <div class="chat-box-transmitter-text">
                                                <div class="chat-box-transmitter-text-container">
                                                    <p>
                                                        {{message.transmitter.name}} {{message.transmitter.lastnamepat}}
                                                    </p>
                                                    <div>
                                                        <p>
                                                            {{message.message}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div></div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <hr class="hr-separator">

                        <div class="chat-input-container">
                            <div class="chat-input-container-img">
                                    <img [src]="url + imageUser" alt="">
                            </div>
                            <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarMensaje(f)">

                                <div class="chat-input-container-text-field">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Envía un mensaje</mat-label>
                                        <input matInput placeholder="Envía un mensaje" name="message" required [(ngModel)]="mensaje">
                                    </mat-form-field>
                                </div>
                                <span class="icon">
                                    <button type="submit" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
                                        <mat-icon>send</mat-icon>
                                    </button>
                                </span>

                            </form>
                            <hr class="hr-separator-vertical">
                            <span class="icon">
                                <mat-icon class="icon-controls">add_photo_alternate</mat-icon>
                            </span>
                            <span class="icon">
                                <mat-icon class="icon-controls">attach_file</mat-icon>
                            </span>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="row">
                <div class="col s3" style="padding-right: 0px;">
                    <div class="pavso-content-contact">
                        <h4 style="margin-top: 0px;">Contactos</h4>
                        <form class="example-form">

                          <mat-selection-list [multiple]="false">
                            <div *ngFor="let administrador of administradores">
                              <mat-list-option *ngIf="administrador.id != userId" [value]="administrador.id" (click)="onSelectionChange(administrador)">
                                {{administrador.role.name}} | {{administrador.name}}
                              </mat-list-option>
                              <mat-divider></mat-divider>
                            </div>
                          </mat-selection-list>

                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Contacto</mat-label>
                              <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
                              <mat-icon matSuffix>person</mat-icon>
                              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChange($event)">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                  {{option.name}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field> -->
                            <br>
                            <!-- <mat-form-field appearance="outline">
                                <mat-label>Asunto</mat-label>
                                <input matInput placeholder="Asunto">
                                <mat-icon matSuffix>subject</mat-icon>

                            </mat-form-field> -->
                        </form>
                    </div>
                </div>
                <div class="col s9">
                    <div class="separator">
                        <h4 class="title-chat">Chat: {{personNameChat.name}}</h4>
                        <mat-divider></mat-divider>

                        <div class="chat-content" >
                            <!-- <div class="message-content" #scroll [scrollTop]="scroll.scrollHeight"> -->
                            <div class="message-content" #scroll>

                                <div class="content-not-found" *ngIf="messages.length == 0">
                                    <mat-card class="card-no-content">No existen mensajes</mat-card>
                                </div>

                                <ng-container *ngIf="messages.length > 0">
                                  <div>
                                      <mat-chip-list aria-label="Fish selection" class="sc-message" *ngFor="let message of messages" [ngClass]="(message.receiver == personNameChat.id)? 'sc-message message-transmitter': 'sc-message'">
                                          <div mat-card-avatar class="example-header-image"></div>
                                          <mat-chip [selected]="message.receiver == personNameChat.id">{{message.message}}</mat-chip>
                                          <!-- <div mat-card-avatar class="example-header-image"></div> -->
                                      </mat-chip-list>
                                  </div>

                                </ng-container>

                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="content-message-send">
                            <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarMensaje(f)" (keydown.enter)="$event.preventDefault()">

                                <div class="row">
                                    <div class="col s11">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Mensaje</mat-label>
                                            <textarea matInput placeholder="Mensaje" [(ngModel)]="message" name="message" (keydown.enter)="enviarMensaje(f)" required></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s1" style="padding-top: 18px;">
                                        <button mat-mini-fab color="primary" aria-label="Example icon button with a filter list icon" type="submit">
                                            <mat-icon>send</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </mat-card>
