import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AttentionService, PatientService, PrivateService, ServiceService, VehicleService, ProfessionalService, CategoryServService, EmployeeService, CompanyService, ParamedicService } from 'src/app/services';
import { Attention } from 'src/app/models/Attention';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatChipInputEvent } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

interface WayPoint {
  location: {
    lat: number,
    lng: number,
  };
  stopover: boolean;
}

declare var google;


@Component({
  selector: 'app-historial-editar',
  templateUrl: './historial-editar.component.html',
  styleUrls: ['./historial-editar.component.css']
})
export class HistorialEditarComponent implements OnInit {

  public origenControl: FormControl;
  public intermedioControl: FormControl;
  public intermedioControl1: FormControl;
  public intermedioControl2: FormControl;
  public intermedioControl3: FormControl;
  public intermedioControl4: FormControl;
  public intermedioControl5: FormControl;
  public intermedioControl6: FormControl;
  public intermedioControl7: FormControl;
  public intermedioControl8: FormControl;
  public intermedioControl9: FormControl;
  public intermedioControl10: FormControl;
  public intermedioControl11: FormControl;
  public intermedioControl12: FormControl;
  public intermedioControl13: FormControl;
  public intermedioControl14: FormControl;
  public intermedioControl15: FormControl;
  public intermedioControl16: FormControl;
  public intermedioControl17: FormControl;
  public intermedioControl18: FormControl;
  public intermedioControl19: FormControl;
  public intermedioControl20: FormControl;

  @ViewChild("origen")
  public origenElementRef: ElementRef;
  origenAddress: String = "";

  @ViewChild("intermedio")
  public intermedioElementRef: ElementRef;
  intermedioAddress: String = "";

  @ViewChild("intermedio1")
  public intermedioElementRef1: ElementRef;
  intermedioAddress1: String = "";

  @ViewChild("intermedio2")
  public intermedioElementRef2: ElementRef;
  intermedioAddress2: String = "";

  @ViewChild("intermedio3")
  public intermedioElementRef3: ElementRef;
  intermedioAddress3: String = "";

  @ViewChild("intermedio4")
  public intermedioElementRef4: ElementRef;
  intermedioAddress4: String = "";

  @ViewChild("intermedio5")
  public intermedioElementRef5: ElementRef;
  intermedioAddress5: String = "";

  @ViewChild("intermedio6")
  public intermedioElementRef6: ElementRef;
  intermedioAddress6: String = "";

  @ViewChild("intermedio7")
  public intermedioElementRef7: ElementRef;
  intermedioAddress7: String = "";

  @ViewChild("intermedio8")
  public intermedioElementRef8: ElementRef;
  intermedioAddress8: String = "";

  @ViewChild("intermedio9")
  public intermedioElementRef9: ElementRef;
  intermedioAddress9: String = "";

  @ViewChild("intermedio10")
  public intermedioElementRef10: ElementRef;
  intermedioAddress10: String = "";

  @ViewChild("intermedio11")
  public intermedioElementRef11: ElementRef;
  intermedioAddress11: String = "";

  @ViewChild("intermedio12")
  public intermedioElementRef12: ElementRef;
  intermedioAddress12: String = "";

  @ViewChild("intermedio13")
  public intermedioElementRef13: ElementRef;
  intermedioAddress13: String = "";

  @ViewChild("intermedio14")
  public intermedioElementRef14: ElementRef;
  intermedioAddress14: String = "";

  @ViewChild("intermedio15")
  public intermedioElementRef15: ElementRef;
  intermedioAddress15: String = "";

  @ViewChild("intermedio16")
  public intermedioElementRef16: ElementRef;
  intermedioAddress16: String = "";

  @ViewChild("intermedio17")
  public intermedioElementRef17: ElementRef;
  intermedioAddress17: String = "";

  @ViewChild("intermedio18")
  public intermedioElementRef18: ElementRef;
  intermedioAddress18: String = "";

  @ViewChild("intermedio19")
  public intermedioElementRef19: ElementRef;
  intermedioAddress19: String = "";

  @ViewChild("intermedio20")
  public intermedioElementRef20: ElementRef;
  intermedioAddress20: String = "";

  icons = {
    start: new google.maps.MarkerImage(
      // URL
      'http://174.138.45.249/wp-content/uploads/2020/09/icon-clinica.png',
      // (width,height)
      new google.maps.Size(71, 71),
      new google.maps.Point(0, 0),
      new google.maps.Point(17, 34),
      new google.maps.Size(25, 25)
    ),
    end: new google.maps.MarkerImage(
      // URL
      'http://174.138.45.249/wp-content/uploads/2020/09/icon-clinica.png',
      // (width,height)
      new google.maps.Size(71, 71),
      new google.maps.Point(0, 0),
      new google.maps.Point(17, 34),
      new google.maps.Size(25, 25)
    )
  }

  distance: number = 0;
  time: number = 0;

  map: any;
  directionsService = new google.maps.DirectionsService();
  directionsDisplay = new google.maps.DirectionsRenderer({
    draggable: true,
    polylineOptions: {
      strokeColor: "#0099cc"
    }
  });

  geocoder = new google.maps.Geocoder();

  // parque simon bolivar
  origin = { lat: 19.485166, lng: -99.134715 };
  // Parque la 93
  destination = { lat: 19.485166, lng: -99.134715 };

  wayPoints: WayPoint[] = [];

  intermediates = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];

  isBilling: boolean = false;
  pacientes: any = [];
  servicios: any = [];
  unidades: any = [];
  paramedics: any = [];
  profesionales: any = [];
  categorias: any = [];
  empresas: any = [];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  correos: any[] = [];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.correos.push({name: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(correo: any): void {
    const index = this.correos.indexOf(correo);

    if (index >= 0) {
      this.correos.splice(index, 1);
    }
  }


  texto : string = 'Wenceslau Braz - Cuidado com as cargas';
	lat: number = -23.8779431;
	lng: number = -49.8046873;
  zoom: number = 15;

  loader: boolean = false;
  atencion: Attention;
  atencionId: any;

  billing = {rfc: "", razon: "", cfdi: "", metodo: ""};

  fechaFormControl = new FormControl('', [
    Validators.required,
  ]);

  horaFormControl = new FormControl('', [
    Validators.required,
  ]);

  lugarPartidaFormControl = new FormControl('', [
    Validators.required,
  ]);

  paradaIntermediaFormControl = new FormControl('', [
    Validators.required,
  ]);

  detalleServicioFormControl = new FormControl('', [
    Validators.required,
  ]);

  correoNotificarFormControl = new FormControl('', [
    Validators.required,
  ]);

  rfcFormControl = new FormControl('', [
    Validators.required,
  ]);

  razonSocialFormControl = new FormControl('', [
    Validators.required,
  ]);

  cfdiFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  waypoints: any;
  intermedios: Array<any> = [];

  constructor(
    public location: Location,
    private _activatedRoute: ActivatedRoute,
    private _attentionService: AttentionService,
    private _patientService: PatientService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _privatedService: PrivateService,
    private _serviceService: ServiceService,
    private _vehicleService: VehicleService,
    private _professionalService: ProfessionalService,
    private _categoryService: CategoryServService,
    private _employeeSercice: EmployeeService,
    private _companyService: CompanyService,
    private _privateService: PrivateService,
    private _paramedicService: ParamedicService,
  ) {
    this.atencion = new Attention();

    this.origenControl = new FormControl();
    this.intermedioControl = new FormControl();
    this.intermedioControl1 = new FormControl();
    this.intermedioControl2 = new FormControl();
    this.intermedioControl3 = new FormControl();
    this.intermedioControl4 = new FormControl();
    this.intermedioControl5 = new FormControl();
    this.intermedioControl6 = new FormControl();
    this.intermedioControl7 = new FormControl();
    this.intermedioControl8 = new FormControl();
    this.intermedioControl9 = new FormControl();
    this.intermedioControl10 = new FormControl();
    this.intermedioControl11 = new FormControl();
    this.intermedioControl12 = new FormControl();
    this.intermedioControl13 = new FormControl();
    this.intermedioControl14 = new FormControl();
    this.intermedioControl15 = new FormControl();
    this.intermedioControl16 = new FormControl();
    this.intermedioControl17 = new FormControl();
    this.intermedioControl18 = new FormControl();
    this.intermedioControl19 = new FormControl();
    this.intermedioControl20 = new FormControl();
  }

  ngOnInit(): void {
    this.obtenerAtencion();

    // this.loadMap();
  }

  loadMap() {
    // create a new map by passing HTMLElement
    const mapEle: HTMLElement = document.getElementById('map');
    const indicatorsEle: HTMLElement = document.getElementById('indicators');
    // create map
    this.map = new google.maps.Map(mapEle, {
      center: this.origin,
      zoom: 12
    });

    this.directionsDisplay.setMap(this.map);
    this.directionsDisplay.setPanel(indicatorsEle);

    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      mapEle.classList.add('show-map');
      this.calculateRoute();
    });
  }

  private calculateRoute() {
    this.directionsService.route({
      origin: this.origin,
      destination: this.destination,
      waypoints: this.wayPoints,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
    }, (response, status)  => {
      if (status === google.maps.DirectionsStatus.OK) {
        var leg = response.routes[0].legs[0];

        // this.makeMarker(leg.start_location, this.icons.start, "title", this.map);
        // this.makeMarker(leg.end_location, this.icons.start, 'title', this.map);

        // new google.maps.DirectionsRenderer({
        //   map: this.map,
        //   directions: response,
        //   suppressMarkers: true
        // });

        this.directionsDisplay.addListener('directions_changed', () => {
          console.log('DIRECCIONES', this.directionsDisplay.getDirections());
        })

        this.directionsDisplay.setDirections(response);



        response.routes[0].legs.forEach(element => {
          this.distance += element.distance.value;
          this.time += element.duration.value;
        });

      } else {
        alert('Could not display directions due to: ' + status);
      }
    });
  }

  makeMarker(position, icon, title, map) {
    new google.maps.Marker({
        position: position,
        map: map,
        icon: icon,
        title: title
    });
  }

  volver(): void {
    this.location.back();
  }

  obtenerAtencion() {
    this._activatedRoute.params.subscribe(
      params => {
        this.atencionId = params['id'];

        this._attentionService.getAttention(this.atencionId).subscribe(
          response => {
            console.log('historial', response)
            this.waypoints = JSON.parse(response.data.waypoints);
            console.log('waypoints', this.waypoints);

            let puntosMedios = [];

            this.waypoints.forEach((point, index) => {
              console.log('index', index)
              if(index != 0 && index != this.waypoints.length -1) puntosMedios.push(point);
            });

            puntosMedios.forEach(point => {
              this.intermedios.push({location: point.location});
            })

            console.log('intermedios', this.intermedios);

            this.atencion.type_patient = response.data.type_patient;
            this.atencion.date = response.data.date;
            this.atencion.hour = response.data.hour;
            this.atencion.paramedic_id = response.data.paramedic.id;
            this.atencion.professionals_id = JSON.parse(response.data.professionals_id).professionals;
            this.atencion.detail = response.data.detail;
            this.atencion.aditional_service_id = JSON.parse(response.data.aditional_service).additionals;
            this.atencion.company_id = response.data.company_id;
            this.atencion.status = response.data.status;
            this.atencion.patient_id = response.data.patient.id;
            this.atencion.waypoints = response.data.waypoints;

            this.correos = JSON.parse(response.data.emails).correos;
            this.obtenerPacientes(this.atencion.type_patient, response.data.patient);
            this.obtenerServicios(response.data.service.id);
            this.obtenerUnidades(response.data.paramedic.vehicle[0].id);
            this.obtenerProfesionales();
            this.obtenerParamedicos();
            this.obtenerCategorias();
            this.obtenerEmpresas();
            console.log('WAYPOINTS', this.wayPoints)

            console.log('atencion', this.atencion);
          },
          error => {
            console.log('historial', error)

          }
        )
      }
    )
  }

  obtenerPacientes(tipo, patient) {

    if (tipo == 'privado') {
      this.pacientes = [];
      this.isBilling = true;
      this._privateService.getPrivates().subscribe(
        response => {
          console.log('PRIVATE PATIENTS', response);
          this.pacientes = response.data;
          this.atencion.patient_id = patient.id;
        },
        error => {
          console.log('ERROR PRIVATE', error);
        }
      )
    } else {
      this.isBilling = false;
      this.pacientes = [];

      this._employeeSercice.getEmployees().subscribe(
        response => {
          this.pacientes = response.data;
        },
        error => {
          console.log('ERROR EMPLOYEE', error);

        }
      )
    }

  }

  seleccionarEmpresa(id) {

    console.log('IDDD', id)

    this._employeeSercice.getEmployees().subscribe(
      response => {
        this.pacientes = response.data;
      },
      error => {
        console.log('ERROR EMPLOYEE', error)
      }
    )

  }

  seleccionarParamedico(id) {
    this._paramedicService.getParamedic(id).subscribe(
      response => {
        this.atencion.vehicle_id = response.data.vehicle_id;
      },
      error => {
        this.openError('Error al obtener paramedico', 'OK');
      }
    )
  }

  obtenerEmpresas() {
    this._companyService.getCompanies().subscribe(
      response => {
        this.empresas = response.data;
      },
      error => {
        console.log('ERROR', error);
      }
    )
  }

  obtenerServicios(id) {
    this._serviceService.getServices().subscribe(
      response => {
        this.servicios = response.data;
        this.atencion.service_id = id
      },
      error => {

      }
    )
  }

  obtenerUnidades(id) {
    this._vehicleService.getVehicles().subscribe(
      response => {
        this.unidades = response.data;
        this.atencion.vehicle_id = id;
      },
      error => {

      }
    )
  }

  obtenerParamedicos() {
    this._professionalService.getProfessionals().subscribe(
      response => {
        this.paramedics = response.data;
        let paramedicos = [];
        response.data.forEach(element => {
          if(element.type == 'paramedico') {
            paramedicos.push(element);
          }
        });
        this.paramedics = paramedicos;
      },
      error => {

      }
    );
  }

  obtenerProfesionales() {
    this._professionalService.getProfessionals().subscribe(
      response => {
        this.profesionales = response.data;
        let profesionales = [];
        response.data.forEach(element => {
          if(element.type != 'paramedico') {
            profesionales.push(element);
          }
        });
        this.profesionales = profesionales;
      },
      error => {

      }
    );
  }

  obtenerCategorias() {
    this._categoryService.getCategories().subscribe(
      response => {
        this.categorias = response.data;
      },
      error => {
        this.openError('No se encontraron categorias', 'ok');
      }
    )
  }

  editarAtencion(f: NgForm) {

    const payload = {
      type_patient: this.atencion.type_patient,
      date: this.atencion.date,
      hour: this.atencion.hour,
      aditional_service_id: JSON.stringify({additionals: this.atencion.aditional_service_id}),
      detail: this.atencion.detail,
      emails: JSON.stringify({correos: this.correos}),
      patient_id: this.atencion.patient_id,
      paramedic_id: this.atencion.paramedic_id,
      professionals_id: JSON.stringify({professionals: this.atencion.professionals_id}),
      vehicle_id: this.atencion.vehicle_id,
      service_id: this.atencion.service_id,
      company_id: this.atencion.company_id,
      status: this.atencion.status
    }


    this.loader = true;
    this._attentionService.updateAttention(this.atencionId, payload).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('Atencion actualizada')

      },
      error => {
        this.loader = false;
        this.openError('Error al editar atención', 'ok')

      }
    )
  }

  seleccionarTipo(event) {

    if (event.value == 'privado') {
      this.pacientes = [];
      this.isBilling = true;
      this._privateService.getPrivates().subscribe(
        response => {
          console.log('PRIVATE PATIENTS', response);
          this.pacientes = response.data;
        },
        error => {
          console.log('ERROR PRIVATE', error);
        }
      )
    } else {
      this.isBilling = false;
      this.pacientes = [];
    }

  }

  seleccionarPaciente(id) {
    if (this.atencion.type_patient == "privado") {
      this._privatedService.getPrivateByPatient(id).subscribe(
        response => {
          if(response.data.billing != null) {
            this.billing.rfc = response.data.billing.rfc;
            this.billing.razon = response.data.billing.business_name;
            this.billing.cfdi = response.data.billing.cfdi;
            this.billing.metodo = response.data.billing.payment_method;
          } else {
            this.billing.rfc = "";
            this.billing.razon = "";
            this.billing.cfdi = "";
            this.billing.metodo = "";
          }
        },
        error => {

        }
      )
    }
  }

  eliminarDestino() {}

  agregarDestino() {}

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
