export class Task {
  name: string;
  description: string;
  date: string;
  hour: string;
  status: string;
  user_id: string;
  priority: string;
  constructor(
  ){
    this.hour = '00:00';
  }
}
