import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { Email } from 'src/app/models/email';
import { EmailService } from 'src/app/services/email.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  sendEmail: Email;

  visible = true;
	selectable = true;
	removable = true;
	addOnBlur = true;
	readonly separatorKeysCodes: number[] = [ENTER, COMMA];
	correos: any[] = [];
  loaderSendEmail: boolean = false;

	add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		// Add our fruit
		if ((value || '').trim()) {
		this.correos.push({name: value.trim()});
		}

		// Reset the input value
		if (input) {
		input.value = '';
		}
	}

	remove(correo: any): void {
		const index = this.correos.indexOf(correo);

		if (index >= 0) {
		this.correos.splice(index, 1);
		}
  }

  deFormControl = new FormControl('', [
    Validators.required,
  ]);

  paraFormControl = new FormControl('', [
    Validators.required,
  ]);

  mensajeFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  constructor(
    public dialog: MatDialog,
    private _emailService: EmailService,
    private _snackBar: MatSnackBar
  ) {
    this.sendEmail = new Email();

  }

  ngOnInit(): void {
  }

  enviarEmail(f: NgForm) {
    this.loaderSendEmail = true;
		console.log('CORREOS',this.correos);
		if(this.correos.length == 0) {
			this.openError('Establecer al menos un correo', 'Ok');
		} else {
			this.sendEmail.para = this.correos;
			console.log('EMAIL OBJECT',this.sendEmail);
			this._emailService.enviarEmail(this.sendEmail).subscribe(
				response => {
					this.loaderSendEmail = false;
					this.mostrarExitoDialog('Correo enviado')
					f.resetForm();
					console.log(response);
					this.correos = [];
				},
				error => {
					this.loaderSendEmail = false;
					this.openError('Error al enviar correo', 'Ok')
				}
			);
		}
  }

  mostrarExitoDialog(message) {
		const dialogRef = this.dialog.open(SuccessComponent, {
			width: '300px',
			data: message
		})

		dialogRef.afterClosed().subscribe(result => {
			if(result) {
			}
		})
	}

	openError(message: string, action: string) {
		this._snackBar.open(message, action, {
      duration: 4000,
		});
	}

}
