import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Administrative } from 'src/app/models/administrative';
import { Configuration } from 'src/app/models/configuration';
import { UploadImage } from 'src/app/models/upload_image';
import { AdministrativeService, ConfigurationService, ServiceService, UserService } from 'src/app/services';
import { LocalStorageService } from 'src/app/services/localstorage.service';
import { LogService } from 'src/app/services/log.service';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { AddEmailComponent } from 'src/app/shareds/emails/add-email/add-email.component';
import { EditEmailComponent } from 'src/app/shareds/emails/edit-email/edit-email.component';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';
import { environment } from 'src/environments/environment';

export class Password {
  constructor(
    public current_password: string,
    public new_password: string,
    public new_password_confirm: string,
  ){}
}

class DataParameterCost {
  constructor(
    public name: any,
    public monto_base: any,
    public tasa_distancia_km: any,
    public indice_hora: any,
    public tipo_unidad: any,
    public horario: any,
    public tipo_servicio: any
  ){}
}

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css'],
  providers: [LogService, LocalStorageService]
})
export class ConfiguracionComponent implements OnInit {

  displayedColumns: string[] = ['delete', 'id', 'name', 'email', 'acciones'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns2: string[] = ['id', 'email', 'action', 'date'];
  dataSource2: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort2: MatSort;

  hide: boolean = true;
  hideNC: boolean = true;
  hideC: boolean = true;

  configuracion: Configuration;
  administrador: Administrative;
  password: Password;

  loaderUpdAdmin: boolean = false;
  loaderUpdDatos: boolean = false;
  loaderUpdKeys: boolean = false;
  loaderUpdPwd: boolean = false;

  sourceUrl = environment.sourceUrl;

  uploadImage: UploadImage;
  tempImage: any;

  userId: any;

  loader: boolean = true;

  displayedColumnsFac: string[] = ['nombre', 'monto_base', 'tasa_de_distancia', 'indice_hora', 'tipo_unidad', 'horario', 'tipo_servicio', 'acciones'];
  dataSourceFac: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorFac: MatPaginator;
  @ViewChild(MatSort) sortFac: MatSort;

  loaderFac: boolean = false;

  servicios: any[] = [];

  data: DataParameterCost;
  loaderReg: boolean;

  constructor(
    private _configurationService: ConfigurationService,
    private _administrativeService: AdministrativeService,
    private _localStorageService: LocalStorageService,
    private _logService: LogService,
    private _userService: UserService,
    private _snackBar: MatSnackBar,
    private _service: ServiceService,
    public dialog: MatDialog
  ) {
    this.configuracion = new Configuration();
    this.administrador = new Administrative();
    this.password = new Password('', '', '');
    this.uploadImage = new UploadImage();

    this.dataSource = new MatTableDataSource([{
      'delete': true, 'id': "23", 'name': "prueba", 'email': "prueba@email.com"
    }])
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;


    this.dataSourceFac = fillTable([], this.paginatorFac, this.sortFac);


    this.data = new DataParameterCost(null,null,null,null,null,null,null);
  }

  ngOnInit(): void {
    // this.obtenerConfiguracion();
    // this.obtenerAdministrador();
    // this.obtenerTiposCosto();
    // this.obtenerCorreosCorporativos();
    this.loadData();
  }

  loadData(): void {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentAdmin = JSON.parse(localStorage.getItem('currentAdmin'));

    const role = JSON.parse(localStorage.getItem('role'));

    this.tempImage = currentUser.image;
    this.userId = currentUser.id;

    this._administrativeService.getAdministrative(currentAdmin.id).subscribe(
      administrador => {
        console.log('ADMINISTRADOR', administrador);
        this.administrador.name = administrador.data.user.name;
        this.administrador.lastnamepat = administrador.data.user.lastnamepat;
        this.administrador.lastnamemat = administrador.data.user.lastnamemat;
        this.administrador.email = administrador.data.user.email;
        this.administrador.phone = administrador.data.user.phone;
        this.administrador.birth_date = administrador.data.user.birth_date;
        this.administrador.address = administrador.data.user.address;
        this.administrador.gender = administrador.data.user.gender;
        this.administrador.telephone = administrador.data.user.telephone;
        this.administrador.role_id = administrador.data.role.id;
        this.administrador.image = administrador.data.image;
        this.tempImage = currentUser.image;
        this.userId = administrador.data.user.id;
        this._configurationService.getConfiguration().subscribe(
          configuraciones => {
            console.log('CONFIGURACION', configuraciones)
            this.configuracion = configuraciones.data;
            this._service.getServices().subscribe(
              response => {
                this.loader = false;
                this.servicios = response.data;
                this._configurationService.getParameters().subscribe(
                  parameters => {

                    this.dataSourceFac = fillTable(parameters.data, this.paginatorFac, this.sortFac);

                    this.loaderFac = false;

                    this._configurationService.getEmails().subscribe(
                      emails => {
                        this.dataSource = fillTable(emails.data, this.paginator, this.sort);

                        this.loader = false;

                        this._logService.getLogs().subscribe(
                          logs => {

                            this.dataSource2 = fillTable(logs.data, this.paginator2, this.sort2)

                          },
                          error => {

                          }
                        )
                      },
                      error => {
                        this.openError('Error al obtener correos coorporativos', 'OK');
                        this.loader = false;
                      }
                    )
                  },
                  error => {
                    this.openError('Error al obtener tipos de costo', 'OK');
                    this.loader = false;
                  }
                )
              },
              error => {
                this.loader = false;
                this.openError('Error al obtener servicios', 'OK');
              }
            )
          },
          error => {
            this.openError('No encontro los datos de la empresa', 'Ok')
          }
        )
      },
      error => {
        this.openError('Error al obtener administrador', 'OK');
      }
    )

  }

  onFileSelected(): void {

  }

  buscarCorreo(event: Event) {
    this.dataSource = searchInTable(event, this.dataSource )

  }

  buscarLog(event: Event) {
    this.dataSource2 = searchInTable(event, this.dataSource2 )

  }

  editarCorreo() {
    const dialogRef = this.dialog.open(EditEmailComponent, {
      width: '300px',
      data: '¿Desea inactivar a este paciente privado?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      this.mostrarExitoDialog('Correo editado');

    })
  }

  agregarCorreo() {
    const dialogRef = this.dialog.open(AddEmailComponent, {
      width: '300px',
      data: '¿Desea inactivar a este paciente privado?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == "true") {
        this.mostrarExitoDialog('Correo agregado');
        this.obtenerCorreosCorporativos();
      }
    })
  }

  cambiarActivacion(element) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea inactivar a este paciente privado?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {
      }
    })
  }

  obtenerAdministrador(): void {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const role = JSON.parse(localStorage.getItem('role'));

    this.administrador.name = currentUser.name;
    this.administrador.lastnamepat = currentUser.lastnamepat;
    this.administrador.lastnamemat = currentUser.lastnamemat;
    this.administrador.email = currentUser.email;
    this.administrador.phone = currentUser.phone;
    this.administrador.birth_date = currentUser.birth_date;
    this.administrador.address = currentUser.address;
    this.administrador.gender = currentUser.gender;
    this.administrador.telephone = currentUser.telephone;
    this.administrador.role_id = role.id;
    this.administrador.image = currentUser.image;
    this.tempImage = currentUser.image;
    this.userId = currentUser.id;
  }

  obtenerConfiguracion(): void {
    this.loaderFac = true;
    this._configurationService.getConfiguration().subscribe(
      response => {
        console.log('CONFIGURACION', response)
        this.configuracion = response.data;
        this._service.getServices().subscribe(
          response => {
            this.loader = false;
            this.servicios = response.data;


          },
          error => {
            this.loader = false;
            this.openError('Error al obtener servicios', 'OK');
          }
        )
      },
      error => {
        this.openError('No encontro los datos de la empresa', 'Ok')
      }
    )
  }

  actualizarAdministrador(f: NgForm) {
    this.loaderUpdAdmin = true;
    const administrativeId = JSON.parse(localStorage.getItem('currentAdmin')).id
    console.log('admin', this.administrador)
    this._administrativeService.updateAdministrative(administrativeId, this.administrador).subscribe(
      response => {
        this._localStorageService.establecerNombreCompleto(this.administrador.name, this.administrador.lastnamepat, this.administrador.lastnamemat);
        this.mostrarExitoDialog('Administrador actualizado');
        this.loaderUpdAdmin = false;
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      error => {
        this.openError('Error al actualizar administrador', 'Ok');
        this.loaderUpdAdmin = false;
      }
    )
  }

  actualizarEmpresa(f: NgForm) {
    this.loaderUpdDatos = true;
    this._configurationService.updateConfiguration(this.configuracion).subscribe(
      response => {
        this.mostrarExitoDialog('Datos de la empresa actualizado');
        this.loaderUpdDatos = false;
      },
      error => {
        this.openError('Error al actualizar datos de la empresa', 'Ok');
        this.loaderUpdDatos = false;
      }
    )
  }

  actualizarLlaves(f: NgForm) {
    this.loaderUpdKeys = true;
    this._configurationService.updateConfiguration(this.configuracion).subscribe(
      response => {
        this.mostrarExitoDialog('Llaves de la empresa actualizado');
        this.loaderUpdKeys = false;
      },
      error => {
        this.openError('Error al actualizar las llaves de la empresa', 'Ok');
        this.loaderUpdKeys = false;
      }
    )
  }

  actualizarContrasenia(f: NgForm) {
    this.loaderUpdPwd = true;
    const userId = JSON.parse(localStorage.getItem('currentUser')).id;
    this._userService.updatePassword(userId, this.password).subscribe(
      response => {
        this.mostrarExitoDialog('Contraseña actualizada');
        this.loaderUpdPwd = false;
        f.resetForm();
      },
      error => {
        this.openError('Error al actualizar contraseña', 'Ok');
        this.loaderUpdPwd = false;
      }
    )
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.uploadImage.image = reader.result;
      this.tempImage = reader.result;
    };
  }

  guardarImagen() {

    this._userService.uploadImage(this.userId, this.uploadImage).subscribe(
      response => {
        console.log('response', response);
        console.log('response', response.data.image)
        this.tempImage = response.data.image;
        this.uploadImage.image = null;
        this.administrador.image = response.data.image;

        this.mostrarExitoDialog('Imagen actualizada')

        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        currentUser.image = response.data.image;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));

      },
      error => {
        this.openError('Error al subir imagen','Ok');
      }
    )
  }

  obtenerCorreosCorporativos(): void {
    this.loader = true;
    this._configurationService.getEmails().subscribe(
      response => {
        this.dataSource = fillTable(response.data, this.paginator, this.sort);

        this.loader = false;
      },
      error => {
        this.openError('Error al obtener correos coorporativos', 'OK');
        this.loader = false;
      }
    )
  }

  eliminarCorreo(id): void {
    this._configurationService.deleteEmail(id).subscribe(
      response => {
        this.mostrarExitoDialog("correo coorporativo Eliminado");
        this.obtenerCorreosCorporativos();
      },
      error => {
        this.openError("Error al eliminar correo coorporativo", "OK");
      }
    )
  }

  obtenerTiposCosto(): void {
    this.loader = true;
    this._configurationService.getParameters().subscribe(
      response => {
        this.dataSourceFac = fillTable(response.data, this.paginatorFac, this.sortFac);

        this.loader = false;
      },
      error => {
        this.openError('Error al obtener tipos de costo', 'OK');
        this.loader = false;
      }
    )
  }

  registrarTipoCosto(f: NgForm): void {
    this.loaderReg = true;
    this._configurationService.saveParameter(this.data).subscribe(
      response => {
        this.mostrarExitoDialog("Tipo de Costo Registrado");
        this.obtenerTiposCosto();
        this.loaderReg = false;
        f.resetForm();
      },
      error => {
        this.openError("Error al registrar tipo de costo", "OK");
        this.loaderReg = false;
      }
    )
  }

  eliminarParametro(id): void {
    this._configurationService.deleteParameter(id).subscribe(
      response => {
        this.mostrarExitoDialog("Tipo de costo Eliminado");
        this.obtenerTiposCosto();
      },
      error => {
        this.openError("Eliminar tipo de costo", "OK");
      }
    )
  }

  cancelar() {
    this.tempImage = this.administrador.image;
    this.uploadImage.image = null;
  }

  mostrarExitoDialog(message) {
		const dialogRef = this.dialog.open(SuccessComponent, {
			width: '300px',
			data: message
		})

		dialogRef.afterClosed().subscribe(result => {
			if(result) {
			}
		})
	}

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  applyFilter(event: Event) {
    this.dataSource = searchInTable(event, this.dataSource )

  }

}
