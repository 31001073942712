import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { CategoryAditionalService } from 'src/app/models/CategoryAditionalService';
import { CategoryServService } from 'src/app/services';
import { Validators, FormControl, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { Router } from '@angular/router';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-categorias-nuevo',
  templateUrl: './categorias-nuevo.component.html',
  styleUrls: ['./categorias-nuevo.component.css']
})
export class CategoriasNuevoComponent implements OnInit {

  categoria: CategoryAditionalService;
  loader: boolean = false;

  nombreFormControl = new FormControl('', [
    Validators.required,
  ]);

  descripcionFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public location: Location,
    public dialog: MatDialog,
    private _router: Router,
    private _categoryService: CategoryServService
  ) {
    this.categoria = new CategoryAditionalService();
  }

  ngOnInit(): void {
  }

  registrarCategoriaServicioAdicional(f: NgForm) {
    this.loader = true;

    this._categoryService.saveCategory(this.categoria).subscribe(
      response => {
        this.mostrarExitoDialog('Categoria adicional registrada');
        this.loader = false;
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.mostrarErrorDialog('Error al registrar una categoria adicional');
        this.loader = false;
      }
    )

  }

  volver() {
    this._router.navigate(['/gestion-de-categoria-de-servicios-adicionales']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

}
