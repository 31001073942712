import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationComponent } from './dialogs/confirmation/confirmation.component';
import { NewTaskComponent } from './dialogs/tasks/new-task/new-task.component';
import { NewServiceComponent } from './dialogs/calendars/new-service/new-service.component';
import { AgmCoreModule } from '@agm/core';
import { SuccessComponent } from './dialogs/success/success.component';
import { ShowImageComponent } from './dialogs/show-image/show-image.component';
import { ErrorComponent } from './dialogs/error/error.component';
import { EditTaskComponent } from './dialogs/tasks/edit-task/edit-task.component';
import { PipesModule } from '../pipes/pipes.module';
import { DetailServiceComponent } from './dialogs/calendars/detail-service/detail-service.component';
import { AddEmailComponent } from './emails/add-email/add-email.component';
import { EditEmailComponent } from './emails/edit-email/edit-email.component';
import { UploadExcelEmployeeComponent } from './dialogs/upload-excel-employee/upload-excel-employee.component';
import { AgmDirectionModule } from "agm-direction";
import { MantenimientoDialogFormComponent } from "./dialogs/app/unidades/manteniento/mantenimiento-form/mantenimiento-form.component";
import { VexSectionTitleComponent } from "./components/vex-section-title/vex-section-title.component";
import { directives } from "./directives";

@NgModule({
    imports: [
        BrowserModule,
        RouterModule,
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        AgmCoreModule.forRoot({

            apiKey: 'AIzaSyAvr3i8Jk1d19Ul7CwozyzpWyyUGuBJiqY',
            libraries: ["places"]
        }),
        AgmDirectionModule,
        PipesModule
    ],
    declarations: [
        FooterComponent,
        ConfirmationComponent,
        NewTaskComponent,
        NewServiceComponent,
        SuccessComponent,
        ShowImageComponent,
        ErrorComponent,
        EditTaskComponent,
        DetailServiceComponent,
        AddEmailComponent,
        EditEmailComponent,
        UploadExcelEmployeeComponent,
        MantenimientoDialogFormComponent,
        VexSectionTitleComponent,
        ...directives
    ],
    exports: [
        FooterComponent,
        ConfirmationComponent,
        NewTaskComponent,
        NewServiceComponent,
        SuccessComponent,
        AddEmailComponent,
        EditEmailComponent,
        MantenimientoDialogFormComponent,
        VexSectionTitleComponent,
        ...directives
    ],
    entryComponents: [
        ConfirmationComponent,
        NewTaskComponent,
        NewServiceComponent,
        SuccessComponent,
        AddEmailComponent,
        EditEmailComponent,
        MantenimientoDialogFormComponent,
        VexSectionTitleComponent
    ]
})

export class SharedModule {

}
