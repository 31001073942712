import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService, EmployeeService } from 'src/app/services';
import { Location } from '@angular/common';
import { Employee } from 'src/app/models/employee';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-colaborador-editar',
  templateUrl: './colaborador-editar.component.html',
  styleUrls: ['./colaborador-editar.component.css']
})
export class ColaboradorEditarComponent implements OnInit {

  empleado: Employee;
  empleadoId: number;
  empresas: any = [];
  loader: boolean = false;

  sourceUrl = environment.sourceUrl;

  tempImage: any;

  nombresFormControl = new FormControl('', [
    Validators.required,
  ])

  apePaternoFormControl = new FormControl('', [
    Validators.required,
  ])

  apeMaternoFormControl = new FormControl('', [
    Validators.required,
  ]);

  celFormControl = new FormControl('', [
    Validators.required,
  ])

  telFormControl = new FormControl('', [
    Validators.required,
  ])

  direccionFormControl = new FormControl('', [
    Validators.required,
  ])

  dateBirthFormControl = new FormControl('', [
    Validators.required,
  ])

  jobFormControl = new FormControl('', [
    Validators.required,
  ])

  affiliationNumberFormControl = new FormControl('', [
    Validators.required,
  ])

  firstNameFormControl = new FormControl('', [
    Validators.required,
  ])

  firstEmailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ])

  firstTelFormControl = new FormControl('', [
    Validators.required,
  ])

  secondNameFormControl = new FormControl('', [
    Validators.required,
  ])

  secondEmailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ])

  secondTelFormControl = new FormControl('', [
    Validators.required,
  ])

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public activatedRoute: ActivatedRoute,
    private _companyService: CompanyService,
    private _employeeService: EmployeeService,
    public location: Location,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _router: Router
    ) {
    this.empleado = new Employee();
  }

  ngOnInit(): void {
    this.obtenerEmpresa();
    this.obtenerEmpresas();
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.tempImage = reader.result;
    };
  }

  cancelar() {
    this.empleado.image = null;
  }

  obtenerEmpresas() {
    this._companyService.getCompanies().subscribe(
      response => {
        this.empresas = response.data;
      },
      error => {
        this.mostrarErrorDialog('No se encontraron empresas');
      }
    )
  }

  obtenerEmpresa() {
    this.activatedRoute.params.subscribe( params => {

      let id = params['id'];
      this.empleadoId = id;

      this._employeeService.getEmployee(id).subscribe(
        response => {
          console.log(response)

          this.empleado.name = response.data.patient.user.name;
          this.empleado.lastnamepat = response.data.patient.user.lastnamepat;
          this.empleado.lastnamemat = response.data.patient.user.lastnamemat;
          this.empleado.birth_date = response.data.patient.user.birth_date;
          this.empleado.gender = response.data.patient.user.gender;
          this.empleado.address = response.data.patient.user.address;
          this.empleado.phone = response.data.patient.user.phone;
          this.empleado.telephone = response.data.patient.user.telephone;
          this.empleado.job = response.data.job;
          this.empleado.company_id = response.data.company.id;
          this.empleado.affiliation_number = response.data.affiliation_number;
          this.empleado.image = response.data.patient.user.image;

          if(response.data.familiars.length > 0) {
            this.empleado.name_first_familiar = response.data.familiars[0]["name"];
            this.empleado.email_first_familiar = response.data.familiars[0]["email"];
            this.empleado.phone_first_familiar = response.data.familiars[0]["phone"];
            this.empleado.name_second_familiar = response.data.familiars[1]["name"];
            this.empleado.email_second_familiar = response.data.familiars[1]["email"];
            this.empleado.phone_second_familiar = response.data.familiars[1]["phone"];
          }
        },
        error => {
          console.log(error)
        }
      )

    });
  }

  editarEmpleado(f: NgForm) {
    this.loader = true;

    if(this.tempImage) {
      this.empleado.image = this.tempImage;
    }

    console.log(this.empleado);

    this._employeeService.updateEmployee(this.empleadoId, this.empleado).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('AFILIADO ACTUALIZADO');
        setTimeout(() => {
          this._router.navigate(['/usuario/paciente/empresas']);
        }, 1500);
      },
      error => {
        this.loader = false;
        this.openError('Error al actualizar afiliado', 'Ok');
      }
    )

  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  volver() {
    this._router.navigate(['/usuario/paciente/empresas']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }
}
