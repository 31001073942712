import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
    selector: 'facturacion',
    templateUrl: './facturacion.component.html',
    styleUrls: ['./facturacion.component.scss']
})
export class FacturacionComponent implements OnInit, OnDestroy {

    constructor() {}

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }
}
