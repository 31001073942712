import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { ReportService } from 'src/app/services/report.service';

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	yaxis: ApexYAxis;
	xaxis: ApexXAxis;
	fill: ApexFill;
	title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor(
    private _reportService: ReportService
  ) { 
    this.chartOptions = {
		series: [
			{
			name: "Atenciones por mes",
			data: [4, 3, 4, 10, 4, 3, 3]
			}
		],
		chart: {
			height: 350,
			type: "bar"
		},
		plotOptions: {
			bar: {
			dataLabels: {
				position: "top" // top, center, bottom
			}
			}
		},
		dataLabels: {
			enabled: true,
			formatter: function(val) {
			return val + "";
			},
			offsetY: -20,
			style: {
			fontSize: "12px",
			colors: ["#304758"]
			}
		},
	
		xaxis: {
			categories: [
			"Lun",
			"Mar",
			"Mie",
			"Jue",
			"Vie",
			"Sab",
			"Dom",
			],
			position: "top",
			labels: {
			offsetY: -18
			},
			axisBorder: {
			show: false
			},
			axisTicks: {
			show: false
			},
			
			tooltip: {
			enabled: true,
			offsetY: -35
			}
		},
		
		yaxis: {
			axisBorder: {
			show: false
			},
			axisTicks: {
			show: false
			},
			labels: {
			show: false,
			formatter: function(val) {
				return val + " Atenciones";
			}
			}
		},
		title: {
			text: "Atenciones realizadas",
			floating: false,
			offsetY: 330,
			align: "center",
			style: {
			color: "#444"
			}
		}
	};
  }

  ngOnInit(): void {
    this.obtenerReportes();
  }

  obtenerReportes() {
    let now = new Date();
    this._reportService.getReports({year: now.getFullYear()}).subscribe(
      response => {
        
        var chartAttention = new ApexCharts(document.querySelector("#chartAttention"), this.chartOptions);
		chartAttention.render();
		chartAttention.updateOptions( 
			{
				series: [
					response.data.attentions_chart.series
				],
				chart: {
					height: 300,
					type: "bar"
				},
				plotOptions: {
					bar: {
					dataLabels: {
						position: "top" // top, center, bottom
					}
					}
				},
				dataLabels: {
					enabled: true,
					formatter: function(val) {
					return val + "";
					},
					offsetY: -20,
					style: {
					fontSize: "12px",
					colors: ["#304758"]
					}
				},
			
				xaxis: {
					categories: response.data.attentions_chart.categories,
					position: "top",
					labels: {
					offsetY: -18
					},
					axisBorder: {
					show: false
					},
					axisTicks: {
					show: false
					},
					
					tooltip: {
					enabled: true,
					offsetY: -35
					}
				},
				
				yaxis: {
					axisBorder: {
					show: false
					},
					axisTicks: {
					show: false
					},
					labels: {
					show: false,
					formatter: function(val) {
						return val + " Atenciones";
					}
					}
				},
				title: {
					text: "Atenciones realizadas Por Mes",
					floating: false,
					offsetY: 280,
					align: "center",
					style: {
					color: "#444"
					}
				}
			}
		)

		var chartAttention = new ApexCharts(document.querySelector("#chartPatient"), this.chartOptions);
		chartAttention.render();
		chartAttention.updateOptions( 
			{
				series: [
					response.data.patients_chart.series
				],
				chart: {
					height: 300,
					type: "bar"
				},
				plotOptions: {
					bar: {
					dataLabels: {
						position: "top" // top, center, bottom
					}
					}
				},
				dataLabels: {
					enabled: true,
					formatter: function(val) {
					return val + "";
					},
					offsetY: -20,
					style: {
					fontSize: "12px",
					colors: ["#304758"]
					}
				},
			
				xaxis: {
					categories: response.data.patients_chart.categories,
					position: "top",
					labels: {
					offsetY: -18
					},
					axisBorder: {
					show: false
					},
					axisTicks: {
					show: false
					},
					
					tooltip: {
					enabled: true,
					offsetY: -35
					}
				},
				
				yaxis: {
					axisBorder: {
					show: false
					},
					axisTicks: {
					show: false
					},
					labels: {
					show: false,
					formatter: function(val) {
						return val + " Atenciones";
					}
					}
				},
				title: {
					text: "Pacientes nuevos Por Mes",
					floating: false,
					offsetY: 280,
					align: "center",
					style: {
					color: "#444"
					}
				}
			}
		)

		var chartAttention = new ApexCharts(document.querySelector("#chartBussines"), this.chartOptions);
		chartAttention.render();
		chartAttention.updateOptions( 
			{
				series: [
					response.data.companies_chart.series
				],
				chart: {
					height: 300,
					type: "bar"
				},
				plotOptions: {
					bar: {
					dataLabels: {
						position: "top" // top, center, bottom
					}
					}
				},
				dataLabels: {
					enabled: true,
					formatter: function(val) {
					return val + "";
					},
					offsetY: -20,
					style: {
					fontSize: "12px",
					colors: ["#304758"]
					}
				},
			
				xaxis: {
					categories: response.data.companies_chart.categories,
					position: "top",
					labels: {
					offsetY: -18
					},
					axisBorder: {
					show: false
					},
					axisTicks: {
					show: false
					},
					
					tooltip: {
					enabled: true,
					offsetY: -35
					}
				},
				
				yaxis: {
					axisBorder: {
					show: false
					},
					axisTicks: {
					show: false
					},
					labels: {
					show: false,
					formatter: function(val) {
						return val + " Atenciones";
					}
					}
				},
				title: {
					text: "Compañías Nuevas Por Mes",
					floating: false,
					offsetY: 280,
					align: "center",
					style: {
					color: "#444"
					}
				}
			}
		)

		var chartAttention = new ApexCharts(document.querySelector("#chartMaintenances"), this.chartOptions);
		chartAttention.render();
		chartAttention.updateOptions( 
			{
				series: [
					response.data.maintenances_chart.series
				],
				chart: {
					height: 300,
					type: "bar"
				},
				plotOptions: {
					bar: {
					dataLabels: {
						position: "top" // top, center, bottom
					}
					}
				},
				dataLabels: {
					enabled: true,
					formatter: function(val) {
					return val + "";
					},
					offsetY: -20,
					style: {
					fontSize: "12px",
					colors: ["#304758"]
					}
				},
			
				xaxis: {
					categories: response.data.maintenances_chart.categories,
					position: "top",
					labels: {
					offsetY: -18
					},
					axisBorder: {
					show: false
					},
					axisTicks: {
					show: false
					},
					
					tooltip: {
					enabled: true,
					offsetY: -35
					}
				},
				
				yaxis: {
					axisBorder: {
					show: false
					},
					axisTicks: {
					show: false
					},
					labels: {
					show: false,
					formatter: function(val) {
						return val + " Atenciones";
					}
					}
				},
				title: {
					text: "Mantenimientos Nuevos Por Mes",
					floating: false,
					offsetY: 280,
					align: "center",
					style: {
					color: "#444"
					}
				}
			}
		)

		var chartAttention = new ApexCharts(document.querySelector("#chartGasoline"), this.chartOptions);
		chartAttention.render();
		chartAttention.updateOptions( 
			{
				series: [
					response.data.gasolines_chart.series
				],
				chart: {
					height: 300,
					type: "bar"
				},
				plotOptions: {
					bar: {
					dataLabels: {
						position: "top" // top, center, bottom
					}
					}
				},
				dataLabels: {
					enabled: true,
					formatter: function(val) {
					return val + "";
					},
					offsetY: -20,
					style: {
					fontSize: "12px",
					colors: ["#304758"]
					}
				},
			
				xaxis: {
					categories: response.data.gasolines_chart.categories,
					position: "top",
					labels: {
					offsetY: -18
					},
					axisBorder: {
					show: false
					},
					axisTicks: {
					show: false
					},
					
					tooltip: {
					enabled: true,
					offsetY: -35
					}
				},
				
				yaxis: {
					axisBorder: {
					show: false
					},
					axisTicks: {
					show: false
					},
					labels: {
					show: false,
					formatter: function(val) {
						return val + " Atenciones";
					}
					}
				},
				title: {
					text: "Combustible Consumido Por Mes",
					floating: false,
					offsetY: 280,
					align: "center",
					style: {
					color: "#444"
					}
				}
			}
		)
      }
    )
  }

}
