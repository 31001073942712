<section>
    <vex-section-title title="ADMINISTRACIÓN" subtitle="AYUDA"></vex-section-title>


    <div class="vex_soluciones_content">

            <mat-tab-group>
                <mat-tab label="Todos">
                    <br>
                    <mat-accordion>

                        <mat-expansion-panel *ngFor="let soporte of soportes">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                {{soporte.question}}
                                </mat-panel-title>
                                <mat-panel-description>

                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <p>
                                {{soporte.answer}}
                            </p>
                            <button mat-button color="warn" (click)="eliminarSoporte(soporte.identifier)">Eliminar</button>

                        </mat-expansion-panel>

                    </mat-accordion>

                </mat-tab>
                <mat-tab [label]="categoria.name" *ngFor="let categoria of categorias">
                    <br>
                    <mat-accordion>

                        <mat-expansion-panel *ngFor="let soporte of categoria.supports">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                {{soporte.question}}
                                </mat-panel-title>
                                <mat-panel-description>

                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <p>{{soporte.answer}}</p>

                        </mat-expansion-panel>

                    </mat-accordion>

                </mat-tab>

            </mat-tab-group>

    </div>

    <br>
    <br>
    <div class="vex_soluciones_tiles">
        <span class="vex_soluciones_title">ADMINISTRACIÓN</span>
        <h2 class="vex_soluciones_subtitle">SOPORTE</h2>
    </div>

    <div class="vex_soluciones_content">
        <mat-card class="mat-elevation-z0">
            <form #createSupport="ngForm" ngNativeValidate (ngSubmit)="registrarSoporte(createSupport)" (keydown.enter)="$event.preventDefault()">

                <div class="row">
                    <div class="col s12">
                        <mat-form-field appearance="outline">
                            <mat-label>Categoria de soporte</mat-label>
                            <mat-select [(ngModel)]="soporte.category_id" required name="categoria">
                                <mat-option *ngFor="let categoria of categorias" [value]="categoria.identifier"> {{categoria.name}} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col s12">
                        <mat-form-field appearance="outline">
                            <mat-label>Pregunta de soporte</mat-label>
                            <input matInput placeholder="Pregunta de soporte" [(ngModel)]="soporte.question" required name="question">
                        </mat-form-field>
                    </div>
                    <div class="col s12">
                        <mat-form-field appearance="outline">
                            <mat-label>Respuesta</mat-label>
                            <textarea matInput placeholder="Respuesta" [(ngModel)]="soporte.answer" required name="answer"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="col s12">

                        <mat-spinner diameter="30" *ngIf="loaderSupport"></mat-spinner>

                        <button mat-raised-button color="primary" type="submit" *ngIf="!loaderSupport">Registrar</button>

                    </div>
                </div>

            </form>
        </mat-card>
    </div>
</section>
