<section>

    <vex-section-title title="ADMINISTRACIÓN" subtitle="EDITAR ATENCIÓN"></vex-section-title>

    <div class="vex_soluciones_content">
        <form #f="ngForm" ngNativeValidate (ngSubmit)="editarAtencion(f)" (keydown.enter)="$event.preventDefault()">
            <mat-card>

                <div class="row">

                    <div class="col s12 m5 l5 vex_soluciones_colum">
                        <h3>Datos de la atención</h3>
                        <mat-form-field>
                          <mat-label>Estado</mat-label>
                          <mat-select name="status" required [(ngModel)]="atencion.status" (selectionChange)="seleccionarTipo($event)">
                              <mat-option value="reservado">
                                Reservado
                              </mat-option>
                              <mat-option value="en_progreso">
                                En progreso
                              </mat-option>
                              <mat-option value="cancelado">
                                Cancelado
                              </mat-option>
                              <mat-option value="finalizado">
                                Finalizado
                              </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Tipo de paciente</mat-label>
                            <mat-select name="tipo_paciente" required [(ngModel)]="atencion.type_patient" (selectionChange)="seleccionarTipo($event)">
                                <mat-option value="privado">
                                    Privado
                                </mat-option>
                                <mat-option value="empresa">
                                    Empresa
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="this.atencion.type_patient == 'empresa'">
                            <mat-label>Empresa</mat-label>
                            <mat-select name="empresa" [(ngModel)]="atencion.company_id" required (selectionChange)="seleccionarEmpresa($event.value)">
                                <mat-option *ngFor="let empresa of empresas" [value]="empresa.identifier">
                                    {{empresa.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Paciente</mat-label>
                            <mat-select name="paciente" [(ngModel)]="atencion.patient_id" required (selectionChange)="seleccionarPaciente($event.value)">
                                <mat-option *ngFor="let paciente of pacientes" [value]="paciente.identifier">
                                    {{paciente.patient.user.name}} {{paciente.patient.user.lastnamepat}} {{paciente.patient.user.lastnamemat}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Fecha</mat-label>
                            <input [formControl]="fechaFormControl" [errorStateMatcher]="matcher" name="fecha" (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="atencion.date" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="fechaFormControl.hasError('required')">
                                Fecha <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <input name="hora" type="time" matInput placeholder="Hora" [(ngModel)]="atencion.hour" required>

                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Servicio</mat-label>
                            <mat-select name="servicio" [(ngModel)]="atencion.service_id" required>
                                <mat-option *ngFor="let servicio of servicios" [value]="servicio.identifier">
                                    {{servicio.name}}
                                </mat-option>

                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Paramédico</mat-label>
                            <mat-select name="paramedic" [(ngModel)]="atencion.paramedic_id" (selectionChange)="seleccionarParamedico($event.value)" required>
                                <mat-option *ngFor="let paramedic of paramedics" [value]="paramedic.identifier">
                                    {{paramedic.user.name}} {{paramedic.user.lastnamepat}} {{paramedic.user.lastnamemat}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Unidad Asignada</mat-label>
                            <mat-select name="unidad_asignada" [(ngModel)]="atencion.vehicle_id" required disabled>
                                <mat-option *ngFor="let unidad of unidades" [value]="unidad.id">
                                    {{unidad.brand}} {{unidad.model}} {{unidad.color}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Profesional</mat-label>
                            <mat-select name="profesional" [(ngModel)]="atencion.professionals_id" required multiple>
                                <mat-option *ngFor="let profesional of profesionales" [value]="profesional.identifier">
                                    {{profesional.user.name}} {{profesional.user.lastnamepat}} - {{profesional.type}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>¿Requiere de servicio adicional?</mat-label>
                            <mat-select name="aditional_service_id" required multiple [(ngModel)]="atencion.aditional_service_id">
                                <div *ngFor="let aditionalService of categorias">
                                    <div *ngIf="aditionalService.servicios.length == 0">
                                        <mat-option [value]="aditionalService.name">
                                            {{aditionalService.name}}
                                        </mat-option>
                                    </div>
                                    <div *ngIf="aditionalService.servicios.length > 0">
                                        <mat-optgroup [label]="aditionalService.name">
                                            <mat-option [value]="serv.name" *ngFor="let serv of aditionalService.servicios">
                                                {{serv.name}}
                                            </mat-option>

                                        </mat-optgroup>
                                    </div>
                                </div>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <mat-label>Detalle de servicio</mat-label>
                            <textarea [formControl]="detalleServicioFormControl" [errorStateMatcher]="matcher" name="detalle_de_servicio" matInput placeholder="Detalle de servicio" [(ngModel)]="atencion.detail" required></textarea>
                            <mat-error *ngIf="detalleServicioFormControl.hasError('required')">
                                Detalle de servicio <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-chip-list #chipList aria-label="Fruit selection">
                                <mat-chip *ngFor="let correo of correos" [selectable]="selectable"
                                    [removable]="removable" (removed)="remove(correo)">
                                    {{correo.name}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="Correos a notificar"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    (matChipInputTokenEnd)="add($event)"
                                    [(ngModel)]="atencion.emails"
                                    name="correos">
                            </mat-chip-list>
                        </mat-form-field>

                    </div>
                    <div class="col s12 m7 l7">
                      <agm-map zoom="15" [latitude]="lat" [longitude]="lng">
                        <agm-direction [origin]="waypoints[0].location" [waypoints]="intermedios" [destination]="waypoints[waypoints.length - 1].location">
                        </agm-direction>
                      </agm-map>
                        <!-- <div id="map" style="width: 100%; height: 820px"></div> -->
                        <!-- <div class="row">
                            <div class="col s6">
                                <h4>Distancia Estimada del Viaje:</h4>
                                <span class="info-data">{{distance/1000}} km</span>
                            </div>
                            <div class="col s6">
                                <h4>Tiempo Estimado del Viaje:</h4>
                                <span class="info-data">{{time | horasMinutos}} min</span>
                            </div>
                        </div>

                        <br>
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Indicaciones
                                </mat-panel-title>
                                <mat-panel-description>
                                    Detalles de la ruta del paramédico.
                                </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div id="indicators"></div>
                            </mat-expansion-panel>
                        </mat-accordion> -->

                        <!-- <agm-map [latitude]="lat" [zoom]="zoom" [longitude]="lng">
                            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                        </agm-map> -->

                    </div>

                </div>

                <div class="row">
                    <div class="col s12" *ngIf="!loader">
                        <button mat-raised-button (click)="volver()" type="button">{{buttonName.BACK}}</button>
                        &nbsp;
                        <button mat-raised-button color="primary" type="submit">{{buttonName.UPDATE}}</button>
                    </div>
                    <div class="col s12" *ngIf="loader">
                        <mat-spinner diameter="40"></mat-spinner>
                    </div>
                </div>

            </mat-card>

        </form>
    </div>
</section>
