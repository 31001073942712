<section>
    <vex-section-title title="ADMINISTRACIÓN" subtitle="EDITAR COMBUSTIBLE"></vex-section-title>

    <div class="vex_soluciones_content">
        <form #f="ngForm" ngNativeValidate (ngSubmit)="editarCombustible(f)" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col s12 m6 l4">

                    <mat-card>
                        <h3>Datos Generales</h3>
                        <mat-form-field appearance="legacy">
                            <mat-label>Vehiculo</mat-label>
                            <mat-select name="vehiculo" [(ngModel)]="gasoline.vehicle_id" required>
                                <mat-option *ngFor="let vehiculo of vehiculos" [value]="vehiculo.id">
                                  {{vehiculo.identifier}} - {{vehiculo.plete}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Responsable de la carga</mat-label>
                            <mat-select name="responsable_carga" [(ngModel)]="gasoline.paramedic_id" (selectionChange)="seleccionarResponble($event.value)" required>
                                <mat-option *ngFor="let paramedico of paramedicos" [value]="paramedico.identifier">
                                    {{paramedico.professional.user.name}} {{paramedico.professional.user.lastnamepat}}
                                </mat-option>
                                <mat-option value="0">Otro</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="example-full-width" *ngIf="inputResponsable">
                            <mat-label>Responsable</mat-label>
                            <input name="responsable" [formControl]="responsableFormControl" [errorStateMatcher]="matcher"  matInput placeholder="Escriba nombre de responsable" [(ngModel)]="gasoline.responsible" required>
                            <mat-error *ngIf="responsableFormControl.hasError('required')">
                                Responsable <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <mat-label>Estación</mat-label>
                            <input name="estacion" [formControl]="estacionFormControl" [errorStateMatcher]="matcher"  matInput placeholder="Estación" [(ngModel)]="gasoline.fuel_station" required>
                            <mat-error *ngIf="estacionFormControl.hasError('required')">
                                Estación <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <mat-label>Monto</mat-label>
                            <input name="monto" [formControl]="montoFormControl" [errorStateMatcher]="matcher"  matInput placeholder="Monto" [(ngModel)]="gasoline.amount" required>
                            <mat-error *ngIf="montoFormControl.hasError('required')">
                                Monto <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <mat-label>Cantidad (litros)</mat-label>
                            <input name="cantidad" [formControl]="cantidadFormControl" [errorStateMatcher]="matcher"  matInput placeholder="Cantidad (litros)" [(ngModel)]="gasoline.quantity" required>
                            <mat-error *ngIf="cantidadFormControl.hasError('required')">
                                Cantidad <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <mat-label>Descripcion</mat-label>
                            <textarea name="descripcion" [formControl]="descripcionFormControl" [errorStateMatcher]="matcher"  matInput placeholder="Descripción" [(ngModel)]="gasoline.description" required></textarea>
                            <mat-error *ngIf="descripcionFormControl.hasError('required')">
                                Descripcion <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <div class="row">
                            <div class="col s12" *ngIf="!loader">
                                <button mat-raised-button type="button" (click)="volver()">{{buttonName.BACK}}</button>
                                &nbsp;
                                <button mat-raised-button color="primary" type="submit">{{buttonName.UPDATE}}</button>
                            </div>
                            <div class="col s12" *ngIf="loader">
                                <mat-spinner diameter="40"></mat-spinner>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </form>

    </div>
</section>
