
<vex-section-title title="ADMINISTRACIÓN" subtitle="CONTACTO"></vex-section-title>

<div class="row">
    <div class="col s12 m4">
            <mat-card class="vex_soluciones_card">
                <mat-card-header>
                    <mat-card-title>Envio de correos</mat-card-title>
                    <mat-card-subtitle>Envía un correo</mat-card-subtitle>
                </mat-card-header>
                <form #formEnviarEmail="ngForm" ngNativeValidate (ngSubmit)="enviarEmail(formEnviarEmail)" (keydown.enter)="$event.preventDefault()">
                    <mat-card-content>

                        <mat-form-field appearance="outline">
                            <mat-chip-list #chipList aria-label="Emails selection">
                                <mat-chip *ngFor="let correo of correos" [selectable]="selectable"
                                    [removable]="removable" (removed)="remove(correo)">
                                    {{correo.name}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>

                                <input placeholder="Para"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    (matChipInputTokenEnd)="add($event)"
                                    name="correos"
                                    >
                                <mat-icon matSuffix>account_circle</mat-icon>

                            </mat-chip-list>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Asunto</mat-label>
                            <input matInput placeholder="Asunto" [(ngModel)]="sendEmail.asunto" required name="asunto">
                            <mat-icon matSuffix>subject</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Mensaje</mat-label>
                            <textarea matInput placeholder="Escriba su mesaje" [(ngModel)]="sendEmail.mensaje" required name="mensaje"></textarea>
                            <mat-icon matSuffix>email</mat-icon>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button *ngIf="!loaderSendEmail" mat-raised-button color="primary" type="submit">ENVIAR</button>
                        <mat-spinner *ngIf="loaderSendEmail" diameter="40"></mat-spinner>
                    </mat-card-actions>
                </form>
            </mat-card>

    </div>

</div>
