import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SocketService {

    newMessage = this.socket.fromEvent<any>('pindoctor-chat');
    newLocation = this.socket.fromEvent<any>('vex-location');

    constructor(private socket: Socket) { }

    sendMessage(data) {
        const event = 'pindoctor-chat';
        this.socket.emit(event, data, function(data) {
            console.log('data', data)
        });
    }

    getMessage() {
        return this.socket.fromEvent('pindoctor-chat');
    }

    getLocation() {
      return this.socket.fromEvent('vex-location').pipe(map((data) => data));
    }

}
