<section>

    <vex-section-title title="ADMINISTRACIÓN" subtitle="NUEVO MANTENIMIENTO"></vex-section-title>
    <div class="vex_soluciones_content">
        <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarMantenimiento(f)" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col s12 m6 l4">
                    <mat-card>
                        <h3>Datos Generales</h3>

                        <mat-form-field appearance="legacy">
                            <mat-label>Vehiculo</mat-label>
                            <mat-select name="vehiculo" [(ngModel)]="mantenimiento.vehicle_id" required>
                              <mat-option *ngFor="let vehiculo of vehiculos" [value]="vehiculo.id">
                                {{vehiculo.brand}} - {{vehiculo.model}} - {{vehiculo.plete}} - {{vehiculo.color}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <mat-label>Mantenimiento</mat-label>
                            <textarea name="mantenimiento" [formControl]="mantenimientoFormControl" [errorStateMatcher]="matcher" matInput placeholder="Descripción del mantenimiento" [(ngModel)]="mantenimiento.description" required></textarea>
                            <mat-error *ngIf="mantenimientoFormControl.hasError('required')">
                                Mantenimiento <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Fecha de mantenimiento</mat-label>
                            <input name="fecha_mantenimiento" [formControl]="fechaMantenimientoFormControl" [errorStateMatcher]="matcher" (click)="picker.open()" matInput [matDatepicker]="picker" [(ngModel)]="mantenimiento.date" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="fechaMantenimientoFormControl.hasError('required')">
                                Fecha de mantenimiento <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <div class="row">
                            <div class="col s12" *ngIf="!loader">
                                <button mat-raised-button (click)="volver()" type="button">{{buttonName.BACK}}</button>
                                &nbsp;
                                <button mat-raised-button color="primary" type="submit">{{buttonName.STORE}}</button>
                            </div>
                            <div class="col s12" *ngIf="loader">
                                <mat-spinner diameter="40"></mat-spinner>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </form>
    </div>
</section>
