import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { ParamedicService } from 'src/app/services';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { environment } from '../../../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-paramedico',
  templateUrl: './paramedico.component.html',
  styleUrls: ['./paramedico.component.css']
})
export class ParamedicoComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'name', 'email', 'phone', 'action'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  srcUrl: any;

  loader: boolean = false;

  paramedicos$: Subscription;
  deleteParamedico$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _paramedicService: ParamedicService,
    private _snackBar: MatSnackBar
  ) {

    this.srcUrl = environment.sourceUrl;

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerParamedicos();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerParamedicos() {
    this.loader = true;
    this.paramedicos$ = this._paramedicService.getParamedics().subscribe(
      response => {
        console.log('PARAMEDICOS', response)
        this.loader = false;
        this.dataSource = fillTable(response.data.reverse(), this.paginator, this.sort);
      },
      error => {
        this.loader = false;
        this.openError('Error al obtener paramédios', 'OK');
      }
    )
  }

  eliminarParamedico(){
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este profesional paramedico?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {



      }
    })
  }

  cambiarActivacion(element) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea cambiar la activación del profesional paramédico?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this.deleteParamedico$ = this._paramedicService.deleteParamedic(element.identifier, false).subscribe(
          response => {
            this.obtenerParamedicos();
          },
          error => {
            this.obtenerParamedicos();
            this.openError('Error en la activación', 'OK');
          }
        )

      } else {
        this.obtenerParamedicos();
      }
    })
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message, action) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  ngOnDestroy(): void {
    if(this.paramedicos$ ) this.paramedicos$.unsubscribe();

    if(this.deleteParamedico$) this.deleteParamedico$.unsubscribe();
  }
}
