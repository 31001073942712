import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': "Bearer " + localStorage.getItem('token')
    })
};

@Injectable({
    providedIn: 'root'
})
export class InboxService {

    constructor(
        private http: HttpClient,
    ) { }

    getInbox(id): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}messages/${id}`);

    }

    getInboxes(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}messages`, httpOptions);

    }

    getUsersChat(payload): Observable<any> {

        return this.http.post<any>(`${environment.apiUrl}user/chats`, payload, httpOptions);

    }

    getMessagesChat(room): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}chat/messages/${room}`, httpOptions);

    }

    updateInbox(id, data): Observable<any> {

        return this.http.patch<any>(`${environment.apiUrl}messages/${id}`, data, httpOptions);

    }

    saveInbox(data): Observable<any> {

        return this.http.post<any>(`${environment.apiUrl}messages`, data, httpOptions);

    }

    deleteInbox(id, value): Observable<any> {

        return this.http.delete<any>(`${environment.apiUrl}messages/${id}?hard=${value}`, httpOptions);

    }

    searchByDate(date): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}chat/messages/search/${date}`, httpOptions);

    }

}
