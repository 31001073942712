import { Component, OnInit } from '@angular/core';
import { AgmInfoWindow } from '@agm/core';
import { ParamedicService } from 'src/app/services';

@Component({
	selector: 'app-panel-monitoreo',
	templateUrl: './panel-monitoreo.component.html',
	styleUrls: ['./panel-monitoreo.component.css']
})
export class PanelMonitoreoComponent implements OnInit {

	texto : string = 'Wenceslau Braz - Cuidado com as cargas';
	lat: number = 19.485166;
	lng: number = -99.134715;
	zoom: number = 25;

	icon = {
		url: 'assets/images/admin/icon-clinica.png',
		scaledSize: {
			width: 60,
			height: 60
		}
	}

	infoList: AgmInfoWindow;

	paramedicos = [];

	constructor(
		private _paramedicService: ParamedicService
	) {}

	ngOnInit(): void {
		this.obtenerParamedicos();
	}

	obtenerParamedicos() {
		this._paramedicService.getParamedics().subscribe(
			response => {
				console.log('RESPONSE paramedicos', response)
				let paramedicosOnline = [];

        response.data.forEach(element => {

          paramedicosOnline.push(element);

        });
				for (let index = 0; index < response.data.length; index++) {
					const element = response.data[index];
					console.log('status', element.professional.status)

				}

				this.paramedicos = paramedicosOnline;

				let markers = [];

				for (let index = 0; index < response.data.length; index++) {
					const element = response.data[index];
					markers.push({lat: element.professional.user.latitude, lng: element.professional.user.longitude});

				}

				// this.agmMap.mapReady.subscribe(map => {
				// 	const bounds: any = new google.maps.LatLngBounds();
				// 	for (const mm of markers) {
				// 	  bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
				// 	}
				// 	map.fitBounds(bounds);
				// });

			},
			error => {
				console.log('ERROR PARAMEDICS', error)
			}
		)
	}

}
