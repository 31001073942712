import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { InboxService } from 'src/app/services';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})
export class InboxComponent implements OnInit {

  displayedColumns: string[] = ['delete', 'emisor', 'receptor', 'mensaje', 'fecha', 'status', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  allChats = [];

  constructor(
    public dialog: MatDialog,
    private _inboxService: InboxService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {

    this.obtenerInboxes();

  }

  buscarMensaje(event: Event) {
    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerInboxes() {
    this._inboxService.getInboxes().subscribe(
      response => {

        console.log('MENSAJES DATA', response)
        this.dataSource = fillTable(response.data, this.paginator, this.sort);

        this.allChats = response.data;
      },
      error => {
        this.openError('Error al obtener mensajes', error);
      }
    )
  }

  eliminarInbox() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este mensaje?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {



      }
    })
  }

  verChat(room) {
    console.log('room', room)
    this._inboxService.getMessagesChat(room).subscribe(
      response => {
        console.log('chats about room', response);
      },
      error => {
        this.openError('Error al obtener mensajes de la sala', error);
      }
    )
  }

  seleccionarFecha(fecha) {
    let d = new Date(fecha);

    let date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

    this._inboxService.searchByDate(date).subscribe(
      response => {
        console.log('MENSAJES', response);
        this.dataSource = fillTable(response, this.paginator, this.sort);

      },
      _ => {
        this.openError('ERROR AL OBTENER MENSAJES', 'OK');
      }
    )
  }

  verTodos() {
    this.obtenerInboxes();
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
