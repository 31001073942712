<section>

    <vex-section-title title="ADMINISTRACIÓN" subtitle="GESTIÓN DE MANTENIMIENTOS"></vex-section-title>

    <div class="vex_soluciones_content">
      <div align="end">
        <mat-form-field>
          <mat-label>Buscador</mat-label>
          <input matInput (keyup)="buscarMantenimiento($event)" placeholder="Buscar">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        &nbsp;
        <button mat-stroked-button color="primary" [routerLink]="['/unidades/mantenimiento-nuevo']">
          NUEVO
        </button>
      </div>

        <mat-card class="mat-elevation-z0 sc_card_more_options">
          Todos los mantenimientos
          <button class="sc_more_options" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
              <button
                  mat-menu-item
                  class="sc_more_options"
                  color="primary"
                  (click)="exporter.exportTable('xlsx', {fileName:'mantenimientos'})">
                  <mat-icon>cloud_download</mat-icon>
                  <span>Exportar a Excel</span>
              </button>

          </mat-menu>
      </mat-card>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort matTableExporter #exporter="matTableExporter">

            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
              <td mat-cell *matCellDef="let element">
                <button (click)="eliminarMantenimiento(element.identifier)" mat-icon-button color="warn">
                  <mat-icon>delete</mat-icon>
                </button>

              </td>
            </ng-container>

            <ng-container matColumnDef="identifier">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let element"> {{element.identifier}} </td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> MANTENIMIENTO </th>
              <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA </th>
              <td mat-cell *matCellDef="let element"> {{element.date}} </td>
            </ng-container>

            <ng-container matColumnDef="vehicle_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> VEHICULO </th>
              <td mat-cell *matCellDef="let element"> {{element.vehicle_name}} </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="pin-size-th"> ACCIONES </th>
              <td mat-cell *matCellDef="let element">

                <button color="primary" [routerLink]="['/unidades/mantenimiento-editar', element.identifier]" mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
              </td>
          </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="3"></td>

              <td class="mat-cell" colspan="1" *ngIf="!loader">No se encontraron registros</td>

              <td class="mat-cell" colspan="1" *ngIf="loader">
                  <mat-spinner diameter="40"></mat-spinner>
              </td>

              <td class="mat-cell" colspan="2"></td>
            </tr>
        </table>
        <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

    </div>

</section>
