
<vex-section-title title="" subtitle="DASHBOARD"></vex-section-title>

<div class="row">
    <div class="col s12 m6 l3">
        <mat-card class="vex_soluciones_card vex_soluciones_indicadores">
            <div class="content-card-info">
                <div class="content-card-info-1">
                    <h3 class="title-card-info-1">Rutas</h3>
                    <div class="number-card-info-1">
                        <h3 style="margin: 0;">{{dashboard.attentions}}</h3>
                    </div>
                </div>
                <div>
                    <mat-icon class="icon-card-info">
                        place
                    </mat-icon>
                </div>
            </div>
        </mat-card>
        <br>
        <mat-card class="vex_soluciones_card vex_soluciones_indicadores">
            <div class="content-card-info">
                    <div class="content-card-info-1">
                        <h3 class="title-card-info-1">Unidades Activas</h3>
                        <div class="number-card-info-1">
                            <h3 style="margin: 0;">{{dashboard.vehicles}}</h3>
                        </div>
                    </div>
                    <div>
                        <mat-icon class="icon-card-info">
                            directions_car
                        </mat-icon>
                    </div>
            </div>

        </mat-card>
    </div>

    <div class="col s12 m6 l3">
        <mat-card class="vex_soluciones_card vex_soluciones_indicadores">
            <div class="content-card-info">
                    <div class="content-card-info-1">
                        <h3 class="title-card-info-1">Atenciones</h3>
                        <div class="number-card-info-1">
                            <h3 style="margin: 0;">{{dashboard.attentions}}</h3>
                        </div>
                    </div>
                    <div>
                        <mat-icon class="icon-card-info">
                            local_hospital
                        </mat-icon>
                    </div>
            </div>

        </mat-card>
        <br>
        <mat-card class="vex_soluciones_card vex_soluciones_indicadores">
            <div class="content-card-info">
                    <div class="content-card-info-1">
                        <h3 class="title-card-info-1">Personal Activo</h3>
                        <div class="number-card-info-1">
                            <h3 style="margin: 0;">{{dashboard.users}}</h3>
                        </div>
                    </div>
                    <div>
                        <mat-icon class="icon-card-info">
                            people_alt
                        </mat-icon>
                    </div>
            </div>

        </mat-card>
    </div>

    <div class="col s12 m6 l3">
        <mat-card>
            <div id="chartAttention">

                <!-- <apx-chart
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [dataLabels]="chartOptions.dataLabels"
                  [plotOptions]="chartOptions.plotOptions"
                  [yaxis]="chartOptions.yaxis"
                  [xaxis]="chartOptions.xaxis"
                  [fill]="chartOptions.fill"
                  [title]="chartOptions.title"
                ></apx-chart> -->
            </div>
        </mat-card>
        <!-- <mat-card class="vex_soluciones_card">
            <mat-card-header>
                <mat-card-title>Estadísticas de viaje</mat-card-title>
                <mat-card-subtitle>Estado de los viajes</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <canvas
                    baseChart
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [legend]="barChartLegend"
                    [chartType]="barChartType">
                </canvas>
            </mat-card-content>

        </mat-card> -->
    </div>

    <div class="col s12 m6 l3">
        <mat-card>
            <div id="chartPatient">
                <!-- <apx-chart
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [dataLabels]="chartOptions.dataLabels"
                  [plotOptions]="chartOptions.plotOptions"
                  [yaxis]="chartOptions.yaxis"
                  [xaxis]="chartOptions.xaxis"
                  [fill]="chartOptions.fill"
                  [title]="chartOptions.title"
                ></apx-chart> -->
            </div>
        </mat-card>
        <!-- <mat-card class="vex_soluciones_card">
            <mat-card-header>
                <mat-card-title>Resultados y Objetivos</mat-card-title>
                <mat-card-subtitle>Objetivos alcanzados</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <canvas
                    baseChart
                    [datasets]="barChartData2"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [legend]="barChartLegend"
                    [chartType]="barChartType">
                </canvas>
            </mat-card-content>
        </mat-card> -->
    </div>
</div>

<div class="row">
    <div class="col s12">
        <mat-card class="vex_soluciones_card">
            <h3>Unidades Activas</h3>
            <agm-map #AgmMap [fullscreenControl]="true" minZoom="3" [zoom]="13" [fitBounds]="true" [latitude]="lat" [longitude]="lng">
              <ng-container *ngFor="let paramedic of paramedicos">
                <agm-marker [agmFitBounds]="true"
                    *ngIf="paramedic.vehicle[0]?.state == 'online' && paramedic.vehicle[0]?.status == 'disponible' "
                    [latitude]="paramedic.professional.user.latitude"
                    [longitude]="paramedic.professional.user.longitude"
                    [label]="{ color: 'black', marginTop: '10px', fontFamily: '', fontSize: '14px', fontWeight: 'bold', text: 'PIN00' + paramedic.identifier}"
                    [iconUrl]="icon">
                    <agm-info-window>
                        <mat-list>
                            <div mat-subheader>Detalle del vehículo {{'PIN00' + paramedic.identifier}}</div>

                            <!-- <mat-list-item>
                                <mat-icon mat-list-icon>task_alt</mat-icon>
                                <div mat-line>ID</div>
                                <div mat-line> {{'PIN 00' + paramedic.identifier}} </div>
                            </mat-list-item> -->

                            <mat-list-item>
                                <mat-icon mat-list-icon>directions_car</mat-icon>
                                <div mat-line> {{paramedic.vehicle[0].plete}} </div>
                                <div mat-line>Placa </div>

                            </mat-list-item>

                            <mat-list-item>
                                <mat-icon mat-list-icon>home_repair_service</mat-icon>
                                <div mat-line>"{{paramedic.vehicle[0].type_equipment}}" </div>
                                <div mat-line>Equipamiento </div>

                            </mat-list-item>


                            <mat-list-item>
                              <mat-icon mat-list-icon>person_pin</mat-icon>
                              <div mat-line>{{paramedic['professional']['user']['name'] + ' ' + paramedic['professional']['user']['lastnamepat']}}</div>
                              <div mat-line> Paramédico </div>
                          </mat-list-item>
                        </mat-list>
                    </agm-info-window>
                </agm-marker>
              </ng-container>
            </agm-map>

        </mat-card>
    </div>
</div>

<div class="row">
    <div class="col s12 m12 l6">
        <mat-card class="vex_soluciones_card">
            <mat-card-header>
                <mat-card-title>Agenda</mat-card-title>
                <!-- <mat-card-subtitle>Agenda una atención médica</mat-card-subtitle> -->
                <span class="spacer"></span>

                <button mat-button color="primary" (click)="nuevoServicio()">PROGRAMAR</button>
            </mat-card-header>
            <mat-card-content>
                <br>
                <full-calendar
                    defaultView="dayGridMonth"
                    [options]="calendarOptions">
                </full-calendar>

            </mat-card-content>
            <!-- <mat-card-actions>
                <button mat-raised-button color="primary" (click)="nuevoServicio()">AGENDAR VIAJE</button>
            </mat-card-actions> -->
        </mat-card>
    </div>
    <div class="col s12 m12 l6">
        <mat-card class="vex_soluciones_card">
            <mat-card-header>
                <mat-card-title>Chat</mat-card-title>
                <mat-card-subtitle>Últimas entradas</mat-card-subtitle>

            </mat-card-header>
            <mat-card-content>
                <mat-list>
                    <mat-list-item>
                        <mat-icon mat-list-icon>email</mat-icon>
                        <div mat-line>Mensaje urgente</div>
                        <div mat-line>Sebastian Ordonez - 12/12/12</div>
                        <div mat-list>
                            <button mat-mini-fab color="primary" (click)="verMensaje()">
                                <mat-icon>chat</mat-icon>
                            </button>
                        </div>

                    </mat-list-item>
                </mat-list>
            </mat-card-content>
            <mat-card-actions align="end">
                <button color="primary" mat-button [routerLink]="['/mensajes/bandeja-de-entrada']">VER MENSAJES</button>
            </mat-card-actions>
        </mat-card>
        <br>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Tareas</mat-card-title>
                <mat-card-subtitle>Últimas tareas programadas</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-list>
                    <div *ngIf="tareas.length == 0">
                        <mat-card class="not-data-tasks">
                            No se encuentraron tareas por realizar
                        </mat-card>
                    </div>
                    <div *ngFor="let tarea of tareas">
                        <mat-list-item>

                            <div mat-line>{{tarea.user.name}} {{tarea.user.lastnamepat}} {{tarea.user.lastnamemat}}</div>
                            <div mat-line> {{tarea.name}} | {{tarea.date | date}} </div>

                            <mat-checkbox (change)="cambiarEstado(tarea.identifier, $event)" [checked]="tarea.isCompleted"  mat-list color="primary"></mat-checkbox>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <div mat-list>
                                <button mat-mini-fab color="edit" (click)="editarTarea(tarea.identifier)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div>
                            <div mat-list>
                                &nbsp;
                            </div>
                            <div mat-list>
                                <button mat-mini-fab color="warn" (click)="eliminarTarea(tarea.identifier)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>

                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </div>

                </mat-list>
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-button (click)="nuevaTarea()">AGREGAR TAREA</button>
                <button mat-button color="primary" [routerLink]="['/administrar-tareas']">VER TODAS LAS TAREAS</button>
            </mat-card-actions>
        </mat-card>
        <br>
        <mat-card class="vex_soluciones_card">
            <mat-card-header>
                <mat-card-title>Correo Ágil</mat-card-title>
                <mat-card-subtitle>Presiona enter o la tecla coma para escribir mas correos</mat-card-subtitle>
            </mat-card-header>
            <form #formEnviarEmail="ngForm" ngNativeValidate (ngSubmit)="enviarEmail(formEnviarEmail)" (keydown.enter)="$event.preventDefault()">
                <mat-card-content style="margin-bottom: 0px !important;">

                    <mat-form-field appearance="outline">
                      <mat-label>Para</mat-label>

                        <mat-chip-list #chipList aria-label="Emails selection">
                            <mat-chip *ngFor="let correo of correos" [selectable]="selectable"
                                [removable]="removable" (removed)="remove(correo)">
                                {{correo.name}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>

                            <input placeholder="Para"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="addOnBlur"
                                (matChipInputTokenEnd)="add($event)"
                                name="correos"
                                >
                            <mat-icon matSuffix>account_circle</mat-icon>

                        </mat-chip-list>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Asunto</mat-label>
                        <input matInput placeholder="Asunto" [(ngModel)]="sendEmail.asunto" required name="asunto">
                        <mat-icon matSuffix>subject</mat-icon>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Mensaje</mat-label>
                        <textarea matInput placeholder="Escriba su mensaje" [(ngModel)]="sendEmail.mensaje" required name="mensaje"></textarea>
                        <mat-icon matSuffix>email</mat-icon>
                    </mat-form-field>
                </mat-card-content>
                <div align="end">
                  <button *ngIf="!loaderSendEmail" mat-button color="primary" type="submit">ENVIAR</button>
                </div>
            </form>
        </mat-card>
    </div>
</div>

<div class="row">
    <div class="col s12 m12 l4">
        <!-- <mat-card class="vex_soluciones_card">
            <mat-card-header>
                <mat-card-title>Correo Ágil</mat-card-title>
                <mat-card-subtitle>Presiona enter o la tecla coma para escribir mas correos</mat-card-subtitle>
            </mat-card-header>
            <form #formEnviarEmail="ngForm" ngNativeValidate (ngSubmit)="enviarEmail(formEnviarEmail)">
                <mat-card-content>

                    <mat-form-field appearance="outline">
                        <mat-chip-list #chipList aria-label="Emails selection">
                            <mat-chip *ngFor="let correo of correos" [selectable]="selectable"
                                [removable]="removable" (removed)="remove(correo)">
                                {{correo.name}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>

                            <input placeholder="Para"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="addOnBlur"
                                (matChipInputTokenEnd)="add($event)"
                                name="correos"
                                >
                            <mat-icon matSuffix>account_circle</mat-icon>

                        </mat-chip-list>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Asunto</mat-label>
                        <input matInput placeholder="Asunto" [(ngModel)]="sendEmail.asunto" required name="asunto">
                        <mat-icon matSuffix>subject</mat-icon>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Mensaje</mat-label>
                        <textarea matInput placeholder="Escriba su meNsaje" [(ngModel)]="sendEmail.mensaje" required name="mensaje"></textarea>
                        <mat-icon matSuffix>email</mat-icon>
                    </mat-form-field>
                </mat-card-content>
                <mat-card-actions align="end">
                    <button *ngIf="!loaderSendEmail" mat-button color="primary" type="submit">ENVIAR</button>
                    <mat-spinner *ngIf="loaderSendEmail" diameter="40"></mat-spinner>
                </mat-card-actions>
            </form>
        </mat-card> -->
    </div>
    <div class="col s12 m12 l4">
        <!-- <mat-card class="vex_soluciones_card">
            <mat-card-header>
                <mat-card-title>Estadísticas de viaje</mat-card-title>
                <mat-card-subtitle>Estado de los viajes</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <canvas
                    baseChart
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [legend]="barChartLegend"
                    [chartType]="barChartType">
                </canvas>
            </mat-card-content>

        </mat-card> -->
    </div>
    <div class="col s12 m12 l4">
        <!-- <mat-card class="vex_soluciones_card">
            <mat-card-header>
                <mat-card-title>Resultados y Objetivos</mat-card-title>
                <mat-card-subtitle>Objetivos alcanzados</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <canvas
                    baseChart
                    [datasets]="barChartData2"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [legend]="barChartLegend"
                    [chartType]="barChartType">
                </canvas>
            </mat-card-content>
        </mat-card> -->
    </div>
</div>
