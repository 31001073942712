<section>
    <div class="row">
        <div class="col s1"></div>
        <div class="col s10">
            <vex-section-title title="ADMINISTRACIÓN" subtitle="CONFIGURACIÓN"></vex-section-title>

            <div>

                <mat-tab-group>
                    <mat-tab label="ADMINISTRADOR">
                        <div class="row padding-content">
                            <div class="col s3 p-l-0">
                                <mat-card>
                                    <div class="center">
                                        <div class="img-content">
                                            <img class="vex-soluciones-img-profile" [src]="(uploadImage.image)? tempImage: sourceUrl + tempImage" alt="" width="120">
                                        </div>
                                    </div>
                                    <h3 class="center m-b-0 vex-soluciones-name-title">{{administrador.name}} {{administrador.lastnamepat}}</h3>

                                    <div class="center">
                                        <button *ngIf="tempImage == administrador.image" mat-stroked-button color="primary" type="button" (click)="photoFile.click()">Cambiar Imagen</button>
                                        <input hidden (change)="subirImagen($event.target.files[0])" #photoFile type="file" accept="image/x-png,image/gif,image/jpeg,image/jpg">

                                    </div>

                                    <button mat-raised-button *ngIf="tempImage != administrador.image" type="button" (click)="cancelar()">Cancelar</button>
                                    &nbsp;
                                    <button mat-raised-button *ngIf="tempImage != administrador.image" type="button" (click)="guardarImagen()" color="primary">Guardar</button>


                                </mat-card>
                            </div>
                            <div class="col s9 p-r-0">
                                <mat-card>
                                    <h4>Perfil</h4>
                                    <mat-divider></mat-divider>

                                    <form #actualizarAdmin="ngForm" ngNativeValidate (ngSubmit)="actualizarAdministrador(actualizarAdmin)" (keydown.enter)="$event.preventDefault()">

                                        <div class="row info-padding-top">
                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Nombre</mat-label>
                                                    <input matInput placeholder="Nombre" [(ngModel)]="administrador.name" required name="name">
                                                </mat-form-field>
                                            </div>
                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Apellido Paterno</mat-label>
                                                    <input matInput placeholder="Apellido Paterno" [(ngModel)]="administrador.lastnamepat" required name="lastnamepat">
                                                </mat-form-field>
                                            </div>
                                            <div class="col s6">
                                              <mat-form-field appearance="outline">
                                                  <mat-label>Apellido Materno</mat-label>
                                                  <input matInput placeholder="Apellido Materno" [(ngModel)]="administrador.lastnamemat" required name="lastnamemat">
                                              </mat-form-field>
                                            </div>

                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Correo Electrónico</mat-label>
                                                    <input matInput placeholder="Correo Electrónico" [(ngModel)]="administrador.email" required name="email">
                                                </mat-form-field>
                                            </div>

                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Teléfono</mat-label>
                                                    <input matInput placeholder="Teléfono" [(ngModel)]="administrador.phone" required name="phone">
                                                </mat-form-field>
                                            </div>

                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Fecha de nacimiento</mat-label>
                                                    <input matInput [matDatepicker]="picker" [(ngModel)]="administrador.birth_date" required name="birth_date">
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                            </div>

                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Dirección</mat-label>
                                                    <input matInput placeholder="Dirección" [(ngModel)]="administrador.address" required name="address">
                                                </mat-form-field>
                                            </div>


                                            <div class="col s12">
                                                <mat-spinner diameter="30" *ngIf="loaderUpdAdmin"></mat-spinner>

                                                <button mat-raised-button color="primary" type="submit" *ngIf="!loaderUpdAdmin">Actualizar</button>
                                            </div>
                                        </div>

                                    </form>
                                </mat-card>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="DATOS">
                        <div class="row padding-content">
                            <div class="col s12 p-r-0 p-l-0">
                                <mat-card>

                                    <form #actualizarEmp="ngForm" ngNativeValidate (ngSubmit)="actualizarEmpresa(actualizarEmp)" (keydown.enter)="$event.preventDefault()">
                                        <h4>Datos de empresa</h4>
                                        <mat-divider></mat-divider>

                                        <div class="row info-padding-top">
                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Razon Social</mat-label>
                                                    <input matInput placeholder="Razon Social" [(ngModel)]="configuracion.name" required name="name">
                                                </mat-form-field>
                                            </div>
                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Domicilio Fiscal</mat-label>
                                                    <input matInput placeholder="Domicilio Fiscal" [(ngModel)]="configuracion.address" required name="address">
                                                </mat-form-field>
                                            </div>

                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Correo Electrónico</mat-label>
                                                    <input matInput placeholder="Correo Electrónico" [(ngModel)]="configuracion.email" required name="email">
                                                </mat-form-field>

                                            </div>

                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Teléfono</mat-label>
                                                    <input matInput placeholder="Teléfono" [(ngModel)]="configuracion.phone" required name="phone">
                                                </mat-form-field>
                                            </div>

                                        </div>

                                        <mat-divider></mat-divider>
                                        <h4>Redes sociales</h4>
                                        <mat-divider></mat-divider>

                                        <div class="row info-padding-top">

                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Facebook</mat-label>
                                                    <input matInput placeholder="Facebook" [(ngModel)]="configuracion.facebook" required name="facebook">
                                                </mat-form-field>
                                            </div>

                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Twitter</mat-label>
                                                    <input matInput placeholder="Twitter" [(ngModel)]="configuracion.twitter" required name="twitter">
                                                </mat-form-field>
                                            </div>

                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Instagram</mat-label>
                                                    <input matInput placeholder="Instagram" [(ngModel)]="configuracion.instagram" required name="instagram">
                                                </mat-form-field>
                                            </div>

                                            <div class="col s6">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Web</mat-label>
                                                    <input matInput placeholder="Web" [(ngModel)]="configuracion.web" required name="web">
                                                </mat-form-field>
                                            </div>

                                        </div>

                                        <mat-divider></mat-divider>
                                        <h4>LLaves del sistema</h4>
                                        <div class="row info-padding-top">
                                          <div class="col s6">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Google Maps Key</mat-label>
                                                <input matInput placeholder="Google Maps Key" [(ngModel)]="configuracion.google_map_key" required name="google_map_key">
                                            </mat-form-field>
                                          </div>
                                          <div class="col s6">
                                              <mat-form-field appearance="outline">
                                                  <mat-label>Google Auth Key</mat-label>
                                                  <input matInput placeholder="Google Auth Key" [(ngModel)]="configuracion.facebook_auth_key" required name="facebook_auth_key">
                                              </mat-form-field>
                                          </div>

                                          <div class="col s6">
                                              <mat-form-field appearance="outline">
                                                  <mat-label>Facebook Auth Key</mat-label>
                                                  <input matInput placeholder="Facebook Auth Key" [(ngModel)]="configuracion.google_auth_key" required name="google_auth_key">
                                              </mat-form-field>
                                          </div>
                                        </div>
                                        <div class="row">
                                            <div class="col s12">
                                                <mat-spinner diameter="30" *ngIf="loaderUpdDatos"></mat-spinner>

                                                <button mat-raised-button color="primary" type="submit" *ngIf="!loaderUpdDatos">Actualizar</button>
                                            </div>
                                        </div>

                                    </form>
                                </mat-card>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="SEGURIDAD">
                        <div class="row padding-content">
                            <div class="col s12 p-r-0 p-l-0">
                                <form #actualizar="ngForm" ngNativeValidate (ngSubmit)="actualizarContrasenia(actualizar)" (keydown.enter)="$event.preventDefault()">

                                    <mat-card>
                                        <h4>Cambiar Contraseña</h4>
                                        <mat-divider></mat-divider>
                                        <div class="row info-padding-top">
                                            <div class="col s12 m4">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Contraseña</mat-label>
                                                    <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="password.current_password" name="current_password" required>
                                                    <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m4">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Nueva Contraseña</mat-label>
                                                    <input matInput [type]="hideNC ? 'password' : 'text'" [(ngModel)]="password.new_password" name="new_password" required>
                                                    <button type="button" mat-icon-button matSuffix (click)="hideNC = !hideNC" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNC">
                                                        <mat-icon>{{hideNC ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m4">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Confirmar Contraseña</mat-label>
                                                    <input matInput [type]="hideC ? 'password' : 'text'" [(ngModel)]="password.new_password_confirm" name="new_password_confirm" required>
                                                    <button type="button" mat-icon-button matSuffix (click)="hideC = !hideC" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideC">
                                                        <mat-icon>{{hideC ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="info-padding-top">
                                            <mat-spinner diameter="30" *ngIf="loaderUpdPwd"></mat-spinner>

                                            <button mat-raised-button color="primary" type="submit" *ngIf="!loaderUpdPwd">Actualizar contraseña</button>
                                        </div>
                                    </mat-card>

                                </form>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="CORREOS ADMIN">
                        <div class="row padding-content">
                            <mat-form-field class="vex_soluciones_input_search">
                                <mat-label>Buscador</mat-label>
                                <input matInput (keyup)="buscarCorreo($event)" placeholder="Buscar" #buscador>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>

                            <button (click)="agregarCorreo()" class="vex_soluciones_btn_add" mat-raised-button color="primary">
                              <mat-icon>add</mat-icon>
                              Agregar
                            </button>

                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort>

                                <ng-container matColumnDef="delete">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-slide-toggle color="primary" (click)="cambiarActivacion(element)" [checked]="true"></mat-slide-toggle>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="id">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                  <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE </th>
                                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="email">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CORREO </th>
                                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                                </ng-container>

                                <ng-container matColumnDef="acciones">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ACCIONES </th>
                                    <td mat-cell *matCellDef="let element">

                                        <button color="warn" (click)="eliminarCorreo(element.id)" mat-icon-button>
                                            <mat-icon>delete</mat-icon>
                                        </button>

                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <!-- <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="9">No hay datos que coincidan con el filtro "{{buscador.value}}"</td>
                                </tr> -->

                                <tr class="mat-row">
                                    <td class="mat-cell" colspan="4"></td>

                                    <td class="mat-cell" colspan="1" *ngIf="!loader">No se encontraron registros</td>

                                    <td class="mat-cell" colspan="1" *ngIf="loader">
                                        <mat-spinner diameter="40"></mat-spinner>
                                    </td>

                                    <td class="mat-cell" colspan="4"></td>
                                </tr>
                            </table>
                            <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                        </div>
                    </mat-tab>
                    <mat-tab label="TARIFAS">
                        <div class="padding-content">
                            <mat-card>
                                <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarTipoCosto(f)" (keydown.enter)="$event.preventDefault()">
                                    <div class="row">
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Tipo de Servicio</mat-label>
                                                <mat-select required [(ngModel)]="data.tipo_servicio" name="tipo_servicio">
                                                  <mat-option *ngFor="let servicio of servicios" [value]="servicio.name">
                                                    {{servicio.name}}
                                                  </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Nombre</mat-label>
                                                <input required type="text" matInput placeholder="Nombre" [(ngModel)]="data.name" name="name">
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Tarifa Base</mat-label>
                                                <input required min="0" type="number" matInput placeholder="Monto Base" [(ngModel)]="data.monto_base" name="monto_base">
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Tasa de Distancia por KM</mat-label>
                                                <input required min="0" type="number" matInput placeholder="Tasa de Distancia por KM" [(ngModel)]="data.tasa_distancia_km" name="tasa_distancia_km">
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Índice por Hora</mat-label>
                                                <input required min="0" type="number" matInput placeholder="Índice por Hora" [(ngModel)]="data.indice_hora" name="indice_hora">
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Tipo de Unidad</mat-label>
                                                <mat-select required [(ngModel)]="data.tipo_unidad" name="tipo_unidad">
                                                  <mat-option value="Equipamiento A">
                                                    Equipamiento A
                                                  </mat-option>
                                                  <mat-option value="Equipamiento B">
                                                    Equipamiento B
                                                  </mat-option>
                                                  <mat-option value="Equipamiento C">
                                                    Equipamiento C
                                                  </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Horario</mat-label>
                                                <mat-select required [(ngModel)]="data.horario" name="horario">
                                                  <mat-option value="DIA">
                                                    DÍA
                                                  </mat-option>
                                                  <mat-option value="TARDE">
                                                    TARDE
                                                  </mat-option>
                                                  <mat-option value="NOCHE">
                                                    NOCHE
                                                  </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col s12">
                                            <button mat-raised-button color="primary" *ngIf="!loaderReg">Registrar</button>
                                            <mat-spinner *ngIf="loaderReg" diameter="30"></mat-spinner>
                                        </div>
                                    </div>
                                </form>
                            </mat-card>
                            <br>
                            <div class="row">
                                <div class="col s12">

                                    <mat-form-field class="vex_soluciones_input_search">
                                        <mat-label>Buscar</mat-label>
                                        <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" #input>
                                      <mat-icon matSuffix>search</mat-icon>
                                    </mat-form-field>

                                    <div class="mat-elevation-z8">
                                        <table mat-table [dataSource]="dataSourceFac" matSort matTableExporter #exporter="matTableExporter">

                                            <ng-container matColumnDef="nombre">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                                <td mat-cell *matCellDef="let row" data-label="Nombre"> {{row.name}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="monto_base">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto Base </th>
                                                <td mat-cell *matCellDef="let row" data-label="Monto Base"> {{row.monto_base}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="tasa_de_distancia">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tasa de Distancia (KM) </th>
                                                <td mat-cell *matCellDef="let row" data-label="Tasa de Distancia (KM)"> {{row.tasa_distancia_km}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="indice_hora">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Índice Hora </th>
                                                <td mat-cell *matCellDef="let row" data-label="Índice Hora"> {{row.indice_hora}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="tipo_unidad">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Unidad </th>
                                                <td mat-cell *matCellDef="let row" data-label="Tipo de Unidad"> {{row.tipo_unidad}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="horario">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Horario </th>
                                                <td mat-cell *matCellDef="let row" data-label="Horario"> {{row.horario}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="tipo_servicio">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Servicio </th>
                                                <td mat-cell *matCellDef="let row" data-label="Tipo de Servicio"> {{row.tipo_servicio}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="acciones">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                                                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                                                    <button matTooltip="Eliminar parámetro" (click)="eliminarParametro(row.id)" mat-icon-button color="warn">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumnsFac"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumnsFac;"></tr>

                                            <tr class="mat-row" *matNoDataRow>
                                                <td class="mat-cell" colspan="2"></td>

                                                <td class="mat-cell" colspan="1" *ngIf="!loaderFac">No se encontraron registros "{{input.value}}"</td>
                                                <td class="mat-cell" colspan="1" *ngIf="loaderFac">
                                                    <mat-spinner diameter="40"></mat-spinner>
                                                </td>
                                                <td class="mat-cell" colspan="5"></td>
                                            </tr>

                                        </table>

                                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="TARIFAS ADICIONALES">
                        <div class="padding-content">
                            <mat-card>
                                <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarTipoCosto(f)" (keydown.enter)="$event.preventDefault()">
                                    <div class="row">
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Tipo de Servicio</mat-label>
                                                <mat-select required [(ngModel)]="data.tipo_servicio" name="tipo_servicio">
                                                  <mat-option *ngFor="let servicio of servicios" [value]="servicio.name">
                                                    {{servicio.name}}
                                                  </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Nombre</mat-label>
                                                <input required type="text" matInput placeholder="Nombre" [(ngModel)]="data.name" name="name">
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Tarifa Base</mat-label>
                                                <input required min="0" type="number" matInput placeholder="Monto Base" [(ngModel)]="data.monto_base" name="monto_base">
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Tasa de Distancia por KM</mat-label>
                                                <input required min="0" type="number" matInput placeholder="Tasa de Distancia por KM" [(ngModel)]="data.tasa_distancia_km" name="tasa_distancia_km">
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Índice por Hora</mat-label>
                                                <input required min="0" type="number" matInput placeholder="Índice por Hora" [(ngModel)]="data.indice_hora" name="indice_hora">
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Tipo de Unidad</mat-label>
                                                <mat-select required [(ngModel)]="data.tipo_unidad" name="tipo_unidad">
                                                  <mat-option value="Equipamiento A">
                                                    Equipamiento A
                                                  </mat-option>
                                                  <mat-option value="Equipamiento B">
                                                    Equipamiento B
                                                  </mat-option>
                                                  <mat-option value="Equipamiento C">
                                                    Equipamiento C
                                                  </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 l12 xl3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Horario</mat-label>
                                                <mat-select required [(ngModel)]="data.horario" name="horario">
                                                  <mat-option value="DIA">
                                                    DÍA
                                                  </mat-option>
                                                  <mat-option value="TARDE">
                                                    TARDE
                                                  </mat-option>
                                                  <mat-option value="NOCHE">
                                                    NOCHE
                                                  </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col s12">
                                            <button mat-raised-button color="primary" *ngIf="!loaderReg">Registrar</button>
                                            <mat-spinner *ngIf="loaderReg" diameter="30"></mat-spinner>
                                        </div>
                                    </div>
                                </form>
                            </mat-card>
                            <br>
                            <div class="row">
                                <div class="col s12">

                                    <mat-form-field class="vex_soluciones_input_search">
                                        <mat-label>Buscar</mat-label>
                                        <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" #input>
                                      <mat-icon matSuffix>search</mat-icon>
                                    </mat-form-field>

                                    <div class="mat-elevation-z8">
                                        <table mat-table [dataSource]="dataSourceFac" matSort matTableExporter #exporter="matTableExporter">

                                            <ng-container matColumnDef="nombre">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                                <td mat-cell *matCellDef="let row" data-label="Nombre"> {{row.name}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="monto_base">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto Base </th>
                                                <td mat-cell *matCellDef="let row" data-label="Monto Base"> {{row.monto_base}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="tasa_de_distancia">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tasa de Distancia (KM) </th>
                                                <td mat-cell *matCellDef="let row" data-label="Tasa de Distancia (KM)"> {{row.tasa_distancia_km}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="indice_hora">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Índice Hora </th>
                                                <td mat-cell *matCellDef="let row" data-label="Índice Hora"> {{row.indice_hora}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="tipo_unidad">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Unidad </th>
                                                <td mat-cell *matCellDef="let row" data-label="Tipo de Unidad"> {{row.tipo_unidad}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="horario">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Horario </th>
                                                <td mat-cell *matCellDef="let row" data-label="Horario"> {{row.horario}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="tipo_servicio">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Servicio </th>
                                                <td mat-cell *matCellDef="let row" data-label="Tipo de Servicio"> {{row.tipo_servicio}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="acciones">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                                                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                                                    <button matTooltip="Eliminar parámetro" (click)="eliminarParametro(row.id)" mat-icon-button color="warn">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumnsFac"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumnsFac;"></tr>

                                            <tr class="mat-row" *matNoDataRow>
                                                <td class="mat-cell" colspan="2"></td>

                                                <td class="mat-cell" colspan="1" *ngIf="!loaderFac">No se encontraron registros "{{input.value}}"</td>
                                                <td class="mat-cell" colspan="1" *ngIf="loaderFac">
                                                    <mat-spinner diameter="40"></mat-spinner>
                                                </td>
                                                <td class="mat-cell" colspan="5"></td>
                                            </tr>

                                        </table>

                                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="LOGS">
                      <div class="row padding-content">
                        <mat-form-field class="vex_soluciones_input_search">
                          <mat-label>Buscador</mat-label>
                          <input matInput (keyup)="buscarLog($event)" placeholder="Buscar" #buscador>
                          <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>

                        <table mat-table [dataSource]="dataSource2" class="mat-elevation-z0" matSort>

                            <ng-container matColumnDef="id">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="email">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> CORREO </th>
                                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                            </ng-container>

                            <ng-container matColumnDef="action">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> ACCIÓN </th>
                              <td mat-cell *matCellDef="let element"> {{element.action}} </td>
                            </ng-container>


                            <ng-container matColumnDef="date">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA </th>
                                <td mat-cell *matCellDef="let element"> {{element.created_at | date: 'MMM d, y, h:mm:ss a'}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

                            <tr class="mat-row">
                                <td class="mat-cell" colspan="4"></td>

                                <td class="mat-cell" colspan="1" *ngIf="!loader">No se encontraron registros</td>

                                <td class="mat-cell" colspan="1" *ngIf="loader">
                                    <mat-spinner diameter="40"></mat-spinner>
                                </td>

                                <td class="mat-cell" colspan="4"></td>
                            </tr>
                        </table>
                        <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                      </div>
                    </mat-tab>
                </mat-tab-group>
            </div>

        </div>
        <div class="col s1"></div>
    </div>
</section>
