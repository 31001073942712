export class Gasoline {
  fuel_station: string;
  amount: string;
  quantity: string;
  description: string;
  vehicle_id: string;
  paramedic_id: string;
  responsible: string;
  constructor(
  ){}
}
