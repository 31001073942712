import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PsychologistService } from 'src/app/services';
import { Location } from '@angular/common';
import { Psychologist } from 'src/app/models/psychologist';
import { MatDialog } from '@angular/material/dialog';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { environment } from '../../../../../../environments/environment';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-psicologo-editar',
  templateUrl: './psicologo-editar.component.html',
  styleUrls: ['./psicologo-editar.component.css']
})
export class PsicologoEditarComponent implements OnInit {

  data: any;
  psicologo: Psychologist;
  psicologoId: number;
  photoTemp: any;
  ineTemp: any;
  certificateTemp: any;
  cedulaTemp: any;
  loader: boolean = false;

  nombresFormControl = new FormControl('', [
    Validators.required,
  ])

  apePaternoFormControl = new FormControl('', [
    Validators.required,
  ])

  apeMaternoFormControl = new FormControl('', [
    Validators.required,
  ]);

  celFormControl = new FormControl('', [
    Validators.required,
  ])

  telFormControl = new FormControl('', [
    Validators.required,
  ])

  direccionFormControl = new FormControl('', [
    Validators.required,
  ])

  dateBirthFormControl = new FormControl('', [
    Validators.required,
  ])

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    private _psychologistService: PsychologistService,
    public location: Location,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.photoTemp = "/assets/images/admin/upload-image.png";
    this.ineTemp = "/assets/images/admin/upload-image.png";
    this.certificateTemp = "/assets/images/admin/upload-image.png";
    this.cedulaTemp = "/assets/images/admin/upload-image.png";
    this.psicologo = new Psychologist();
  }

  ngOnInit(): void {
    this.obtenerPsicologo();
  }

  onFileSelectedPhoto(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.photoTemp = reader.result;
      this.psicologo.photo = reader.result;
    };

  }

  verFoto() {

    if(this.photoTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.photoTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedIne(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.ineTemp = reader.result;
      this.psicologo.ine = reader.result;
    };

  }

  verIne() {

    if(this.ineTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.ineTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedCertificate(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.certificateTemp = reader.result;
      this.psicologo.certificate = reader.result;
    };

  }

  verCertificado() {

    if(this.certificateTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.certificateTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedCedula(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.cedulaTemp = reader.result;
      this.psicologo.cedula = reader.result;
    };

  }

  verCedula() {

    if(this.cedulaTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.cedulaTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  obtenerPsicologo() {
    this.activatedRoute.params.subscribe( params => {

      let id = params['id'];
      this.psicologoId = id;

      this._psychologistService.getPsychologist(id).subscribe(
        response => {
          console.log(response)
          this.psicologo.name = response.data.professional.user.name;
          this.psicologo.lastnamepat = response.data.professional.user.lastnamepat;
          this.psicologo.lastnamemat = response.data.professional.user.lastnamemat;
          this.psicologo.birth_date = response.data.professional.user.birth_date;
          this.psicologo.gender = response.data.professional.user.gender;
          this.psicologo.telephone = response.data.professional.user.telephone;
          this.psicologo.address = response.data.professional.user.address;
          this.psicologo.institution = response.data.institution;
          this.psicologo.phone = response.data.professional.user.phone;
          this.psicologo.photo = `${environment.sourceUrl}${response.data.professional.user.image}`;
          this.psicologo.ine = `${environment.sourceUrl}${response.data.professional.ine}`;
          this.psicologo.certificate = `${environment.sourceUrl}${response.data.certificate}`;
          this.psicologo.cedula = `${environment.sourceUrl}${response.data.cedula}`;
        },
        error => {
          this.openError('ERROR AL OBTENER DATOS DEL PSICOLOGO', 'Ok');
        }
      )

    });
  }

  editarPsicologo(f: NgForm) {
    this.loader = true;

    this._psychologistService.updatePsychologist(this.psicologoId, this.psicologo).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('DATOS DEL PSICOLOGO ACTUALIZADO');
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.loader = false;
        this.openError('ERROR AL ACTUALIZAR DATOS DEL PSICOLOGO', 'Ok');
      }
    )

  }

  volver() {
    this._router.navigate(['/usuario/profesional/psicologo']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
