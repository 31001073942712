<section>
    <vex-section-title title="ADMINISTRACIÓN" subtitle="EDITAR TAREA"></vex-section-title>

    <div class="vex_soluciones_content">
        <form #f="ngForm" ngNativeValidate (ngSubmit)="editarTarea(f)" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <h3>Datos</h3>
                <div class="col s12 m5 l5">
                    <mat-card>
                        <mat-form-field>
                            <mat-label>Tarea</mat-label>
                            <input [formControl]="nombreFormControl" [errorStateMatcher]="matcher"  matInput placeholder="Nombre" name="name" [(ngModel)]="tarea.name" required>
                            <mat-error *ngIf="nombreFormControl.hasError('required')">
                                Nombre <strong>Requerido</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <mat-label>Notas (opcional)</mat-label>
                            <textarea matInput placeholder="Notas" name="descripcion" [(ngModel)]="tarea.description"></textarea>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Fecha</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="tarea.date" name="fecha">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="legacy">
                            <mat-label>Hora (opcional)</mat-label>
                            <input matInput placeholder="Hora" type="time" [(ngModel)]="tarea.hour" name="hour">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>asignado a</mat-label>
                            <mat-select name="usuario" [(ngModel)]="tarea.user_id" required>
                              <mat-option *ngFor="let usuario of usuarios" [value]="usuario.user.id">
                                {{usuario.user.name}} {{usuario.user.lastnamemat}} {{usuario.user.lastnamepat}} <b>{{usuario.type}}</b>
                            </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Prioridad</mat-label>
                            <mat-select name="prioridad" [(ngModel)]="tarea.priority" required>
                                <mat-option value="critico">
                                    Crítico
                                </mat-option>
                                <mat-option value="alto">
                                    Alto
                                </mat-option>
                                <mat-option value="normal">
                                    Normal
                                </mat-option>
                                <mat-option value="bajo">
                                    Bajo
                                </mat-option>
                                <mat-option value="menor">
                                    Menor
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="row" *ngIf="!loader">
                            <div class="col s12">
                                <button mat-raised-button (click)="volver()" type="button">{{buttonName.BACK}}</button>
                                &nbsp;
                                <button mat-raised-button color="primary" type="submit">{{buttonName.UPDATE}}</button>
                            </div>
                        </div>

                        <div class="row" *ngIf="loader">
                            <mat-spinner diameter="40"></mat-spinner>
                        </div>
                    </mat-card>
                </div>

            </div>

        </form>

    </div>
</section>
