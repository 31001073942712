import { Component, OnInit } from '@angular/core';
import { NurseService, UserService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Nurse } from 'src/app/models/nurse';
import { environment } from '../../../../../../environments/environment';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Password } from 'src/app/admin/configuracion/configuracion.component';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { UploadImage } from 'src/app/models/upload_image';
import { Location } from '@angular/common';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-enfermero-ver',
  templateUrl: './enfermero-ver.component.html',
  styleUrls: ['./enfermero-ver.component.css']
})
export class EnfermeroVerComponent implements OnInit {

  hide = true;
  hideC = true;
  hideA = true;

  loaderUpdPwd = false;

  nurse: Nurse;
  nurseId: any;

  userId: any;

  password: Password;

  sourceUrl = environment.sourceUrl;

  uploadImage: UploadImage;
  tempImage: any;

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    private _nurseService: NurseService,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _router: Router,
    public dialog: MatDialog,
    public location: Location
  ) {
    this.nurse = new Nurse();
    this.password = new Password('', '', '');
    this.uploadImage = new UploadImage();
  }

  ngOnInit(): void {
    this.obtenerEnfermero();
  }

  obtenerEnfermero() {
    this._activatedRoute.params.subscribe(
      params => {
        this.nurseId = params['id'];

        this._nurseService.getNurse(this.nurseId).subscribe(
          response => {
            console.log(response)
            this.nurse.name = response.data.professional.user.name;
            this.nurse.lastnamepat = `${response.data.professional.user.lastnamepat}`;
            this.nurse.email = response.data.professional.user.email;
            this.nurse.telephone = response.data.professional.user.telephone;
            this.nurse.birth_date = response.data.professional.user.birth_date;
            this.nurse.address = response.data.professional.user.address;
            this.userId = response.data.professional.user.id;
            this.nurse.photo = `${environment.sourceUrl}${response.data.professional.photo}`;
            this.nurse.image = response.data.professional.user.image;
            this.tempImage = response.data.professional.user.image;
            this.nurse.cedula = response.data.cedula;
            this.nurse.title = response.data.title;
            this.nurse.institution = response.data.institution;
            this.nurse.date_admission = response.data.professional.created_at;
            this.nurse.ine = response.data.professional.ine;
            this.nurse.status = response.data.active;

            console.log(this.nurse.photo)
          },
          error => {
            this.openError('Enfermero no encontrado', 'Ok');
          }
        )
      }
    )
  }

  actualizarContrasenia(f: NgForm) {
    this.loaderUpdPwd = true;
    this._userService.updatePassword(this.userId, this.password).subscribe(
      response => {
        this.loaderUpdPwd = false;
        if(response.code == 404) {
          this.openError('Error al actualizar contraseña', 'Ok');
        }else {
          f.resetForm();
          this.mostrarExitoDialog('Contraseña actualizada');
        }
      },
      error => {
        this.loaderUpdPwd = false;
        this.openError('Error al actualizar contraseña', 'Ok');
      }
    )
  }

  subirImagen(file: File): void {
    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.uploadImage.image = reader.result;
      this.tempImage = reader.result;
    };
  }

  guardarImagen() {

    this._userService.uploadImage(this.userId, this.uploadImage).subscribe(
      response => {
        console.log('response', response);
        console.log('response', response.data.image)
        this.tempImage = response.data.image;
        this.uploadImage.image = null;
        this.nurse.image = response.data.image;

        this.mostrarExitoDialog('Imagen actualizada')

      },
      error => {
        console.log('error', error);
      }
    )
  }

  verDocumento(documento) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${documento}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  cancelar() {
    this.tempImage = this.nurse.image;
    this.uploadImage.image = null;
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  volver() {
    this._router.navigate(['/usuario/profesional/enfermero']);
  }
}
