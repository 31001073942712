<h1 mat-dialog-title>DETALLE DE ATENCION</h1>
<div mat-dialog-content>
    <h3>Servicio:</h3>
    <p>{{data.props.service.name}}</p>

    <h3>Servicio Adicional:</h3>
    <p>{{data.props.aditional_service}}</p>

    <h3>Detalle:</h3>
    <p>{{data.props.detail}}</p>

    <h3>Paciente:</h3>
    <p>{{data.props.patient.user.name}} {{data.props.patient.user.lastnamepat}} {{data.props.patient.user.lastnamemat}}</p>

    <h3>Paramédico:</h3>
    <p>{{data.props.paramedic.user.name}} {{data.props.paramedic.user.lastnamepat}} {{data.props.paramedic.user.lastnamemat}}</p>

    <h3>Fecha y Hora:</h3>
    <p>{{data.props.date}} {{data.props.hour}}</p>
    
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</div>