<section>
    <vex-section-title title="ADMINISTRACIÓN" subtitle="NOTIFICACIONES"></vex-section-title>

    <div class="vex_soluciones_content">
      <div align="end">
        <mat-form-field>
          <mat-label>Buscador</mat-label>
          <input matInput (keyup)="buscarNotificacion($event)" placeholder="Buscar">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
        <mat-card class="mat-elevation-z0 sc_card_more_options">
          Todas las notificaciones
          <button class="sc_more_options" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
              <button
                  mat-menu-item
                  class="sc_more_options"
                  color="primary"
                  (click)="exporter.exportTable('xlsx', {fileName:'notificaciones'})">
                  <mat-icon>cloud_download</mat-icon>
                  <span>Exportar a Excel</span>
              </button>

          </mat-menu>
      </mat-card>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort matTableExporter #exporter="matTableExporter">

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                <td mat-cell *matCellDef="let element">
                    <button (click)="eliminarNotificacion(element.identifier)" mat-icon-button color="warn" class="vex_soluciones_btn_delete">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="identifier">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let element"> {{element.identifier}} </td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> TITULO </th>
              <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>

            <ng-container matColumnDef="body">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CONTENIDO </th>
              <td mat-cell *matCellDef="let element"> {{element.body}} </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA </th>
              <td mat-cell *matCellDef="let element"> {{element.date | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="pin-size-th"> ACCIONES </th>
                <td mat-cell *matCellDef="let element">
                  <!-- <button color="primary" mat-icon-button>
                    <mat-icon>visibility</mat-icon>
                  </button>
                  &nbsp; -->
                  <button color="primary" mat-icon-button>
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="6">No se encontraron registros.</td>
            </tr>
        </table>
        <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

    </div>

</section>
