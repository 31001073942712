import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { RadiologistService } from 'src/app/services';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { environment } from '../../../../../../environments/environment';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-radiologo',
  templateUrl: './radiologo.component.html',
  styleUrls: ['./radiologo.component.css']
})
export class RadiologoComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'name', 'email', 'phone','action'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  srcUrl: any = environment.sourceUrl;

  loader: boolean = true;

  radiologos$: Subscription;
  deleteRadiologo$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _radiologistService: RadiologistService
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.obtenerRadiologos()
  }

  buscarRadiologo(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerRadiologos() {
    this.loader = true;
    this.radiologos$ = this._radiologistService.getRadiologists().subscribe(
      response => {
        this.dataSource = fillTable(response.data.reverse(), this.paginator, this.sort)

        this.loader = false;
      },
      error => {
        this.loader = false;
      }
    )
  }

  eliminarRadiologo(){
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este profesional radiologo?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {



      }
    })
  }

  verFoto(foto) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
      data: `${environment.sourceUrl}${foto}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })


  }

  verIne(ine) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${ine}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })


  }

  verCedula(cedula) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${cedula}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  verCertificado(certificado) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${certificado}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  cambiarActivacion(element) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea cambiar la activación del profesional radiólogo?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this.deleteRadiologo$ = this._radiologistService.deleteRadiologist(element.identifier, false).subscribe(
          response => {
            this.obtenerRadiologos();
          },
          error => {
            this.obtenerRadiologos();
            this.mostrarErrorDialog('Error en la activación');
          }
        )

      } else {
        this.obtenerRadiologos();
      }
    })
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  ngOnDestroy(): void {
    if(this.radiologos$) this.radiologos$.unsubscribe();
    if(this.deleteRadiologo$) this.deleteRadiologo$.unsubscribe();
  }
}
