<section>
    <vex-section-title title="ADMINISTRACIÓN" subtitle="GESTIÓN DE CUPONES"></vex-section-title>

    <div class="vex_soluciones_content">
        <div align="end">
            <mat-form-field>
              <mat-label>Buscador</mat-label>
              <input matInput (keyup)="buscarCupon($event)" placeholder="Buscar">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            &nbsp;
            <button mat-raised-button color="primary" [routerLink]="['/servicios/cupones-nuevo']">
              <mat-icon>add</mat-icon>
              Agregar
            </button>
        </div>

        <mat-card class="mat-elevation-z0 sc_card_more_options">
            Todos los cupones
            <button class="sc_more_options" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    class="sc_more_options"
                    color="primary"
                    (click)="exporter.exportTable('xlsx', {fileName:'cupones'})">
                    <mat-icon>cloud_download</mat-icon>
                    <span>Exportar a Excel</span>
                </button>

            </mat-menu>
        </mat-card>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort matTableExporter #exporter="matTableExporter">

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                <td mat-cell *matCellDef="let element">
                    <button (click)="eliminarCupon(element.identifier)" mat-icon-button color="warn">
                        <mat-icon>delete</mat-icon>
                    </button>

                </td>
            </ng-container>

            <ng-container matColumnDef="identifier">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let element"> {{element.identifier}} </td>
            </ng-container>

            <ng-container matColumnDef="code">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CODIGO </th>
              <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <ng-container matColumnDef="discount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> DESCUENTO </th>
              <td mat-cell *matCellDef="let element"> {{element.discount}} </td>
            </ng-container>

            <ng-container matColumnDef="date_in">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA DE INICIO </th>
              <td mat-cell *matCellDef="let element"> {{element.date_in | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="date_out">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA DE FINALIZACION </th>
                <td mat-cell *matCellDef="let element"> {{element.date_out | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CANTIDAD </th>
                <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ESTADO </th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>

            <ng-container matColumnDef="activacion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ACTIVACION </th>
                <td mat-cell *matCellDef="let element">
                    <mat-slide-toggle color="primary" [checked]="element.status == 'vigente'"></mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="pin-size-th"> ACCIONES </th>
                <td mat-cell *matCellDef="let element">
                    <!-- <button color="primary" mat-icon-button>
                        <mat-icon>visibility</mat-icon>
                    </button>
                    &nbsp; -->
                    <button color="primary" [routerLink]="['/servicios/cupones-editar', element.identifier]" mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>
                    &nbsp;
                    <button color="primary" mat-icon-button>
                        <mat-icon>share</mat-icon>
                    </button>

                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4"></td>

                <td class="mat-cell" colspan="1" *ngIf="!loader">No se encontraron registros</td>

                <td class="mat-cell" colspan="1" *ngIf="loader">
                    <mat-spinner diameter="40"></mat-spinner>
                </td>

                <td class="mat-cell" colspan="4"></td>
            </tr>
        </table>
        <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

    </div>

</section>
