import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RadiologistService } from 'src/app/services';
import { Location } from '@angular/common';
import { Radiologist } from 'src/app/models/radiologist';
import { MatDialog } from '@angular/material/dialog';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { environment } from '../../../../../../environments/environment';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { Validators, FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-radiologo-editar',
  templateUrl: './radiologo-editar.component.html',
  styleUrls: ['./radiologo-editar.component.css']
})
export class RadiologoEditarComponent implements OnInit {

  data: any;
  radiologo: Radiologist;
  radiologoId: number;
  photoTemp: any;
  ineTemp: any;
  certificateTemp: any;
  cedulaTemp: any;
  loader: boolean = false;



  nombresFormControl = new FormControl('', [
    Validators.required,
  ])

  apePaternoFormControl = new FormControl('', [
    Validators.required,
  ])

  apeMaternoFormControl = new FormControl('', [
    Validators.required,
  ]);

  celFormControl = new FormControl('', [
    Validators.required,
  ])

  telFormControl = new FormControl('', [
    Validators.required,
  ])

  direccionFormControl = new FormControl('', [
    Validators.required,
  ])

  dateBirthFormControl = new FormControl('', [
    Validators.required,
  ])

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    private _radiologistService: RadiologistService,
    public location: Location,
    private _router: Router,
    private _snackBar: MatSnackBar
    ) {
    this.photoTemp = "/assets/images/admin/upload-image.png";
    this.ineTemp = "/assets/images/admin/upload-image.png";
    this.certificateTemp = "/assets/images/admin/upload-image.png";
    this.cedulaTemp = "/assets/images/admin/upload-image.png";
    this.radiologo = new Radiologist();
  }

  ngOnInit(): void {
    this.obtenerRadiologo();
  }

  onFileSelectedPhoto(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.photoTemp = reader.result;
      this.radiologo.photo = reader.result;
    };

  }

  verFoto() {

    if(this.photoTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.photoTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedIne(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.ineTemp = reader.result;
      this.radiologo.ine = reader.result;
    };

  }

  verIne() {

    if(this.ineTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.ineTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedCertificate(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.certificateTemp = reader.result;
      this.radiologo.certificate = reader.result;
    };

  }

  verCertificado() {

    if(this.certificateTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.certificateTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  onFileSelectedCedula(file: File): void {

    let reader = new FileReader;
    let urlImageTemp = reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.cedulaTemp = reader.result;
      this.radiologo.cedula = reader.result;
    };

  }

  verCedula() {

    if(this.cedulaTemp == '/assets/images/admin/upload-image.png') {

    } else {
      const dialogRef = this.dialog.open(ShowImageComponent, {
        width: 'auto',
        data: this.cedulaTemp
      })

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        }
      })
    }

  }

  obtenerRadiologo() {
    this.activatedRoute.params.subscribe( params => {

      let id = params['id'];
      this.radiologoId = id;

      this._radiologistService.getRadiologist(id).subscribe(
        response => {
          console.log(response)
          this.radiologo.name = response.data.professional.user.name;
          this.radiologo.lastnamepat = response.data.professional.user.lastnamepat;
          this.radiologo.lastnamemat = response.data.professional.user.lastnamemat;
          this.radiologo.birth_date = response.data.professional.user.birth_date;
          this.radiologo.gender = response.data.professional.user.gender;
          this.radiologo.telephone = response.data.professional.user.telephone;
          this.radiologo.address = response.data.professional.user.address;
          this.radiologo.institution = response.data.institution;
          this.radiologo.phone = response.data.professional.user.phone;
          this.radiologo.photo = `${environment.sourceUrl}${response.data.professional.user.image}`;
          this.radiologo.ine = `${environment.sourceUrl}${response.data.professional.ine}`;
          this.radiologo.certificate = `${environment.sourceUrl}${response.data.certificate}`;
          this.radiologo.cedula = `${environment.sourceUrl}${response.data.cedula}`;
        },
        error => {
          console.log(error)
        }
      )

    });
  }

  editarRadiologo(f: NgForm) {
    this.loader = true;

    this._radiologistService.updateRadiologist(this.radiologoId, this.radiologo).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('DATOS DEL RADIOLOGO ACTUALIZADO');
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.loader = false;
        this.openError('ERRO AL ACTUALIZAR DATOS DEL RADIOLOGO', 'Ok');
      }
    )

  }

  volver() {
    this._router.navigate(['/usuario/profesional/radiologo']);
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
