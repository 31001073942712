import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inbox-nuevo',
  templateUrl: './inbox-nuevo.component.html',
  styleUrls: ['./inbox-nuevo.component.css']
})
export class InboxNuevoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
