<section>

    <vex-section-title title="ADMINISTRACIÓN" subtitle="HISTORIAL DE ATENCIONES"></vex-section-title>
    <div class="vex_soluciones_content">
        <div align="end">
            <mat-form-field>
              <mat-label>Buscador</mat-label>
              <input matInput (keyup)="buscarHistorial($event)" placeholder="Buscar">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            &nbsp;
            <button mat-raised-button color="primary" routerLink="/nueva-atencion">
              <mat-icon>add</mat-icon>
              Agregar
            </button>
        </div>

        <mat-card class="mat-elevation-z0 sc_card_more_options">
            Todas las atenciones
            <button class="sc_more_options" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    class="sc_more_options"
                    color="primary"
                    (click)="exporter.exportTable('xlsx', {fileName:'atenciones'})">
                    <mat-icon>cloud_download</mat-icon>
                    <span>Exportar a Excel</span>
                </button>

            </mat-menu>
        </mat-card>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort matTableExporter #exporter="matTableExporter">

            <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

            <ng-container matColumnDef="identifier">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let element"> {{element.identifier}} </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA </th>
              <td mat-cell *matCellDef="let element"> {{element.date | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="patient_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> PACIENTE </th>
              <td mat-cell *matCellDef="let element"> {{element.patient_name}} </td>
            </ng-container>

            <ng-container matColumnDef="company">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> EMPRESA </th>
              <td mat-cell *matCellDef="let element"> {{element.company?.name}} </td>
            </ng-container>

            <ng-container matColumnDef="professional_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> PARAMÉDICO </th>
              <td mat-cell *matCellDef="let element"> {{element.professional_name}} - {{element.paramedic.type}}</td>
            </ng-container>

            <ng-container matColumnDef="vehicle_identifier">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> VEHÍCULO </th>
                <td mat-cell *matCellDef="let element">
                    <div class="vex-content-column-unidad">
                      <mat-icon aria-hidden="false" aria-label="Example home icon">time_to_leave</mat-icon>
                      &nbsp;
                      <span>PIN00{{element.vehicle.id}}</span>

                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="service_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> SERVICIO </th>
                <td mat-cell *matCellDef="let element"> {{element.service_name}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ESTADO </th>
                <td mat-cell *matCellDef="let element">
                    <mat-chip-list aria-label="Fish selection">
                        <mat-chip *ngIf="element.status == 'reservado'" selected class="attention-status-reserved">{{element.status}}</mat-chip>
                        <mat-chip *ngIf="element.status == 'en_progreso'" selected class="attention-status-on-way">{{element.status}}</mat-chip>
                        <mat-chip *ngIf="element.status == 'finalizado'" selected class="attention-status-finalized">{{element.status}}</mat-chip>
                        <mat-chip *ngIf="element.status == 'cancelado'" selected class="attention-status-finalized">{{element.status}}</mat-chip>
                    </mat-chip-list>
                </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="pin-size-th"> ACCIONES </th>
                <td mat-cell *matCellDef="let element">
                    <button color="primary" mat-icon-button [routerLink]="['/ver-atencion', element.identifier]">
                        <mat-icon>visibility</mat-icon>
                    </button>
                    &nbsp;
                    <button color="primary" [routerLink]="['/editar-atencion', element.identifier]" mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8">No se encontraron registros.</td>
            </tr>
        </table>
        <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

    </div>

</section>
