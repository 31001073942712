import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { VehicleService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-unidades',
  templateUrl: './unidades.component.html',
  styleUrls: ['./unidades.component.css']
})
export class UnidadesComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'identifier', 'type_equipment', 'plete', 'estado', 'status', 'asignado_a', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  loader: boolean = true;

  unidades$: Subscription;
  deleteUnidad$: Subscription;

  constructor(
    private _vehicleService: VehicleService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerUnidades();
  }

  obtenerUnidades() {
    this.loader = true;
    this.unidades$ = this._vehicleService.getVehicles().subscribe(
      response => {
        this.loader = false;
        console.log('unidades', response)
        this.dataSource = fillTable(response.data, this.paginator, this.sort);

      },
      error => {
        this.loader = false;
        this.openError("Error al obtener unidades", 'OK');
      }
    )

  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  buscarUnidades(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  eliminarUnidad(id) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea cambiar activacion de la unidad?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this.deleteUnidad$ = this._vehicleService.deleteVehicle(id, false).subscribe(
          response => {
            this.mostrarExitoDialog('Cambio de activación exitoso');
            this.obtenerUnidades();
          },
          error => {
            this.mostrarErrorDialog('Error al cambiar activación');
          }
        )

      } else {
        this.obtenerUnidades();
      }

    })
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  ngOnDestroy(): void {
    if(this.unidades$) this.unidades$.unsubscribe();

    if(this.deleteUnidad$) this.deleteUnidad$.unsubscribe();
  }
}
