import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { CouponService } from 'src/app/services';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-gestion-cupones',
  templateUrl: './gestion-cupones.component.html',
  styleUrls: ['./gestion-cupones.component.css']
})
export class GestionCuponesComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'identifier', 'code', 'discount', 'date_in', 'date_out', 'quantity', 'status', 'activacion', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  loader: boolean = true;

  cupones$: Subscription;
  deleteCupon$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _couponService: CouponService
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerCupones();

  }

  buscarCupon(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  eliminarCupon(id) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este cupón?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {
        this.deleteCupon$ = this._couponService.deleteCoupon(id).subscribe(
          response => {
            this.mostrarExitoDialog('Cupon eliminado')
            this.obtenerCupones();
          },
          error => {
            this.mostrarErrorDialog('Error al eliminar cupon')
          }
        )
      }
    })
  }

  obtenerCupones() {
    this.loader = true;
    this.cupones$ = this._couponService.getCoupons().subscribe(
      response => {
        this.loader = false;

        this.dataSource = fillTable(response.data, this.paginator, this.sort);

      },
      error => {
        this.loader = false;
        this.mostrarErrorDialog('NO SE ENCONTRARON CUPONES');
      }
    )
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  ngOnDestroy(): void {
    if(this.cupones$) this.cupones$.unsubscribe();

    if(this.deleteCupon$) this.deleteCupon$.unsubscribe();
  }
}
