import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationComponent } from 'src/app/shareds/dialogs/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { PsychologistService } from 'src/app/services';
import { ShowImageComponent } from 'src/app/shareds/dialogs/show-image/show-image.component';
import { ErrorComponent } from 'src/app/shareds/dialogs/error/error.component';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { environment } from '../../../../../../environments/environment';
import { Subscription } from 'rxjs';
import { fillTable, searchInTable } from 'src/app/utils/tables/tables';

@Component({
  selector: 'app-psicologo',
  templateUrl: './psicologo.component.html',
  styleUrls: ['./psicologo.component.css']
})
export class PsicologoComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['delete', 'name', 'email', 'phone', 'action'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  srcUrl: any;

  loader: boolean = true;

  psicologos$: Subscription;
  deletePsicologo$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _psychologistService: PsychologistService
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort)
    this.srcUrl = environment.sourceUrl;

  }

  ngOnInit(): void {
    this.obtenerPsicologos();
  }

  buscarPsicologo(event: Event) {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerPsicologos() {
    this.loader = true;
    this.psicologos$ = this._psychologistService.getPsychologists().subscribe(
      response => {
        this.dataSource = fillTable(response.data.reverse(), this.paginator, this.sort);

        this.loader = false;
      },
      error => {
        this.loader = false;
      }
    )
  }

  eliminarPsicologo(){
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea eliminar este profesional psicologo?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {



      }
    })
  }

  verFoto(foto) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${foto}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  verIne(ine) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${ine}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  verCedula(cedula) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${cedula}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  verCertificado(certificado) {

    const dialogRef = this.dialog.open(ShowImageComponent, {
      width: 'auto',
      data: `${environment.sourceUrl}${certificado}`
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })

  }

  cambiarActivacion(element) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: '¿Desea cambiar la activación del profesional psicólogo?',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'true') {

        this.deletePsicologo$ = this._psychologistService.deletePsychologist(element.identifier, false).subscribe(
          response => {
            this.obtenerPsicologos();
          },
          error => {
            this.obtenerPsicologos();
            this.mostrarErrorDialog('Error en la activación');
          }
        )

      } else {
        this.obtenerPsicologos();
      }
    })
  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  mostrarErrorDialog(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  ngOnDestroy(): void {
    if(this.psicologos$) this.psicologos$.unsubscribe();

    if(this.deletePsicologo$) this.deletePsicologo$.unsubscribe();
  }
}
