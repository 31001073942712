import { Component, OnInit } from '@angular/core';
import { Role } from 'src/app/models/role';
import { Location } from '@angular/common';
import { RoleService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { Router } from '@angular/router';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SidebarService } from 'src/app/services/shared/sidebar.service';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-roles-nuevo',
  templateUrl: './roles-nuevo.component.html',
  styleUrls: ['./roles-nuevo.component.css']
})
export class RolesNuevoComponent implements OnInit {

  role: Role = new Role();
  permisos = [true, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
  loader: boolean = false;

  rolFormControl = new FormControl('', [
    Validators.required,
  ]);

  descripcionFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    private _roleService: RoleService,
    public location: Location,
    public dialog: MatDialog,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _sidebarService: SidebarService
  ) {
    this.role.access = this._sidebarService.menuMasterAdmin;
  }

  ngOnInit(): void {
  }

  volver() {
    this._router.navigate(['/roles-y-permisos']);
  }

  registrarRol(f: NgForm) {

    console.log(this.role);
    this.loader = true;

    this.role.field = this.role.name.trim().replace(/\s/g, '').toLocaleLowerCase();

    this._roleService.saveRole(this.role).subscribe(
      response => {
        console.log('response', response)
        this.loader = false;
        this.mostrarExitoDialog('Nuevo Rol Registrado')
        setTimeout(() => {
          this.location.back();
        }, 1500);
      },
      error => {
        this.openError('Error al registrar rol', 'ok');
        console.log('error', error)
        this.loader = false;
      }
    )



  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._router.navigate(['/roles-y-permisos']);
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
