import { Component, OnInit, Inject } from '@angular/core';
import { Task } from 'src/app/models/task';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaskService } from 'src/app/services/task.service';
import { AdministrativeService } from 'src/app/services/administrative.service';
import { ProfessionalService } from 'src/app/services/professional.service';
import { SuccessComponent } from '../../success/success.component';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-task',
  templateUrl: './edit-task.component.html',
  styleUrls: ['./edit-task.component.css']
})
export class EditTaskComponent implements OnInit {

  tarea: Task;
  usuarios: any = [];
  tareaId: any;
  loader = false;

  constructor(
    public dialog: MatDialog,
    private _taskService: TaskService,
    private _administrativeService: AdministrativeService,
    private _professionalService: ProfessionalService,
    public dialogRef: MatDialogRef<EditTaskComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.tarea = new Task();
  }

  ngOnInit(): void {
    this.obtenerTarea();
  }

  obtenerTarea() {
    this._taskService.getTask(this.data).subscribe(
      response => {
        console.log('TAREA', response)
        this.tareaId = this.data;
        this.tarea.name = response.data.name;
        this.tarea.description = response.data.description;
        this.tarea.date = response.data.date;
        this.tarea.priority = response.data.priority;
        this.tarea.status = response.data.status;
        this.tarea.hour = response.data.hour;
        this.tarea.user_id = response.data.user.id;
        this.obtenerUsuarios();
      },
      error => {
        this.openError('Error al obtener tareas', 'Ok');
      }
    )
  }

  editarTarea(f: NgForm) {
    this.loader = true;
    this._taskService.updateTask(this.tareaId, this.tarea).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('Tarea actualizada');
        this.dialog.closeAll();
      },
      error => {
        this.loader = false;
        this.openError('Error al editar tarea', 'Ok');
        this.dialog.closeAll();

      }
    )

  }

  obtenerUsuarios() {
    this._administrativeService.getAdministratives().subscribe(
      administrativos => {
        this._professionalService.getProfessionals().subscribe(
          profesionales => {
            this.usuarios = administrativos.data.concat(profesionales.data)
            console.log('usaurios', this.usuarios)
          },
          error => {
            this.openError('No se encontraron usuarios', 'Ok');
          }
        )
      },
      error => {
        this.openError('No se encontraron usuarios', 'Ok');
      }
    )
  }

  mostrarExitoDialog(message) {
		const dialogRef = this.dialog.open(SuccessComponent, {
			width: '300px',
			data: message
		})

		dialogRef.afterClosed().subscribe(result => {
			if(result) {
			}
		})
	}

	openError(message: string, action: string) {
		this._snackBar.open(message, action, {
      duration: 4000,
		});
	}
}
