import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from 'src/app/shareds/dialogs/success/success.component';
import { TaskService, ProfessionalService, AdministrativeService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Task } from 'src/app/models/task';
import { Location } from '@angular/common';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/utils/error.matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUTTON_NAME, IButtonName } from 'src/app/data/constants/button-names.constant';

@Component({
  selector: 'app-editar-tarea',
  templateUrl: './editar-tarea.component.html',
  styleUrls: ['./editar-tarea.component.css']
})
export class EditarTareaComponent implements OnInit {

  tareaId: any;
  usuarios: any = [];
  tarea: Task;
  loader = false;

  nombreFormControl = new FormControl('', [
    Validators.required,
  ]);

  descripcionFormControl = new FormControl('', [
    Validators.required,
  ]);

  fechaFormControl = new FormControl('', [
    Validators.required,
  ]);

  tiempoRequeridoFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  buttonName: IButtonName = BUTTON_NAME;

  constructor(
    public dialog: MatDialog,
    public location: Location,
    private _taskService: TaskService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _professionalService: ProfessionalService,
    private _administrativeService: AdministrativeService,
    private _snackBar: MatSnackBar
    ) {
    this.tarea = new Task();
  }

  ngOnInit(): void {
    this.obtenerUsuarios();
  }

  obtenerUsuarios() {
    this._administrativeService.getAdministratives().subscribe(
      administrativos => {
        this._professionalService.getProfessionals().subscribe(
          profesionales => {
            this.usuarios = administrativos.data.concat(profesionales.data)
            console.log('usaurios', this.usuarios)
            this.obtenerTarea();
          },
          error => {
            this.openError('No se encontraron usuarios', 'Ok');
          }
        )
      },
      error => {
        this.openError('No se encontraron usuarios', 'Ok');
      }
    )
  }

  obtenerTarea() {
    this._activatedRoute.params.subscribe(
      params => {
        this.tareaId = params['id'];
        this._taskService.getTask(this.tareaId).subscribe(
          response => {
            this.tarea.date = response.data.date;
            this.tarea.description = response.data.description;
            this.tarea.name = response.data.name;
            this.tarea.priority = response.data.priority;
            this.tarea.status = response.data.status;
            this.tarea.hour = response.data.hour;
            this.tarea.user_id = response.data.user.id;
            console.log('USER ID', this.tarea.user_id);
          },
          error => {
            this.openError('No se encontro la tarea', 'Ok');
          }
        )
      }
    )
  }

  editarTarea(f: NgForm) {
    this.loader = true;
    this._taskService.updateTask(this.tareaId, this.tarea).subscribe(
      response => {
        this.loader = false;
        this.mostrarExitoDialog('Datos de la tarea actualizada.')
      },
      error => {
        this.loader = false;
        this.openError('Error al editar la tarea.', 'Ok')
      }
    )

  }

  mostrarExitoDialog(message) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '300px',
      data: message
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      }
    })
  }

  openError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  volver() {
    this._router.navigate(['/administrar-tareas']);
  }

}
